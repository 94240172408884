import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { space, position } from 'styled-system';

const FAIcon = styled(FontAwesomeIcon)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  color: ${({ theme, color }) => theme.colors[color] || color || theme.colors.icons};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${space};
  ${position};

  /* &:hover {
    transform: ${({ onClick }) => (onClick ? 'scale(1.05)' : 'none')};
  } */
`;

const Icon = React.forwardRef((props, ref) => <FAIcon ref={ref} {...props} />);

export default Icon;
