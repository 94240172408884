import { takeLatest, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import { sagaFunctionBuilder } from '../reduxUtils';
import { endpoints } from '../../config/endpoints';
import API from '../../services/API';
import { uploadFileToS3 } from '../../services/S3/generator';

import c from './user.constants';

import { userActions, applicationActions } from '../actions';
import { userSelectors, applicationSelectors } from '../selectors';

import { joinUrlWithParams } from '../../utils';

function* fetchUser({ user_id, params }) {
  const ENDPOINT = endpoints.user.show;

  return yield API()({
    url: joinUrlWithParams(ENDPOINT.url.replace(':user_id', user_id), params),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
  });
}

function* updateUser({ user, params }) {
  const ENDPOINT = endpoints.user.update;
  const userId = yield select(userSelectors.userId);

  return yield API()({
    url: joinUrlWithParams(ENDPOINT.url.replace(':user_id', userId), params),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
    data: { user },
  });
}

function* showCompany({ companyId, params }) {
  const ENDPOINT = endpoints.managers.companies.show;

  return yield API()({
    url: joinUrlWithParams(ENDPOINT.url.replace(':id', companyId), params),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
  });
}

function* updateCompany({ company, params }) {
  const ENDPOINT = endpoints.managers.companies.update;

  return yield API()({
    url: joinUrlWithParams(ENDPOINT.url.replace(':id', (company || {}).id), params),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
    data: { company },
  });
}

function* enrollUser(user_id, application_questionnaire_token) {
  const ENDPOINT = endpoints.respondents.enrollments.create;

  return yield API()({
    url: joinUrlWithParams(ENDPOINT.url, `application_questionnaire_token=${application_questionnaire_token}`),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
    data: {
      enrollment: { user_id },
    },
  });
}

function* enrollUserRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const { data } = yield enrollUser(payload.user_id, payload.aq_tkn);
    return data.data;
  }, userActions.onFetchUserFail);
}

function* fetchUserRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const { data } = yield fetchUser(payload);

    const user = data.data;
    yield put(userActions.onFetchUserSuccess(user));

    // if (payload.aq_tkn) {
    //   console.log('SHOULD ENROLL USER');
    // }

    return user;
  }, userActions.onFetchUserFail);
}

function* updateUserRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const { user: userPayload, params } = payload;
    const { avatar, ...user } = userPayload;
    const userId = yield select(userSelectors.userId);

    // new avatar
    if (avatar instanceof File) {
      const oldAvatarUrl = yield select(userSelectors.attribute('avatar_url'));
      const avatar_url = yield uploadFileToS3(avatar, oldAvatarUrl);
      user.avatar_url = avatar_url;
    }

    const { data } = yield updateUser({ user: { id: userId, ...user }, params });
    const newUser = data.data;
    yield put(userActions.onUpdateUserSuccess(newUser));

    return data.data;
  }, userActions.onUpdateUserFail);
}

export function* setCurrentCompany({ payload }) {
  const { user } = payload;
  const persistUser = yield select(applicationSelectors.shouldPersistUser);

  const currentCompany = yield select(userSelectors.currentCompany);
  const firstCompany = get(user, 'companies_users.0.company');
  const isManager = Boolean(firstCompany);

  const userToCache = persistUser ? JSON.stringify(user) : null;
  localStorage.setItem(c.REDUX_NAMESPACE, userToCache);

  if (isManager) {
    yield put(applicationActions.toggleManagerView());

    if (!currentCompany && firstCompany) {
      yield put(userActions.setCurrentCompany(firstCompany));
    }
  }
}

function* toggleAllCompaniesView({ payload }) {
  const hasCompany = Boolean(payload.company);

  if (hasCompany) {
    yield put(applicationActions.disableAllCompaniesView());
  }

  else {
    yield put(applicationActions.enableAllCompaniesView());
  }
}

function* showCompanyRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const { data } = yield showCompany(payload);

    const company = data.data;
    yield put(userActions.onShowCompanySuccess(company));

    return company;
  }, userActions.onShowCompanyFail);
}

function* updateCompanyRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const { company: companyPayload, params } = payload;
    const { avatar, ...company } = companyPayload;
    const currentCompany = yield select(userSelectors.currentCompany);

    // new avatar
    if (avatar instanceof File) {
      const oldAvatarUrl = currentCompany.avatar_url;
      const avatar_url = yield uploadFileToS3(avatar, oldAvatarUrl);
      company.avatar_url = avatar_url;
    }

    const { data } = yield updateCompany({ company: { id: currentCompany.id, ...company }, params });
    yield put(userActions.onUpdateCompanySuccess(data.data));

    return data.data;
  }, userActions.onUpdateCompanyFail);
}

export function* setActiveLanguage({ payload }) {
  yield put(userActions.setActiveLanguage(payload));
}

export default function* userSaga() {
  yield takeLatest(c.FETCH_USER_REQUEST, fetchUserRequest);
  yield takeLatest(c.UPDATE_USER_REQUEST, updateUserRequest);
  yield takeLatest(c.ON_FETCH_USER_SUCCESS, setCurrentCompany);
  yield takeLatest(c.SET_ACTIVE_COMPANY, toggleAllCompaniesView);
  yield takeLatest(c.SHOW_COMPANY_REQUEST, showCompanyRequest);
  yield takeLatest(c.UPDATE_COMPANY_REQUEST, updateCompanyRequest);
  yield takeLatest(c.ENROLL_USER, enrollUserRequest);
}
