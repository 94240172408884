import React from 'react';
import { useIntl } from 'react-intl';
import routes from '../../i18n/routes/routes';

const withRoutes = (Component) => (props) => {
  const { locale } = useIntl();
  const localizedRoutes = routes[locale] || routes['pt-BR'];

  return <Component routes={localizedRoutes} {...props} />;
};

export default withRoutes;
