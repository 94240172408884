import { combineReducers } from 'redux';

import authConstants from './auth/auth.constants';
import authReducer from './auth/auth.reducer';

import applicationConstants from './application/application.constants';
import applicationReducer from './application/application.reducer';

import cachesConstants from './caches/caches.constants';
import cachesReducer from './caches/caches.reducer';

import userConstants from './user/user.constants';
import userReducer from './user/user.reducer';

const appReducer = combineReducers({
  [authConstants.REDUX_NAMESPACE]: authReducer,
  [applicationConstants.REDUX_NAMESPACE]: applicationReducer,
  [cachesConstants.REDUX_NAMESPACE]: cachesReducer,
  [userConstants.REDUX_NAMESPACE]: userReducer,
});

export default function rootReducer(state, action) {
  if (action.type === authConstants.LOGOUT_REQUEST) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}
