import { fork, all } from 'redux-saga/effects';
import auth from './auth/auth.saga';
import user from './user/user.saga';

export default function* rootSaga() {
  yield all([
    auth,
    user,
  ].map(fork));
}
