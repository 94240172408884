import constants from './application.constants';

const initialState = {
  loading: false,
  dialog: { visible: false },
  errorDialog: { visible: false },
  viewMode: constants.modes.respondent,
  isAllCompaniesView: false,
  application: {},
  persistUser: true,
};

const reducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
  case constants.TOGGLE_DIALOG: {
    return {
      ...state,
      dialog: {
        visible: true,
        ...payload,
      },
    };
  }

  case constants.SET_VIEW_MODE: {
    const { mode } = payload;

    return {
      ...state,
      viewMode: mode,
      ...mode === constants.modes.respondent && {
        isAllCompaniesView: false,
      },
    };
  }

  case constants.ENABLE_ALL_COMPANIES_VIEW:
    return {
      ...state,
      isAllCompaniesView: true,
    };

  case constants.DISABLE_ALL_COMPANIES_VIEW:
    return {
      ...state,
      isAllCompaniesView: false,
    };

  case constants.HIDE_DIALOG: {
    return {
      ...state,
      dialog: {
        ...initialState.dialog,
      },
    };
  }

  case constants.TOGGLE_ERROR_DIALOG: {
    return {
      ...state,
      errorDialog: {
        visible: true,
        ...payload,
      },
    };
  }

  case constants.HIDE_ERROR_DIALOG: {
    return {
      ...state,
      errorDialog: {
        ...initialState.errorDialog,
      },
    };
  }

  case constants.TOGGLE_LOADING:
    return {
      ...state,
      loading: true,
    };

  case constants.HIDE_LOADING:
    return {
      ...state,
      loading: false,
    };

  case constants.SAVE_APPLICATION: {
    return {
      ...state,
      application: payload,
    };
  }

  case constants.DISABLE_PERSIST_USER: {
    return {
      ...state,
      persistUser: false,
    };
  }

  default:
    return state;
  }
};

export default reducer;
