import enMessages from './messages-en-us';
import ptMessages from './messages-pt';
import ptBrMessages from './messages-ptbr';
import esMessages from './messages-es';
import esMxMessages from './messages-es-mx';

export default {
  pt: ptMessages,
  'pt-PT': ptMessages,
  'pt-BR': ptBrMessages,
  es: esMessages,
  'es-ES': esMessages,
  'es-419': esMxMessages,
  'es-AR': esMxMessages,
  'es-BO': esMxMessages,
  'es-CL': esMxMessages,
  'es-CO': esMxMessages,
  'es-CR': esMxMessages,
  'es-DO': esMxMessages,
  'es-EC': esMxMessages,
  'es-GT': esMxMessages,
  'es-HN': esMxMessages,
  'es-MX': esMxMessages,
  'es-NI': esMxMessages,
  'es-PA': esMxMessages,
  'es-PE': esMxMessages,
  'es-PR': esMxMessages,
  'es-PY': esMxMessages,
  'es-SV': esMxMessages,
  'es-US': esMxMessages,
  'es-UY': esMxMessages,
  'es-VE': esMxMessages,
  en: enMessages,
  'en-US': enMessages,
  'en-029': enMessages,
  'en-AU': enMessages,
  'en-BZ': enMessages,
  'en-CA': enMessages,
  'en-GB': enMessages,
  'en-IE': enMessages,
  'en-IN': enMessages,
  'en-JM': enMessages,
  'en-MY': enMessages,
  'en-NZ': enMessages,
  'en-PH': enMessages,
  'en-SG': enMessages,
  'en-TT': enMessages,
  'en-ZA': enMessages,
  'en-ZW': enMessages,
};
