// rotas publicas
const demos = '/demos';
const resetPassword = '/reset-password';
const signupFromEmail = '/finish-signup';
const publicReport = '/report/:id(\\d+)';

// Acesso de impressão para relatório Cba one page
const reportCbaOnePage = '/report-cba-one-page/:token';
const reportCbaOnePageRating = '/report-cba-one-page-rating/:token';
const reportCbaFull = '/report-cba-full/:token';
const reportCbaEssential = '/report-cba-essential/:token';
const reportCbaV2 = '/report-cba-v2';
const reportCla = '/report-cla';

// cadastro
const start = '/start';
const login = `${start}/login`;
const signup = `${start}/signup`;
const unauthenticatedContact = `${start}/contact-us`;
const recoverEmail = `${start}/recover-email`;
const recoverPassword = `${start}/recover-password`;

// rotas privadas
const home = '/home';
const myProfile = `${home}/my-profile`;
const editMyProfile = `${home}/my-profile/edit`;
const support = `${home}/contact-us`;
const termsConfirmation = `${home}/terms-of-use`;

const enroll = '/enroll/:token';

const messages = '/messages';
// rotas de mensagens
const messagesRoutes = {
  root: `${messages}`,
  thankYou: `${messages}/thank-you`,
  expired: `${messages}/expired`,
  notStarted: `${messages}/not-started`,
  notAvailable: `${messages}/not-available`,
  error404: `${messages}/404`,
  error403: `${messages}/403`,
  error500: `${messages}/500`,
  invalidLink: `${messages}/invalid-link`,
  lastReport: `${messages}/last-report`,
  thankYouRegister: `${messages}/thank-you-register`,
};

// papeis
const respondentScope = 'respondent';
const respondentRouteRoot = `${home}/${respondentScope}`;
const respondentIndividualAssessments = `${respondentRouteRoot}/individual-assessments`;

const respondent = {
  root: respondentRouteRoot,

  role: `${respondentRouteRoot}/role`,

  individualAssessments: respondentIndividualAssessments,
  individualAssessmentsOthers: `${respondentIndividualAssessments}/others`,
  individualAssessmentsSelf: `${respondentIndividualAssessments}/self-assessments`,

  organizationalAssessments: `${respondentRouteRoot}/organizational-assessments`,

  questionnaire: `${respondentRouteRoot}/questionnaire/:id(\\d+)`,
  _questionnaire: `${respondentRouteRoot}/questionnaire/:id`,

  report: `${respondentRouteRoot}/questionnaire/:id/report`,
  _report: `${respondentRouteRoot}/questionnaire/:id/report`,

  monitorAssessment: `${respondentRouteRoot}/monitor-assessment/:id(\\d+)/:questionnaire_title?`,
  _monitorAssessment: `${respondentRouteRoot}/monitor-assessment/:id`,
};

const managerScope = 'manager';
const managerRouteRoot = `${home}/${managerScope}`;

const organizationProfile = `${managerRouteRoot}/organization-profile`;

const managerRespondentsRoot = `${managerRouteRoot}/respondents`;
const managerOrganizationalAssessments = `${managerRouteRoot}/organizational-assessments`;
const managerIndividualAssessments = `${managerRouteRoot}/individual-assessments`;
const managerSelfAssessments = `${managerRouteRoot}/self-assessment`;

const manager = {
  root: managerRouteRoot,
  // individualAssessments: `${managerRouteRoot}/individual-assessments`,
  allCompaniesSufix: 'all',

  application: `${managerRouteRoot}/application/:id(\\d+)/:questionnaire_title?`,
  _application: `${managerRouteRoot}/application/:id`,

  report: `${managerRouteRoot}/questionnaire/:id/report`,
  _report: `${managerRouteRoot}/questionnaire/:id/report`,

  reportCbaOnePage: `${managerRouteRoot}${reportCbaOnePage}`,
  reportCbaOnePageRating: `${managerRouteRoot}${reportCbaOnePageRating}`,

  users: `${managerRouteRoot}/users`,
  managers: `${managerRouteRoot}/managers`,

  organizationProfile,
  organizationInformations: `${organizationProfile}/informations`,
  editOrganizationInformations: `${organizationProfile}/informations/edit`,
  organizationIndicators: `${organizationProfile}/indicators`,
  editOrganizationIndicators: `${organizationProfile}/indicators/edit`,

  respondents: managerRespondentsRoot,
  addRespondents: `${managerRespondentsRoot}/add`,

  addRespondentsList: `${home}/${managerScope}/add-list/:id(\\d+)/:questionnaire_title?`,
  _addRespondentsList: `${home}/${managerScope}/add-list/:id/:questionnaire_title?`,

  addRespondentsNew: `${home}/${managerScope}/add-new/:id(\\d+)/:questionnaire_title?`,
  _addRespondentsNew: `${home}/${managerScope}/add-new/:id/:questionnaire_title?`,

  editRespondent: `${managerRespondentsRoot}/:id(\\d+)/edit`,
  _editRespondent: `${managerRespondentsRoot}/:id/edit`,

  organizationalAssessments: managerOrganizationalAssessments,
  organizationalAssessmentsConfig: `${managerOrganizationalAssessments}/config`,
  organizationalAssessmentsRelease: `${managerOrganizationalAssessments}/release`,
  organizationalAssessmentsMonitor: `${managerOrganizationalAssessments}/monitor`,
  organizationalSeeAllCompanies: `${managerOrganizationalAssessments}/see-all-companies`,

  individualAssessments: managerIndividualAssessments,
  individualAssessmentsConfig: `${managerIndividualAssessments}/config`,
  individualAssessmentsRelease: `${managerIndividualAssessments}/release`,
  individualAssessmentsMonitor: `${managerIndividualAssessments}/monitor`,
  individualSeeAllCompanies: `${managerIndividualAssessments}/see-all-companies`,

  selfAssessments: managerSelfAssessments,
  selfAssessmentsConfig: `${managerSelfAssessments}/config`,
  selfAssessmentsRelease: `${managerSelfAssessments}/release`,
  selfAssessmentsMonitor: `${managerSelfAssessments}/monitor`,
  selfSeeAllCompanies: `${managerSelfAssessments}/see-all-companies`,

  configAssessment: `${home}/${managerScope}/config-assessment/:id(\\d+)/:questionnaire_title?`,
  _configAssessment: `${home}/${managerScope}/config-assessment/:id`,
  organizationDashboard: `${home}/${managerScope}/organization-dashboard/:id(\\d+)/:questionnaire_title?`,
  _organizationDashboard: `${home}/${managerScope}/organization-dashboard/:id`,
  individualDashboard: `${home}/${managerScope}/individual-dashboard/:token/:questionnaire_title?`,
  _individualDashboard: `${home}/${managerScope}/individual-dashboard/:token`,
  selfAssessmentDashboard: `${home}/${managerScope}/self-assessment-dashboard/:id(\\d+)/:questionnaire_title?`,
  _selfAssessmentDashboard: `${home}/${managerScope}/self-assessment-dashboard/:id`,

  claWebReport: `${home}/${managerScope}/cla-relatorio-web/:token`,
  _claWebReport: `${home}/${managerScope}/cla-relatorio-web/:token`,
  claWebSelfReport: `${home}/${managerScope}/cla-relatorio-web-autoavaliacao/:token`,
  _claWebSelfReport: `${home}/${managerScope}/cla-relatorio-web-autoavaliacao/:token`,

  configIndividualAssessment: `${home}/${managerScope}/config-individual-assessment/:id(\\d+)`,
  _configIndividualAssessment: `${home}/${managerScope}/config-individual-assessment/:id`,
  configIndividualAssessmentGeneral: `${home}/${managerScope}/config-individual-assessment/:id(\\d+)/general/:questionnaire_title?`,
  _configIndividualAssessmentGeneral: `${home}/${managerScope}/config-individual-assessment/:id/general`,
  configIndividualAssessmentEnrollments: `${home}/${managerScope}/config-individual-assessment/:id(\\d+)/enrollments/:questionnaire_title?`,
  _configIndividualAssessmentEnrollments: `${home}/${managerScope}/config-individual-assessment/:id/enrollments`,

  releaseIndividualAssessment: `${home}/${managerScope}/release-individual-assessment/:token`,
  releaseIndividualAssessmentOverview: `${home}/${managerScope}/release-individual-assessment/overview/:token`,
  releaseIndividualAssessmentConfig: `${home}/${managerScope}/release-individual-assessment/config/:id(\\d+)/:questionnaire_title?`,
  _releaseIndividualAssessmentConfig: `${home}/${managerScope}/release-individual-assessment/config/:id`,

  monitorIndividualAssessment: `${home}/${managerScope}/monitor-individual-assessment/:token`,
  _monitorIndividualAssessment: `${home}/${managerScope}/monitor-individual-assessment/:token`,
  monitorIndividualAssessmentGeneral: `${home}/${managerScope}/monitor-individual-assessment/:token/general`,
  _monitorIndividualAssessmentGeneral: `${home}/${managerScope}/monitor-individual-assessment/:token/general`,
  monitorIndividualAssessmentEmail: `${home}/${managerScope}/monitor-individual-assessment/:token/email`,
  _monitorIndividualAssessmentEmail: `${home}/${managerScope}/monitor-individual-assessment/:token/email`,
  monitorIndividualAssessmentInvite: `${home}/${managerScope}/monitor-individual-assessment/:id(\\d+)/invite/:questionnaire_title?`,
  _monitorIndividualAssessmentInvite: `${home}/${managerScope}/monitor-individual-assessment/:id/invite`,
};

const publicRoot = '/open';

const publicRoutes = {
  root: publicRoot,
  role: `${publicRoot}/role`,
  enrollQuestionnaire: `${publicRoot}/enroll_questionnaire/:token`,
  questionnaire: `${publicRoot}/questionnaire/`,
};

export default {
  // publico
  demos,
  resetPassword,
  signupFromEmail,
  publicReport,
  enroll,
  // CBA
  reportCbaOnePage,
  reportCbaOnePageRating,
  reportCbaFull,
  reportCbaEssential,
  reportCbaV2,
  reportCla,
  // cadastro
  start,
  login,
  signup,
  unauthenticatedContact,
  recoverEmail,
  recoverPassword,

  public: publicRoutes,

  // privado
  home,
  myProfile,
  editMyProfile,
  support,
  termsConfirmation,

  // mensagens
  messages: messagesRoutes,

  // papeis
  respondent,
  manager,
};
