import constants from './application.constants';

const toggleDialog = (dialogProps = {}) => ({
  type: constants.TOGGLE_DIALOG,
  payload: { ...dialogProps },
});

const hideDialog = () => ({
  type: constants.HIDE_DIALOG,
});

const toggleErrorDialog = (dialogProps = {}) => ({
  type: constants.TOGGLE_ERROR_DIALOG,
  payload: { ...dialogProps },
});

const hideErrorDialog = () => ({
  type: constants.HIDE_ERROR_DIALOG,
});

const toggleLoading = () => ({
  type: constants.TOGGLE_LOADING,
});

const hideLoading = () => ({
  type: constants.HIDE_LOADING,
});

const resetApplicationLoading = () => ({
  type: constants.RESET_APPLICATION_LOADING,
});

const setViewMode = (mode) => ({
  type: constants.SET_VIEW_MODE,
  payload: { mode },
});

const enableAllCompaniesView = () => ({
  type: constants.ENABLE_ALL_COMPANIES_VIEW,
});

const disableAllCompaniesView = () => ({
  type: constants.DISABLE_ALL_COMPANIES_VIEW,
});

const toggleRespondentView = () => setViewMode(constants.modes.respondent);
const toggleManagerView = () => setViewMode(constants.modes.manager);

const saveApplication = (applicationData) => ({
  type: constants.SAVE_APPLICATION,
  payload: { ...applicationData },
});

const disablePersistUser = () => ({
  type: constants.DISABLE_PERSIST_USER,
});

export default {
  toggleDialog,
  hideDialog,

  toggleErrorDialog,
  hideErrorDialog,

  toggleLoading,
  hideLoading,

  setViewMode,
  toggleRespondentView,
  toggleManagerView,

  enableAllCompaniesView,
  disableAllCompaniesView,

  resetApplicationLoading,

  saveApplication,
  disablePersistUser,
};
