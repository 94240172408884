import { transform, isEqual, isObject } from 'lodash';

export const noop = () => {};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const objectDifference = (object, base) => transform(object, (result, value, key) => {
  if (!isEqual(value, base[key])) {
    // eslint-disable-next-line no-param-reassign
    result[key] = isObject(value) && isObject(base[key])
      ? objectDifference(value, base[key])
      : value;
  }
});

export const onPressEnter = (callback) => (event) => {
  if (event.keyCode === 13) {
    callback();
  }
};

export const isEllipsisActive = (el) => Boolean(el) && (el.offsetWidth < el.scrollWidth);

export const replaceRouteKey = (route, key, value, params = '') => (
  `${(route || '').replace(key, value).replace('(\\d+)', '')}${params && `?${params}`}`
);

export const joinUrlWithParams = (url, params) => [url, params].filter((d) => d).join('?');

export const mountRequiredParams = (requiredParams = [], values = {}) => {
  let _values = [];
  if ((requiredParams || []).length) {
    _values = requiredParams;
  }

  if (Object.keys(values || {}).length) {
    _values = Object.keys(values || {});
  }

  return _values
    .filter((d) => typeof values[d] !== 'undefined' && values[d] !== null)
    .map((d) => `${d}=${values[d]}`)
    .join('&');
};

export const joinUrlWithRequiredParams = ({
  url,
  requiredParams = [],
  params,
  requiredParamsValues,
} = []) => {
  const mountedRequiredParams = mountRequiredParams(requiredParams, requiredParamsValues);
  const mountedParams = [mountedRequiredParams, params].filter((d) => d).join('&');
  return joinUrlWithParams(url, mountedParams);
};

export const promiseDispatch = ({ dispatch, actionCreator, payload }) => (
  new Promise((resolve, reject) => dispatch(actionCreator({ ...payload, resolve, reject })))
);

export default {
  noop,
  objectDifference,
};
