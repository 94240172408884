import React from 'react';

import { useSelector } from 'react-redux';

import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import spanishLocale from 'moment/locale/es';
import mexicanSpanishLocale from 'moment/locale/es-mx';
import brazilianPortugueseLocale from 'moment/locale/pt-br';
import portugueseLocale from 'moment/locale/pt';

import messages from '../i18n/messages/messages';
import { userSelectors } from '../redux/selectors';

import App from './App';

const TranslationMiddleWare = () => {
  const activeLanguage = useSelector(userSelectors.activeLanguage) || 'pt-br';

  moment.locale('pt', portugueseLocale);
  moment.locale('pt-br', brazilianPortugueseLocale);
  moment.locale('es', spanishLocale);
  moment.locale('es-mx', mexicanSpanishLocale);
  moment.locale(activeLanguage);

  window.recaptchaOptions = { lang: activeLanguage };

  return (
    <>
      <IntlProvider
        defaultLocale='pt-BR'
        locale={(activeLanguage)}
        messages={flatten(messages[activeLanguage] || messages['pt-BR'])}
      >
        <MuiPickersUtilsProvider utils={MomentUtils} locale={(activeLanguage).toLowerCase()}>
          <App />
        </MuiPickersUtilsProvider>

      </IntlProvider>
    </>
  );
};

export default TranslationMiddleWare;
