export default {
  criar_conta: {
    simple: 'Sign up',
  },

  entrar: {
    simple: 'Sign in',
  },

  olar: {
    simple: 'Hello!',
    placeholder: 'Hello {name}!',
  },

  plural: {
    simple: 'I have {num, plural, one {cat} other {cats}}',
  },

  select: {
    placeholder: 'Seleciona',
    simple: 'I am a {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Perfil da organização',
      my_profile: 'A Minha conta',
      respondents: 'Inquiridos da organização',
      managers: 'Gestores',
    },
    assessments: 'Avaliações',
    choose_view_mode: 'Alternar para {isManagerView, select, true {respondente} false {gestor}}',
    sign_out: 'Sair da minha conta',
    help: 'Preciso de ajuda.',
    see_all_companies: 'Ver todas as empresas',
    answer_assessments: 'Responder a avaliações',
  },

  statuses: {
    questionnaires: {
      created: 'Servo',

      ready_to_start: 'Não {gender, select, male {Iniciado} female {Iniciada}}',
      started: '{gender, select, male {Iniciado} female {Iniciada}}',
      answered: '{gender, select, male {Respondido} female {Respondida}}',

      not_answered: '{gender, select, female {Não respondida} male {Não respondido} }',
      partially_answered: 'Não {gender, select, male {Encerrado} female {Encerrada}}',
      results_available: 'Ver Resultados',
    },

    assessments: {
      released: 'Liberada',
      paused: 'Pausada',
      finished: 'Encerrada',
      results: 'Resultados divulgados',
    },

    see_all_companies: {
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      desisted: '{num, plural, one {Desistiu} other {Desistiram}}',
      in_progress: '{num, plural, one {Liberada} other {Liberadas}}',
      paused: '{num, plural, one {Pausada} other {Pausadas}}',
      finished: '{num, plural, one {Encerrada} other {Encerradas}}',
      results_available: '{num, plural, one {Ver resultado} other {Ver resultados}}',
    },

    respondents: {
      pending: 'Pendente',
      registered: 'Registado',
      not_answered: 'Sem resposta',
      answered: 'Respondido',
    },

    assessment_subtype: {
      self_assessment: 'Automático',
      180: '180',
      270: '270',
      360: '360',
      720: '720',
    },
  },

  user_roles: {
    manager: 'Gestor',
    owner: 'Gerente Principal',
    respondent: 'Inquirido',
  },

  errors: {
    required: {
      simple: 'Campo é necessário',
      email: 'O e-mail é necessário',
      password: 'A palavra-passe é necessária',
      cpf: 'O NIF é obrigatório',
      cnpj: 'O NIPC é obrigatório',
      first_name: 'O nome é obrigatório',
      last_name: 'O sobrenome é obrigatório',
    },

    invalid: {
      start_date: 'A data deve ser mais longa do que hoje',
      end_date: 'A data deve ser maior ou igual à data de início',
      simple: 'Campo inválido',
      email: 'E-mail inválido',
      cpf: 'NIF inválido',
      cep: 'Código Postal inválido',
      cnpj: 'NIPC inválido',
      url: 'URL inválido',
      value: 'Valor não permitido',
    },

    invalid_fields: 'As tuas alterações não serão guardadas se houver erros no formulário!',
    generic_error_message: 'Raios, houve um erro a processar o teu pedido! Se persistir, entra em contato conosco através do menu de suporte',
    file_too_big: 'Arquivo maior do que {num} MB',
    wrong_mime: 'Tipo de ficheiro inválido',
    passwords_do_not_match: 'A confirmação não corresponde à palavra-passe inserida',
    min_string_len: 'Mínimo de {num, plural, one {# caractere} other {# caracteres}}',
    max_string_len: 'Máximo de {num, plural, one {# caractere} other {# caracteres}}',
    min_uppercase_letters: 'Mínimo de {num, plural, one {# letra maiúscula} other {# letras maiúsculas}}',
    min_lowercase_letters: 'Mínimo de {num, plural, one {# letra minúscula} other {# letras minúsculas}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Mulher',
      male: 'Homem',
      non_binary: 'Não Binário',
      fluid: 'Fluido',
      other: 'Outros',
      undeclared: 'Prefiro não declarar',
    },

    sex: {
      female: 'Feminino',
      male: 'Masculino',
      intersexual: 'Intersexo',
      other: 'Outros',
    },

    orientation: {
      assexual: 'Assexuado(a)',
      bissexual: 'Bissexual',
      straight: 'Heterossexual',
      homosexual: 'Gay',
      pansexual: 'Pansexual',
      fluid: 'Fluido',
      other: 'Outros',
      undeclared: 'Prefiro não declarar',
    },

    race: {
      yellow: 'Amarelo',
      white: 'Branco',
      indian: 'Indígena',
      pardo: 'Castanho',
      black: 'Preto',
      undeclared: 'Prefiro não declarar',
    },

    pcd: {
      nao_pcd: 'Não sou uma PcD',
      deficiencia_fisica: 'Deficiência física',
      deficiencia_auditivo: 'Deficiência auditiva',
      deficiencia_visual: 'Deficiência visual',
      deficiencia_mental: 'Deficiência mental',
      deficiencia_multipla: 'Incapacidade múltipla',
      undeclared: 'Prefiro não declarar',
    },

    job_titles: {
      estagiario: 'Estágio',
      professor: 'Professor(a)',
      consultoria: 'Consultoria',
      supervisao: 'Supervisão',
      analista: 'Analista',
      assistente: 'Assistente',
      auxiliar: 'Auxiliar',
      supervisor: 'Supervisor',
      coordenador: 'Coordenação',
      gerente: 'Gestão',
      senior: 'Especialista Sénior',
      diretor: 'Direcção (C-Level)',
      conselho: 'Conselho',
      prestador_de_servicos: 'Prestador(a) de serviços',
      other: 'Outros',
    },

    departments: {
      administracao: 'Administração',
      assistencia_ou_suporte_tecnico: 'Assistência ou apoio técnico',
      atendimento: 'Serviço',
      auditoria: 'Auditoria e consultoria',
      comercial: 'Comercial',
      compras: 'Compras',
      complience: 'Conformidade',
      comunicacao_e_assessoria: 'Comunicação e aconselhamento',
      controladoria: 'Controlador',
      contabilidade: 'Contabilidade',
      contratos: 'Contratos',
      engenharia: 'Engenharia',
      estrategia: 'Estratégia',
      financeiro: 'Finanças',
      inovacao: 'Inovação',
      inteligência: 'Inteligência',
      juridico: 'Legal',
      logistica: 'Logística',
      manutencao: 'Manutenção',
      marketing: 'Marketing',
      melhoria: 'Melhoria',
      novos_negocios: 'Novo negócio',
      operacoes: 'Operações',
      turismo: 'Turismo',
      pd: 'I&D',
      pos_vendas: 'Pós-vendas',
      projetos: 'Projectos',
      producao: 'Produção',
      processos: 'Processos',
      qualidade: 'Qualidade',
      rh_dho: 'RH / DHO',
      relacoes_institucionais: 'Relações institucionais',
      seguranca: 'Segurança',
      servicos_tecnicos: 'Serviços técnicos',
      suprimentos: 'Fornecimentos',
      tecnologia_da_informacao: 'Tecnologias da Informação',
      other: 'Outros',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Avaliações',
    },

    multi_perspective_assessment_individual: {
      title: 'Avaliações individuais multiperspectivas',
    },

    individual_assessments: {
      title: 'Avaliações Individuais',
      self: 'As minhas avaliações',
      others: 'Avaliação de pessoas',
    },

    multi_perspective_assessment: {
      title: 'Avaliações multiperspectivas',
    },

    respondents: {
      title: 'Inquiridos',
    },

    organization_profile: {
      title: 'Perfil da Organização',
    },

    my_profile: {
      title: 'O meu perfil',
    },

    managers: {
      title: 'Gestores',
    },
  },

  indicators: {
    true: 'Sim',
    false: 'Não',
    uninformed: 'Não informado',
    placeholder: {
      money: 'Por exemplo: R$ 1.000.000',
      number: 'Por exemplo: 10',
      selector: 'Seleciona',
      percent: 'Por exemplo: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Informação',
      name: 'Informação',
    },

    indicators: {
      id: 'Indicadores',
      name: 'Indicadores',
    },
  },

  emails: {
    manual: 'Envio manual',
    invite_template: {
      title: 'E-mail de convite',
      date: 'Envio imediato',
    },
    general_template: {
      title: 'Lembrete Geral',
      date: 'Envio imediato',
    },
    week_before_template: {
      title: 'Lembrete - Semana antes do fim',
      date: '1 semana antes do fim da avaliação',
    },
    day_before_template: {
      title: 'Lembrete - Véspera da rescisão',
      date: '1 dia antes do fim da avaliação',
    },
    results_available_template: {
      title: 'Divulgação de resultados',
      date: 'Depois que a avaliação terminar',
    },
    application_email: {
      all: 'Envia e-mail a todos os inquiridos deste avaliado',
      assessed: 'Envia e-mail para este avaliador',
      unanswered: 'Envia e-mail para sem resposta deste avaliado',
      role_type_title: {
        all: 'Enviar e-mail a todos que ainda não responderam?',
        internal: 'Enviar e-mail a todas as partes interessadas internas?',
        external: 'Enviar e-mail a todas as partes interessadas externas?',
      },
      role_type_body: {
        all: 'Este e-mail será enviado a todos os inquiridos registados por e-mail que ainda não tenham concluído a sua resposta. Depois de submeteres, não podes desfazer a acção.',
        internal: 'Ao enviares, este e-mail será desencadeado para todos os inquiridos registados via e-mail como partes interessadas internas, e não será possível desfazer esta acção',
        external: 'Ao enviares, este e-mail será desencadeado para todos os inquiridos registados via e-mail como partes interessadas externas, e não será possível desfazer esta acção',
      },
    },
    collection_email: {
      all: 'Envia a todos os avaliadores e seus inquiridos',
      assessed: 'Envia a todos os avaliadores',
      unanswered: 'Envia a todos sem resposta de todos avaliados',
    },
  },

  progress_bar: {
    text: 'Tempo de {mobile, select, true {resp.} false {respostas}}',
  },

  words: {
    email: 'E-mail',
    email_simple: 'E-mail',
    password: 'Palavra-passe',
    sector: 'Sector',
    gender: 'Género',
    company: 'Empresa',
    role: 'Papel',
    your_role: 'O teu papel',
    description: 'Descrição',
    behaviour: 'Comportamento',
    instructions: 'Instruções',
    cancel: 'Cancelar',
    error: 'Erro',
    close: 'Fechar',
    continue: 'Continuar',
    finish: 'Encerrar',
    pause: 'Pausa',
    next: 'A seguir',
    previous: 'Anterior',
    status: 'Estado',
    submit: 'Concluir',
    and: 'e',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    minimum_of: 'Mínimo {num}',
    maximum_of: 'Máximo {num}',
    page: 'Página',
    available: 'Disponível',
    unavailable: 'Indisponível',
    of: 'De',
    address: 'Endereço',
    config: 'Configura',
    release: 'Lançamento',
    monitor: 'Acompanhar',
    edit: 'Editar',
    view: 'Visualizar',
    actions: 'Acções',
    required: 'Obrigatório',
    type: 'Tipo',
    questions: 'Questões',
    invited: 'Convidados',
    answered: 'Respondidos',
    not_answered: 'Sem resposta',
    answer: 'Resposta',
    beginning: 'Início',
    end: 'Fim',
    duration: 'Duração',
    add: 'Adicionar',
    new: 'Novo',
    name: 'Nome',
    position: 'Função',
    registered: 'Registado',
    pending: 'Pendente',
    manager: 'Gestor',
    owner: 'Gerente Principal',
    hour: 'hora',
    day: 'dia',
    organization: 'Organização',
    individual: 'Individual',
    sex: 'Sexo',
    pcd: 'PcD (Pessoa com Deficiência)',
    color_race: 'Cor / Raça',
    sexual_orientation: 'Orientação Sexual',
    assessed: 'Avaliado',
    assessment: 'Avaliação',
    self_assessment: 'Autoavaliação',
    respondent: 'Inquirido',
    respondents: 'Inquiridos',
    back_main: 'Voltar para a página inicial',
    manual_sending: 'Envio manual',
    date: 'Data',
    sampling: 'Amostra',
    positive: 'Positivo',
    negative: 'Negativo',
    neutral: 'Neutro',
    conclusion: 'Conclusão',
    recommendation: 'Recomendação',
    do_not_apply: 'Não se aplica',
    search: 'Pesquisar',
    intro: 'Introdução',
    average: 'Média',
    in: 'em',
    general: 'Geral',
    participant: 'Participante',
    terms_of_use: 'Termos de utilização',
    privacy_policy: 'Política de privacidade',
    filter: 'Filtro',
    occupation: 'Agir',
    assessment_name: 'Nome da Avaliação',
    assessment_status: 'Estado de Autoavaliação',
    choose: 'Escolha',
    send_all: 'Enviar a todos',
    view_template: 'Visualizar modelo',
    smallest_score: 'Nota mais baixa',
    biggest_score: 'Nota mais alta',
    select_all: 'Marque todos',
    deselect_all: 'Desmarcar tudo',
    score: 'Nota',
    meaning: 'Significado',
    legend: 'Legenda',
    population: 'População',
    ideal_sample: 'Amostra ideal',
    minimum_sample: 'Amostra mínima geral',
    general_result: 'Resultado Global',
    current: 'Corrente',
    desired: 'Desejada',
    total: 'Total',
    come_back: 'Volta',
    start: 'Começar',
    assessed_organization: 'Organização avaliada',
    application: 'Aplicação',
    finished: 'Encerrada',
    initiation: 'Iniciou',
    finale: 'Fim',
    participations: 'Participações',
    reports: 'Relatórios',
    factors: 'Factores',
    subgroups: 'Subgrupos',
    optional: 'Opcional',

    plurals: {
      day: '{num, plural, one {# dia} other {# dias}}',
      hour: '{num, plural, one {# hora} other {# horas}}',
      answered: '{num, plural, one {Respondido} other {Respondidos}}',
      result: '{num, plural, one {Resultado} other {Resultados}}',
      assessed: '{num, plural, one {Avaliado} other {Avaliados}}',
      available: '{num, plural, one {# avaliação} other {# avaliações}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Questão} other {Questões}}',
      alternative: '{num, plural, one {Alternativa} other {Alternativas}}',
      answer: '{num, plural, one {Resposta} other {Respostas}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Pessoa} other {Pessoas}}',
    },

    try_again: 'Tentar novamente',
    delete: 'Excluir',
    confirm: 'Confirmar',
    save: 'Guardar',
    last_name: 'Sobrenome',
    not_informed: 'Não {gender, select, male {informado} female {informada}}',
  },

  checkbox: {
    title: {
      sector: 'Sector',
      role: 'Função',
      cba: 'CBA - Conscious Business Assessment',
      vyl: 'VYL - Valuable Young Leaders',
    },

    label: {
      Administrative: 'Administrativo',
      rh: 'RH',
      attendance: 'Serviço',
      commercial: 'Comercial',
      self_employed: 'Autónomo',
      specialist: 'Especialista',
      manager: 'Gestor',
      ceo: 'CEO',
      leaderships: 'Líderes',
      collaborators: 'Colaboradores',
      clients_partners: 'Clientes e parceiros',
      partnerships: 'Sociedade',
      inidividual_assessment: 'Avaliações de Indivíduos',
      organizational_assessment: 'Avaliações de Organizações',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Fala-nos de ti?',
      subtitle: 'Precisamos de mais dados sobre o teu perfil. A informação completa será utilizada para fins de pesquisa em conjunto, garantindo assim o teu anonimato.',

      email_label: 'E-mail',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'Não tenho e-mail.',
      external_email: 'Resposta via Open Link',

      first_name_label: 'Nome',
      first_name_placeholder: 'Por exemplo: Mariana',

      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Por exemplo: Dias',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Por exemplo: Humanizadas',

      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Seleciona',

      working_company_job_title_label: 'Função',
      working_company_job_title_placeholder: 'Seleciona',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleciona',

      gender_label: 'Género',
      gender_placeholder: 'Seleciona',
      gender_tooltip: 'Identidade de género refere-se à experiência de uma pessoa com o seu próprio sexo',

      pcd_label: 'PcD (Pessoa com Deficiência)',
      pcd_placeholder: 'Seleciona',

      pcd_tooltip_pt1_title: 'Deficiência física',
      pcd_tooltip_pt1_body: 'alteração completa ou parcial de um ou mais segmentos do corpo humano, resultando em comprometimento da função física, apresentando-se sob a forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputação ou ausência de membro, paralisia cerebral, nanismo, membros com deformidade congénita ou adquirida, exceto as deformidades estéticas e as que não produzam dificuldades para o desempenho de funções',

      pcd_tooltip_pt2_title: 'Deficiência auditiva',
      pcd_tooltip_pt2_body: 'Perda parcial ou total das possibilidades auditivas sonoras, variando de graus e níveis na forma seguinte: de 41 a 55 db - surdez moderada | de 56 a 70 db - surdez acentuada | de 71 a 90 db - surdez severa | acima de 91 db - surdez profunda | anacusia - perda total',

      pcd_tooltip_pt3_title: 'Deficiência visual',
      pcd_tooltip_pt3_body: 'A deficiência visual caracteriza-se pela limitação ou perda das funções básicas do olho e do sistema visual. Os deficientes visuais podem ser as pessoas cegas ou de baixa visão. A cegueira pressupõe a falta de perceção visual devido a factores fisiológicos ou neurológicos. A cegueira total ou simplesmente amaurose é caracterizada por perda total de visão sem perceção visual da luz e forma. A cegueira pode ser congénita ou adquirida. A acuidade visual de pessoas com baixa visão é muito variável; mas, em geral, a baixa visão é definida como uma condição em que a visão da pessoa não pode ser totalmente corrigida por óculos, interferindo nas suas actividades diárias, bem como na leitura e na locomoção.',

      pcd_tooltip_pt4_title: 'Deficiência mental',
      pcd_tooltip_pt4_body: 'Funcionamento intelectual significativamente inferior á média, com manifestação antes dos dezoito anos e limitações associadas a duas ou mais áreas de habilidades adaptativas, tais como: comunicação | cuidado pessoal | habilidades sociais | utilização da comunidade | saúde e segurança | habilidades académicas | lazer | trabalho',

      color_label: 'Cor / Raça',
      color_placeholder: 'Seleciona',
      color_tooltip: 'Como referenciado pelo IBGE',

      sexual_orientation_label: 'Orientação Sexual',
      sexual_orientation_placeholder: 'Seleciona',
      sexual_orientation_tooltip: 'A orientação diz respeito à atração que sente por outros indivíduos. Geralmente também envolve questões sentimentais, não apenas questões sexuais. Referência: UFSC',

      cpf_label: 'NIF',
      cpf_placeholder: 'Por exemplo: 123.456.789-10',
      cpf_tooltip: 'Pedimos o seu número de NIF para validar a sua identidade.',
    },

    questionnaire: {
      warning_dialog: {
        title: 'Tudo bem? Uma vez iniciada, não se pode parar a avaliação.',
        body: 'Não vais conseguir interrompê-la para terminá-la mais tarde. Esta restrição faz parte da nossa metodologia de investigação científica.',
        button: 'Responder à avaliação',
        left_button: 'Cancelar',
      },
    },
  },

  pages: {
    login: {
      title: 'Estou feliz que tenhas voltado!',
      subtitle: 'Para aceder à tua conta, insere o e-mail no qual recebeste o convite e a senha registada.',
      stay_logged_in: 'Mantém-me ligado',
    },

    signup: {
      title: 'Estávamos à tua espera!',
      subtitle: 'Temos tudo pronto para ti. Para aceder às tuas avaliações, basta criares uma conta.',
    },

    lastReport: {
      title: 'Aqui está o Relatório Geral da última edição do inquérito.',
      subtitle: 'Clica no link abaixo para aceder ao relatório geral da última edição da Pesquisa Empresas Humanizadas do Brasil.',
      button: 'Relatório de acesso',
    },

    thankYouRegister: {
      title: 'As suas respostas foram registadas com sucesso.',
      subtitle: 'Agradecemos a sua contribuição.',
    },

    unauthenticated_contact: {
      title: 'Envia-nos uma mensagem e depois te contactaremos.',
      subtitle: 'Conta-nos mais sobre o problema que encontraste, e encontraremos uma solução.',
    },

    password_recovery_enter_email: {
      title: 'Estamos aqui para ajudar-te.',
      subtitle: 'Insira o teu email de registo, e enviar-te-emos um link para criares uma nova senha.',
    },

    password_recovery_reset_password: {
      title: 'Aqui está o teu novo começo.',
      subtitle: 'Crie a tua nova senha e reaproveita todas as vantagens da plataforma.',
    },

    my_profile: {
      title: 'O meu perfil',
      edit_button: 'Editar',
    },

    organization_profile: {
      title: 'Perfil da Organização',
      edit_button: 'Editar',
    },

    support: {
      title: 'Envia-nos uma mensagem e depois te contactaremos.',
      subtitle: 'Conta-nos mais sobre o problema que encontraste, e encontraremos uma solução.',
    },

    respondents: {
      title: 'Inquiridos',
      title_add: 'Adicionar Inquiridos',
      search_field: {
        placeholder: 'Pesquisar',
      },

      actions: {
        add_sheet: 'Adicionar por folha de cálculo',
        download_template: 'Descarregar modelo de folha de cálculo',
        upload_sheet: 'Carregar folha de cálculo',
        bulk_actions: 'Acções a granel',
      },

      filter: {
        title: 'Filtro',
      },

      add_button: 'Adicionar',

      no_respondents: {
        message: 'Ainda não acrescentaste os inquiridos.',
        button_text: 'Adiciona agora',
      },

      owner_informations_dialog: {
        label: {
          email: 'E-mail',
          company: 'Empresa',
          sector: 'Sector',
          role: 'Função',
        },

        close_button: 'Fechar',
      },

      configure_assessment_dialog: {
        title: 'Queres fazer uma avaliação agora?',
        body: 'Agora que adicionaste novos inquiridos à base, escolhe um tipo de teste para configurares.',

        close_button: 'Fechar',
        config_button: 'Configura',
      },

      respondents_filter_dialog: {
        title: 'Filtrar Inquiridos',

        cancel_button: 'Cancelar',
        aplly_button: 'Aplicar filtros',
      },

      upload_and_download_sheet_dialog: {
        title: 'Lembra-te de usares o modelo de folha de cálculo de carga dos inquiridos.',
        body: 'Se ainda não descarregaste o modelo, faz a descarga e adiciona a tua folha de cálculo ao nosso padrão. Isto evitará erros na tua base de dados dos inquiridos.',

        download_button: 'Descarregar modelo ',
        upload_button: 'Carregar',
      },

      delete_respondents_dialog: {
        title: 'Excluir {num, plural, one {inquirido?} other {inquiridos?}}',
        body: 'Ao excluíres {num, plural, one {este inquirido} other {estes inquiridos}}, {num, plural, one {ele não poderá} other {eles não poderão}} mais aceder a nenhuma das tuas avaliações. Caso {num, plural, one {ele já tenha} other {eles já tenham}} respondido a alguma delas, todos os dados serão excluídos dos cálculos de resultados.',
        body_attention: 'Atenção: este é um processo que é irreversível.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      delete_enrollments_dialog: {
        title: 'Excluir {num, plural, one {inquirido?} other {inquiridos?}}',
        body: 'Ao excluíres {num, plural, one {este inquirido} other {estes inquiridos}}, {num, plural, one {ele não poderá} other {eles não poderão}} mais visualizar a avaliação, e deixarás de receber os e-mails referentes a ela. Caso {num, plural, one {ele já tenha} other {eles já tenham}} respondido às perguntas, os dados serão excluídos dos cálculos de resultados.',
        body_attention: 'Atenção: este é um processo que é irreversível.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      invitation_dialog: {
        title: 'Convite para avaliação',
        body: 'Escolhe a avaliação para que desejas convidar os inquiridos a quem seleccionaste.',
        empty: 'Não existem avaliações registadas na tua organização',
        cancel_button: 'Cancelar',
        invitation_button: 'Convidar',
      },

      edit: {
        title: 'Editar Inquirido',
      },

      add: {
        title: 'Adicionar Inquiridos',
      },

      add_list: {
        title: 'Adicionar Inquiridos a',
        return: 'Voltar ao ecrã de configuração',
        cancel_dialog: {
          title: 'Queres ir embora sem guardares?',
          body: 'Se saíres agora, perderás toda a informação que preencheste.',
          left_button: 'Sair sem guardar',
          right_button: 'Não sair',
        },
      },

      confirmation_message_dialog: {
        title: 'Muito obrigado!',
        body: 'Obrigado pelo teu tempo a nos ajudares a recolher esta informação.',

        button_finish: 'Encerrar',
        button_check: 'Verificar respostas',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: 'Queres terminar a avaliação?',
        body: 'Ao finalizares a avaliação, não poderás recolher mais respostas ou lançá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
        body_attention: 'Se quiseres suspender o julgamento por um período de tempo, tenta fazer uma pausa.',

        cancel_button: 'Cancelar',
        finalize_button: 'Encerrar',
      },

      send_email_dialog: {
        title: 'Enviar e-mail para {num, plural, one {# inquirido} other {# inquiridos}}?',
        body: 'Ao enviares, este e-mail será accionado, e não poderás desfazer esta acção. Para enviares um e-mail a apenas alguns inquiridos, selecciona-os na tabela abaixo e clica em "Acções a granel".',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },

      reminder_email_dialog: {
        title: 'Enviar e-mail',
        body: 'Escolhe o e-mail que pretendes enviar para {num, plural, one {o inquirido seleccionado} other {inquiridos seleccionados}}. Ao enviares, este e-mail será accionado, e não poderás desfazer esta acção.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },
    },

    organizational_assessments: {
      title: 'Avaliações Individuais',

      answered_tooltip: 'Estamos a calcular os resultados!',

      read_only_dialog: {
        title: 'Já reagiste a esta avaliação.',
        message: 'Nenhum campo pode ser mudado. Queres ver a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      no_invites: {
        message: 'Ainda não te foi pedido que respondesses a quaisquer avaliações das organizações.',
        button_text: 'Preciso de ajuda.',
      },

      empty_search: {
        message: 'Não foram encontrados resultados para a tua pesquisa. Experimenta os filtros para mais opções.',
      },
    },

    config_assessments: {
      success_dialog: {
        title: 'Avaliação configurada e pronta a ser lançada!',
        body: 'Esta avaliação estará agora disponível no menu "Lançamento". Ela começará automaticamente na data de início agendada e poderá ser editada até lá.',
        button_text: 'Concluir',
      },

      success_dialog_2: {
        title: 'Questionário configurado com sucesso!',
        body: 'Depois de configurar todas as sondagens, a avaliação estará agora disponível no menu "Lançamento". Ela começará automaticamente na data de início agendada e poderá ser editada até lá.',
        button_text: 'Tudo bem.',
      },

      cancel_dialog: {
        title: 'Queres ir embora sem guardares?',
        body: 'Se saíres agora, perderás toda a informação que preencheste.',
        left_button: 'Sair sem guardar',
        right_button: 'Não sair',
      },

      release_dialog: {
        title: 'Publicar comentários sem guardar as edições recentes?',
        body: 'Se pretenderes guardar as edições antes de lançares a avaliação, clica em "não soltar" abaixo e guarda as definições antes de prosseguires.',
        left_button: 'Lançar sem guardar',
        right_button: 'Não lançar',
      },

      template_dialog: {
        use_template: 'Enviar este e-mail aos inquiridos?',
        send_date: 'Data de envio',
        subject: 'Assunto da mensagem',
        body: 'Corpo da mensagem',
      },

      save_config: 'Guardar',
      email_section: 'Envio de e-mails',
      email_instructions: 'Selecciona quais os modelos de e-mail que enviarás aos inquiridos durante a tua avaliação.',
      start_date: 'Data de início',
      end_date: 'Data de fim',
      link: 'https://app.humanizadas.com/avaliacoes/',
      link_title: 'Link de compartilhamento para {name}',
      copy_link: 'Copiar link',

      invite_template: {
        title: 'E-mail de convite',
        date: 'Envio imediato',
      },
      general_template: {
        title: 'Lembrete geral',
        date: 'Envio imediato',
      },
      week_before_template: {
        title: 'Lembrete - Semana antes do fim',
        date: '1 semana antes do fim da avaliação',
      },
      day_before_template: {
        title: 'Lembrete - Véspera da rescisão',
        date: '1 dia antes do fim da avaliação',
      },
      results_available_template: {
        title: 'Divulgação de resultados',
        date: 'Depois que a avaliação terminar',
      },

      header_items: {
        role: 'Papel dos inquiridos',
      },

      header_menu: {
        release: 'Lançamento agora',
        play: 'Retomar',
        pause: 'Pausa',
        finish: 'Termine agora',
        results: 'Ver resultado',
        view: 'Ver inquéritos',
        answer_self_assessment: 'Responder à autoavaliação',
      },

      respondents_section: 'Inquiridos',
      respondents_instructions: 'Além dos inquiridos adicionados por esta área, tu podes também convidar outros a responder à tua avaliação através de um link - que será disponibilizado após a divulgação da avaliação.',
      empty_table: 'Nenhum modelo foi ainda disponibilizado para a tua organização.',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Inquiridos (público em geral)',
        specific_public_respondents: 'Inquiridos (Audiências específicas)',
        specific_public: 'Públicos específicos',
      },
      organization_table_title: 'Todas as Avaliações de Organizações',
      individual_table_title: 'Todas as Avaliações Individuais',
      self_assessment_table_title: 'Todas as Autoavaliações',
    },

    individual_assessments: {
      title: 'Avaliações de Indivíduos',
      manager: {
        email: {
          manual: 'Envio manual',
          assessed_respondents: 'Envia a todos os avaliadores e seus inquiridos',
          assessed: 'Envia a todos os avaliadores',
          not_answered_assessed: 'Envia a todos sem resposta de todos avaliados',
        },
        invite: {
          warning: 'Verifica a caixa à esquerda do nome de cada entrevistado para convidá-lo.',
          respondents: 'Além dos inquiridos adicionados por esta área, tu podes ainda convidar outros a responder à tua avaliação através de um link aberto - que será disponibilizado após a divulgação da avaliação.',
        },
      },
      title_self: 'As minhas avaliações',
      title_others: 'Avaliação de pessoas',

      read_only_dialog: {
        title: 'Já reagiste a esta avaliação.',
        message: 'Nenhum campo pode ser mudado. Queres ver a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      no_invites: {
        message: 'Ainda não te foi pedido que respondesses a qualquer avaliação dos indivíduos.',
        button_text: 'Preciso de ajuda.',
      },

      empty_search: {
        message: 'Não foram encontrados resultados para a tua pesquisa. Experimenta os filtros para mais opções.',
      },
    },

    config_individual_assessments: {
      title: 'Configurar Avaliações Individuais',
      confirm_button: 'Concluir Configurações',
      dialog_complete: {
        title: 'Avaliação configurada e pronta a ser lançada!',
        body: 'Esta avaliação estará agora disponível no menu "Lançamento". Ela começará automaticamente na data de início agendada e poderá ser editada até lá.',
        button: 'Concluir',
      },
      dialog_incomplete: {
        title: 'Concluir configuração sem preencher todos os avaliados disponíveis?',
        body: 'As restantes avaliações que adquiriste continuarão no menu "Configurar" para que sejam configuradas e lançadas mais tarde.',
        left_button: 'Continuar a configurar',
        right_button: 'Concluir',
      },
      dialog_add: {
        title: 'Adicionar novo utilizador',
        title_edit: 'Editar utilizador',
        subtitle: 'Este utilizador ainda não está registado. Para convidá-lo como avaliado numa avaliação, preencha os seus dados abaixo.',
        email_label: 'E-mail avaliado',
      },
      info: {
        title: 'Configurações gerais da {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        date_title: 'Início e Fim',
        date_subtitle: 'As datas de início e fim desta avaliação serão as mesmas para todas as empresas avaliadas abaixo.',
        start_date: 'Data de início',
        end_date: 'Data de fim',
      },
      enrollments: {
        title: 'Configurar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
      },
      details: {
        email: 'E-mail avaliado',
        warning: 'Verifica a caixa à esquerda do nome de cada entrevistado para convidá-lo.',
        respondents: 'Além dos inquiridos adicionados por esta área, tu podes ainda convidar outros a responder à tua avaliação através de um link aberto - que será disponibilizado após a divulgação da avaliação.',
      },
    },

    release_individual_assessments: {
      title: 'Lançar Avaliações de Indivíduos',

      assessment_dialog: {
        title: 'Lançar avaliação agora?',
        body: 'Se divulgares a avaliação agora, será enviada a todos os inquiridos. Esta ação não pode ser desfeita.',
        release_button: 'Lançamento',
        wait_button: 'Aguarda a data de início',
      },

      success_save_changes_dialog: {
        title: 'Edições salvas com sucesso!',
        body: 'Esta avaliação começará automaticamente na data de início agendada e poderá ser editada até lá.',
        conclude_button: 'Concluir',
      },

      release_assessed: {
        title: 'Lançar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        email_label: 'E-mail avaliado',
      },
    },

    monitor_individual_assessments: {
      title: 'Acompanhar Avaliações Individuais',

      finish_dialog: {
        title: 'Queres terminar a avaliação?',
        body: 'Ao finalizares a avaliação, não poderás recolher mais respostas ou lançá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
      },

      pause_dialog: {
        title: 'Queres parar a avaliação?',
        body: 'Enquanto a revisão é interrompida, os inquiridos não poderão vê-la ou enviar respostas. Podes retomá-la a qualquer momento.',
        warn: 'Nota: a data do fim não será alterada.',
      },

      invite: {
        all: 'Envia a todos os avaliadores e seus inquiridos',
        assessed: 'Envia a todos os avaliadores',
        not_answered: 'Envia a todos sem resposta de todos avaliados',
      },

      assessed_invite: {
        all: 'Envia e-mail a todos os inquiridos deste avaliado',
        assessed: 'Envia e-mail para este avaliador',
        not_answered: 'Envia e-mail para sem resposta deste avaliado',
      },

      general: {
        title: 'Acompanhar {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        date_save_button: 'Guardar data',
      },

      assessed_user: {
        status: 'Estado da autoavaliação',
      },
    },

    release_assessments: {
      table_title: 'Lançar Avaliações',
      table_empty: 'Ainda não tens nenhuma avaliação configurada para ser lançada.',
    },

    monitor_assessments: {
      table_title: 'Acompanhar Avaliações',
      table_empty: 'Ainda não tens quaisquer avaliações para acompanhares.',
    },

    organizational_assessments_manager: {
      title: 'Configurar Avaliações',

      read_only_dialog: {
        title: 'Já reagiste a esta avaliação.',
        message: 'Nenhum campo pode ser mudado. Queres ver a resposta enviada?',
        submit_button: 'Ver resposta',
      },

      empty_table: 'Nenhum modelo foi ainda disponibilizado para a tua organização.',

      no_invites: {
        message: 'Ainda não te foi pedido que respondesses a qualquer avaliação dos indivíduos.',
        button_text: 'Preciso de ajuda.',
      },

      empty_search: {
        message: 'Não foram encontrados resultados para a tua pesquisa. Experimenta os filtros para mais opções.',
      },
    },

    questionnaire: {
      progress: 'Parte {start} de {end}',
      required_fields: 'Os campos obrigatórios são marcados com um *',
      back_to_main_page: 'Voltar para a página inicial',
      answer_all_required: 'Por favor, preenche todos os campos necessários',
      matrix_error: 'Escolhe ao menos {min} itens',
      assessment_description: 'Descrição da avaliação',
      exit_view: 'Sair da Visualização',
      no_answers: 'Não sei responder',

      warning_dialog: {
        title: 'Conclusão',
        message: 'Para completares a avaliação, clica em enviar respostas ou clica em verificar se desejas editar as tuas respostas.',
        cancel_button: 'Verificar respostas',
      },

      success_dialog: {
        title: 'Muito obrigado!',
        message: 'Obrigado pelo teu tempo a nos ajudares a recolher esta informação.',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: 'Raios! Deixaste de ser o Gestor Principal da empresa.',
        body: 'Deixarás de poder editar utilizadores ou perfil da tua organização.',
      },

      confirm_promote_dialog: {
        title: 'Transferir papel de gerente principal para {name}',
        body: 'Ao tornar outro utilizador o Gestor Principal, perderás este papel. Apenas o Gestor Principal pode editar os Gestores e o Perfil da Organização.',

        button_submit: 'Tornar Gerente Principal',
      },

      confirm_destroy_dialog: {
        title: 'Excluir Gestor',
        body: 'Ao eliminares este Gestor, ele deixará de poder aceder à plataforma da tua organização ou gerir avaliações e inquiridos.',
      },

      add_manager_dialog: {
        title: 'Adicionar Gestor',
        body: 'Para adicionar um Gestor, deve(m) ele(s) inscrever-se para o link abaixo:',
      },

      user_not_found_dialog: {
        title: 'Opa... Não encontrámos registos com este e-mail.',
        body: 'Certifica-te de que a pessoa que desejas convidar já está registada e vê se escreveste o e-mail correctamente.',
      },

      table: {
        title: 'Gestores',
        empty_table: 'Nenhum gerente foi encontrado.',
      },

      dialog: {
        email: 'E-mail',
        company: 'Empresa',
        sector: 'Sector',
        position: 'Função',
      },
    },

    messages: {
      thank_you: {
        title: 'Muito obrigado!',
        subtitle: 'Obrigado pelo teu tempo a nos ajudares a recolher esta informação.',
        alt_desktop: 'A imagem mostra duas pessoas na frente de um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um telemóvel',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      thank_you_public: {
        title: 'Muito obrigado!',
        subtitle: 'Obrigado pelo teu tempo a nos ajudares a recolher esta informação.',
        subtitle_2: 'Se quiseres aproveitar a experiência da Plataforma Humanizada, acede à tua conta através de um computador.',
        alt_desktop: 'A imagem mostra duas pessoas na frente de um computador',
        alt_mobile: 'A imagem mostra duas pessoas na frente de um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      assessment_expired: {
        title: 'Pesquisa fechada',
        subtitle: 'O tempo de resposta desta avaliação já terminou. Obrigado pelo teu interesse!',
        alt_desktop: 'A imagem mostra uma pessoa na frente de um computador',
        alt_mobile: 'A imagem mostra uma pessoa na frente de um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      assessment_not_started: {
        title: 'A avaliação ainda não começou.',
        subtitle: 'Esta avaliação ainda não está a receber respostas, verifica com quem remeteu este link qual a data correta. Esperamos dar-te uma excelente experiência. Obrigado pelo teu tempo!',
        alt_desktop: 'A imagem mostra uma pessoa na frente de um computador',
        alt_mobile: 'A imagem mostra uma pessoa na frente de um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      mobile_not_available: {
        title: 'Opa...',
        subtitle: 'Desculpa-nos pelo inconveniente. Ainda estamos a construir a experiência Humanizada para que possas aceder via smartphone. Por enquanto, só podes aceder à plataforma no teu computador.',
        alt_desktop: 'A imagem mostra duas pessoas na frente de um computador',
        alt_mobile: 'A imagem mostra duas pessoas em frente a um telemóvel',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      error: {
        title: 'Erro',
        subtitle: 'Página não encontrada.',
        errorMessage: 'Lamentamos, mas algo correu mal durante a tua navegação. Tenta uma das alternativas abaixo.',
        alt_desktop: 'A imagem mostra duas pessoas na frente de um computador',
        alt_mobile: 'A imagem mostra duas pessoas na frente de um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
      invalid_link: {
        title: 'Link inválido',
        subtitle: 'Se estás aqui é porque recebeste um link único de outra pessoa.',
        body: 'Por favor, certifica-te de que recebeste um e-mail de inscrição na tua caixa de correio electrónico (incluindo spam) e se não o recebeste, por favor contacta-nos através do',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'A imagem mostra uma pessoa na frente de um computador',
        alt_mobile: 'A imagem mostra uma pessoa na frente de um computador',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vetor',
      },
    },

    terms_confirmation: {
      title: 'Notificação de Privacidade',
      subtitle: 'Olá, atualizámos a Política de Privacidade e Os Termos de Utilização da nossa plataforma para te trazer mais transparência sobre como tratamos os teus dados. Portanto, para continuares a usar a nossa plataforma precisamos que tu leias e aceites os termos abaixo:',
      confirm_button: 'Eu li e concordo',
    },

    monitor_assessments_page: {
      title: 'Acompanhar',
      respondents_section: 'Inquiridos',
      header_menu: {
        answer_self_assessment: 'Responder à autoavaliação',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Envia e-mail a todos os inquiridos {num, plural, one {deste avaliado} other {destes avaliados}}',
          assessed: 'Envia e-mail para {num, plural, one {teste avaliado} other {estes avaliados}}',
          not_answered: 'Envia e-mail para sem resposta deste avaliado',
        },
        evaluators_link: 'Link avaliadores',
        assessed_link: 'Link avaliados',
        internal_link: 'Avaliadores Internos',
        external_link: 'Avaliadores Externos',
      },
      end_application: 'Para o final da aplicação',
      send_reminder: 'Enviar lembrete',
      higher_answers: 'Número de respostas superior à amostra',
      lower_answers: 'Número de respostas abaixo da amostra',
      absolute_number_answers: 'Respostas (números absolutos)',
      desired_sample: '{answers, plural, one {# resposta} other {# respostas}} /n acima da amostra desejada',
      achieve_ideal_sample: '{answers, plural, one {Falta # resposta} other {Faltam # respostas}} /n para atingir a amostra ideal',
      email_reminder: 'E-mails e lembretes',
      completed_assessments: 'Avaliações concluídas',
      see_all: 'Ver todos',
      number_of_answers: 'Número de Respostas',
      header: {
        play_and_pause: '{isPaused, select, true {Retomar} false {Pausar}} aplicação',
        view_reports: 'Ver Relatórios',
        view_group_reports: 'Ver Resultados do Grupo',
        export_reports: 'Exportar Relatório',
        share_reports: 'Compartilhar Resultados',
        view_questionnaire: 'Ver questionário',
        finish_application: 'Finalizar aplicação',
        share_link: 'Link de partilha',
        internal_stakeholders: 'Partes interessadas internas',
        external_stakeholders: 'Partes interessadas externas',
        response_rate: 'Taxa de Respostas',
        calculate_results: 'Calcular resultados',
        results_being_calculate: 'Os resultados estão a ser calculados',
      },
      line_graph: {
        answers_per_day: 'Respostas por dia',
        total_answers: 'Total de respostas',
        number_of_responses: 'Número de Respostas',
      },
      reminder: {
        automatic_emails: 'E-mails automáticos',
        email_results_appraised: 'Email com Resultados para Avaliados',
        send_email_to_participants: 'Envia um e-mail (predefinido) aos participantes desta avaliação',
        send_to_all_unanswered: 'Envia a todos os sem resposta',
        send_email_to_appraised: 'Envia um e-mail (pré-definido) disponibilizando os resultados para os avaliados',
        send_results_to_all_appraised: 'Envia Resultados a todos os avaliados',
        send_all_internal_stakeholders: 'Envia a todas as Partes Interessadas Internas',
        send_all_external_stakeholders: 'Envia a todas as Partes Interessadas Externas',
      },
      individual: {
        conclusion_rate_summary: 'Resumo da Taxa de Conclusão',
        total_number_answers: 'N.º total de respostas',
        ideal_sample_reached: 'da Amostra Ideal alcançada',
        desired_sample_reached: 'da Amostra Desejada alcançada',
        minimum_sample_reached: 'da Amostra Geral Miníma alcançada',
        engagement_by_appraisees: 'Envolvimento por Avaliados',
        bigger_engagement: 'Maior envolvimento',
        less_engagement: 'Menor envolvimento',
        multiperspective_assessment: 'Avaliação de multiperspectivas',
      },
      organization: {
        absolute_number_responses: 'Número absoluto de respostas',
        organization_assessment: 'Avaliação das Organizações',
      },
      self_assessment: {
        last_answers: 'Últimas Respostas',
        individual_self_assessment: 'Autoavaliação Individual',
      },
    },

    reports: {
      yours_answers: 'As tuas respostas',
      global_average: 'Média geral: {value}',
      your_result: 'O teu resultado',
      answered_average: 'Média dos respondidos',
      less_developed: 'Menos desenvolvidos',
      more_developed: 'Mais desenvolvidos',
      smallest_result: 'Resultado mais baixo: {value}',
      biggest_result: 'Resultado mais alto: {value}',

      competences_title: 'Competências',
      charts_title: 'Gráficos',
      behavior_title: 'Expoentes comportamentais',
      development_title: 'Dicas de desenvolvimento',

      report_360: {
        title: 'Resultado de {type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        valued_questions_title: 'Médias por comportamento',
        text_questions_title: 'Respostas Abertas',
      },

      skeleton: {
        title: 'Descrição da avaliação',
        description: 'Estás a participar da Investigação Empresas Humanizadas. Ela visa identificar práticas humanizadas presentes dentro das organizações através de um processo de escuta de todas as partes envolvidas. A tua contribuição é muito importante para que a empresa possa identificar como servir melhor a ti e também à Sociedade.',
      },
    },

    respondent_role: {
      title_individual: 'Olá, bem-vindo(a), foi-te pedido para responderes à avaliação de',
      title_organization: 'Olá, bem-vindo(a), foi-te pedido que respondesses à avaliação da organização.',
      subtitle_organization: 'Selecciona abaixo a tua relação com a organização avaliada.',
      subtitle_individual: 'Selecciona abaixo qual é a tua relação com {value} .',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Grupo total',
        completed_answers: 'Respostas completas',
        group_and_self_legend: 'Visão do Grupo e Autoavaliação',
        potentially_limiting: 'Potencialmente limitante',
      },
      graphs: {
        labels: {
          bigger: 'Valor mais elevado',
          median: 'Mediana',
          smaller: 'Valor mais baixo',
          gravity_center: 'CGC corrente',
        },
        group_legend: 'Vista de Grupo',
        axis: {
          very_high: 'Muito alta',
          high: 'Alta',
          medium: 'Mediana',
          low: 'Baixa',
          very_low: 'Muito baixa',
          stage_replace: 'Estágio {number}',
        },
      },
      recommendation_analysis: 'Recomendações para o processo de revisão',
      individual_development: 'Plano de Desenvolvimento Individual',
      considerations: 'Considerações',
      recommendation: 'Recomendações',
      respondents_section: {
        reliability: 'Fiabilidade',
        applications: 'Candidaturas',
      },
      cards_section: {
        group_view: 'Vista de Grupo',
        self_assessment: 'Autoavaliação',
        benchmark: 'Referência',
        influence: 'Influência',
        stage: 'Estágio',
      },
      one_page: {
        top_3_card_title: 'Top 3 | Cultura Organizacional',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Sociedade',
          bar_2: 'Parceiros',
          bar_3: 'Clientes',
          bar_4: 'Colaboradores',
          bar_5: 'Líderes',
          bar_6: 'Geral',
        },
        radar: {
          bar_1: 'Criação',
          bar_2: 'Realização',
          bar_3: 'Organização',
          bar_4: 'Colaboração',
          label_1: 'Experiência actual',
          label_2: 'Experiência desejada',
        },
      },
      labels: {
        difference: 'Diferença',
        benchmark: 'Referência',
        trust_level: 'Nível de confiança',
        perspective: 'Perspectiva Público {isInternal, select, true {Interno} false {Externo}}',
        meaning: 'Significado',
        score: 'Pontuação',
        ratings_scale: 'Escala de classificação',
        level: 'Nível',
      },
      vertical_levels: {
        level_1: {
          title: 'Sobrevivência',
          description: 'foco na protecção e nas necessidades básicas da organização.<br/>Podem ocorrer manipulação, coerção, baixo nível de confiança e envolvimento.',
          text: 'Autoprotecção e necessidades a curto prazo.',
        },
        level_2: {
          title: 'Processos',
          description: 'foco na conformidade e estabilidade de processos e relações.<br/>As regras e os padrões de trabalho regem o comportamento e a atitude das pessoas.',
          text: 'Manutenção dos padrões e normas atuais.',
        },
        level_3: {
          title: 'Especialização',
          description: 'foco na especialização, competências e eficiência.<br/>As competências e as áreas de interesse dos indivíduos impulsionam os comportamentos.',
          text: 'Especialização do processo e eficiência.',
        },
        level_4: {
          title: 'Conclusões',
          description: 'foco em análises, resultados e objectivos a alcançar.<br/>Nível elevado de responsabilidade e forte sentido de meritocracia dominam as relações.',
          text: 'Entrega de resultados e eficácia.',
        },
        level_5: {
          title: 'Pessoas',
          description: 'foco nas relações humanas e na interacção com o sistema.<br/>A perspetiva da concorrência é instituída pela abundância e interdependência sistémica.',
          text: 'Relações humanas e ambiente.',
        },
        level_6: {
          title: 'Cocriação',
          description: 'focar-se na capacidade de integração e transformação sistémica.<br/>As divergências tornam-se pontos de apoio para cocriação e coinovação.',
          text: 'Alta finalidade e gestão dinâmica.',
        },
        level_7: {
          title: 'Finalidade',
          description: 'focar-se na sabedoria e na inteligência coletiva. Capacidade de sustentar tensões como complexidade e simplicidade, integridade e fractal.',
          text: 'Ao serviço do bem-estar da humanidade.',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfeccionista',
          description: 'fazer a coisa certa, evitar enganos e trabalhar em busca de autodesenvolvimento e autocontrolo.',
        },
        level_2: {
          title: 'Prestativa',
          description: 'ser útil e fiável, estar disponível para as pessoas, antecipar e fornecer as necessidades dos outros.',
        },
        level_3: {
          title: 'Realizadora',
          description: 'atingir objectivos, apresentar uma imagem que apoie o sucesso da organização, seja produtivo e realize.',
        },
        level_4: {
          title: 'Criativa',
          description: 'ser autêntico e expressar individualidade, estar conectado e verdadeiro com emoções, ter significado e fazer a diferença no mundo.',
        },
        level_5: {
          title: 'Observadora',
          description: 'dar sentido ao mundo, poupar recursos, pensar, analisar, resolver, evitar a dependência e perseguir o conhecimento.',
        },
        level_6: {
          title: 'Questionadora',
          description: 'criar estabilidade, confiança e segurança, proteger a organização, evitar ameaças e riscos, ser leal e responsável.',
        },
        level_7: {
          title: 'Entusiasta',
          description: 'sempre com uma perspetiva positiva, foco no futuro, perseguir prazer, liberdade e expandir as possibilidades de vida.',
        },
        level_8: {
          title: 'Protectora',
          description: 'ser forte e controlar, evitar fraqueza e vulnerabilidade, fazer as coisas acontecerem, tomar decisões e alcançar resultados.',
        },
        level_9: {
          title: 'Pacificadora',
          description: 'promover a harmonia e a paz, receber influência das pessoas, criar conforto e rotinas, acolher e reduzir conflitos no grupo.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margem de erro',
      confidence: 'Grau de confiança',
      print_button: 'Guardar PDF',

      engagement_section: 'Envolvimento geral das partes interessadas',

      organizational_identity: {
        title: 'Identidade organizacional',
        vertical_development: 'Desenvolvimento Vertical (Corrente)',
        horizontal_development: 'Desenvolvimento Horizontal (Corrente)',
        values_to_develop: 'Top 3 Valores para Desenvolver',
        values_to_adjust_reframe: 'Top 3 Valores para Ajustar ou Dar Novo Significado',
      },

      stakeholders_engagement: {
        title: 'Envolvimento das partes interessadas',
        description: 'Resultado do grau de envolvimento das partes interessadas no negócio (pontuação de -100 a +100). A pontuação negativa expressa a desvinculação, e a pontuação positiva expressa o envolvimento.',
      },

      stakeholders_management: {
        title: 'Princípios de gestão',
        description: 'Resultado da avaliação das práticas de gestão e dos resultados da gestão em cinco princípios (pontuação de 0 a 100).',
      },

      scores_scale: {
        focus: 'Foco',
        meaning: 'Significado',
        min_max: '{min} para {max}',

        quality: {
          title: 'Qualidade das relações',
          description: 'Índice de Qualidade de Relacionamento (IQR)',
          '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
          '50_60': 'Relações com um elevado grau de criticidade, a exigirem mudanças de urgência.',
          '60_70': 'Relações com problemas sérios que exigem intervenção imediata com os líderes.',
          '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
          '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
          '90_95': 'Relações positivas e bastante saudáveis.',
          '95_100': 'Relações extremamente positivas e saudáveis.',
        },

        maturity: {
          title: 'Maturidade organizacional',
          description: 'Centro de Gravidade da Consciência (CGC)',
          value1: 'Autoprotecção e necessidades a curto prazo.',
          value2: 'Manutenção dos padrões e normas atuais.',
          value3: 'Especialização e eficiência do processo.',
          value4: 'Entrega de resultados e eficácia.',
          value5: 'Relações humanas e ambiente.',
          value6: 'Alto propósito e gestão dinâmica.',
          value7: 'Ao serviço do bem-estar da humanidade.',
        },
      },

      engagement_messages: {
        '-100_-50': 'Na percepção dos stakeholders, a organização expressa um nível péssimo de engajamento com problemas que colocam em risco o futuro do negócio.',
        '-49_-1': 'Na percepção dos stakeholders a organização expressa um nível positivo de engajamento, tendo relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '0_24': 'Na percepção dos stakeholders, a organização expressa um nível baixo de engajamento com problemas críticos que demandam atenção.',
        '25_49': 'Na percepção dos stakeholders, a organização expressa um nível bom de engajamento com problemas pontuais que demandam atenção.',
        '50_64': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com várias oportunidades para melhorias que devem ser exploradas.',
        '65_74': 'Na percepção dos stakeholders, a organização expressa um alto nível de engajamento com presença de oportunidades para melhorias.',
        '75_84': 'Na percepção dos stakeholders, a organização expressa um nível muito alto de engajamento e deve atuar para que continue assim.',
        '85_100': 'Na percepção dos stakeholders, a organização expressa um nível excelente de engajamento e provavelmente continuará assim no futuro.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimensão',
        score: 'Pontuação',
        scale: 'Escala',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO e fundador',
    },
    iqr_section: {
      levels: {
        '95_100': 'Relações extremamente positivas e saudáveis.',
        '90_95': 'Relações positivas e bastante saudáveis.',
        '80_90': 'Relações com problemas pontuais exigindo atenção imediata para não se deteriorar.',
        '70_80': 'Relações apresentam problemas críticos exigindo intervenções em sistemas internos.',
        '60_70': 'Relações com problemas sérios que exigem intervenção imediata com os líderes.',
        '50_60': 'Relações com um elevado grau de criticidade, a exigirem mudanças de urgência.',
        '0_50': 'Relações extremamente deterioradas, colocando em risco o futuro do negócio.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'gráfico de identidade organizacional',
    },
    narratives_analysis: {
      internal: 'Narrativas internas',
      external: 'Narrativas externas',
      title: 'As narrativas revelam...',
      levels: {
        level100: 'Relações extremamente positivas ao serviço da Humanidade e da Vida.',
        level80: 'Relações saudáveis com problemas pontuais que requerem atenção.',
        level60: 'Relações saudáveis com problemas críticos que requerem intervenções.',
        level40: 'Relações com problemas sérios que exigem intervenção imediata com os líderes.',
        level20: 'Relações com um elevado grau de criticidade, a exigirem mudanças de urgência.',
        level0: 'Relações com um elevado grau de criticidade, a exigirem mudanças de urgência.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Orientação Cultural',
    },
    components: {
      consolidated_results: {
        score: 'Pontuação',
        sample_size: 'Tamanho da amostra',
        margin_of_error: 'Margem de erro',
        trust_rating: 'Grau de confiança',
      },
      consolidated_levels: {
        very_high: 'Extremamente alto',
        high: 'Alto',
        middle: 'Mediana',
        low: 'Baixo',
        extremely_low: 'Extremamente baixo',
      },
      time_circles: {
        past: 'Passado',
        present: 'Presente',
        future: 'Futuro',
      },
      base_page: {
        answered_by: 'Respondido por',
      },
      center_chart: {
        optimistic_outlook: 'Perspetiva otimista',
        pessimistic_outlook: 'Perspetiva pessimista',
      },
      start_chart: {
        degree_of_maturity: 'Grau de maturidade organizacional',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Centro de Gravidade',
      },
      side_menu: {
        menu_intro: 'Introdução',
        letter: 'Carta ao leitor',
        intro_comments: 'Comentários gerais',
        menu_rating: 'Classificação',
        intro: 'Introdução às classificações',
        general_results: 'Resultado geral',
        general_comments: 'Comentários gerais',
        focus: 'Onde se concentrar',
        menu_perspective: 'Perspetiva multipartes interessadas',
        respondents: 'Inquiridos',
        engagement: 'Envolvimento',
        experience: 'Experiência',
        score: 'Satisfação',
        perspective: 'Perspetiva do futuro',
        menu_principles: 'Princípios',
        overview: 'Visão geral dos princípios',
        purpose: 'Propósito maior',
        strategy: 'Estratégia de valor',
        conscious_culture: 'Cultura consciente',
        adaptability: 'Aprendizado e mudança',
        leadership: 'Liderança consciente',
        menu_values: 'Valores',
        menu_identity: 'Identidade',
        formation: 'Formação de identidade',
        vertical_dev: 'Desenvolvimento vertical',
        horizontal_dev: 'Desenvolvimento horizontal',
        quality: 'Índice de qualidade da relação',
        menu_narratives: 'Narrativas',
        map: 'Mapa de narrativas',
        main: 'Principais narrativas',
        analysis: 'Análise de conteúdo',
        highlights: 'Práticas de destaque',
        recommended: 'Práticas recomendadas',
        demographics: 'Demografia',
        reputation: 'Reputação',
        general_reputation: 'Reputação geral',
        reputation_by_stakeholder: 'Reputação por Parte Interessada',
        responding_data: 'Dados dos inquiridos',
        learning_and_change: 'Aprendizagem e mudança',
        perceived_result: 'Resultado percebido',
        culture: 'Cultura',
        organizational_culture: 'Cultura organizacional',
        respondent_profile: 'Perfil dos inquiridos',
        group_view: 'Vista de Grupo',
        self_assessment: 'Autoavaliação',
        influence_capacity: 'Capacidade de Influenciar',
        leadership_profile: 'Perfil de liderança',
        maturity_stage: 'Estágio de Maturidade',
        leadership_style: 'Estilo de liderança',
        values_to_provide: 'Valores a fortalecer',
        values_to_adjust: 'Valores a ajustar',
      },
    },
  },

  components: {
    average_card: {
      general: 'Média geral',
    },

    competence_card: {
      average_title: 'Médias por grupo de resposta',
    },

    star_question: {
      select_a_number: 'Selecciona um número',
    },

    score_question: {
      good: 'Muito bom',
      bad: 'Muito mau',
    },

    terms_of_use: {
      read: 'Eu li e concordo com os',
      terms_link: 'Termos de Utilização',
      and: 'e a',
      policies_link: 'Política de Privacidade',
      platform: 'da Plataforma Humanizadas.',
    },

    table_pagination: {
      items_per_page: 'Itens por página',
      count: '{isPlural, select, true {Itens cadastrados} false {Item cadastrado}}',
      page: 'Página',
      more_button: 'Carregar mais',
    },

    values_matrix: {
      amount: 'Número de características selecionadas',
    },
  },

  forms: {
    login: {
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',
      password_label: 'Palavra-passe',
      forgot_password: 'Esqueci-me da minha palavra-chave',
      forgot_email: 'Esqueci-me do meu e-mail',
      login_button: 'Entrar',
      signup_button: 'Criar conta',

      warning_dialog: {
        title: 'Preencheste o e-mail corretamente?',
        message: 'Certifica-te de que já criaste a tua conta e que utiliza-te o e-mail no qual recebeste o convite para usares a plataforma.',
        cancel_button: 'Preenchido correctamente',
        confirm_button: 'Conferir',
      },
    },

    my_profile: {
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',

      first_name_label: 'Nome',
      first_name_placeholder: 'Por exemplo: Mariana',

      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Por exemplo: Dias',

      current_password_label: 'Senha actual',
      password_label: 'Nova senha',
      confirm_password_label: 'Confirma a tua nova senha',

      working_company_name_label: 'Organização em que operas',
      working_company_name_placeholder: 'Por exemplo: Humanizadas',

      sector_label: 'Sector em que operas',
      sector_placeholder: 'Por exemplo: Administrativo',

      role_label: 'Função em que operas',
      role_placeholder: 'Por exemplo: Gestor Administrativo',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleciona',

      gender_label: 'Género',
      gender_placeholder: 'Por exemplo: Outros',
      gender_tooltip: 'Identidade de género refere-se à experiência de uma pessoa com o seu próprio sexo',

      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      pcd_label: 'PcD (Pessoa com Deficiência)',
      pcd_placeholder: 'Seleciona',

      pcd_tooltip_pt1_title: 'Deficiência física',
      pcd_tooltip_pt1_body: 'alteração completa ou parcial de um ou mais segmentos do corpo humano, resultando em comprometimento da função física, apresentando-se sob a forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputação ou ausência de membro, paralisia cerebral, nanismo, membros com deformidade congénita ou adquirida, exceto as deformidades estéticas e as que não produzam dificuldades para o desempenho de funções',

      pcd_tooltip_pt2_title: 'Deficiência auditiva',
      pcd_tooltip_pt2_body: 'Perda parcial ou total das possibilidades auditivas sonoras, variando de graus e níveis na forma seguinte: de 41 a 55 db - surdez moderada | de 56 a 70 db - surdez acentuada | de 71 a 90 db - surdez severa | acima de 91 db - surdez profunda | anacusia - perda total',

      pcd_tooltip_pt3_title: 'Deficiência visual',
      pcd_tooltip_pt3_body: 'A deficiência visual caracteriza-se pela limitação ou perda das funções básicas do olho e do sistema visual. Os deficientes visuais podem ser as pessoas cegas ou de baixa visão. A cegueira pressupõe a falta de perceção visual devido a factores fisiológicos ou neurológicos. A cegueira total ou simplesmente amaurose é caracterizada por perda total de visão sem perceção visual da luz e forma. A cegueira pode ser congénita ou adquirida. A acuidade visual de pessoas com baixa visão é muito variável; mas, em geral, a baixa visão é definida como uma condição em que a visão da pessoa não pode ser totalmente corrigida por óculos, interferindo nas suas actividades diárias, bem como na leitura e na locomoção.',

      pcd_tooltip_pt4_title: 'Deficiência mental',
      pcd_tooltip_pt4_body: 'Funcionamento intelectual significativamente inferior á média, com manifestação antes dos dezoito anos e limitações associadas a duas ou mais áreas de habilidades adaptativas, tais como: comunicação | cuidado pessoal | habilidades sociais | utilização da comunidade | saúde e segurança | habilidades académicas | lazer | trabalho',

      color_label: 'Cor / Raça',
      color_placeholder: 'Seleciona',
      color_tooltip: 'Como referenciado pelo IBGE',

      sexual_orientation_label: 'Orientação Sexual',
      sexual_orientation_placeholder: 'Seleciona',
      sexual_orientation_tooltip: 'A orientação diz respeito à atração que sente por outros indivíduos. Geralmente também envolve questões sentimentais, não apenas questões sexuais. Referência: UFSC',

      success_dialog: {
        title: 'Alterações guardadas',
        message: 'As tuas alterações foram salvas com sucesso!',
      },
    },

    organization_profile: {
      company_name_label: 'Nome da empresa',
      company_name_placeholder: 'Por exemplo: Humanizadas',
      company_corporate_name_label: 'Denominação social',
      company_corporate_name_placeholder: 'Por exemplo: Humanizadas LTDA',

      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',
      website_label: 'Sítio',
      website_placeholder: 'Por exemplo, www.site.com',
      cnpj_label: 'NIPC',
      cnpj_placeholder: 'Por exemplo: 12.345.678/0001-99',
      state_registry_label: 'Registo do Estado',
      state_registry_placeholder: 'Por exemplo: 000.000.00',
      foundation_date_label: 'Fundação',
      foundation_date_placeholder: 'Por exemplo: 01/01/2020',
      number_of_employees_label: 'Número de empregados',
      number_of_employees_placeholder: 'Por exemplo: 100',
      foundation_city_label: 'Origem',
      foundation_city_placeholder: 'Por exemplo: Belo Horizonte',
      sector_label: 'Sector',
      sector_placeholder: 'Por exemplo: Serviços',

      company_type_label: 'Tipo',
      revenue_label: 'Receitas',

      postal_code_label: 'Código Postal',
      postal_code_placeholder: 'Por exemplo: 12.345-678',
      street_label: 'Rua/avenida',
      street_placeholder: 'Por exemplo: Rua São Félix',
      number_label: 'Número',
      number_placeholder: 'Por exemplo: 10',
      neighborhood_label: 'Bairro',
      neighborhood_placeholder: 'Por exemplo: Planalto',
      complement_label: 'Informação Complementar',
      complement_placeholder: 'Por exemplo: Quarto 101',
      city_label: 'Cidade',
      city_placeholder: 'Por exemplo: Belo-Horizonte',
      state_label: 'Estado',
      state_placeholder: 'Por exemplo: Minas Gerais',

      select_placeholder: 'Seleciona',
      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      select_options: {
        public: 'Pública',
        private: 'Privada',
        public_private: 'Público-privada',
      },
    },

    respondents_builder: {
      first_name_label: 'Nome',
      first_name_placeholder: 'Por exemplo: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Por exemplo: Oliveira',
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',

      add_button: 'Adicionar outro',
      cancel_button: 'Cancelar',
      submit_button: 'Adicionar',
    },

    signup: {
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',
      first_name_label: 'Nome',
      first_name_placeholder: 'Por exemplo: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Por exemplo: Dias',
      cpf_label: 'NIF',
      cpf_placeholder: 'Por exemplo: 123.456.789-10',
      cpf_tooltip: 'Pedimos o seu número de NIF para validar a sua identidade.',
      password_label: 'Palavra-passe',
      password_confirmation_label: 'Confirmar senha',
      login_button: 'Eu já tenho uma conta',
      signup_button: 'Criar registo',
      password_helper: 'Mínimo de 6 caracteres, 1 maiúscula e 1 minúscula',

      dialog: {
        title: 'Muito obrigado por te registares!',
        message: 'Para garantir a tua segurança, caso pretendas aceder à plataforma, terás de iniciar sessão com o utilizador e senha na página de início de sessão.',
        confirm_button: 'Iniciar sessão',
      },

      dialog_warning: {
        title: 'Atenção',
        message: 'Este link de registo é exclusivo do e-mail {email} e só deve ser concluído se tu fores o responsável por isso. Gostarias de continuar?',
        left_button: 'Não',
        right_button: 'Sim',
      },
    },

    support: {
      message_label: 'Mensagem',
      message_placeholder: 'Por exemplo, e-mail não encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Fica de olho no teu e-mail!',
        message: 'Enviaremos mais informações nos próximos dias para apoiar-te. Lembra-te de verificares a tua caixa de spam também.',
        confirm_button: 'Encerrar',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Nome',
      first_name_placeholder: 'Por exemplo: Mariana',
      last_name_label: 'Sobrenome',
      last_name_placeholder: 'Por exemplo: Oliveira',
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',
      message_label: 'Mensagem',
      message_placeholder: 'Por exemplo, e-mail não encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Fica de olho no teu e-mail!',
        message: 'Enviaremos mais informações nos próximos dias para apoiar-te. Lembra-te de verificares a tua caixa de spam também.',
        confirm_button: 'Encerrar',
      },
    },

    password_recovery_enter_email: {
      email_label: 'E-mail',
      email_placeholder: 'Por exemplo: mariana@email.com',

      cancel_button: 'Cancelar',
      confirm_button: 'Enviar',
      email_not_found: 'E-mail não encontrado. Preciso de ajuda.',

      success_dialog: {
        title: 'Fica de olho no teu e-mail!',
        message: 'Receberás um link para redefinires a tua palavra-passe. Lembra-te de verificares a tua caixa de spam também.',
        help_button: 'Não recebi o e-mail',
        confirm_button: 'Encerrar',
      },

      warning_dialog: {
        title: 'Preencheste o e-mail corretamente?',
        message: 'Certifica-te de que já criaste a tua conta e que utiliza-te o e-mail no qual recebeste o convite para usares a plataforma.',

        cancel_button: 'Preenchido correctamente',
        confirm_button: 'Conferir',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Palavra-passe',
      password_confirmation_label: 'Confirmar senha',
      password_helper: 'Mínimo de 6 caracteres, 1 maiúscula e 1 minúscula',

      confirm_button: 'Enviar',

      dialog: {
        title: 'A tua palavra-passe foi alterada com sucesso!',
        message: 'Já podes iniciar a sessão novamente',
        confirm_button: 'Iniciar sessão',
      },
    },

    complete_register: {
      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Por exemplo: Humanizadas',
      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Seleciona',
      working_company_job_title_label: 'Função',
      working_company_job_title_placeholder: 'Seleciona',
      gender_label: 'Género',
      gender_placeholder: 'Seleciona',

      complete_register_dialog: {
        title: 'Fala-nos de ti mesmo!',
        subtitle: 'Precisamos de registar mais informações para completar o teu registo.',
        subtitle2: 'As informações preenchidas a partir daqui serão usadas para fins de pesquisa em conjunto, para garantir, assim, o teu anonimato.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Am. ideal',
      minimum_column: 'Am. mínima',
    },

    respondents: {
      title: 'Inquiridos',
      subtitle_1: 'Os contactos adicionados nesta página também serão adicionados à tua base, e estarão disponíveis no menu "Inquiridos".',
      subtitle_2: 'Nota: Estás a adicionar os inquiridos ao {questionnaire} do {user}.',
      subtitle_3: 'Nota: estás a adicionar inquiridos {showTitle, select, true {ao questionário {title}} false {a este questionário}} {showUser, select, true {do avaliado {user}} false {}}.',
      empty: 'Nenhum entrevistado encontrado',
      no_content: 'Não informado',

      actions: {
        invite: 'Convite para avaliação',
        edit: 'Editar',
        delete: 'Excluir',
        send: 'Enviar um e-mail',
      },

      filter: {
        department: 'Sector',
        jobTitle: 'Função',
      },

      dialogs: {
        empty: {
          title: 'Nenhum utilizador seleccionado.',
          body: 'Selecciona pelo menos um utilizador na tabela para esta acção.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Tipo',
          360: 'Avaliação 360',
          270: 'Avaliação 270',
          180: 'Avaliação 180',
          720: 'Avaliação 720',
          self_assessment: 'Autoavaliação',
        },
      },
      config: {
        view_questionnaire: 'Ver Questionários',
        config_assessment: 'Configurar avaliação',

        dialogs: {
          respondents: {
            title: 'Ainda não tens nenhum inquirido registado na base.',
            body: 'Regista os inquiridos e facilita a configuração das tuas avaliações.',

            left_button: 'Agora não',
            right_button: 'Registar inquiridos',
          },
        },
      },
      monitor: {
        show_report: 'Ver relatório',
        tooltips: {
          play: 'Retomar',
          pause: 'Pausa',
          finish: 'Termine agora',
          results: 'Ver resultado',
          view: 'Ver inquéritos',
        },

        pause_dialog: {
          title: 'Queres parar a avaliação?',
          body_1: 'Enquanto a revisão é interrompida, os inquiridos não poderão vê-la ou enviar respostas. Podes retomá-la a qualquer momento.',
          body_2: 'Nota: a data do fim não será alterada.',
        },

        stop_dialog: {
          title: 'Queres terminar a avaliação?',
          body_1: 'Ao finalizares a avaliação, não poderás recolher mais respostas ou lançá-la novamente. Os resultados serão feitos com base nos dados já recebidos.',
          body_2: 'Se quiseres suspender o julgamento por um período de tempo, tenta fazer uma pausa.',
        },
      },
      release: {
        tooltips: {
          release: 'Lançamento agora',
          edit: 'Editar',
          view: 'Ver inquéritos',
        },


        header: {
          startWithin: 'Começar em',
        },

        release_dialog: {
          title: 'Lançar avaliação agora?',
          body: 'Se divulgares a avaliação agora, será enviada a todos os inquiridos. Esta ação não pode ser desfeita.',
          left_button: 'Aguarda a data de início',
          right_button: 'Lançamento',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'Ver inquéritos',
          results: 'Ver resultado',
          monitor: 'Acompanhar',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Responder à autoavaliação',
        monitor: 'Rastreio de inquiridos',
      },
    },

    header: {
      bulk_actions: 'Acções a granel',
    },

    filters: {
      clean_filters: 'Limpar Filtros',
      apply_filters: 'Aplicar Filtros',
      assessment_title: 'Filtrar Avaliações',
      invited_option_start: 'Até {number} convidados',
      invited_option_middle: 'De {start} a {end} convidados',
      invited_option_end: 'Mais de {number} convidados',
      time_option_hour: 'Próximas {number}h',
      time_option_days: 'Próximos {number} dias',
      assessments_option_start: 'Até {number} avaliações',
      assessments_option_middle: 'De {start} a {end} avaliações',
      assessments_option_end: 'Mais de {number} avaliações',
      questions_option_start: 'Até {number} questões',
      questions_option_middle: 'De {start} a {end} questões',
      questions_option_end: 'Mais de {number} questões',
      assessed_option_start: 'Até {number} avaliados',
      assessed_option_middle: 'De {start} a {end} avaliados',
      assessed_option_end: 'Mais de {number} avaliados',
    },
  },

  dialogs: {
    reminder: {
      title: 'Enviar e-mail',
      body: 'Escolhe o e-mail que pretendes enviar aos inquiridos seleccionados. Ao enviares, este e-mail será accionado, e não poderás desfazer esta acção.',
      body_amount: 'Escolhe o e-mail que pretendes enviar para {num, plural, one {o inquirido seleccionado} other {inquiridos seleccionados}}. Ao enviares, este e-mail será accionado, e não poderás desfazer esta acção.',
      left_button: 'Cancelar',
      right_button: 'Enviar e-mail',
    },
    send_email_all: {
      title: 'Enviar e-mail para os selecionados?',
      title_number: 'Enviar e-mail para {num, plural, one {# inquirido} other {# inquiridos}}?',
      body: 'Ao enviares, este e-mail será accionado, e não poderás desfazer esta acção. Para enviares um e-mail a apenas alguns inquiridos, selecciona-os na tabela abaixo e clica em "Acções a granel".',
      left_button: 'Cancelar',
      right_button: 'Enviar a todos',
    },
    email_successfully_sent: {
      title: 'Envio de {multiple, select, true {emails} false {email}}',
      body: '{multiple, select, true {Todos os emails foram enviados} false{O email foi enviado}} com sucesso!',
      button: 'Concluir',
    },
  },
};
