import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Button from '../Buttons/Button';
import Typography from '../Typography/Typography';
import Box from '../Styled/Box';
import Icon from '../Icons/Icon';

import { useIsMobileLayout } from '../../hooks';

const DefaultDialogContent = ({
  title,
  body,
  variant,
  button,
  onSubmit,
  leftButton,
  closeDialog,
  icon,
  boxProps,
}) => {
  const isMobile = useIsMobileLayout();

  return (
    <Box p={isMobile ? '24px' : '40px'} {...boxProps}>
      {icon && (
        <Box
          display='flex'
          width='100%'
          mb={3}
          justifyContent='center'
        >
          <Icon icon={icon} size='2x' color={variant} />
        </Box>
      )}

      <Typography block variant='h4' color='purple100'>
        {title}
      </Typography>

      {typeof body === 'string' && (
        <Typography block variant='body' color='coldgrey600' mt={3}>
          {body}
        </Typography>
      )}

      {typeof body !== 'string' && (
        <Box mt={3}>
          {body}
        </Box>
      )}

      {(Boolean(leftButton) || Boolean(button)) && (
        <Box
          mt={4}
          display='flex'
          justifyContent={isMobile ? 'center' : 'flex-end'}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          {Boolean(leftButton) === true && (
            <Button
              variant='secondary'
              onClick={() => {
                if (leftButton.onClick) { leftButton.onClick(); }
                closeDialog();
              }}
              centerText={isMobile}
              mt={isMobile ? 2 : 0}
              {...leftButton.props}
            >
              {leftButton.text || 'Cancelar'}
            </Button>
          )}

          {Boolean(button) && (
            <Button
              variant={variant}
              onClick={() => {
                if (onSubmit) { onSubmit(); }
                else if (button && button.onClick) { button.onClick(); }
                closeDialog();
              }}
              centerText={isMobile}
              ml={isMobile ? 0 : 2}
              {...button.props}
            >
              {button.text || 'OK'}
            </Button>
          )}
        </Box>
      ) }
    </Box>
  );
};

DefaultDialogContent.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  variant: PropTypes.string,
  theme: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSubmit: PropTypes.func,
  closeDialog: PropTypes.func.isRequired,
  boxProps: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  button: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.any,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    props: PropTypes.object,
  }),
  leftButton: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.any,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    props: PropTypes.object,
  }),
};

DefaultDialogContent.defaultProps = {
  onSubmit: null,
  body: '',
  title: '',
  variant: 'primary',
  width: '100%',
  button: null,
  leftButton: null,
  icon: null,
  boxProps: {},
};

export default withTheme(DefaultDialogContent);
