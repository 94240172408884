import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  graphism: true,
  color: false,
  image: '',
  hubSpotCss: '',
};

const initialFunctions = {
  updateValues: () => {},
  resetValue: () => {},
};

const LayoutContext = React.createContext({ ...initialFunctions, ...initialState });

const LayoutProvider = ({ children }) => {
  const [values, setValues] = useState(initialState);

  const updateValues = useCallback((newValues) => {
    setValues(newValues);
  }, []);

  const resetValues = useCallback(() => {
    setValues(initialState);
  }, []);

  return (
    <LayoutContext.Provider value={{ ...values, updateValues, resetValues }}>
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { LayoutContext, LayoutProvider };
