import { useCallback, useRef, useEffect } from 'react';

function useDidMount(fn, inputs) {
  const didMountRef = useRef(false);

  const callback = useCallback(fn, [fn]);

  useEffect(() => {
    if (didMountRef.current) {
      callback();
    }
    else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...inputs, callback]);
}

export default useDidMount;
