import authConstants from './auth/auth.constants';
import userConstants from './user/user.constants';
import applicationConstants from './application/application.constants';

export { authConstants };
export { userConstants };
export { applicationConstants };

export default {
  auth: authConstants,
  user: userConstants,
  application: applicationConstants,
};
