import browserLocale from 'browser-locale';
import { defaultLanguage, languages } from '../../config/maps';
import c from './user.constants';

const selectLanguage = () => {
  const locale = browserLocale();
  const hasLanguage = languages.find((d) => {
    const _language = locale.split('-')[0];
    return d.id === locale || d.language === locale || d.language === _language;
  });

  if (hasLanguage) {
    return hasLanguage.id;
  }

  return defaultLanguage;
};

const initialState = {
  user: null,
  showWelcomeDialog: true,
  activeLanguage: selectLanguage(),
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case c.RESET_STORE: {
    localStorage.setItem(c.REDUX_NAMESPACE, null);
    return { ...initialState };
  }

  case c.SET_SHOW_WELCOME_DIALOG: {
    const { flag } = payload;

    return {
      ...state,
      showWelcomeDialog: flag,
    };
  }

  case c.SET_ACTIVE_COMPANY: {
    const { company } = payload;

    return {
      ...state,
      currentCompany: (company || {}).id,
    };
  }

  case c.UPDATE_COMPANY_USER_ROLE: {
    const { companyUserId, role } = payload;

    return {
      ...state,
      user: {
        ...state.user,
        companies_users: state.user.companies_users
          .map((d) => (d.id === companyUserId ? {
            ...d,
            role,
          } : d)),
      },
    };
  }

  case c.ON_UPDATE_COMPANY_SUCCESS:
  case c.ON_SHOW_COMPANY_SUCCESS: {
    const { company } = payload;

    return {
      ...state,
      user: {
        ...state.user,
        companies_users: state.user.companies_users
          .map((d) => (d.company.id === (company || {}).id ? {
            ...d,
            company: {
              ...company,
              fromApi: true,
            },
          } : d)),
      },
    };
  }

  case c.ON_FETCH_USER_SUCCESS: {
    const { user } = payload;
    return { ...state, user };
  }

  case c.ON_CREATE_USER_SUCCESS:
  case c.ON_UPDATE_USER_SUCCESS: {
    const { user } = payload;
    return { ...state, user };
  }

  case c.SET_ACTIVE_LANGUAGE: {
    return {
      ...state,
      activeLanguage: payload,
    };
  }

  default:
    return state;
  }
};

export default reducer;
