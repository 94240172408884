import { useState, useEffect, useCallback } from 'react';

const mountSortParams = (sort) => Object.keys(sort)
  .map((key) => `q[sorts][]=${key} ${sort[key]}`).join('&');

const useTableApi = ({
  callback,
  initialValues = {},
  transformData = (d) => d,
  getResponseData = () => {},
} = {}) => {
  const [data, setData] = useState(initialValues.data || null);
  const [totalPages, setTotalPages] = useState(initialValues.totalPages || 1);
  const [count, setCount] = useState(initialValues.count || 1);
  const [loading, setLoading] = useState(initialValues.loading || true);
  const [perPage, setPerPage] = useState(initialValues.perPage || 10);
  const [filters, setFilters] = useState(initialValues.filters || null);
  const [search, setSearch] = useState(initialValues.search || '');
  const [sort, setSort] = useState(initialValues.sort || {});

  const [initialized, setInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResults, setNoResults] = useState(false);
  const [fetchData, setFetchData] = useState(() => callback);

  const _fetchData = useCallback(async () => {
    const controlParams = [
      `page=${currentPage}`,
      `per_page=${perPage}`,
    ].join('&');

    const filterParams = [
      filters,
      search,
    ].filter((d) => d).join('&');

    const params = [
      controlParams,
      filterParams,
      mountSortParams(sort),
    ].filter((d) => d).join('&');

    setLoading(true);

    const { data: apiData, ...response } = await fetchData(params);

    getResponseData(response);
    setData(transformData(apiData));
    setCurrentPage(response.current_page);
    setCount(response.count);
    setTotalPages(response.total_pages);
    setNoResults(Boolean(filterParams && (apiData.length === 0)));

    setLoading(false);
    setInitialized(true);
  }, [
    fetchData,
    currentPage,
    filters,
    search,
    perPage,
    getResponseData,
    transformData,
    sort,
  ]);

  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    if (initialized) {
      if (currentPage > 1) {
        setCurrentPage(1);
      }
      else {
        _fetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, search, perPage, sort]);

  useEffect(() => {
    if (initialized) {
      _fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  return {
    setPage: setCurrentPage,
    setFilters,
    setSearch,
    setPerPage,
    setData,
    setCount,
    setSort,
    request: _fetchData,
    setCallback: useCallback((d) => setFetchData(() => d), []),
    resetData: useCallback(() => setData(null), []),
    response: {
      data,
      currentPage,
      totalPages,
      count,
    },
    state: {
      sort,
      search,
      filters,
      loading,
      perPage,
      noResults,
    },
  };
};

export default useTableApi;
