import { useState, useCallback } from 'react';

function useAPI(callback) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const request = useCallback(async (...args) => {
    setLoading(true);

    try {
      const _response = await callback(...args);
      setResponse(_response);
    }

    catch (err) {
      setError(err);
    }

    finally {
      setLoading(false);
    }
  }, [callback]);

  return {
    response,
    loading,
    error,
    request,
  };
}

export default useAPI;
