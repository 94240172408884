import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  color,
  space,
  shadow,
  layout,
  border,
  flexbox,
  position,
} from 'styled-system';
import { darken } from 'polished';
import { get } from 'lodash';

import Box from '../Styled/Box';
import Typography from '../Typography/Typography';
import Icon from '../Icons/Icon';
import Avatar from '../Avatars/Avatar';

import { useDimensions } from '../../hooks';

const StyledLink = styled(Link)`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  z-index: 1;
`;

const Button = styled.button`
  position: relative;
  outline: none;
  border-radius: 80px;
  border: none;
  text-align: center;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  transition: ${({ theme }) => theme.transitions.button};
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  user-select: none;
  box-sizing: border-box;

  ${color};
  ${space};
  ${shadow};
  ${layout};
  ${border};
  ${flexbox};
  ${position};

  ${({
    backgroundColor,
    hoverColor,
    disabled,
    theme,
  }) => !disabled && `
      &:hover {
        background-color: ${hoverColor || darken(0.05, theme.colors[backgroundColor] || backgroundColor)};
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform .5s, opacity 1s;
      }

      &:active::after {
        transform: scale(0, 0);
        opacity: .3;
        transition: 0s;
      }
    `};
`;

const getTypographyColor = (typographyColorProps, hovered) => (
  hovered
    ? ((typographyColorProps || {}).hoverColor || (typographyColorProps || {}).color)
    : (typographyColorProps || {}).color
);

const StyledButton = ({
  typographyProps,
  children,
  variant,
  disabled,
  size,
  theme,
  leftIcon,
  rightIcon,
  link,
  centerText,
  linkProps,
  image,
  avatar,
  imageProps,
  ...buttonProps
}) => {
  const [hovered, setHovered] = useState(false);
  const sizeProps = theme.buttons.size[size] ? theme.buttons.size[size] : theme.buttons.size.md;

  const _variant = disabled ? 'disabled' : variant;
  const colorProps = variant !== 'unset' ? (theme.buttons.variants[_variant] || theme.buttons.variants.primary) : {};
  const darkDisabled = variant === 'dark' && disabled && 'background.dark';

  const typographyColor = getTypographyColor(colorProps.typography, hovered);

  const [buttonRef, buttonDimensions] = useDimensions();


  return (
    <Button
      ref={buttonRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      type='button'
      disabled={disabled}
      {...sizeProps.button}
      {...darkDisabled ? { backgroundColor: get(colorProps.background, darkDisabled, '') } : colorProps.background}
      {...buttonProps}
    >
      {link && (
        <StyledLink
          to={link}
          width={buttonDimensions.width}
          height={buttonDimensions.height}
          {...linkProps}
        />
      )}

      <Box
        display='flex'
        alignItems='center'
        justifyContent={centerText ? 'center' : 'flex-start'}
        style={{ whiteSpace: 'nowrap' }}
      >
        {leftIcon && (
          <Icon
            fixedWidth
            mr={2}
            fontSize='1rem'
            color={typographyColor || typographyProps.color || null}
            {...leftIcon}
          />
        )}

        {image && (
          <img alt='language' src={image} {...imageProps} />
        )}

        {avatar.alt && (
          <Box mr={2} maxHeight='38px'>
            <Avatar size='sm' style={{ height: 24, width: 24 }} {...avatar} />
          </Box>
        )}

        <Typography
          {...sizeProps.typography}
          {...{ ...colorProps.typography, color: typographyColor }}
          {...typographyProps}
        >
          {children}
        </Typography>

        {rightIcon && (
          <Icon
            fixedWidth
            ml={2}
            fontSize='1rem'
            color={typographyColor || typographyProps.color || null}
            {...rightIcon}
          />
        )}
      </Box>
    </Button>
  );
};

StyledButton.propTypes = {
  theme: PropTypes.object.isRequired,
  variant: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  disabled: PropTypes.bool,
  typographyProps: PropTypes.object,
  children: PropTypes.any,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  link: PropTypes.string,
  centerText: PropTypes.bool,
  linkProps: PropTypes.object,
  image: PropTypes.node,
  avatar: PropTypes.object,
  imageProps: PropTypes.object,
};

StyledButton.defaultProps = {
  size: 'md',
  variant: 'primary',
  disabled: false,
  typographyProps: {},
  children: '',
  leftIcon: null,
  rightIcon: null,
  link: null,
  centerText: false,
  linkProps: {},
  image: null,
  avatar: {},
  imageProps: {},
};

export default withTheme(StyledButton);
