import { takeLatest, put } from 'redux-saga/effects';
import browserLocale from 'browser-locale';
import { sagaFunctionBuilder } from '../reduxUtils';
import { endpoints } from '../../config/endpoints';
import API from '../../services/API';
import c from './auth.constants';

import { userConstants } from '../constants';
import { userActions, applicationActions } from '../actions';

import { joinUrlWithParams } from '../../utils';
import { setActiveLanguage, setCurrentCompany } from '../user/user.saga';

function* authenticateUser({ email, password, params = '' }) {
  const ENDPOINT = endpoints.auth.login;
  const payload = { email, password };

  return yield API()({
    url: `${ENDPOINT.url}?${params}`,
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
    data: payload,
  });
}

function* updateUnauthenticatedUser({ user, params, headers }) {
  const ENDPOINT = endpoints.auth.resetPassword;

  return yield API({ config: { headers }, options: { noApiV1: true } })({
    url: joinUrlWithParams(ENDPOINT.url, params),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
    data: user,
  });
}

function* authenticateRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    yield put(applicationActions.toggleLoading());
    const response = yield authenticateUser(payload);
    const { headers, data } = response;

    const token = headers['access-token'];
    const tokenType = headers['token-type'];
    const { client, uid, expiry } = headers;

    const accessHeaders = {
      token,
      tokenType,
      client,
      uid,
      expiry,
    };

    localStorage.setItem(c.ACCESS_HEADERS, JSON.stringify(accessHeaders));

    const user = data.data;
    const language = user.locale || browserLocale();

    if (!payload.stayLoggedIn) {
      yield put(applicationActions.disablePersistUser());
    }
    yield setCurrentCompany({ payload: { user } });
    yield setActiveLanguage({ payload: language });

    // vamos testar
    // localStorage.setItem(userConstants.REDUX_NAMESPACE, JSON.stringify(user || ''));

    yield put(userActions.onFetchUserSuccess(user));
  });
}

function* updateUnauthenticatedUserRequest({ payload }) {
  yield sagaFunctionBuilder(payload, function* saga() {
    const response = yield updateUnauthenticatedUser(payload);
    const { headers, data } = response;

    const token = headers['access-token'];
    const tokenType = headers['token-type'];
    const { client, uid, expiry } = headers;

    const accessHeaders = {
      token,
      tokenType,
      client,
      uid,
      expiry,
    };

    // vamos testar
    // localStorage.setItem(userConstants.REDUX_NAMESPACE, JSON.stringify(data.data || ''));

    localStorage.setItem(c.ACCESS_HEADERS, JSON.stringify(accessHeaders));

    yield put(userActions.onFetchUserSuccess(data.data));
  });
}

function* logoutRequest() {
  yield localStorage.setItem(c.ACCESS_HEADERS, null);
  yield localStorage.setItem(userConstants.REDUX_NAMESPACE, null);
  yield put(userActions.resetStore());
}

// process.env.REACT_APP_ENV !== 'production' ? JSON.parse(localStorage.getItem(c.REDUX_NAMESPACE) || null) : null

function* restoreUser() {
  const user = JSON.parse(localStorage.getItem(userConstants.REDUX_NAMESPACE));

  if (user) {
    const language = user.locale || browserLocale();

    yield setCurrentCompany({ payload: { user } });
    yield setActiveLanguage({ payload: language });
    yield put(userActions.onFetchUserSuccess(user));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(c.AUTHENTICATE_REQUEST, authenticateRequest);
  yield takeLatest(c.UPDATE_UNAUTHENTICATED_USER, updateUnauthenticatedUserRequest);
  yield takeLatest(c.LOGOUT_REQUEST, logoutRequest);
  yield takeLatest(c.RESTORE, restoreUser);
}
