import axios from 'axios';
import { v4 as uuid } from 'uuid';
import API from '../API';
import { API_HOST, endpoints } from '../../config/endpoints';

export const getFileExtension = ({ type }) => {
  const split = type.split('/');
  return split[split.length - 1];
};

export const mountFilename = (file) => {
  const ext = getFileExtension(file);
  const filename = `${uuid()}.${ext}`;
  return filename;
};

export function* submitFileToS3(presignedUrl, file) {
  return yield axios({
    url: presignedUrl,
    method: 'PUT',
    headers: { 'Content-Type': file.type },
    data: file,
  });
}

export function* getS3PresignedURL(filename) {
  const ENDPOINT = endpoints.s3.getS3URL;

  return yield API({ config: { baseURL: API_HOST.replace('/v1', '') } })({
    url: ENDPOINT.url.replace(':file_name', filename),
    method: ENDPOINT.method,
    headers: ENDPOINT.headers,
  });
}

export function* uploadFileToS3(file, oldFilename) {
  let filename = mountFilename(file);

  if (oldFilename) {
    filename = new URL(oldFilename).pathname.replace('/', '');
  }

  const { data: { data: { presigned_aws_url } } } = yield getS3PresignedURL(filename);
  yield submitFileToS3(presigned_aws_url, file);

  const { origin, pathname } = new URL(presigned_aws_url);
  return `${origin}${pathname}`;
}
