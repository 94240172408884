import { createSelector } from 'reselect';
import { get } from 'lodash';

import c from './user.constants';

const getStore = (store) => store[c.REDUX_NAMESPACE];

const _userSelector = (store) => getStore(store).user;

const showWelcomeDialogSelector = (store) => getStore(store).showWelcomeDialog;

const _currentCompanySelector = (store) => getStore(store).currentCompany;

const activeLanguage = (store) => getStore(store).activeLanguage;

const currentCompanyIdSelector = _currentCompanySelector;

const userIdSelector = createSelector(
  _userSelector,
  (user) => (user || {}).id,
);

const usernameSelector = createSelector(
  _userSelector,
  (user) => [get(user, 'first_name', ''), get(user, 'last_name', '')]
    .filter((d) => d)
    .join(' '),
);

const userSelector = createSelector(
  _userSelector,
  usernameSelector,
  (user, username) => ({ ...user, username }),
);

const userAttributeSelector = (attribute) => createSelector(
  userSelector,
  (user) => (user || {})[attribute],
);

const currentCompanyUserSelector = createSelector(
  currentCompanyIdSelector,
  userAttributeSelector('companies_users'),
  (currentCompanyId, companiesUsers) => (companiesUsers || [])
    .find((d) => d.company_id === currentCompanyId),
);

const currentCompanySelector = createSelector(
  currentCompanyUserSelector,
  (companyUser) => (companyUser || {}).company,
);

const userIsManagerSelector = createSelector(
  userAttributeSelector('companies_users'),
  (companies_users) => (companies_users || []).length > 0,
);

const userIsOwnerSelector = createSelector(
  userAttributeSelector('companies_users'),
  currentCompanySelector,
  (companies_users, currentCompany) => {
    const companyUser = (companies_users || [])
      .find((d) => d.company_id === (currentCompany || {}).id);

    return get(companyUser, 'role') === 'owner';
  },
);

const companiesSelector = createSelector(
  userAttributeSelector('companies_users'),
  (companies_users) => (companies_users || []).map((d) => d.company),
);

const userHasCompletedRegistrationSelector = createSelector(
  userAttributeSelector('working_company_name'),
  userAttributeSelector('working_company_job_title'),
  userAttributeSelector('working_company_department'),
  userAttributeSelector('gender'),
  userAttributeSelector('biological_sex'),
  userAttributeSelector('sexual_orientation'),
  userAttributeSelector('color'),
  userAttributeSelector('pcd'),
  (...args) => (args || []).filter((d) => d).length !== 0,
);

const userHasAcceptedLegalAgreement = createSelector(
  userAttributeSelector('legal_agreement'),
  (legal_agreement) => legal_agreement,
);

export default {
  getStore,

  showWelcomeDialog: showWelcomeDialogSelector,

  user: userSelector,
  user_id: userIdSelector,
  userId: userIdSelector,
  username: usernameSelector,
  attribute: userAttributeSelector,
  getUserAttribute: userAttributeSelector,

  userIsManager: userIsManagerSelector,
  userIsOwner: userIsOwnerSelector,

  companies: companiesSelector,
  currentCompany: currentCompanySelector,
  currentCompanyId: currentCompanyIdSelector,
  currentCompanyUser: currentCompanyUserSelector,

  userHasCompletedRegistration: userHasCompletedRegistrationSelector,
  activeLanguage,
  userHasAcceptedLegalAgreement,
};
