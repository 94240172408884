const companies_users_fields = [
  'id',
  'company_id',
  'role',
  'company[id, name, corporate_name]',
].join(',');

const loginFields = [
  'id',
  'avatar_url',
  'password_changed',
  'first_name',
  'last_name',
  'email',
  'gender',
  'working_company_name',
  'working_company_job_title',
  'working_company_department',
  'biological_sex',
  'sexual_orientation',
  'color',
  'pcd',
  `companies_users[${companies_users_fields}]`,
  'locale',
  'legal_agreement',
].join(',');

const companyFields = [
  'id',
  'corporate_name',
  'name',
  'cpf_cnpj',
  'website',
  'avatar_url',
  'email',
  'address',
  'state_registry',
  'foundation_date',
  'foundation_city',
  'number_of_employees',
  'company_type',
  'sector',
  'revenue',
].join(',');

const fields = {
  login: loginFields,
  show: loginFields,
  company: companyFields,
};

const params = {
  // login: `fields=${fields.login}`,
  login: `fields=${fields.login}`,
  company: `fields=${fields.company}`,
};

fields.default = fields.login;
params.default = params.login;

export default {
  REDUX_NAMESPACE: 'HUMANIZADAS/USER',
  RESET_STORE: 'USER/RESET_STORE',

  SAVE_USER_ON_STORE: 'USER/SAVE_USER_ON_STORE',
  DISABLE_LOADING: 'USER/DISABLE_LOADING',
  ENROLL_USER: 'USER/ENROLL_USER',

  FETCH_USER_REQUEST: 'USER/FETCH_USER_REQUEST',
  ON_FETCH_USER_SUCCESS: 'USER/FETCH_USER_SUCCESS',
  ON_FETCH_USER_FAIL: 'USER/FETCH_USER_FAIL',

  CREATE_USER_REQUEST: 'USER/CREATE_USER_REQUEST',
  ON_CREATE_USER_SUCCESS: 'USER/CREATE_USER_SUCCESS',
  ON_CREATE_USER_FAIL: 'USER/CREATE_USER_FAIL',

  UPDATE_USER_REQUEST: 'USER/UPDATE_USER_REQUEST',
  ON_UPDATE_USER_SUCCESS: 'USER/UPDATE_USER_SUCCESS',
  ON_UPDATE_USER_FAIL: 'USER/UPDATE_USER_FAIL',

  UPDATE_COMPANY_USER_ROLE: 'USER/UPDATE_COMPANY_USER_ROLE',

  SET_SHOW_WELCOME_DIALOG: 'USER/SET_SHOW_WELCOME_DIALOG',
  SET_ACTIVE_COMPANY: 'USER/SET_ACTIVE_COMPANY',

  SHOW_COMPANY_REQUEST: 'COMPANY/SHOW_COMPANY_REQUEST',
  ON_SHOW_COMPANY_SUCCESS: 'COMPANY/SHOW_COMPANY_SUCCESS',
  ON_SHOW_COMPANY_FAIL: 'COMPANY/SHOW_COMPANY_FAIL',

  UPDATE_COMPANY_REQUEST: 'COMPANY/UPDATE_COMPANY_REQUEST',
  ON_UPDATE_COMPANY_SUCCESS: 'COMPANY/UPDATE_COMPANY_SUCCESS',
  ON_UPDATE_COMPANY_FAIL: 'COMPANY/UPDATE_COMPANY_FAIL',

  SET_ACTIVE_LANGUAGE: 'USER/SET_ACTIVE_LANGUAGE',

  fields,
  params,
};
