import useDidMount from './useDidMount';
import useDidUpdate from './useDidUpdate';
import useDimensions from './useDimensions';
import useEndpoints from './useEndpoints';
import usePosition from './usePosition';
import useRoutes from './useRoutes';
import useTheme from './useTheme';
import useHomepages from './useHomepages';
import { useWindowSize, useIsMobileLayout } from './useWindowSize';

import useApi from './apis/useApi';
import useTableApi from './apis/useTableApi';
import useCustomLayout from './useCustomLayout';

export { useDidMount };
export { useDimensions };
export { useEndpoints };
export { useRoutes };
export { useTheme };
export { useApi };
export { useTableApi };
export { usePosition };
export { useHomepages };
export { useWindowSize };
export { useIsMobileLayout };
export { useCustomLayout };

export default {
  useDidMount,
  useDimensions,
  useEndpoints,
  useRoutes,
  useDidUpdate,
  useTheme,
  useApi,
  useTableApi,
  usePosition,
  useHomepages,
  useWindowSize,
  useIsMobileLayout,
  useCustomLayout,
};
