import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useTheme } from '../../hooks';

const CustomSkeleton = ({ themeProps, ...props }) => {
  const theme = useTheme();

  return (
    <SkeletonTheme color={theme.colors.coldgrey300} {...themeProps}>
      <Skeleton {...props} />
    </SkeletonTheme>
  );
};

CustomSkeleton.propTypes = {
  themeProps: PropTypes.object,
};

CustomSkeleton.defaultProps = {
  themeProps: {},
};

export default CustomSkeleton;
