import {
  applyMiddleware,
  createStore,
  compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import logger from 'redux-logger';

import reducers from './reducers';
import watcher from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = process.env.NODE_ENV === 'production'
  ? applyMiddleware(sagaMiddleware)
  : compose(applyMiddleware(sagaMiddleware), applyMiddleware(logger));

const store = createStore(
  reducers,
  middlewares,
);

sagaMiddleware.run(watcher);

export default store;
