import constants from './caches.constants';

const getStore = (store) => store[constants.REDUX_NAMESPACE];

const cachedValue = (key) => (store) => getStore(store)[key];

export default {
  getStore,
  cachedValue,
};
