import { rgba } from 'polished';

const black = '#000000';
const white = '#FFFFFF';

const pink = '#FF666E';
const yellow = '#FFC965';
const orange = '#FF5E20';

const success = '#2DAE7D';
const warning = '#F7951D';
const danger = '#F4392F';

const warmgrey = '#B2AEAE';
const coldgrey = '#FFFFFF';

const coldgrey100 = '#F7F8FB';
const coldgrey200 = '#EFF1F5';
const coldgrey300 = '#E4E7EC';
const coldgrey400 = '#CDD2DB';
const coldgrey500 = '#A6AEBA';
const coldgrey600 = '#6F798B';
const coldgrey700 = '#4D5365';
const coldgrey800 = '#3A3C4B';
const coldgrey900 = '#242533';

const purple_humanizadas = '#A026FB';
const humanizadas = '#BA0DE9';
const lightpurple = '#67568C';
const purple24 = '#EFBAFE';
const purple64 = '#E17EFC';
const purple72 = '#D354F5';
const purple100 = '#AB42C8';
const darkpurple = '#5B0772';
const purple = purple100;

const blue = '#6B77E5';
const lightblue = '#A0A8EE';
const extralightblue = '#DBDEF9';
const darkblue = '#4954B5';
const darkblue100 = '#020035';

const teal = '#00E6D2';
const teal16 = rgba(teal, 0.16);
const teal72 = rgba(teal, 0.72);

const legend1 = '#B1CCFF';
const legend2 = '#5A86DA';
const legend3 = '#16459D';
const legend4 = '#C5F0A3';
const legend5 = '#6CBF2A';
const legend6 = '#3E7115';
const legend7 = '#FAAEF2';
const legend8 = '#D248C5';
const legend9 = '#941388';
const legend10 = '#9F9DF7';
const legend11 = '#7558E9';
const legend12 = '#20048F';
const legend13 = '#FEB9B5';
const legend14 = '#EA2578';
const legend15 = '#BBF0F2';
const legend16 = '#0B898D';
const legend17 = '#A4ECD1';
const legend18 = '#1E6349';
const legend19 = '#D0C0BF';
const legend20 = '#9F7270';
const legend21 = '#551F0E';
const legend22 = '#ECE893';
const legend23 = '#BEB953';
const legend24 = '#7E7A15';
const legend25 = '#FF75AF';

const PRIMARY = purple;
const HOVER_PRIMARY = darkpurple;

const colors = {
  primary: PRIMARY,
  humanizadas,
  hover_primary: HOVER_PRIMARY,

  blue,
  lightpurple,
  pink,
  orange,
  orange100: orange,

  purple,
  purple24,
  purple64,
  purple72,
  purple100,
  darkpurple,
  purple_humanizadas,

  darkblue,
  darkblue100,

  teal,
  teal100: teal,
  teal16,
  teal72,

  yellow,
  success,
  warning,
  danger,
  black,
  white,
  lightblue,
  extralightblue,

  icons: coldgrey500,
  icon: coldgrey600,

  warmgrey,
  coldgrey,
  coldgrey100,
  coldgrey200,
  coldgrey300,
  coldgrey400,
  coldgrey500,
  coldgrey600,
  coldgrey700,
  coldgrey800,
  coldgrey900,

  legend1,
  legend2,
  legend3,
  legend4,
  legend5,
  legend6,
  legend7,
  legend8,
  legend9,
  legend10,
  legend11,
  legend12,
  legend13,
  legend14,
  legend15,
  legend16,
  legend17,
  legend18,
  legend19,
  legend20,
  legend21,
  legend22,
  legend23,
  legend24,
  legend25,

  getOpaqueColor(color, index = 13) {
    const opacity = (index * 0.08) < 1 ? index * 0.08 : 1;
    return rgba(this[color] || color, opacity);
  },
};
export default colors;
