/* eslint-disable no-console */
import { library } from '@fortawesome/fontawesome-svg-core';
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';

export default () => {
  library.add(
    fas.faCheck,
    far.faCheckCircle,
    far.faCopy,
    fas.faIndustry,
    far.faListAlt,
    fas.faStarHalfAlt,
    far.faCircle,
    far.faUserCircle,
    fas.faChevronDown,
    fas.faChevronUp,
    far.faClipboard,
    fas.faPencilAlt,
    fas.faUsers,
    fas.faArrowLeft,
    fas.faArrowRight,
    fas.faArrowUp,
    fas.faCaretDown,
    fas.faCaretUp,
    fas.faCheckCircle,
    fas.faCheckSquare,
    fas.faChevronDown,
    fas.faChevronLeft,
    fas.faChevronRight,
    fas.faCircle,
    fas.faClipboardCheck,
    fas.faCog,
    fas.faDotCircle,
    fas.faDownload,
    fas.faEllipsisH,
    fas.faEllipsisV,
    fas.faEnvelope,
    fas.faEye,
    fas.faEyeSlash,
    fas.faFileAlt,
    fas.faForward,
    fas.faHome,
    fas.faMinusSquare,
    fas.faMoneyBill,
    fas.faNetworkWired,
    fas.faPause,
    fas.faPlay,
    fas.faPlus,
    fas.faQuestion,
    fas.faQuestionCircle,
    fas.faRandom,
    fas.faSearch,
    fas.faSignOutAlt,
    fas.faSlidersH,
    fas.faSquare,
    fas.faStar,
    fas.faStop,
    fas.faTimes,
    fas.faTrash,
    fas.faTrashAlt,
    fas.faUpload,
    fas.faUserCircle,
    fas.faChartLine,
    fas.faChartBar,
    fas.faChevronCircleRight,
    fas.faFile,
    fas.faFlag,
    fas.faFileAlt,
    fas.faSave,
    fas.faSpinner,
    fas.faCommentDots,
  );
};
