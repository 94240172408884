import {
  useState,
  useEffect,
} from 'react';

// Hook
export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useIsMobileLayout = () => {
  const windowSize = useWindowSize();
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    setIsMobileLayout(windowSize && windowSize.width < 1024);
  }, [windowSize]);

  return isMobileLayout;
};

export const useIsTabletLayout = () => {
  const windowSize = useWindowSize();
  const [isTabletLayout, setIsTabletLayout] = useState(false);

  useEffect(() => {
    setIsTabletLayout(windowSize && windowSize.width >= 1024 && windowSize.width < 1280);
  }, [windowSize]);

  return isTabletLayout;
};

export const useFullHdLayout = () => {
  const windowSize = useWindowSize();
  const [isFullHdLayout, setIsFullHdLayout] = useState(false);

  useEffect(() => {
    setIsFullHdLayout(windowSize && windowSize.width >= 1920);
  }, [windowSize]);

  return isFullHdLayout;
};
