// eslint-disable-next-line no-unused-vars
import enRoutes from './routes-en';
import ptRoutes from './routes-ptbr';

export default {
  pt: ptRoutes,
  'pt-BR': ptRoutes,
  'pt-PT': ptRoutes,
  // en: enRoutes,
  // 'en-029': enRoutes,
  // 'en-AU': enRoutes,
  // 'en-BZ': enRoutes,
  // 'en-CA': enRoutes,
  // 'en-GB': enRoutes,
  // 'en-IE': enRoutes,
  // 'en-IN': enRoutes,
  // 'en-JM': enRoutes,
  // 'en-MY': enRoutes,
  // 'en-NZ': enRoutes,
  // 'en-PH': enRoutes,
  // 'en-SG': enRoutes,
  // 'en-TT': enRoutes,
  // 'en-US': enRoutes,
  // 'en-ZA': enRoutes,
  // 'en-ZW': enRoutes,
};
