import API from '../../API.redux';
import { endpoints } from '../../../config/endpoints';
import { joinUrlWithParams, joinUrlWithRequiredParams } from '../../../utils';

export const defaultFields = {
  index: 'all,cla,company[name],applications_questionnaires',
  show: 'all,company[name],applications_questionnaires[id,assessment_type,assessment_subtype,token,default_link_open,users_invited,users_answered,users_answered_percentage,questionnaire[id,role,role_name],start_date,end_date,questions_count,week_before_reminder,day_before_reminder,enrollments]',
};

export const defaultParams = {
  index: `fields=${defaultFields.index}`,
  show: `fields=${defaultFields.show}`,
};

export const getEndpoint = (which) => endpoints.respondents.applications[which];

export const fetchApplicationsIndex = ({
  apiArgs = {},
  params = defaultParams.show,
} = {}) => {
  const ENDPOINT = getEndpoint('index');

  return API(apiArgs)({
    url: joinUrlWithParams(ENDPOINT.url, params),
    method: ENDPOINT.method,
  });
};

export const showApplication = ({
  id,
  apiArgs = {},
  params = defaultParams.show,
} = {}) => {
  const ENDPOINT = getEndpoint('show');

  return API(apiArgs)({
    url: joinUrlWithParams(ENDPOINT.url.replace(':id', id), params),
    method: ENDPOINT.method,
  });
};

export const showApplicationByToken = ({
  params,
  apiArgs = {},
  applicationToken,
  roleType,
  assessmentType,
} = {}) => {
  const ENDPOINT = getEndpoint('show_by_token');

  return API(apiArgs)({
    url: joinUrlWithRequiredParams({
      params,
      url: ENDPOINT.url,
      requiredParams: ENDPOINT.requiredParams,
      requiredParamsValues: {
        token: applicationToken,
        role_type: roleType,
        assessment_type: assessmentType,
      },
    }),
    method: ENDPOINT.method,
  });
};
