export default {
  criar_conta: {
    simple: 'Inicie Sesión',
  },

  entrar: {
    simple: 'Inicie Sesión',
  },

  olar: {
    simple: '¡Hola!',
    placeholder: '¡Hola {name}!',
  },

  plural: {
    simple: 'Tengo {num, plural, one { cat} other { cats}}',
  },

  select: {
    placeholder: 'Seleccione',
    simple: 'Soy {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Perfil de la organización',
      my_profile: 'Mi cuenta',
      respondents: 'Encuestados de la organización',
      managers: 'Gerentes',
    },
    assessments: 'Evaluaciones',
    choose_view_mode: 'Cambiar a {isManagerView, select, true {respondedor} false {gerente}}',
    sign_out: 'Salir de mi cuenta',
    help: 'Necesito ayuda',
    see_all_companies: 'Ver todas las empresas',
    answer_assessments: 'Responder evaluaciones',
  },

  statuses: {
    questionnaires: {
      created: 'Creado',

      ready_to_start: 'No {gender, select, male {Iniciado} female {Iniciada}}',
      started: '{gender, select, male {Iniciado} female {Iniciada}}',
      answered: '{gender, select, male {Respondido} female {Respondida}}',

      not_answered: '{gender, select, female {No respondido} male {No respondido}}',
      partially_answered: 'No {gender, select, male {Finalizado} female {Finalizada}}',
      results_available: 'Ver Resultado',
    },

    assessments: {
      released: 'Liberada',
      paused: 'Pausada',
      finished: 'Finalizada',
      results: 'Resultados publicados',
    },

    see_all_companies: {
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      desisted: '{num, plural, one {Desistió} other {Desistieron}}',
      in_progress: '{num, plural, one {Liberada} other {Liberadas}}',
      paused: '{num, plural, one {En pausa} other {En pausa}}',
      finished: '{num, plural, one {Cerrada} other {Cerradas}}',
      results_available: '{num, plural, one {Ver el resultado} other {Ver los resultados}}',
    },

    respondents: {
      pending: 'Pendiente',
      registered: 'Registrado',
      not_answered: 'No respondido',
      answered: 'Respondido',
    },

    assessment_subtype: {
      self_assessment: 'Auto',
      180: '180',
      270: '270',
      360: '360',
      720: '720',
    },
  },

  user_roles: {
    manager: 'Gestor',
    owner: 'Gestor Principal',
    respondent: 'Encuestado',
  },

  errors: {
    required: {
      simple: 'Campo obligatorio',
      email: 'El e-mail es obligatorio',
      password: 'La contraseña es obligatoria',
      cpf: 'El CPF es obligatorio',
      cnpj: 'El CNPJ es obligatorio',
      first_name: 'Nombre es obligatorio',
      last_name: 'Apellido es obligatorio',
    },

    invalid: {
      start_date: 'Debe ser mayor a hoy',
      end_date: 'Debe ser mayor que la inicial',
      simple: 'Campo no válido',
      email: 'Correo electrónico no válido',
      cpf: 'Código fiscal de persona física no válido',
      cep: 'Código postal no válido',
      cnpj: 'Código fiscal de persona jurídica no válido',
      url: 'URL no válida',
      value: 'Valor no permitido',
    },

    invalid_fields: '¡Sus cambios no se guardarán si hay errores en el formulario!',
    generic_error_message: 'Ups, ¡ocurrió un error al procesar su solicitud! Si persiste, por favor entrar en contacto a través del menú de soporte.',
    file_too_big: 'Archivo mayor a {num} MB',
    wrong_mime: 'Tipo de archivo inválido',
    passwords_do_not_match: 'Confirmación no corresponde con contraseña inserta',
    min_string_len: 'Mínimo de {num, plural, one {# caractere} other {# caracteres}}',
    max_string_len: 'Máximo de {num, plural, one {# caractere} other {# caracteres}}',
    min_uppercase_letters: 'Mínimo de {num, plural, one {# letra mayúscula} other {# letras mayúsculas}}',
    min_lowercase_letters: 'Mínimo de {num, plural, one {# letra minúscula} other {# letras minúsculas}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Mujer',
      male: 'Hombre',
      non_binary: 'No binario',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declarar',
    },

    sex: {
      female: 'Femenino',
      male: 'Masculino',
      intersexual: 'Intersexual',
      other: 'Otro',
    },

    orientation: {
      assexual: 'Asexual',
      bissexual: 'Bisexual',
      straight: 'Heterosexual',
      homosexual: 'Homosexual',
      pansexual: 'Pansexual',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declarar',
    },

    race: {
      yellow: 'Amarillo',
      white: 'Blanco',
      indian: 'Indígena',
      pardo: 'Pardo',
      black: 'Negro',
      undeclared: 'Prefiero no declarar',
    },

    pcd: {
      nao_pcd: 'No sou PcD',
      deficiencia_fisica: 'Deficiencia física',
      deficiencia_auditivo: 'Deficiencia auditiva',
      deficiencia_visual: 'Deficiencia visual',
      deficiencia_mental: 'Deficiencia mental',
      deficiencia_multipla: 'Deficiencia múltiple',
      undeclared: 'Prefiero no declarar',
    },

    job_titles: {
      estagiario: 'Pasantía',
      professor: 'Profesor',
      consultoria: 'Consultoría',
      supervisao: 'Supervisión',
      analista: 'Analista',
      assistente: 'Asistente',
      auxiliar: 'Auxiliar',
      supervisor: 'Supervisión',
      coordenador: 'Coordinación',
      gerente: 'Gerencia',
      senior: 'Especialista senior',
      diretor: 'Directorio (C-Level)',
      conselho: 'Consejo',
      prestador_de_servicos: 'Prestador de servicios',
      other: 'Otro',
    },

    departments: {
      administracao: 'Administración',
      assistencia_ou_suporte_tecnico: 'Asistencia o soporte técnico',
      atendimento: 'Atención',
      auditoria: 'Auditoría y consultoría',
      comercial: 'Comercial',
      compras: 'Compras',
      complience: 'Compliance',
      comunicacao_e_assessoria: 'Comunicación y asesoría',
      controladoria: 'Controladoría',
      contabilidade: 'Contabilidad',
      contratos: 'Contratos',
      engenharia: 'Ingeniería',
      estrategia: 'Estrategia',
      financeiro: 'Finanzas',
      inovacao: 'Innovación',
      inteligência: 'Inteligencia',
      juridico: 'Jurídico',
      logistica: 'Logística',
      manutencao: 'Mantenimiento',
      marketing: 'Marketing',
      melhoria: 'Mejora',
      novos_negocios: 'Nuevos negocios',
      operacoes: 'Operaciones',
      turismo: 'Turismo',
      pd: 'P&D',
      pos_vendas: 'Posventas',
      projetos: 'Proyectos',
      producao: 'Producción',
      processos: 'Procesos',
      qualidade: 'Calidad',
      rh_dho: 'RH/DHO',
      relacoes_institucionais: 'Relaciones institucionales',
      seguranca: 'Seguridad',
      servicos_tecnicos: 'Servicios técnicos',
      suprimentos: 'Suministros',
      tecnologia_da_informacao: 'Tecnología de la Información',
      other: 'Otro',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Evaluaciones',
    },

    multi_perspective_assessment_individual: {
      title: 'Evaluaciones Individuales',
    },

    individual_assessments: {
      title: 'Evaluaciones Individuales',
      self: 'Mis evaluaciones',
      others: 'Evaluar personas',
    },

    multi_perspective_assessment: {
      title: 'Evaluaciones desde múltiples perspectivas',
    },

    respondents: {
      title: 'Encuestados',
    },

    organization_profile: {
      title: 'Perfil de Organización',
    },

    my_profile: {
      title: 'Mi perfil',
    },

    managers: {
      title: 'Gestores',
    },
  },

  indicators: {
    true: 'Sí',
    false: 'No',
    uninformed: 'No informado',
    placeholder: {
      money: 'Ex.: R$1.000.000',
      number: 'Ex.: 10',
      selector: 'Seleccione',
      percent: 'Ex.: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Información',
      name: 'Información',
    },

    indicators: {
      id: 'Indicadores',
      name: 'Indicadores',
    },
  },

  emails: {
    manual: 'Envío manual',
    invite_template: {
      title: 'E-mail de invitación',
      date: 'Envío inmediato',
    },
    general_template: {
      title: 'Recordatorio General',
      date: 'Envío inmediato',
    },
    week_before_template: {
      title: 'Recordatorio - La semana antes del término',
      date: 'Un semana antes del término de la evaluación',
    },
    day_before_template: {
      title: 'Recordatorio -víspera del término',
      date: 'Un día antes del término de la evaluación',
    },
    results_available_template: {
      title: 'Liberación de resultados',
      date: 'Después del término de la evaluación',
    },
    application_email: {
      all: 'Enviar e-mail a todos los encuestados de esta evaluación',
      assessed: 'Enviar e-mail a este evaluado',
      unanswered: 'Enviar e-mail a no respondidos de este evaluado',
      role_type_title: {
        all: '¿Enviar un correo electrónico a todos los que aún no han respondido?',
        internal: '¿Enviar un correo electrónico a todas las partes interesadas internas?',
        external: '¿Enviar un correo electrónico a todas las partes interesadas externas?',
      },
      role_type_body: {
        all: 'Este correo electrónico se enviará a todos los encuestados registrados por correo electrónico que aún no hayan completado su respuesta. Después de enviar, no se puede deshacer la acción.',
        internal: 'Tras el envío, este correo electrónico se activará para todos los encuestados registrados por correo electrónico como partes interesadas internas, y no será posible deshacer esta acción.',
        external: 'Tras el envío, este correo electrónico se activará para todos los encuestados registrados por correo electrónico como partes interesadas externas, y no será posible deshacer esta acción.',
      },
    },
    collection_email: {
      all: 'Enviar a todos los evaluados y sus encuestados',
      assessed: 'Enviar a todos los evaluados',
      unanswered: 'Enviar a todos los no respondidos de todos los evaluados',
    },
  },

  progress_bar: {
    text: 'Tempo de {mobile, select, true {resp.} false {respostas}}',
  },

  words: {
    email: 'E-mail',
    email_simple: 'Email',
    password: 'Contraseña',
    sector: 'Área',
    gender: 'Género',
    company: 'Empresa',
    role: 'Papel',
    your_role: 'Tu papel',
    description: 'Descripción',
    behaviour: 'Comportamiento',
    instructions: 'Instrucciones',
    cancel: 'Cancelar',
    error: 'Error',
    close: 'Cerrar',
    continue: 'Continuar',
    finish: 'Finalizar',
    pause: 'Pausa',
    next: 'Próximo',
    previous: 'Anterior',
    status: 'Estatus',
    submit: 'Concluir',
    and: 'y',
    minimum: 'mínimo',
    maximum: 'máximo',
    minimum_of: 'Mínimo {num}',
    maximum_of: 'Máximo {num}',
    page: 'Página',
    available: 'Disponible',
    unavailable: 'Indisponible',
    of: 'de',
    address: 'Dirección',
    config: 'Configurar',
    release: 'Liberar',
    monitor: 'Monitorear',
    edit: 'Editar',
    view: 'Visualizar',
    actions: 'Acciones',
    required: 'Obligatorio',
    type: 'Tipo',
    questions: 'Preguntas',
    invited: 'Invitados',
    answered: 'Respondidos',
    not_answered: 'No respondido',
    answer: 'Respuesta',
    beginning: 'Inicio',
    end: 'Término',
    duration: 'Duración',
    add: 'Agregar',
    new: 'Nuevo',
    name: 'Nombre',
    position: 'Función',
    registered: 'Registrado',
    pending: 'Pendiente',
    manager: 'Gestor',
    owner: 'Gestor Principal',
    hour: 'hora',
    day: 'día',
    organization: 'Organización',
    individual: 'Individuo',
    sex: 'Sexo',
    pcd: 'PcD (Persona con Deficiencia)',
    color_race: 'Color/Raza',
    sexual_orientation: 'Orientación Sexual',
    assessed: 'Evaluado',
    assessment: 'Evaluación',
    self_assessment: 'Autoevaluación',
    respondent: 'Encuestado',
    respondents: 'Escuestados',
    back_main: 'Volver a la página inicial',
    manual_sending: 'Envío manual',
    date: 'Data',
    sampling: 'Amostra',
    positive: 'Positivo',
    negative: 'Negativo',
    neutral: 'Neutro',
    conclusion: 'Conclusão',
    recommendation: 'Recomendação',
    do_not_apply: 'No se aplica',
    search: 'Buscar',
    intro: 'Introducción',
    average: 'Promedio',
    in: 'en',
    general: 'General',
    participant: 'Partícipe',
    terms_of_use: 'Terminos de uso',
    privacy_policy: 'Política de privacidad',
    filter: 'Filtrar',
    occupation: 'Actuación',
    assessment_name: 'Nombre de Evaluación',
    assessment_status: 'Estado de Autoevaluación',
    choose: 'Elección',
    send_all: 'Enviar a todos',
    view_template: 'Ver plantilla',
    smallest_score: 'Grado menor',
    biggest_score: 'Grado maior',
    select_all: 'Comprobar todo',
    deselect_all: 'Deseleccionar todo',
    score: 'Puntuación',
    meaning: 'Significado',
    legend: 'Legenda',
    population: 'População',
    ideal_sample: 'Amostra ideal',
    minimum_sample: 'Muestra mínima general',
    general_result: 'Resultado Geral',
    current: 'Atual',
    desired: 'Desejada',
    total: 'Total',
    come_back: 'Vuelve',
    start: 'Começar',
    assessed_organization: 'Organización evaluada',
    application: 'Aplicación',
    finished: 'Finalizada',
    initiation: 'Empezó',
    finale: 'Fin',
    participations: 'Participações',
    reports: 'Relatórios',
    factors: 'Fatores',
    subgroups: 'Subgrupos',
    optional: 'Opcional',

    plurals: {
      day: '{num, plural, one {# día} other {# días}}',
      hour: '{num, plural, one {# hora} other {# horas}}',
      answered: '{num, plural, one {Respondido} other {Respondidos}}',
      result: '{num, plural, one {Resultado} other {Resultados}}',
      assessed: '{num, plural, one {Avaliado} other {Avaliados}}',
      available: '{num, plural, one {# evaluación} other {# evaluaciones}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Questão} other {Questões}}',
      alternative: '{num, plural, one {Alternativa} other {Alternativas}}',
      answer: '{num, plural, one {Resposta} other {Respostas}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Pessoa} other {Pessoas}}',
    },

    try_again: 'Intentar nuevamente',
    delete: 'Excluir',
    confirm: 'Confirmar',
    save: 'Guardar',
    last_name: 'Apellido',
    not_informed: 'No informado',
  },

  checkbox: {
    title: {
      sector: 'Área',
      role: 'Función ',
      cba: 'CBA -Conscious Business Assessment',
      vyl: 'VYL - Valuable Young Leaders',
    },

    label: {
      Administrative: 'Administrativo',
      rh: 'RH',
      attendance: 'Atención',
      commercial: 'Comercial',
      self_employed: 'Autónomo',
      specialist: 'Especialista',
      manager: 'Gerente',
      ceo: 'CEO',
      leaderships: 'Liderazgo',
      collaborators: 'Colaboradores',
      clients_partners: 'Clientes y socios',
      partnerships: 'Sociedad',
      inidividual_assessment: 'Evaluación de individuos',
      organizational_assessment: 'Evaluación de Organizaciones',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Cuéntanos sobre ti',
      subtitle: 'Necesitamos algunos datos acerca de ti para garantizar la legitimidad de la respuesta. Sin embargo, todas las respuestas son confidenciales y los datos van a ser utilizados de manera conjuntas para fines estadísticos. Solo el equipo de Humanizadas tiene acceso a las respuestas y tu anonimato para la empresa es garantizado.',

      email_label: 'E-mail',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'No poseo un e-mail',
      external_email: 'Respuesta a través de Link Abierto',

      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      working_company_department_label: 'Área',
      working_company_department_placeholder: 'Seleccione',

      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'Seleccione',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género',

      pcd_label: 'PcD (Persona con deficiencia)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Deficiencia física',
      pcd_tooltip_pt1_body: 'Alteración completa o parcial de uno o más segmentos del cuerpo humano, comprometiendo la función física, presentada bajo la forma de paraplegia, paraparesia, monoplegia, monoparesia, teraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomia, amputación o ausencia de mienbro, parálisis cerebral, namismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no produzcan dificultades para el desempeño de funciones.',

      pcd_tooltip_pt2_title: 'Deficiencia auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las posibilidades auditivas sonoras, variando de grados y niveles en la forma siguiente',

      pcd_tooltip_pt3_title: 'Deficiencia visual',
      pcd_tooltip_pt3_body: 'La deficiencia visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo o del sistema visual. El deficiente visual puede ser la persona ciega o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total o simplemente amaurosis, se caracteriza por la completa pérdida de visión sin percepción visual de luz y forma. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, en general, la baja visión es definida como una condición en la cual la visión de la persona no puede ser totalmente corregida con gafas, interfiriendo en sus actividades diarias, así como la lectura y la locomoción.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente inferior al promedio, con manifestación antes de los dieciocho años y limitaciones asociadas a dos o más áread de habilidades adaptativas, tales como',

      color_label: 'Color/Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según la referencia del IBGE',

      sexual_orientation_label: 'Orientación Sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'Orientación se refiere a la atracción que se siente por otro individuos. Generalmente también involucra cuestiones sentimentales, y no solamente sexuales. Referencia',

      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos su CPF para validar su identidad',
    },

    questionnaire: {
      warning_dialog: {
        title: '¿Comenzamos? Una vez iniciada, no es posible interrumpir la evaluación',
        body: 'Usted no podrá pausarla para finalizar más tarde. Esa restricción forma parte de nuestra metodología científica de investigación.',
        button: 'Responder de evaluación',
        left_button: 'Cancelar',
      },
    },
  },

  pages: {
    login: {
      title: '¡Qué bueno que usted regresó!',
      subtitle: 'Para acceder a su cuenta, inserte el e-mail en el que recibió la invitación y contraseña registrada.',
      stay_logged_in: 'Permanecer conectado',
    },

    signup: {
      title: '¡Estábamos esperándolo!',
      subtitle: 'Ya dejamos todo listo para usted. Para acceder a sus evaluaciones, basta con crear una cuenta.',
    },

    lastReport: {
      title: 'Aquí está el Informe general de la última edición de la encuesta.',
      subtitle: 'Haga clic en el enlace a continuación para acceder al informe general de la última edición de la Pesquisa Empresas Humanizadas do Brasil.',
      button: 'Informe de acceso',
    },

    thankYouRegister: {
      title: 'Tus respuestas han sido registradas exitosamente.',
      subtitle: 'Agradecemos su contribución.',
    },

    unauthenticated_contact: {
      title: 'Envíenos un mensaje y entraremos en contacto',
      subtitle: 'Cuéntenos más sobre el problema que encontró y buscaremos una solución',
    },

    password_recovery_enter_email: {
      title: 'Estamos aquí para ayudarte',
      subtitle: 'Digita tu email de registro y te enviaremos un link para crear una nueva contraseña.',
    },

    password_recovery_reset_password: {
      title: 'Aquí está su nuevo comienzo.',
      subtitle: 'Cree su nueva contraseña y vuelva a aprovehcar todas las ventajas de la plataforma',
    },

    my_profile: {
      title: 'Mi perfil',
      edit_button: 'Editar',
    },

    organization_profile: {
      title: 'Perfil de la Organización',
      edit_button: 'Editar',
    },

    support: {
      title: 'Envíenos un mensaje y entraremos en contacto',
      subtitle: 'Cuéntenos más sobre el problema que encontró y buscaremos una solución',
    },

    respondents: {
      title: 'Encuestados',
      title_add: 'Agregar Encuestados',
      search_field: {
        placeholder: 'Buscar',
      },

      actions: {
        add_sheet: 'Agregar por planilla',
        download_template: 'Bajar modelo de planilla',
        upload_sheet: 'Hacer el upload de planilla',
        bulk_actions: 'Acciones en masa',
      },

      filter: {
        title: 'Filtrar',
      },

      add_button: 'Agregar',

      no_respondents: {
        message: 'Usted no agregó encuentados aún',
        button_text: 'Agregar ahora',
      },

      owner_informations_dialog: {
        label: {
          email: 'E-mail',
          company: 'Empresa',
          sector: 'Área',
          role: 'Función',
        },

        close_button: 'Cerrar',
      },

      configure_assessment_dialog: {
        title: '¿Usted desea configurar una evaluación ahora?',
        body: 'Ahora que agregó nuevos encuestados a la base, seleccione un tipo de evaluación para configurar.',

        close_button: 'Cerrar',
        config_button: 'Configurar',
      },

      respondents_filter_dialog: {
        title: 'Filtrar Encuestados',

        cancel_button: 'Cancelar',
        aplly_button: 'Aplicar filtros',
      },

      upload_and_download_sheet_dialog: {
        title: 'Recuerde usar el modelo de la planilla de upload de encuestados',
        body: 'Si usted aún no bajó el modelo, haga el download y adapte su planilla para que quede como nuestro estándar. Así, usted evitará errores en su base de encuestados.',

        download_button: 'Bajar modelo',
        upload_button: 'Hacer upload',
      },

      delete_respondents_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir este/estos encuestado/s, él/ellos no podrá/n acceder a ninguna de sus evaluaciones. En caso de que .... ya hayan respondido alguna, todos los datos serán excluídos de los cálculos de resultados.',
        body_attention: 'Atención',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      delete_enrollments_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir ...., no podrá/n visualizar la evaluación y dejará de recibir los emails referentes a ella. En caso de que alguno haya respondido a las preguntas, los datos serán excluidos de los cálculos de resultados.',
        body_attention: 'Atención',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      invitation_dialog: {
        title: 'Invitar a la evaluación',
        body: 'Seleccione la evaluación para la cual desea invitar a los encuestados que usted seleccionó',
        empty: 'No hay evaluaciones registradas en su organización',
        cancel_button: 'Cancelar',
        invitation_button: 'Invitar',
      },

      edit: {
        title: 'Editar Encuestado',
      },

      add: {
        title: 'Agregar Encuestados',
      },

      add_list: {
        title: 'Agregar Encuestados a',
        return: 'Volver a la pantalla de configuración',
        cancel_dialog: {
          title: '¿Usted desea salir sin guardar?',
          body: 'Si usted sale ahora, perderá toda la información que completó',
          left_button: 'Salir sin guardar',
          right_button: 'No salir',
        },
      },

      confirmation_message_dialog: {
        title: '¡Muchas gracias!',
        body: 'Agradecemos por tu tiempo en brindarnos esta información',

        button_finish: 'Finalizar',
        button_check: 'Verificar respuestas',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: '¿Usted desea finalizar la evaluación?',
        body: 'Al finalizar la evaluación, no será posible colectar más respuestas ni liberarlas nuevamente. Los resultados serán hechos en base a los datos ya recibidos.',
        body_attention: 'Atención: si desea suspender la evaluación por un período, intente pausarla.',

        cancel_button: 'Cancelar',
        finalize_button: 'Finalizar',
      },

      send_email_dialog: {
        title: 'Enviar e-mail a {num, plural, one {# encuestado} other {# encuestados}}?',
        body: 'Al enviar, este e-mail será disparado y no será posible deshacer la acción. Para enviar un e-mail a apenas algunos encuestados, selecciónelos en la tabla debajo y haga click en acciones en masa.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },

      reminder_email_dialog: {
        title: 'Enviar e-mail',
        body: 'Seleccione el e-mail que desea enviar a {num, plural, one {al encuestado seleccionado} other {a los encuestados seleccionados}}. Al clickear enviar, este e-mail será disparado y no será posible deshacer esa acción.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },
    },

    organizational_assessments: {
      title: 'Evaluaciones individuales',

      answered_tooltip: '¡Estamos calculando los resultados!',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      no_invites: {
        message: 'Usted aún no fue invitado a responder ninguna evaluación de organizaciones',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones',
      },
    },

    config_assessments: {
      success_dialog: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú --- Será iniciada automáticamente en la fecha de inicio programada, y podrá ser editada hasta ---',
        button_text: 'Concluir',
      },

      success_dialog_2: {
        title: '¡Evaluación configurada correctamente!',
        body: 'Después de configurar todos los cuestionarios, la evaluación ahora estará disponible en el menú "Liberar". Se iniciará automáticamente en la fecha de inicio programada y se puede editar hasta entonces.',
        button_text: 'Okay',
      },

      cancel_dialog: {
        title: '¿Usted desea salir sin guardar?',
        body: 'Si usted sale ahora, perderá toda la información que completó',
        left_button: 'Salir sin guardar',
        right_button: 'No salir',
      },

      release_dialog: {
        title: '¿Desea liberar evaluación sin guardar las modificaciones recientes?',
        body: 'Si usted desea guardar las modificaciones antes de liberar la evaluación, haga click en ---- debajo y guarde las configuraciones antes de continuar.',
        left_button: 'Liberar sin guardar',
        right_button: 'No liberar',
      },

      template_dialog: {
        use_template: 'Enviar este e-mail a los encuestados',
        send_date: 'Fecha de envío',
        subject: 'Asunto del mensaje',
        body: 'Cuerpo del mensaje',
      },

      save_config: 'Salvar',
      email_section: 'Envío de e-mails',
      email_instructions: 'Seleccione cuáles modelos de e-mail usted enviará a los encuestados invitados durante el curso de su evaluación',
      start_date: 'Fecha de inicio',
      end_date: 'Fecha de término',
      link: 'https',
      link_title: 'Link de compartir a {name}',
      copy_link: 'Copiar link',

      invite_template: {
        title: 'E-mail de invitación',
        date: 'Envío inmediato',
      },
      general_template: {
        title: 'Recordatorio general',
        date: 'Envío inmediato',
      },
      week_before_template: {
        title: 'Recordatorio - La semana antes del término',
        date: 'Una semana antes del término de la evaluación',
      },
      day_before_template: {
        title: 'Recordatorio - víspera del término',
        date: 'Un día antes del término de la evaluación',
      },
      results_available_template: {
        title: 'Liberación de resultados',
        date: 'Después del término de la evaluación',
      },

      header_items: {
        role: 'Papel de los resp.',
      },

      header_menu: {
        release: 'Liberar ahora',
        play: 'Retomar',
        pause: 'Pausar',
        finish: 'Finalizar ahora',
        results: 'Ver resultado',
        view: 'Visualizar cuestionarios',
        answer_self_assessment: 'Responder autoevaluación',
      },

      respondents_section: 'Encuestados',
      respondents_instructions: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link que será disponibilizado después de liberar la evaluación',
      empty_table: 'Aún no fue disponibilizado ningún modelo para su organización',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Encuestados (público en general)',
        specific_public_respondents: 'Encuestados (públicos específicos)',
        specific_public: 'Públicos específicos',
      },
      organization_table_title: 'Todas las evaluaciones de las organizaciones',
      individual_table_title: 'Todas las evaluaciones de individuos',
      self_assessment_table_title: 'Todas las autoevaluaciones',
    },

    individual_assessments: {
      title: 'Evaluaciones individuales',
      manager: {
        email: {
          manual: 'Envío manual',
          assessed_respondents: 'Enviar a todos los evaluados y sus encuestados',
          assessed: 'Enviar a todos lo evaluados',
          not_answered_assessed: 'Enviar a todos los no respondidos de todos los evaluados',
        },
        invite: {
          warning: 'Marque la caja a la izquierda del nombre de cada encuestado para invitarlo',
          respondents: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link abierto, que será disponibilizado después de liberar la evaluación',
        },
      },
      title_self: 'Mis evaluaciones',
      title_others: 'Evaluar personas',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      no_invites: {
        message: 'Usted aún no ha sido invitado a responder ninguna evaluación de individuos',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones',
      },
    },

    config_individual_assessments: {
      title: 'Configurar evaluación de individuos',
      confirm_button: 'Concluir configuraciones',
      dialog_complete: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú ---- Será iniciada automáticamente en la fecha de inicio programada, y podrá ser editada hasta ---',
        button: 'Concluir',
      },
      dialog_incomplete: {
        title: '¿Concluir configuaciones sin completar todos los evaluados disponibles?',
        body: 'El resto de las evaluaciones que usted adquirió continuarán en el menú  ---- para que sean configuradas y liberadas posteriormente',
        left_button: 'Continuar configurando',
        right_button: 'Concluir',
      },
      dialog_add: {
        title: 'Adicionar nuevo usuario',
        title_edit: 'Editar usuario',
        subtitle: 'Este usuario aún no está registrado. Para invitarlo como evaluado en una evaluación, complete sus datos abajo.',
        email_label: 'E-mail del evaluado',
      },
      info: {
        title: 'Configuraciones generales de la ---',
        date_title: 'Inicio y término',
        date_subtitle: 'Las fechas de inicio y término de esa evaluación serán las mismas para todos los evaluados configurados abajo',
        start_date: 'Fecha de inicio',
        end_date: 'Fecha de término',
      },
      enrollments: {
        title: 'Configurar',
      },
      details: {
        email: 'E-mail del evaluado',
        warning: 'Marque la caja a la izquierda del nombre de cada encuestado para invitarlo',
        respondents: 'Además de los encuestados agregados por esta área, usted podrá invitar también a otras personas para responder su evaluación a través de un link abierto, que será disponibilizado después de liberar la evaluación.',
      },
    },

    release_individual_assessments: {
      title: 'Liberar evaluaciones de indiduos',

      assessment_dialog: {
        title: '¿Liberar evaluación ahora?',
        body: 'Si usted libera la evaluación ahora, será enviada a todos los encuestados. No se puede deshacer esa acción',
        release_button: 'Liberar',
        wait_button: 'Esperar fecha de inicio',
      },

      success_save_changes_dialog: {
        title: '¡Modificaciones guardadas exitosamente!',
        body: 'Esta evaluación no se iniciará automáticamente en la fecha de inicio programada, y puede ser editada hasta...',
        conclude_button: 'Concluir',
      },

      release_assessed: {
        title: 'Liberar{type, plural, one {Autoavaliação} other {Avaliação #}} - {title}',
        email_label: 'E-mail del evaluado',
      },
    },

    monitor_individual_assessments: {
      title: 'Seguir evaluaciones de individuos',

      finish_dialog: {
        title: '¿Usted desea finalizar la evaluación?',
        body: 'Al finalizar la evaluación no será posible colectar más respuestas ni liberarla nuevamente. Los resultados serán calculados en base a los datos ya recibidos.',
      },

      pause_dialog: {
        title: '¿Usted desea pausar la evaluación?',
        body: 'Mientras la evaluación está en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarlo en cualquier momento.',
        warn: 'Atención: la fecha de finalización no se modificará.',
      },

      invite: {
        all: 'Enviar a todos los evaluados y a sus encuestados',
        assessed: 'Enviar a todos los evaluados',
        not_answered: 'Enviar a todos los no respondidos de todos los evaluados',
      },

      assessed_invite: {
        all: 'Enviar email a todos los encuestados de este evaluado',
        assessed: 'Enviar e-mail a este evaluado',
        not_answered: 'Enviar e-mail a no respondidos de este evaluado',
      },

      general: {
        title: 'Monitorear',
        date_save_button: 'Guardar fecha',
      },

      assessed_user: {
        status: 'Estatus de autoevaluación',
      },
    },

    release_assessments: {
      table_title: 'Liberar las evaluaciones',
      table_empty: 'Usted aún no posee ninguna evaluación liberada para monitorear',
    },

    monitor_assessments: {
      table_title: 'Seguir las evaluaciones',
      table_empty: 'Usted aún no posee ninguna evaluación liberada para monitorear',
    },

    organizational_assessments_manager: {
      title: 'Configurar las evaluaciones',

      read_only_dialog: {
        title: 'Usted ya respondió esta evaluación',
        message: 'Ningún campo podrá ser alterado. ¿Usted desea visualizar la respuesta enviada?',
        submit_button: 'Ver respuesta',
      },

      empty_table: 'Aún no está disponible ningún modelo para su organización',

      no_invites: {
        message: 'Usted aún no ha sido invitado a responder ninguna evaluación de individuos',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No fue encontrado ningún resultado para la búsqueda. Pruebe los filtros para más opciones.',
      },
    },

    questionnaire: {
      progress: 'Parte {start} de {end}',
      required_fields: 'Los campos obligatorios están marcados con un *',
      back_to_main_page: 'Volver a la página inicial',
      answer_all_required: 'Por favor, complete todos los campos obligatorios',
      matrix_error: 'Seleccione al menos un ítem',
      assessment_description: 'Descripción de la evaluación',
      exit_view: 'Salir de la vista previa',
      no_answers: 'No sé responder',

      warning_dialog: {
        title: 'Conclusión',
        message: 'Para concluir la evaluación haga click en enviar respuestas, o haga click para verificar en caso de querer editar sus respuestas',
        cancel_button: 'Conferir respuestas',
      },

      success_dialog: {
        title: '¡Muchas gracias!',
        message: 'Agradecemos por tu tiempo en brindarnos esta información',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: '¡Ups! Usted dejó de ser el Gestor Principal de la empresa',
        body: 'Usted ya no podrá editar los usuarios ni el perfil de la organización',
      },

      confirm_promote_dialog: {
        title: 'Transferir actuación de Gestor Principal a ...',
        body: 'Al cambiar el usuario de Gestor Princial, usted perderá ese papel. Solamente el Gestor Principal puede editar los Gestores y el Perfil de la Organización',

        button_submit: 'Pasar a ser Gestor Principal',
      },

      confirm_destroy_dialog: {
        title: 'Excluir Gestor',
        body: 'Al excluir este Gestor ya no podrá acceder a la plataforma de su organización ni gerenciar las evaluaciones o encuestados',
      },

      add_manager_dialog: {
        title: 'Agregar Gestor',
        body: 'Para agregar un Gestor, es necesario que él se registre a través del link debajo',
      },

      user_not_found_dialog: {
        title: 'Ups... No encontramos ningún registro con este e-mail',
        body: 'Certifique que la persona que usted quiere invitar ya esté registrada y verifique si digitó el e-mail correctamente.',
      },

      table: {
        title: 'Gestores',
        empty_table: 'No fue encontrado ningún gestor',
      },

      dialog: {
        email: 'E-mail',
        company: 'Empresa',
        sector: 'Área',
        position: 'Función',
      },
    },

    messages: {
      thank_you: {
        title: '¡Muchas gracias!',
        subtitle: 'Agradecemos por tu tiempo en brindarnos esta información',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a un celular',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      thank_you_public: {
        title: '¡Muchas gracias!',
        subtitle: 'Agradecemos por tu tiempo en brindarnos esta información',
        subtitle_2: 'En caso de que desee aprovechar aún más la experiencia de la Plataforma Humanizadas, acceda a su cuenta por medio de una computadora.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      assessment_expired: {
        title: 'Investigación finalizada',
        subtitle: 'El tiempo de respuesta de esa evaluación ya terminó. ¡Le agradecemos por su interés!',
        alt_desktop: 'La imagen muestra una persona frente a una computadora',
        alt_mobile: 'La imagen muestra una persona frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      assessment_not_started: {
        title: 'Evaluación no iniciada.',
        subtitle: 'Esta evaluación todavía no recibe respuestas, consulte con la persona que le envió este enlace para conocer la fecha de inicio correcta. Esperamos brindarle una excelente experiencia. ¡Gracias por su tiempo!',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      mobile_not_available: {
        title: 'Ups...',
        subtitle: 'Disculpe las molestias. Aún estamos construyendo la experiencia Humanizadas para que usted pueda acceder via smartphone. Mientras tanto, usted podrá acceder a la plataforma únicamente por computadora.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a un celular',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      error: {
        title: 'Error',
        subtitle: 'Página no encontrada',
        errorMessage: 'Lo sentimos mucho, pero hubo un error durante la navegación. Intente una de las alternativas abajo.',
        alt_desktop: 'La imagen muestra dos personas frente a una computadora',
        alt_mobile: 'La imagen muestra dos personas frente a una computadora',
        subtitle_desktop: 'Ilustración creada por pikisuperstar',
        subtitle_mobile: 'Ilustración creada por pikisuperstar y pch.vector',
      },
      invalid_link: {
        title: 'Enlace inválido',
        subtitle: 'Si está aquí es porque recibió un enlace único de otra persona.',
        body: 'Compruebe si ha recibido un correo electrónico de registro en su casilla de correo electrónico (incluido el correo no deseado) y, si no lo ha recibido, contáctenos a través de',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora',
        subtitle_desktop: 'Diseñado por pikisuperstar',
        subtitle_mobile: 'Obra diseñada por pikisuperstar & pch.vector',
      },
    },

    terms_confirmation: {
      title: 'Aviso de Privacidad',
      subtitle: 'Hola, hemos actualizado la Política de privacidad y los Términos de uso de nuestra plataforma para brindarle más transparencia sobre cómo tratamos sus datos. Por lo tanto, para continuar usando nuestra plataforma, necesitamos que lea y acepte los términos a continuación:',
      confirm_button: 'Yo leo y estoy de acuerdo',
    },

    monitor_assessments_page: {
      title: 'Monitorear',
      respondents_section: 'Encuestados',
      header_menu: {
        answer_self_assessment: 'Responder autoevaluación',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Enviar un correo electrónico a todos los encuestados {num, plural, one {de este evaluado} other {de estos evaluados}}',
          assessed: 'Enviar un correo electrónico a {num, plural, one {este evaluado} other {estos evaluados}}',
          not_answered: 'Enviar un correo electrónico a los no respondidos de este evaluado',
        },
        evaluators_link: 'Enlace evaluadores',
        assessed_link: 'Enlace evaluados',
        internal_link: 'Evaluadores internos',
        external_link: 'Evaluadores externos',
      },
      end_application: 'Para los fines de la aplicación',
      send_reminder: 'Enviar recordatorio',
      higher_answers: 'Número de respuestas mayor que la muestra',
      lower_answers: 'Número de respuestas inferior a la muestra',
      absolute_number_answers: 'Respuestas (números absolutos)',
      desired_sample: '{answers, plural, one {# respuesta} other {# respuestas}} /npor encima de la muestra deseada',
      achieve_ideal_sample: '{answers, plural, one {Falta # respuesta} other {Faltan # respuestas}} /npara alcanzar la muestra ideal',
      email_reminder: 'Correos electrónicos y recordatorios',
      completed_assessments: 'Evaluaciones completadas',
      see_all: 'Ver todas',
      number_of_answers: 'Nº de respuestas',
      header: {
        play_and_pause: '{isPaused, select, true {Reanudar} false {Pausar}} la aplicación',
        view_reports: 'Ver los informes',
        view_group_reports: 'Ver los resultados del grupo',
        export_reports: 'Exportar el informe',
        share_reports: 'Compartir los resultados',
        view_questionnaire: 'Ver el cuestionario',
        finish_application: 'Cerrar la aplicación final',
        share_link: 'Enlace de compartimiento',
        internal_stakeholders: 'Partes interesadas internas',
        external_stakeholders: 'Partes interesadas externas',
        response_rate: 'Índice de respuestas',
        calculate_results: 'Calcular los resultados',
        results_being_calculate: 'Los resultados se están calculando',
      },
      line_graph: {
        answers_per_day: 'Respuestas por día',
        total_answers: 'Respuestas totales',
        number_of_responses: 'Nº de respuestas',
      },
      reminder: {
        automatic_emails: 'Correos electrónicos automáticos',
        email_results_appraised: 'Correo electrónico con resultados para Evaluados',
        send_email_to_participants: 'Envíe un correo electrónico (predeterminado) a los participantes de esta evaluación',
        send_to_all_unanswered: 'Enviar a todos los no respondidos',
        send_email_to_appraised: 'Enviar un correo electrónico (predeterminado) poniendo los resultados a disposición de los evaluados',
        send_results_to_all_appraised: 'Enviar los resultados a todos los evaluados',
        send_all_internal_stakeholders: 'Enviar a todas las partes interesadas internas',
        send_all_external_stakeholders: 'Enviar a todas las partes interesadas externas',
      },
      individual: {
        conclusion_rate_summary: 'Resumen del índice de conclusión',
        total_number_answers: 'N.º total de respuestas',
        ideal_sample_reached: 'de la Muestra Ideal alcanzada',
        desired_sample_reached: 'de la Muestra Deseada alcanzada',
        minimum_sample_reached: 'de la Muestra General Mínima alcanzada',
        engagement_by_appraisees: 'Participación de los evaluados',
        bigger_engagement: 'Mayor participación',
        less_engagement: 'Menor participación',
        multiperspective_assessment: 'Evaluación por múltiples perspectivas',
      },
      organization: {
        absolute_number_responses: 'N.º absoluto de respuestas',
        organization_assessment: 'Evaluación de las organizaciones',
      },
      self_assessment: {
        last_answers: 'Últimas respuestas',
        individual_self_assessment: 'Autoevaluación individual',
      },
    },

    reports: {
      yours_answers: 'Tus respuestas',
      global_average: 'Media general: {value}',
      your_result: 'Tu resultado',
      answered_average: 'Encuestados promedio',
      less_developed: 'Menos desarrollado',
      more_developed: 'Más desarrollado',
      smallest_result: 'Resultado más bajo: {value}',
      biggest_result: 'Resultado más alto: {value}',

      competences_title: 'Habilidades',
      charts_title: 'Gráficos',
      behavior_title: 'Exponentes del comportamiento',
      development_title: 'Consejos de desarrollo',

      report_360: {
        title: 'Resultado de {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        valued_questions_title: 'Promedios por comportamiento',
        text_questions_title: 'Respuestas abiertas',
      },

      skeleton: {
        title: 'Descripción de la evaluación',
        description: 'Estás participando de la Investigación Empresas Humanizadas. Tiene como objetivo identificar las prácticas humanizadas presentes dentro de las Organizaciones a través de un proceso de escucha de todas las partes involucradas. Su contribución es muy importante para que la empresa pueda identificar cómo servirle mejor a usted y también a la Sociedad.',
      },
    },

    respondent_role: {
      title_individual: 'Hola, bienvenido, has sido invitado a responder a la evaluación de',
      title_organization: 'Hola, bienvenido, has sido invitado a responder a la evaluación de la organización',
      subtitle_organization: 'Seleccione su relación con la organización evaluada a continuación.',
      subtitle_individual: 'Seleccione a continuación cuál es su relación con {value}.',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Grupo total',
        completed_answers: 'Respuestas completas',
        group_and_self_legend: 'Vista del grupo y autoevaluación',
        potentially_limiting: 'Potencialmente limitante',
      },
      graphs: {
        labels: {
          bigger: 'Valor más alto',
          median: 'Mediana',
          smaller: 'Valor más bajo',
          gravity_center: 'CGC actual',
        },
        group_legend: 'Vista del grupo',
        axis: {
          very_high: 'Muy alta',
          high: 'Alta',
          medium: 'Mediana',
          low: 'Baja',
          very_low: 'Muy baja',
          stage_replace: 'Etapa {número}',
        },
      },
      recommendation_analysis: 'Recomendaciones para el proceso de análisis',
      individual_development: 'Plan de desarrollo individual',
      considerations: 'Consideraciones',
      recommendation: 'Recomendaciones',
      respondents_section: {
        reliability: 'Fiabilidad',
        applications: 'Aplicaciones',
      },
      cards_section: {
        group_view: 'Vista del grupo',
        self_assessment: 'Autoevaluación',
        benchmark: 'Punto de referencia',
        influence: 'Influencia',
        stage: 'Prácticas',
      },
      one_page: {
        top_3_card_title: 'Los 3 mejores | Cultura de la organización',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Sociedad',
          bar_2: 'Socios',
          bar_3: 'Clientes',
          bar_4: 'Empleados',
          bar_5: 'Líderes',
          bar_6: 'General',
        },
        radar: {
          bar_1: 'Creación',
          bar_2: 'Realización',
          bar_3: 'Organización',
          bar_4: 'Colaboración',
          label_1: 'Experiencia actual',
          label_2: 'Experiencia deseada',
        },
      },
      labels: {
        difference: 'Diferencia',
        benchmark: 'Punto de referencia',
        trust_level: 'Nivel de confianza',
        perspective: 'Perspectiva público {isInternal, select, true {Interno} false {Externo}}',
        meaning: 'Significado',
        score: 'Puntaje',
        ratings_scale: 'Escala de valoraciones',
        level: 'Nivel',
      },
      vertical_levels: {
        level_1: {
          title: 'Supervivencia',
          description: 'enfoque en la protección y las necesidades básicas de la organización.<br/>Puede producirse manipulación, coerción, bajo nivel de confianza y participación.',
          text: 'Autoprotección y necesidad a corto plazo.',
        },
        level_2: {
          title: 'Procesos',
          description: 'enfoque en el cumplimiento y la estabilidad de los procesos y las relaciones.<br/>Las reglas y normas de trabajo rigen el comportamiento y la actitud de las personas.',
          text: 'Mantenimiento de las normas y los estándares vigentes.',
        },
        level_3: {
          title: 'Especialización',
          description: 'enfoque en la especialización, las competencias y la eficiencia.<br/>Las habilidades de los individuos y las áreas de interés impulsan los comportamientos.',
          text: 'Especialización del procesos y de la eficiencia.',
        },
        level_4: {
          title: 'Resultados',
          description: 'enfoque en los análisis, los resultados y las metas que alcanzar.<br/>Un alto nivel de responsabilidad y un fuerte sentido de meritocracia dominan las relaciones.',
          text: 'Entrega de resultados y efectividad.',
        },
        level_5: {
          title: 'Personas',
          description: 'enfoque en las relaciones humanas y la interacción con el sistema.<br/>La perspectiva de competencia es instituida por la abundancia y la interdependencia sistémica.',
          text: 'Relaciones humanas y con el medio ambiente.',
        },
        level_6: {
          title: 'Creación conjunta',
          description: 'enfoque en la capacidad de integración y transformación sistémica.<br/>Las divergencias se convierten en puntos de apoyo para la creación y la innovación conjuntas.',
          text: 'Propósito elevado y gestión dinámica.',
        },
        level_7: {
          title: 'Propósito',
          description: 'enfoque en la sabiduría y la inteligencia colectivas. Capacidad de sostener tensiones como complejidad y simplicidad, integridad y fractal.',
          text: 'Al servicio del bienestar de la humanidad.',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfeccionista',
          description: 'hacer lo correcto, evitar errores y trabajar hacia el autodesarrollo y el autocontrol.',
        },
        level_2: {
          title: 'Útil',
          description: 'ser útil y fiable, estar disponible para las personas, prever y satisfacer las necesidades de los demás.',
        },
        level_3: {
          title: 'Realizadora',
          description: 'lograr metas, presentar una imagen que apoye el éxito de la organización, ser productivo y desempeñarse.',
        },
        level_4: {
          title: 'Creativa',
          description: 'ser auténtico y expresar la individualidad, estar conectado y fiel a las emociones, tener un significado y marcar una diferencia en el mundo.',
        },
        level_5: {
          title: 'Observadora',
          description: 'aportar un sentido al mundo, ahorrar recursos, pensar, analizar, resolver, evitar la dependencia y dedicarse al conocimiento.',
        },
        level_6: {
          title: 'Preguntadora',
          description: 'crear estabilidad, confianza y seguridad, proteger la organización, evitar amenazas y riesgos, ser leal y responsable.',
        },
        level_7: {
          title: 'Entusiasta',
          description: 'siempre con una perspectiva positiva, foco en el futuro, busca el placer, la libertad y amplía las posibilidades de la vida.',
        },
        level_8: {
          title: 'Protectora',
          description: 'ser fuerte y controlar, evitar la debilidad y la vulnerabilidad, hacer que las cosas sucedan, tomar decisiones y lograr resultados.',
        },
        level_9: {
          title: 'Pacificadora',
          description: 'promover la armonía y la paz, recibir la influencia de las personas, crear comodidad y rutinas, acoger y reducir los conflictos en el grupo.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margen de error',
      confidence: 'Nivel de confianza',
      print_button: 'Guardar PDF',

      engagement_section: 'Participación general de las partes interesadas',

      organizational_identity: {
        title: 'Identidad organizativa',
        vertical_development: 'Desarrollo vertical (actual)',
        horizontal_development: 'Desarrollo horizontal (actual)',
        values_to_develop: 'Los 3 principales valores que desarrollar',
        values_to_adjust_reframe: 'Los 3 valores principales que ajustar o reencuadrar',
      },

      stakeholders_engagement: {
        title: 'Participación de las partes interesadas',
        description: 'Resultado del grado de participación de las partes interesadas de la empresa (puntaje de -100 a +100). Un puntaje negativo expresa desconexión y un puntaje positivo expresa la participación.',
      },

      stakeholders_management: {
        title: 'Principios de gerencia',
        description: 'Resultado de la evaluación de las prácticas y los resultados de la gestión en cinco principios (puntaje de 0 a 100).',
      },

      scores_scale: {
        focus: 'Enfoque',
        meaning: 'Significado',
        min_max: '{min} a {max}',

        quality: {
          title: 'Calidad de las relaciones',
          description: 'Índice de calidad de las relaciones (IQR)',
          '0_50': 'Relaciones extremadamente deterioradas, poniendo en riesgo el futuro del negocio.',
          '50_60': 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
          '60_70': 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
          '70_80': 'Las relaciones presentan problemas críticos que requieren intervenciones en los sistemas internos.',
          '80_90': 'Relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
          '90_95': 'Relaciones positivas y bastante sanas.',
          '95_100': 'Relaciones extremadamente positivas y saludables.',
        },

        maturity: {
          title: 'Madurez organizativa',
          description: 'Centro de gravedad de conciencia (CGC)',
          value1: 'Autoprotección y necesidades a corto plazo.',
          value2: 'Mantenimiento de las normas y los estándares vigentes.',
          value3: 'Especialización de los procesos y de la eficiencia.',
          value4: 'Entrega de resultados y efectividad.',
          value5: 'Relaciones humanas y con el medio ambiente..',
          value6: 'Propósito elevado y gestión dinámica.',
          value7: 'Al servicio del bienestar de la Humanidad.',
        },
      },

      engagement_messages: {
        '-100_-50': 'En la percepción de los grupos de interés, la organización expresa un pésimo nivel de compromiso con los problemas que ponen en riesgo el futuro del negocio.',
        '-49_-1': 'En la percepción de los grupos de interés, la organización expresa un nivel de compromiso positivo, teniendo relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
        '0_24': 'En la percepción de los grupos de interés, la organización expresa un bajo nivel de compromiso con los temas críticos que demandan atención.',
        '25_49': 'En la percepción de los grupos de interés, la organización expresa un buen nivel de compromiso con problemas específicos que demandan atención.',
        '50_64': 'En la percepción de los grupos de interés, la organización expresa un alto nivel de compromiso con varias oportunidades de mejora que deben explorarse.',
        '65_74': 'En la percepción de los grupos de interés, la organización expresa un alto nivel de compromiso con la presencia de oportunidades de mejora.',
        '75_84': 'En la percepción de los grupos de interés, la organización expresa un nivel muy alto de compromiso y debe actuar para mantenerlo así.',
        '85_100': 'En la percepción de las partes interesadas, la organización expresa un excelente nivel de compromiso y probablemente seguirá haciéndolo en el futuro.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimensión',
        score: 'Puntaje',
        scale: 'Escala',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO y fundador',
    },
    iqr_section: {
      levels: {
        '95_100': 'Relaciones extremadamente positivas y saludables.',
        '90_95': 'Relaciones positivas y bastante sanas.',
        '80_90': 'Relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
        '70_80': 'Las relaciones presentan problemas críticos que requieren intervenciones en los sistemas internos.',
        '60_70': 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
        '50_60': 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
        '0_50': 'Relaciones extremadamente deterioradas, poniendo en riesgo el futuro del negocio.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'gráfico de identidad organizativa',
    },
    narratives_analysis: {
      internal: 'Narrativas internas',
      external: 'Narrativas externas',
      title: 'Las narrativas revelan...',
      levels: {
        level100: 'Relaciones sumamente positivas al servicio de la Humanidad y de la Vida.',
        level80: 'Relaciones saludables, con problemas ocasionales que necesitan atención.',
        level60: 'Relaciones saludables, con problemas críticos que necesitan intervención.',
        level40: 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
        level20: 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
        level0: 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Orientación cultural',
    },
    components: {
      consolidated_results: {
        score: 'Puntaje',
        sample_size: 'Tamaño de la muestra',
        margin_of_error: 'Margen de error',
        trust_rating: 'Nivel de confianza',
      },
      consolidated_levels: {
        very_high: 'Extremadamente alto',
        high: 'Alto',
        middle: 'Mediano',
        low: 'Bajo',
        extremely_low: 'Extremadamente bajo',
      },
      time_circles: {
        past: 'Pasado',
        present: 'Presente',
        future: 'Futuro',
      },
      base_page: {
        answered_by: 'Respondido por',
      },
      center_chart: {
        optimistic_outlook: 'Perspectiva optimista',
        pessimistic_outlook: 'Perspectiva pesimista',
      },
      start_chart: {
        degree_of_maturity: 'Grado de madurez organizativa',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Centro de gravedad',
      },
      side_menu: {
        menu_intro: 'Introducción',
        letter: 'Carta al lector',
        intro_comments: 'Comentarios generales',
        menu_rating: 'Calificaciones',
        intro: 'Introducción a las calificaciones',
        general_results: 'Resultado general',
        general_comments: 'Comentarios generales',
        focus: 'Dónde enfocarse',
        menu_perspective: 'Perspectiva de múltiples partes interesadas',
        respondents: 'Encuestados',
        engagement: 'Participación',
        experience: 'Experiencia',
        score: 'Satisfacción',
        perspective: 'Perspectiva de futuro',
        menu_principles: 'Principios',
        overview: 'Vista general de los principios',
        purpose: 'Propósito mayor',
        strategy: 'Estrategia de valor',
        conscious_culture: 'Cultura consciente',
        adaptability: 'Aprendizaje y cambio',
        leadership: 'Liderazgo consciente',
        menu_values: 'Valores',
        menu_identity: 'Identidad',
        formation: 'Formación de la identidad',
        vertical_dev: 'Desarrollo vertical',
        horizontal_dev: 'Desarrollo horizontal',
        quality: 'Índice de calidad de las relaciones',
        menu_narratives: 'Narrativas',
        map: 'Mapa de narrativas',
        main: 'Narrativas principales',
        analysis: 'Análisis de contenido',
        highlights: 'Prácticas destacadas',
        recommended: 'Prácticas recomendadas',
        demographics: 'Datos demográficos',
        reputation: 'Reputación',
        general_reputation: 'Reputación general',
        reputation_by_stakeholder: 'Reputación por parte interesada',
        responding_data: 'Datos de los encuestados',
        learning_and_change: 'Aprendizaje y cambio',
        perceived_result: 'Resultado percibido',
        culture: 'Cultura',
        organizational_culture: 'Cultura organizativa',
        respondent_profile: 'Perfil de los encuestados',
        group_view: 'Vista del grupo',
        self_assessment: 'Autoevaluación',
        influence_capacity: 'Capacidad de influencia',
        leadership_profile: 'Perfil de liderazgo',
        maturity_stage: 'Etapa de madurez',
        leadership_style: 'Estilo de liderazgo',
        values_to_provide: 'Valores que fortalecer',
        values_to_adjust: 'Valores que ajustar',
      },
    },
  },

  components: {
    average_card: {
      general: 'Media general',
    },

    competence_card: {
      average_title: 'Promedios por grupos de respuesta',
    },

    star_question: {
      select_a_number: 'Seleccione un número',
    },

    score_question: {
      good: 'Muy bueno',
      bad: 'Muy malo',
    },

    terms_of_use: {
      read: 'Leí y concuerdo con los',
      terms_link: 'Términos de uso',
      and: 'y',
      policies_link: 'Política de privacidad',
      platform: 'de la Plataforma Humanizadas',
    },

    table_pagination: {
      items_per_page: 'Ítems por página',
      count: '{isPlural, select, true {Itens cadastrados} false {Item cadastrado}}',
      page: 'Página',
      more_button: 'Cargar más',
    },

    values_matrix: {
      amount: 'Número de características seleccionadas',
    },
  },

  forms: {
    login: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      password_label: 'Contraseña',
      forgot_password: 'Olvidé mi contraseña',
      forgot_email: 'Olvidé mi e-mail',
      login_button: 'Entrar',
      signup_button: 'Crear cuenta',

      warning_dialog: {
        title: '¿Usted escribió el e-mail correctamente?',
        message: 'Certifique que usted ya creó su cuenta y que usó el e-mail en el que recibió la invitación para usar la plataforma',
        cancel_button: 'Completé correctamente',
        confirm_button: 'Verificar',
      },
    },

    my_profile: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',

      current_password_label: 'Contraseña actual',
      password_label: 'Contraseña nueva',
      confirm_password_label: 'Confirme su contraseña nueva',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      sector_label: 'Área',
      sector_placeholder: 'Ex.: Administrativo',

      role_label: 'Función',
      role_placeholder: 'Ex.: Gerente Administrativo',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'Selecione',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género',

      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      pcd_label: 'PcD (Persona con Deficiencia)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Deficiencia Física',
      pcd_tooltip_pt1_body: 'Alteración completa o parcial de uno o más segmentos del cuerpo humano, comprometiendo la función física, presentándose bajo la forma de paraplegia, paraparesia, monoplegia, monoparesia, tetraplegia, tetraparesia, triplegia, hemiplegia, hemiparesia, ostomía, amputación o ausencia de miembro, parálisis cerebral, enanismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no producen dificultades para el desempeño de funciones.',

      pcd_tooltip_pt2_title: 'Deficiencia auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las posibilidades auditivas sonoras, variando de grados y niveles en la siguiente forma',

      pcd_tooltip_pt3_title: 'Deficiencia Visual',
      pcd_tooltip_pt3_body: 'La deficiencia visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo o del sistema visual. El deficiente visual puede ser la persona ciega o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total o simplemente amaurosis, se caracteriza por la completa pérdida de visión sin percepción visual de luz y forma. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, en general, la baja visión es definida como una condición en la cual la visión de la persona no puede ser totalmente corregida con gafas, interfiriendo en sus actividades diarias, así como la lectura y la locomoción.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente inferior al promedio, con manifestaciones antes de los dieciocho años y limitaciones asociadas a dos o más áreas de habilidades adaptativas, tales como',

      color_label: 'Color/Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según referencia del IBGE',

      sexual_orientation_label: 'Orientación Sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'Orientación en relación a la atracción que se siente por otros individuos. Generalmente también involucra cuestiones sentimentales y no solamente sexuales. Referencia: UFSC',

      success_dialog: {
        title: 'Alteraciones guardadas',
        message: '¡Sus alteraciones fueron guardadas exitosamente!',
      },
    },

    organization_profile: {
      company_name_label: 'Nombre de la empresa',
      company_name_placeholder: 'Ex.: Humanizadas',
      company_corporate_name_label: 'Razón social',
      company_corporate_name_placeholder: 'Ex.: Humanizadas LTDA',

      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      website_label: 'Sitio',
      website_placeholder: 'Ex.: www.site.com',
      cnpj_label: 'CNPJ',
      cnpj_placeholder: 'Ex.: 12.345.678/0001-99',
      state_registry_label: 'Inscripción estadual',
      state_registry_placeholder: 'Ex.: 000.000.00',
      foundation_date_label: 'Fundación',
      foundation_date_placeholder: 'Ex.: 01/01/2020',
      number_of_employees_label: 'Nro. de colaboradores',
      number_of_employees_placeholder: 'Ex.: 100',
      foundation_city_label: 'Origen',
      foundation_city_placeholder: 'Ex.: Ciudad De Mexico',
      sector_label: 'Área',
      sector_placeholder: 'Ex.: Servicios',

      company_type_label: 'Tipo',
      revenue_label: 'Renta',

      postal_code_label: 'CEP',
      postal_code_placeholder: 'Ex.: 12.345-678',
      street_label: 'Calle/Avenida',
      street_placeholder: 'Ex.: Calle Félix',
      number_label: 'Número',
      number_placeholder: 'Ex.: 10',
      neighborhood_label: 'Barrio',
      neighborhood_placeholder: 'Ex.: Planalto',
      complement_label: 'Complemento',
      complement_placeholder: 'Ex.: Sala 101',
      city_label: 'Ciudad',
      city_placeholder: 'Ex.: Ciudad De Mexico',
      state_label: 'Estado',
      state_placeholder: 'Ex.: Ciudad De Mexico',

      select_placeholder: 'Seleccione',
      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      select_options: {
        public: 'Pública',
        private: 'Privada',
        public_private: 'Pública-privada',
      },
    },

    respondents_builder: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      add_button: 'Agregar otro',
      cancel_button: 'Cancelar',
      submit_button: 'Agregar',
    },

    signup: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',
      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'Pedimos su CPF para validar su identidad',
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar contraseña',
      login_button: 'Ya estoy registrado',
      signup_button: 'Crear registro',
      password_helper: 'Mínimo de 6 caracteres, 1 maíuscula e 1 minúscula',

      dialog: {
        title: '¡Muchas gracias por registrarse!',
        message: '¡Ahora usted ya puede aprovechar la experiencia Humanizadas!',
        confirm_button: 'Hacer login',
      },

      dialog_warning: {
        title: 'Atención',
        message: 'Este enlace de registro es exclusivo del correo electrónico {email} y solo debe completarse si usted es responsable de él. ¿Te gustaria continuar?',
        left_button: 'No',
        right_button: 'Si',
      },
    },

    support: {
      message_label: 'Mensaje',
      message_placeholder: 'Ex.: El correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Esté atento a su correo',
        message: 'Enviaremos en los próximos días más información para darle soporte. Recuerde verificar también su bandeja de Spam.',
        confirm_button: 'Finalizar',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'Ex.: Dias',
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      message_label: 'Mensaje',
      message_placeholder: 'Ex.: El correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: 'Esté atento a su correo',
        message: 'Enviaremos en los próximos días más información para darle soporte. Recuerde verificar también su bandeja de Spam.',
        confirm_button: 'Finalizar',
      },
    },

    password_recovery_enter_email: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      cancel_button: 'Cancelar',
      confirm_button: 'Enviar',
      email_not_found: 'E-mail no encontrado. Necesito ayuda.',

      success_dialog: {
        title: 'Esté atento a su correo',
        message: 'Usted recibirá un link para resetear su contraseña. Recuerde verificar también su bandeja de Spam',
        help_button: 'No recibí el email',
        confirm_button: 'Finalizar',
      },

      warning_dialog: {
        title: '¿Usted completó su e-mail correctamente?',
        message: 'Verifique si usted ya creó su cuenta y si usó el e-mail en el que recibió la invitación para usar la plataforma.',

        cancel_button: 'Completé correctamente',
        confirm_button: 'Verificar',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar contraseña',
      password_helper: 'Mínimo de 6 caracteres, 1 mayúscula y 1 minúscula',

      confirm_button: 'Enviar',

      dialog: {
        title: '¡Su contraseña fue alterada exitosamente!',
        message: 'Usted ya puede hacer el login nuevamente',
        confirm_button: 'Hacer login',
      },
    },

    complete_register: {
      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'Ex.: Humanizadas',
      working_company_department_label: 'Área',
      working_company_department_placeholder: 'Seleccione',
      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',
      gender_label: 'Género',
      gender_placeholder: 'Seleccione',

      complete_register_dialog: {
        title: '¡Cuéntanos sobre ti!',
        subtitle: 'Necesitamos registrar más información para completar su registro',
        subtitle2: 'La información completada, a partir de ahora, será utilizada con fines de investigaciones de manera agregada, garantizando por lo tanto su anonimato.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Muestra ideal',
      minimum_column: 'Muestra mín.',
    },

    respondents: {
      title: 'Encuestados',
      subtitle_1: 'Los contactos agregados en esta página también se agregarán a su base de datos y estarán disponibles en el menú "Encuestados"',
      subtitle_2: 'Atención: está agregando encuestados al {questionnaire} del usuario evaluado {user}.',
      subtitle_3: 'Atención: está agregando encuestados {showTitle, select, true {al cuestionario {title}} false {a este cuestionario}} {showUser, select, true {del usuario evaluado {user}} false {}}.',
      empty: 'No fue encontrado ningún encuestado',
      no_content: 'No informado',

      actions: {
        invite: 'Invitar para evaluación',
        edit: 'Editar',
        delete: 'Excluir',
        send: 'Enviar un e-mail',
      },

      filter: {
        department: 'Área',
        jobTitle: 'Función',
      },

      dialogs: {
        empty: {
          title: 'No se seleccionó ningún usuario.',
          body: 'Seleccione al menos un usuario en la tabla para esta acción.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Tipo',
          360: 'Evaluación 360',
          270: 'Evaluación 270',
          180: 'Evaluación 180',
          720: 'Evaluación 720',
          self_assessment: 'Autoevaluación',
        },
      },
      config: {
        view_questionnaire: 'Visualizar cuestionarios',
        config_assessment: 'Configurar evaluación',

        dialogs: {
          respondents: {
            title: 'Usted aún no posee ningún encuestado registrado en la base',
            body: 'Registre encuestados y facilite la configuración de sus evaluaciones',

            left_button: 'Ahora no',
            right_button: 'Registrar encuestados',
          },
        },
      },
      monitor: {
        show_report: 'Ver relatório',
        tooltips: {
          play: 'Retomar',
          pause: 'Pausar',
          finish: 'Finalizar ahora',
          results: 'Ver resultado',
          view: 'Visualizar cuestionarios',
        },

        pause_dialog: {
          title: '¿Quieres pausar la evaluación?',
          body_1: 'Mientras la evaluación está en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarlo en cualquier momento.',
          body_2: 'Atención: la fecha de finalización no se modificará.',
        },

        stop_dialog: {
          title: '¿Quieres finalizar la evaluación?',
          body_1: 'Una vez finalizada la evaluación, no será posible recopilar más respuestas o publicarla nuevamente. Los resultados se realizarán en base a los datos ya recibidos.',
          body_2: 'Atención: si desea suspender la evaluación por un período, intente pausarla.',
        },
      },
      release: {
        tooltips: {
          release: 'Liberar ahora',
          edit: 'Editar',
          view: 'Visualizar cuestionarios',
        },


        header: {
          startWithin: 'Inicio en',
        },

        release_dialog: {
          title: '¿Liberar evaluación ahora?',
          body: 'Si usted libera la evaluación ahora, será enviada a todos los encuestados. No será posible deshacer esa acción.',
          left_button: 'Guardar fecha de inicio',
          right_button: 'Liberar',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'Visualizar cuestionarios',
          results: 'Ver resultados',
          monitor: 'Monitorear',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Responder autoevaluación',
        monitor: 'Monitorear encuestados',
      },
    },

    header: {
      bulk_actions: 'Acciones masivas',
    },

    filters: {
      clean_filters: 'Borrar Filtros',
      apply_filters: 'Aplicar Filtros',
      assessment_title: 'Reseñas de Filtros',
      invited_option_start: 'Hasta {number} invitados',
      invited_option_middle: 'De {start} a {end} invitados',
      invited_option_end: 'Más de {number} invitados',
      time_option_hour: 'Siguientes {number}h',
      time_option_days: 'Siguientes {number} dias',
      assessments_option_start: 'Hasta {number} evaluaciones',
      assessments_option_middle: 'De {start} a {end} evaluaciones',
      assessments_option_end: 'Más de {number} evaluaciones',
      questions_option_start: 'Hasta {number} preguntas',
      questions_option_middle: 'De {start} a {end} preguntas',
      questions_option_end: 'Más de {number} preguntas',
      assessed_option_start: 'Hasta {number} calificados',
      assessed_option_middle: 'De {start} a {end} calificados',
      assessed_option_end: 'Más de {number} calificados',
    },
  },

  dialogs: {
    reminder: {
      title: 'Enviar e-mail',
      body: 'Seleccione el e-mail que desea enviar a los encuestados seleccionados. Al enviar este e-mail, será disparado y no será posible deshacer esa acción',
      body_amount: 'Seleccione el e-mail que desea enviar a ----. Al enviar este e-mail, será disparado y no será posible deshacer esa acción',
      left_button: 'Cancelar',
      right_button: 'Enviar e-mail',
    },
    send_email_all: {
      title: '¿Enviar e-mail a los seleccionados?',
      title_number: '¿Enviar e-mail a ...?',
      body: 'Al enviar este e-mail, será disparado y no será posible deshacer esa acción. Para enviar un e-mail a apenas algunos encuestados, selecciónelos en la tabla debajo y haga click en --- en masa',
      left_button: 'Cancelar',
      right_button: 'Enviar a todos',
    },
    email_successfully_sent: {
      title: 'Envío de {multiple, select, true {emails} false {email}}',
      body: '{multiple, select, true {Todos los correos electrónicos fueron enviados} false{El correo electrónico ha sido enviado}} con éxito!',
      button: 'Concluir',
    },
  },
};
