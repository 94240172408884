export default {
  RESET_STORE: 'AUTH/RESET_STORE',
  REDUX_NAMESPACE: 'HUMANIZADAS/AUTH',

  AUTHENTICATE_REQUEST: 'AUTH/AUTHENTICATE_REQUEST',
  LOGOUT_REQUEST: 'AUTH/LOGOUT_REQUEST',
  UPDATE_UNAUTHENTICATED_USER: 'AUTH/UPDATE_UNAUTHENTICATED_USER',

  ACCESS_HEADERS: 'HUMANIZADAS/AUTH/ACCESS_HEADERS',

  RESTORE: 'AUTH/RESTORE',
};
