import authActions from './auth/auth.actions';
import applicationActions from './application/application.actions';
import userActions from './user/user.actions';
import cacheActions from './caches/caches.actions';
import persistentCacheActions from './persistent.caches/caches.actions';

export { authActions };
export { applicationActions };
export { cacheActions };
export { persistentCacheActions };
export { userActions };

export default {
  auth: authActions,
  application: applicationActions,
  user: userActions,
  caches: cacheActions,
  cache: cacheActions,
  persistentCache: persistentCacheActions,
};
