// import { useIntl } from 'react-intl';
import routes from '../i18n/routes/routes';

const useRoutes = () => {
  // const { locale } = useIntl();
  // const localizedRoutes = routes[locale] || routes['pt-BR'];
  const localizedRoutes = routes['pt-BR'];

  return localizedRoutes;
};

export default useRoutes;
