export default {
  criar_conta: {
    simple: 'Sign up',
  },

  entrar: {
    simple: 'Login',
  },

  olar: {
    simple: 'Hello!',
    placeholder: 'Hello {name}!',
  },

  plural: {
    simple: 'I have {num, plural, one { cat} other { cats}}',
  },

  select: {
    placeholder: 'Select',
    simple: 'I am {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Organizational profile',
      my_profile: 'My account',
      respondents: 'Organization respondents',
      managers: 'Managers',
    },
    assessments: 'Assessments',
    choose_view_mode: 'Switch to {isManagerView, select, true {respondent} false {manager}}',
    sign_out: 'Exit from my account',
    help: 'I need help',
    see_all_companies: 'See all companies',
    answer_assessments: 'Reply assessments',
  },

  statuses: {
    questionnaires: {
      created: 'Created',

      ready_to_start: 'No {gender, select, male {Started} female {Started}}',
      started: '{gender, select, male {Started} female {Started}}',
      answered: '{gender, select, male {Answered} female {Answered}}',

      not_answered: '{gender, select, female {Not answered} male {Not answered}}',
      partially_answered: 'Not ended {gender, select, male {Finished} female {Finished}}',
      results_available: 'View results',
    },

    assessments: {
      released: 'Released',
      paused: 'Paused',
      finished: 'Ended',
      results: 'Results released',
    },

    see_all_companies: {
      companies: '{num, plural, one {Company} other {Companies}}',
      desisted: '{num, plural, one {Quit} other {Quit}}',
      in_progress: '{num, plural, one {Released} other {Released}}',
      paused: '{num, plural, one {Paused} other {Paused}}',
      finished: '{num, plural, one {Finished} other {Finished}}',
      results_available: '{num, plural, one {See result} other {See results}}',
    },

    respondents: {
      pending: 'Pending',
      registered: 'Registered',
      not_answered: 'Not answered',
      answered: 'Answered',
    },

    assessment_subtype: {
      self_assessment: 'Auto',
      180: '180',
      270: '270',
      360: '360',
      720: '360',
    },
  },

  user_roles: {
    manager: 'Manager',
    owner: 'Owner',
    respondent: 'Respondent',
  },

  errors: {
    required: {
      simple: 'Field required',
      email: 'The e-mail is required',
      password: 'Password required',
      cpf: 'CPF required',
      cnpj: 'CNPJ required',
      first_name: 'Name required',
      last_name: 'Last name required',
    },

    invalid: {
      start_date: 'Must be greater than today',
      end_date: 'Must be greater than the initial',
      simple: 'Invalid field',
      email: 'Invalid e-mail',
      cpf: 'Invalid ID',
      cep: 'Invalid Zip code',
      cnpj: 'Invalid CNPJ',
      url: 'Invalid URL',
      value: 'Amount not allowed',
    },

    invalid_fields: 'Your changes will not be saved if there are errors in the form!',
    generic_error_message: 'Ops, there was an error to process your request! If it persists, please contact us through the menu support.',
    file_too_big: 'File larger than {num} MB',
    wrong_mime: 'Invalid file type',
    passwords_do_not_match: 'Confirmation does not match inserted  password',
    min_string_len: 'Minimum of {num, plural, one {# character} other {# characters}}',
    max_string_len: 'Maximum of {num, plural, one {# character} other {# characters}}',
    min_uppercase_letters: 'Minimum of {num, plural, one {# capital letter} other {# capital letters}}',
    min_lowercase_letters: 'Minimum of {num, plural, one {# lower case} other {# small letters}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Woman',
      male: 'Man',
      non_binary: 'No binary',
      fluid: 'Fluid',
      other: 'Other',
      undeclared: 'I would rather not say',
    },

    sex: {
      female: 'Female',
      male: 'Male',
      intersexual: 'Intersex',
      other: 'Other',
    },

    orientation: {
      assexual: 'Asexual',
      bissexual: 'Bisexual',
      straight: 'Heterosexual',
      homosexual: 'Homosexual',
      pansexual: 'Pansexual',
      fluid: 'Fluid',
      other: 'Other',
      undeclared: 'I would rather not say',
    },

    race: {
      yellow: 'Yellow',
      white: 'White',
      indian: 'Indigenous',
      pardo: 'Brown',
      black: 'Black',
      undeclared: 'I would rather not say',
    },

    pcd: {
      nao_pcd: 'I have no disability',
      deficiencia_fisica: 'Physical disability',
      deficiencia_auditivo: 'Hearing impairment',
      deficiencia_visual: 'Visual impairment',
      deficiencia_mental: 'Mental disability',
      deficiencia_multipla: 'Multiple disabilities',
      undeclared: 'I would rather not say',
    },

    job_titles: {
      estagiario: 'Internship',
      professor: 'Professor',
      consultoria: 'Consultancy',
      supervisao: 'Supervision',
      analista: 'Analyst',
      assistente: 'Assistant',
      auxiliar: 'Apprentice',
      supervisor: 'Supervision',
      coordenador: 'Coordination',
      gerente: 'Management',
      senior: 'Senior specialist',
      diretor: 'Directory (C-Level)',
      conselho: 'Board',
      prestador_de_servicos: 'Service provider',
      other: 'Other',
    },

    departments: {
      administracao: 'Management',
      assistencia_ou_suporte_tecnico: 'Technical assistance or support',
      atendimento: 'Customer Service',
      auditoria: 'Audit and consulting',
      comercial: 'Sales',
      compras: 'Purchase',
      complience: 'Compliance',
      comunicacao_e_assessoria: 'Communication and counseling',
      controladoria: 'Controllership',
      contabilidade: 'Accounting',
      contratos: 'Contracts',
      engenharia: 'Engineering',
      estrategia: 'Strategy',
      financeiro: 'Finance',
      inovacao: 'Innovation',
      inteligência: 'Intelligence',
      juridico: 'Legal',
      logistica: 'Logistics',
      manutencao: 'Maintenance',
      marketing: 'Marketing',
      melhoria: 'Improvement',
      novos_negocios: 'New business',
      operacoes: 'Operations',
      turismo: 'Tourism',
      pd: 'P&D',
      pos_vendas: 'After Sales',
      projetos: 'Projects',
      producao: 'Production',
      processos: 'Processes',
      qualidade: 'Quality',
      rh_dho: 'HR / HOD',
      relacoes_institucionais: 'Institutional Relations',
      seguranca: 'Security',
      servicos_tecnicos: 'Technical services',
      suprimentos: 'Supplies',
      tecnologia_da_informacao: 'Information Technology',
      other: 'Other',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Assessments',
    },

    multi_perspective_assessment_individual: {
      title: 'Assessment of Individuals',
    },

    individual_assessments: {
      title: 'Assessment of Individuals',
      self: 'My Assessment',
      others: 'Evaluate people',
    },

    multi_perspective_assessment: {
      title: 'Multi-Perspective Assessments',
    },

    respondents: {
      title: 'Surveyed',
    },

    organization_profile: {
      title: 'Profile of Organization',
    },

    my_profile: {
      title: 'My profile',
    },

    managers: {
      title: 'Managers',
    },
  },

  indicators: {
    true: 'Yes',
    false: 'No',
    uninformed: 'Not informed',
    placeholder: {
      money: 'Ex.: R$1.000.000',
      number: 'Ex.: 10',
      selector: 'Select',
      percent: 'Ex.: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Information',
      name: 'Information',
    },

    indicators: {
      id: 'Indicators',
      name: 'Indicators',
    },
  },

  emails: {
    manual: 'Manual submission',
    invite_template: {
      title: 'E-mail invitation',
      date: 'Immediate delivery',
    },
    general_template: {
      title: 'General reminder',
      date: 'Immediate delivery',
    },
    week_before_template: {
      title: 'Reminder - A week before the end',
      date: 'A week before the end of the assessment',
    },
    day_before_template: {
      title: 'Reminder - Term Eve',
      date: 'One day before the end of the assessment',
    },
    results_available_template: {
      title: 'Results available',
      date: 'After the end of the assessment',
    },
    application_email: {
      all: 'Send email to all respondents of this subject',
      assessed: 'Send email to this appraiser',
      unanswered: 'Email this respondent for unanswered',
      role_type_title: {
        all: 'Send email everyone who has not responded yet?',
        internal: 'Send email to all internal stakeholders?',
        external: 'Send email to all external stakeholders?',
      },
      role_type_body: {
        all: 'This email will be sent to all respondents registered via email who have not yet completed their response. After sending, it is not possible to undo the action.',
        internal: 'When sending, this email will be sent to all respondents registered via email as internal stakeholders, and it will not be possible to undo this action.',
        external: 'When sending, this email will be sent to all respondents registered via email as external stakeholders, and it will not be possible to undo this action.',
      },
    },
    collection_email: {
      all: 'Send to all subjects and their respondents',
      assessed: 'Send to all evaluated',
      unanswered: 'Send to all unanswered of all evaluated',
    },
  },

  progress_bar: {
    text: 'Tempo de {mobile, select, true {resp.} false {respostas}}',
  },

  words: {
    email: 'E-mail',
    email_simple: 'Email',
    password: 'Password',
    sector: 'Sector',
    gender: 'Gender',
    company: 'Company',
    role: 'Role',
    your_role: 'Your role',
    description: 'Description',
    behaviour: 'Behavior',
    instructions: 'Instructions',
    cancel: 'Cancel',
    error: 'Error',
    close: 'To close',
    continue: 'Continue',
    finish: 'End',
    pause: 'Pause',
    next: 'Next',
    previous: 'Previous',
    status: 'Status',
    submit: 'Submit',
    and: 'and',
    minimum: 'minimum',
    maximum: 'Maximum',
    minimum_of: 'minimum {num}',
    maximum_of: 'Maximum {num}',
    page: 'Page',
    available: 'Available',
    unavailable: 'Unavailable',
    of: 'of',
    address: 'Address',
    config: 'Set up',
    release: 'Release',
    monitor: 'Monitor',
    edit: 'Edit',
    view: 'Visualize',
    actions: 'Actions',
    required: 'Required',
    type: 'Type',
    questions: 'Questions',
    invited: 'Guests',
    answered: 'Answered',
    not_answered: 'Not answered',
    answer: 'Answer',
    beginning: 'Beginning',
    end: 'End',
    duration: 'Duration',
    add: 'Add',
    new: 'New',
    name: 'Name',
    position: 'Position',
    registered: 'Registered',
    pending: 'Pending',
    manager: 'Manager',
    owner: 'Main manager',
    hour: 'time',
    day: 'day',
    organization: 'Organization',
    individual: 'Individual',
    sex: 'Sex',
    pcd: 'PWDs (Person with Disabilities)',
    color_race: 'Color / Race',
    sexual_orientation: 'Sexual Orientation',
    assessed: 'Assessed',
    assessment: 'Assessment',
    self_assessment: 'Self-assessment',
    respondent: 'Respondent',
    respondents: 'Respondents',
    back_main: 'Return to initial page',
    manual_sending: 'Manual submission',
    date: 'Date',
    sampling: 'Sampling',
    positive: 'Positive',
    negative: 'Negative',
    neutral: 'Neutral',
    conclusion: 'Conclusion',
    recommendation: 'Recommendation',
    do_not_apply: 'Does not apply',
    search: 'Search',
    intro: 'Introduction',
    average: 'Average',
    in: 'in',
    general: 'General',
    participant: 'Participant',
    terms_of_use: 'Terms of use',
    privacy_policy: 'Privacy policy',
    filter: 'Filter',
    occupation: 'Operation',
    assessment_name: 'Assessment name',
    assessment_status: 'Self-assessment status',
    choose: 'Choose',
    send_all: 'Send all',
    view_template: 'View template',
    smallest_score: 'Smallest score',
    biggest_score: 'Biggest score',
    select_all: 'Select all',
    deselect_all: 'Deselect all',
    score: 'Score',
    meaning: 'Meaning',
    legend: 'Legend',
    population: 'Population',
    ideal_sample: 'Ideal sample',
    minimum_sample: 'General minimum sample',
    general_result: 'General result',
    current: 'Actual',
    desired: 'Desired',
    total: 'Total',
    come_back: 'Come back',
    start: 'Start',
    assessed_organization: 'Assessed organization',
    application: 'Application',
    finished: 'Finalizada',
    initiation: 'Started',
    finale: 'End',
    participations: 'Participações',
    reports: 'Relatórios',
    factors: 'Fatores',
    subgroups: 'Subgrupos',
    optional: 'Optional',

    plurals: {
      day: '{num, plural, one {# day} other {# days}}',
      hour: '{num, plural, one {# hour} other {# hours}}',
      answered: '{num, plural, one {Answered} other {Answered}}',
      result: '{num, plural, one {Result} other {Results}}',
      assessed: '{num, plural, one {Rated} other {Rated}}',
      available: '{num, plural, one {# evaluation} other {# evaluations}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Question} other {Questions}}',
      alternative: '{num, plural, one {Alternative} other {Alternatives}}',
      answer: '{num, plural, one {Answer} other {Answers}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Pessoa} other {Pessoas}}',
    },

    try_again: 'Try again',
    delete: 'Delete',
    confirm: 'Confirm',
    save: 'Save',
    last_name: 'Last name',
    not_informed: 'Uninformed {gender, select, male {informed} female {informed}}',
  },

  checkbox: {
    title: {
      sector: 'Sector',
      role: 'Role',
      cba: 'CBA - Conscious Business Assessment',
      vyl: 'VYL - Valuable Young Leaders',
    },

    label: {
      Administrative: 'Administrative',
      rh: 'HR',
      attendance: 'Customer Service',
      commercial: 'Commercial',
      self_employed: 'Self employed',
      specialist: 'Specialist',
      manager: 'Manager',
      ceo: 'CEO',
      leaderships: 'Leadership',
      collaborators: 'Collaborators',
      clients_partners: 'Clients and Partners',
      partnerships: 'Partnerships',
      inidividual_assessment: 'Individuals assessment',
      organizational_assessment: 'Organizational assessment',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Tell us about you',
      subtitle: 'We need more information about your profile. The data provided will be used for research purposes in an aggregate manner, thus guaranteeing your anonymity.',

      email_label: 'E-mail',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'I do not have an e-mail',
      external_email: 'Answer through Open Link',

      first_name_label: 'Name',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Last Name',
      last_name_placeholder: 'Ex.: days',

      working_company_name_label: 'Company',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Select',

      working_company_job_title_label: 'Function',
      working_company_job_title_placeholder: 'Select',

      biological_sex_label: 'Sex',
      biological_sex_placeholder: 'Select',

      gender_label: 'Gender',
      gender_placeholder: 'Select',
      gender_tooltip: 'Gender identity refers to a person\'s experience with their own gender',

      pcd_label: 'PWD (Person with Disability)',
      pcd_placeholder: 'Select',

      pcd_tooltip_pt1_title: 'Physical impairment',
      pcd_tooltip_pt1_body: 'Complete or partial alteration of one or more segments of the human body, compromising physical function, presented in the form of paraplegia, paraparesis, monoplegia, monoparesis, teraplegia, tetraparesis, triplegia, hemiplegia, hemiparesis, ostomy, amputation or absence of a limb, paralysis brain, namism, limbs with congenital or acquired deformities, except aesthetic deformities and those that do not cause difficulties in performing functions.',

      pcd_tooltip_pt2_title: 'Hearing impairment',
      pcd_tooltip_pt2_body: 'Partial or total loss of hearing possibilities, varying in degrees and levels as follows',

      pcd_tooltip_pt3_title: 'Visual impairment',
      pcd_tooltip_pt3_body: 'Visual impairment is characterized by the limitation or loss of the basic functions of the eye or of the visual system. The visually impaired may be blind or with low vision. Blindness presupposes lack of visual perception due to physiological or neurological factors. Total blindness or just amaurosis, is characterized by the complete loss of vision without visual perception of light and form. Blindness can be congenital or acquired. The visual acuity of people with low vision is very variable; but in general, low vision is defined as a condition in which the person\'s vision can not be fully corrected with glasses, interfering with your daily activities, as well as reading and mobility.',

      pcd_tooltip_pt4_title: 'Mental impairment',
      pcd_tooltip_pt4_body: 'Intellectual functioning significantly lower than average, manifested before the age of eighteen and limitations associated with two or more areas of adaptive skills, such as',

      color_label: 'Color / Race',
      color_placeholder: 'Select',
      color_tooltip: 'According to IBGE reference',

      sexual_orientation_label: 'Sexual Orientation',
      sexual_orientation_placeholder: 'Select',
      sexual_orientation_tooltip: 'Orientation refers to the attraction one feels for other individuals. Usually it also involves emotional issues, not just sexual. Reference: UFSC',

      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'We ask your CPF to validate your identity',
    },

    questionnaire: {
      warning_dialog: {
        title: 'Can we begin? Once started, it is not possible stop the assessment',
        body: 'You can not pause to end later. This restriction is part of our scientific research methodology.',
        button: 'Reply to assessment',
        left_button: 'Cancel',
      },
    },
  },

  pages: {
    login: {
      title: 'It\'s good to have you back!',
      subtitle: 'To access your account, insert the e-mail in which you received the invitation and register your password.',
      stay_logged_in: 'Stay logged in',
    },

    signup: {
      title: 'We were waiting for you!',
      subtitle: 'We have everything ready for you. To access your assessment, simply create an account.',
    },

    lastReport: {
      title: 'Here is the General Report from the latest edition of the research.',
      subtitle: 'Click on the link below to access the general report of the last edition of Pesquisa Empresas Humanizadas do Brasil.',
      button: 'Access report',
    },

    thankYouRegister: {
      title: 'Your answers have been successfully recorded.',
      subtitle: 'We appreciate your contribution.',
    },

    unauthenticated_contact: {
      title: 'Send us a message and we\'ll be in touch.',
      subtitle: 'Tell us more about the problem had and we\'ll find a solution',
    },

    password_recovery_enter_email: {
      title: 'We are here to help you',
      subtitle: 'Enter your registration email and we will send you a link to create a new password.',
    },

    password_recovery_reset_password: {
      title: 'Here is your restart.',
      subtitle: 'Create your new password and then enjoy all the advantages of the platform',
    },

    my_profile: {
      title: 'My profile',
      edit_button: 'Edit',
    },

    organization_profile: {
      title: 'Organization Profile',
      edit_button: 'Edit',
    },

    support: {
      title: 'Send us a message and we\'ll be in touch.',
      subtitle: 'Tell us more about the problem had and we\'ll find a solution',
    },

    respondents: {
      title: 'Respondents',
      title_add: 'Add respondents',
      search_field: {
        placeholder: 'Search',
      },

      actions: {
        add_sheet: 'Add spreadsheet',
        download_template: 'Download spreadsheet template',
        upload_sheet: 'Upload spreadsheeta',
        bulk_actions: 'Bulk actions',
      },

      filter: {
        title: 'Filter',
      },

      add_button: 'Add',

      no_respondents: {
        message: 'You have not added respondents yet',
        button_text: 'Add now',
      },

      owner_informations_dialog: {
        label: {
          email: 'E-mail',
          company: 'Company',
          sector: 'Sector',
          role: 'Role',
        },

        close_button: 'Close',
      },

      configure_assessment_dialog: {
        title: 'Do you want to set up an assessment now?',
        body: 'Now that it\'s been added new respondents to the database, select a type of assessment to set up.',

        close_button: 'Close',
        config_button: 'Set up',
      },

      respondents_filter_dialog: {
        title: 'Filter Respondents',

        cancel_button: 'Cancel',
        aplly_button: 'Apply filters',
      },

      upload_and_download_sheet_dialog: {
        title: 'Remember to use the uploaded spreadsheet template of respondents',
        body: 'If you do not already under the model, then the download and adapt your return to make it as our standard. Thus, you will avoid mistakes at the base of respondents.',

        download_button: 'Download template',
        upload_button: 'Upload',
      },

      delete_respondents_dialog: {
        title: 'Delete {num, plural, one {respondent?} other {respondents?}}',
        body: 'By deleting this {num, plural, one {this respondent} other {these respondents}}, {num, plural, one {they will not} other {they will not}} be able to access any of the assessments. In case ... they have already answered any, all data will be deleted from the results calculations.',
        body_attention: 'Attention: essa ação é irreversível.',

        cancel_button: 'Cancel',
        delete_button: 'Delete',
      },

      delete_enrollments_dialog: {
        title: 'Delete {num, plural, one {respondent?} other {respondents?}}',
        body: 'By deleting {num, plural, one {this respondent} or {these respondents}}, {num, plural, one {ele não poderá} other {eles não poderão}} will not be able view the assessment and will no longer receive emails regarding it. Should you have any questions answered, the data will be deleted from the calculation results.',
        body_attention: 'Attention: essa ação é irreversível.',

        cancel_button: 'Cancel',
        delete_button: 'Delete',
      },

      invitation_dialog: {
        title: 'Invite to assessment',
        body: 'Select the assessment for which respondents would like to invite you selected.',
        empty: 'No ratings recorded in your organization',
        cancel_button: 'Cancel',
        invitation_button: 'Invite',
      },

      edit: {
        title: 'Edit Respondent',
      },

      add: {
        title: 'Add Respondent',
      },

      add_list: {
        title: 'Add Respondent',
        return: 'Back to configuration screen',
        cancel_dialog: {
          title: 'Do you want to exit without saving?',
          body: 'If you quit now, you will lose all information completed',
          left_button: 'Exit without saving',
          right_button: 'Do not exit',
        },
      },

      confirmation_message_dialog: {
        title: 'Thank you very much!',
        body: 'Thank you for your time in helping us collect this information',

        button_finish: 'End',
        button_check: 'Check answers',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: 'Do you want to end the assessment?',
        body: 'At the end of the assessment, you cannot collect more answers or release them again. The results will be made based on the data already received.',
        body_attention: 'Attention: if you want to suspend the Assessment for a period of time, try pausing it.',

        cancel_button: 'Cancel',
        finalize_button: 'End',
      },

      send_email_dialog: {
        title: 'Send email to {num, plural, one {# respondent} other {# respondents}}?',
        body: 'When sending, this email will be triggered and it will not be possible to undo this action. To send an email to only a few respondents, select them in the table below and click on "Bulk Actions".',

        cancel_button: 'Cancel',
        everyone_button: 'Send',
      },

      reminder_email_dialog: {
        title: 'Send all',
        body: 'Choose the email you want to send to {num, plural, one {the selected respondent} other {selected respondents}}. When sending, this email will be triggered and it will not be possible to undo this action.',

        cancel_button: 'Cancel',
        everyone_button: 'Send all',
      },
    },

    organizational_assessments: {
      title: 'Individual Assessments',

      answered_tooltip: 'We are calculating the results!',

      read_only_dialog: {
        title: 'You already answered this assessment',
        message: 'No field can be altered. Do you want to see the answers?',
        submit_button: 'See answer',
      },

      no_invites: {
        message: 'You have not yet been invited to answer to any organization assessment',
        button_text: 'I need help',
      },

      empty_search: {
        message: 'It was found no results for the search. Try the filters for more options',
      },
    },

    config_assessments: {
      success_dialog: {
        title: 'Assessment set up and ready for release!',
        body: 'This assessment will now be available on the menu "Release" It will be automatically started on the scheduled start date, and may be edited until then',
        button_text: 'Complete',
      },

      success_dialog_2: {
        title: 'Assessment successfully configured!',
        body: 'After configuring all the questionnaires, the evaluation will now be available in the “Release” menu. It will start automatically on the scheduled start date, and can be edited until then.',
        button_text: 'Ok',
      },

      cancel_dialog: {
        title: 'Do you want to exit without saving?',
        body: 'If you quit now, you will lose all information completed',
        left_button: 'Exit without saving',
        right_button: 'Do not leave',
      },

      release_dialog: {
        title: 'Do you want to release the assessment without saving recent modifications?',
        body: 'If you want to save the edits before releasing the trial, click on “do not release” below and save the settings before proceeding.',
        left_button: 'Release without saving',
        right_button: 'Do not release',
      },

      template_dialog: {
        use_template: 'Send to all assessed',
        send_date: 'Send date',
        subject: 'Message Subject',
        body: 'Message body',
      },

      save_config: 'Save',
      email_section: 'Send an email',
      email_instructions: 'Send email',
      start_date: 'Start date',
      end_date: 'Completion date',
      link: 'https://app.humanizadas.com/avaliacoes/',
      link_title: 'Share link to {name}',
      copy_link: 'Copy link',

      invite_template: {
        title: 'E-mail invitation',
        date: 'Send immediately',
      },
      general_template: {
        title: 'General reminder',
        date: 'Send immediately',
      },
      week_before_template: {
        title: 'Reminder - A week before the end',
        date: 'A week before the end of the assessment',
      },
      day_before_template: {
        title: 'Reminder - The day before the end',
        date: 'One day before the end of the assessment',
      },
      results_available_template: {
        title: 'Results release',
        date: 'After the end of the assessment',
      },

      header_items: {
        role: 'Role of resp.',
      },

      header_menu: {
        release: 'Release now',
        play: 'Resume',
        pause: 'Pause',
        finish: 'End now',
        results: 'View results',
        view: 'Display questionnaires',
        answer_self_assessment: 'Self-assessment',
      },

      respondents_section: 'Respondent',
      respondents_instructions: 'In addition to respondents added by this area, you can also invite others to answer your assessment through a link that will available after releasing the assessment',
      empty_table: 'No template has yet been made available for your organization',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Respondents (General public)',
        specific_public_respondents: 'Respondents (Specific public)',
        specific_public: 'Specific public',
      },
      organization_table_title: 'All Organization Assessments',
      individual_table_title: 'All Individual Assessments',
      self_assessment_table_title: 'All Self-assessments',
    },

    individual_assessments: {
      title: 'Individual Assessments',
      manager: {
        email: {
          manual: 'Manual submission',
          assessed_respondents: 'Send email',
          assessed: 'Send email',
          not_answered_assessed: 'Send e-mail to',
        },
        invite: {
          warning: 'Check the box to the left of each respondent\'s name to invite them',
          respondents: 'In addition to respondents added by this area, you can also invite others to answer their assessment through an open link, which will be available after releasing the assessment',
        },
      },
      title_self: 'My assessments',
      title_others: 'Evaluate people',

      read_only_dialog: {
        title: 'You already answered this assessment',
        message: 'No field can be altered. Do you want to view the response sent?',
        submit_button: 'See answer',
      },

      no_invites: {
        message: 'You have not yet been invited to answer to any individual assessments',
        button_text: 'I need help',
      },

      empty_search: {
        message: 'No search results were found. Try the filters for more options',
      },
    },

    config_individual_assessments: {
      title: 'Set up Individual Assessment',
      confirm_button: 'Complete configurations',
      dialog_complete: {
        title: 'Assessment set up and ready for release!',
        body: 'This assessment will now be available in the “Release” menu. It will start automatically on the scheduled start date, and can be edited until then.',
        button: 'Complete',
      },
      dialog_incomplete: {
        title: 'Close configurations without completing all available assessed?',
        body: 'The rest of the assessments you have purchased will remain in the “Configure” menu to be configured and released later.',
        left_button: 'Continue setting',
        right_button: 'Complete',
      },
      dialog_add: {
        title: 'Add new user',
        title_edit: 'Edit user',
        subtitle: 'This user is not registered. To invite as assessed in an assessment, complete your details below.',
        email_label: 'E-mail of the assessed',
      },
      info: {
        title: 'General settings {type, plural, one {Self-assessment} other {Assessment #}} - {title}',
        date_title: 'Start and end',
        date_subtitle: 'Dates of beginning and end of that assessment will be the same for all assessed set up below',
        start_date: 'Start date',
        end_date: 'Completion date',
      },
      enrollments: {
        title: 'Set up {type, plural, one {Self-assessment} other {Assessment #}} - {title}',
      },
      details: {
        email: 'E-mail of the assessed',
        warning: 'Check the box to the left of the name of each respondent to invite',
        respondents: 'In addition to respondents added by this area, you can also invite others to answer their assessment through an open link, which will be available after releasing the assessment.',
      },
    },

    release_individual_assessments: {
      title: 'Release individual\'s assessments',

      assessment_dialog: {
        title: 'Freeing assessment now?',
        body: 'If you release the assessment now it will be sent to all respondents. You can not undo this action',
        release_button: 'Release',
        wait_button: 'Expected start date',
      },

      success_save_changes_dialog: {
        title: 'Modifications saved successfully!',
        body: 'This assessment will start automatically on the scheduled start date, and can be edited until then.',
        conclude_button: 'Complete',
      },

      release_assessed: {
        title: 'Release {type, plural, one {Self-assessment} other {Assessment #}} - {title}',
        email_label: 'E-mail of the assessed',
      },
    },

    monitor_individual_assessments: {
      title: 'Follow assessments of individuals',

      finish_dialog: {
        title: 'Do you want to end the assessment?',
        body: 'At the end of the assessment it will not be possible to collect more answers or release it again. The results will be calculated based on the data already received.',
      },

      pause_dialog: {
        title: 'Do you want to pause the assessment?',
        body: 'While the assessment is paused, respondents will not be able to view it or send responses. You can resume it again at any time.',
        warn: 'Attention: the end date will not be changed.',
      },

      invite: {
        all: 'Send to all subjects and their respondents',
        assessed: 'Send to all evaluated',
        not_answered: 'Send to all unanswered of all evaluated',
      },

      assessed_invite: {
        all: 'Send email to all respondents of this subject',
        assessed: 'Send email to this appraiser',
        not_answered: 'Email this respondent for unanswered',
      },

      general: {
        title: 'Monitor {type, plural, one {Self-assessment} other {Assessment #}} - {title}',
        date_save_button: 'Save the date',
      },

      assessed_user: {
        status: 'Self-assessment status',
      },
    },

    release_assessments: {
      table_title: 'Release Assessments',
      table_empty: 'You do not have any released assessment to monitor',
    },

    monitor_assessments: {
      table_title: 'Track Assessments',
      table_empty: 'You do not have any released assessment to monitor',
    },

    organizational_assessments_manager: {
      title: 'Configure Assessments',

      read_only_dialog: {
        title: 'You already answered this assessment',
        message: 'No field can be altered. Do you want to view the answer sent?',
        submit_button: 'See answer',
      },

      empty_table: 'No template is available for your organization yet',

      no_invites: {
        message: 'You have not yet been invited to answer to any individual assessments',
        button_text: 'I need help',
      },

      empty_search: {
        message: 'No search results were found. Try the filters for more options.',
      },
    },

    questionnaire: {
      progress: 'Part {start} of {end}',
      required_fields: 'Required fields are marked with a *',
      back_to_main_page: 'Return to initial page',
      answer_all_required: 'Please complete all required fields',
      matrix_error: 'Select at least {min} item',
      assessment_description: 'Assessment description',
      exit_view: 'Exit preview',
      no_answers: 'No answers',

      warning_dialog: {
        title: 'Conclusion',
        message: 'To complete the assessment click submit answers, or click to verify in case you want to edit their responses',
        cancel_button: 'Review answers',
      },

      success_dialog: {
        title: 'Thank you very much!',
        message: 'Thank you for your time in helping us collect this information',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: 'Ops! You stopped being the main manager of the company',
        body: 'You can no longer edit users or the profile of the organization',
      },

      confirm_promote_dialog: {
        title: 'Transfer action from Main Manager to {name}',
        body: 'By changing the user of Main Manager, you will lose that role. Only the Main Manager can edit the Managers and the Organization Profile',

        button_submit: 'Become Main Manager',
      },

      confirm_destroy_dialog: {
        title: 'Delete Manager',
        body: 'By excluding this Manager, you will no longer be able to access your organization\'s platform or manage the evaluations or respondents',
      },

      add_manager_dialog: {
        title: 'Add Manager',
        body: 'To add a manager, it is necessary to register through the link below:',
      },

      user_not_found_dialog: {
        title: 'Ops ... we did not find any record related to this e-mail',
        body: 'Make sure that the person you want to invite is already registered and check if you typed the email correctly.',
      },

      table: {
        title: 'Managers',
        empty_table: 'No manager was found.',
      },

      dialog: {
        email: 'E-mail',
        company: 'Company',
        sector: 'Sector',
        position: 'Position',
      },
    },

    messages: {
      thank_you: {
        title: 'Thank you very much!',
        subtitle: 'Thank you for your time in helping us collect this information',
        alt_desktop: 'The image shows two people at a computer',
        alt_mobile: 'The image shows two people in front of a cellphone',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
      thank_you_public: {
        title: 'Thank you very much!',
        subtitle: 'Thank you for your time in helping us collect this information',
        subtitle_2: 'If you want to make the most of your experience at the Humanizadas Platform, access your account through a computer.',
        alt_desktop: 'The image shows two people at a computer',
        alt_mobile: 'The image shows two people at a computer',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
      assessment_expired: {
        title: 'Research ended',
        subtitle: 'The response time of this assessment is over. We thank you for your interest!',
        alt_desktop: 'The image shows a person at a computer',
        alt_mobile: 'The image shows a person at a computer',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
      assessment_not_started: {
        title: 'Assessment not started.',
        subtitle: 'This assessment is still not receiving responses, check with whoever sent you this link for the correct start date. We hope to give you an excellent experience. Thanks for your time!',
        alt_desktop: 'The image shows a person in front of a computer',
        alt_mobile: 'The image shows a person in front of a computer',
        subtitle_desktop: 'Ilustração criada por pikisuperstar',
        subtitle_mobile: 'Ilustração criada por pikisuperstar & pch.vector',
      },
      mobile_not_available: {
        title: 'Ops ...',
        subtitle: 'Sorry for the inconvenience. We are still building the Humanizadas experience so that you can access it through your smartphone. Meanwhile, you can only access though the computer platform.',
        alt_desktop: 'The image shows two people at a computer',
        alt_mobile: 'The image shows two people in front of a cellphone',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
      error: {
        title: 'Error',
        subtitle: 'Page not found',
        errorMessage: 'We are very sorry, but there was an error while browsing. Try one of the alternatives below.',
        alt_desktop: 'The image shows two people at a computer',
        alt_mobile: 'The image shows two people at a computer',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
      invalid_link: {
        title: 'Invalid link',
        subtitle: 'If you are here it is because you received a unique link from someone else.',
        body: 'Please check if you have received a registration email in your email box (including spam) and, if you have not received it, contact us via',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'The image shows a person at a computer',
        alt_mobile: 'The image shows a person at a computer',
        subtitle_desktop: 'Illustration created by pikisuperstar',
        subtitle_mobile: 'Illustration created by pikisuperstar and pch.vector',
      },
    },

    terms_confirmation: {
      title: 'Privacy notification',
      subtitle: 'Hello, we\'ve updated our platform\'s Privacy Policy and Terms of Use to bring you more transparency about how we treat your data. Therefore, to continue using our platform we need you to read and accept the terms below:',
      confirm_button: 'I read and agree',
    },

    monitor_assessments_page: {
      title: 'Monitor',
      respondents_section: 'Respondent',
      header_menu: {
        answer_self_assessment: 'Reply to self-assessment',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Send email to all respondents {num, plural, one {of this evaluated} other {of these evaluated}}',
          assessed: 'Send email to {num, plural, one {this evaluated} other {these evaluated}}',
          not_answered: 'Send email to unanswered for this evaluated',
        },
        evaluators_link: 'Link Evaluators',
        assessed_link: 'Link Evaluated',
        internal_link: 'Internal Evaluators',
        external_link: 'External Evaluators',
      },
      end_application: 'To the end of the application',
      send_reminder: 'Send reminder',
      higher_answers: 'No. of answers greater than the sample',
      lower_answers: 'No. of answers lower than the sample',
      absolute_number_answers: 'Answers (absolute numbers)',
      desired_sample: '{answers, plural, one {# answer} other {# answers}} /nabove the ideal desired',
      achieve_ideal_sample: '{answers, plural, one {Missing # answer} other {# answers missing}} /nto achieve the ideal sample',
      email_reminder: 'Emails and reminders',
      completed_assessments: 'Completed assessments',
      see_all: 'See all',
      number_of_answers: 'No. of answers',
      header: {
        play_and_pause: '{isPaused, select, true {Resume} false {Pause}} application',
        view_reports: 'View reports',
        view_group_reports: 'View group reports',
        export_reports: 'Export reports',
        share_reports: 'Share reports',
        view_questionnaire: 'View questionnaire',
        finish_application: 'Finalize application',
        share_link: 'Share link',
        internal_stakeholders: 'Internal stakeholders',
        external_stakeholders: 'External stakeholders',
        response_rate: 'Response Rate',
        calculate_results: 'Calculate results',
        results_being_calculate: 'The results are being calculated',
      },
      line_graph: {
        answers_per_day: 'Answers per day',
        total_answers: 'Total answers',
        number_of_responses: 'No. of Answers',
      },
      reminder: {
        automatic_emails: 'Automatic emails',
        email_results_appraised: 'Email with Results for Assessees',
        send_email_to_participants: 'Send an email (default) to the participants of this assessment',
        send_to_all_unanswered: 'Send to all unanswered',
        send_email_to_appraised: 'Send an email (pre-defined) making the results available to the evaluated',
        send_results_to_all_appraised: 'Send results to all evaluated',
        send_all_internal_stakeholders: 'Send to all Internal Stakeholders',
        send_all_external_stakeholders: 'Send to all External Stakeholders',
      },
      individual: {
        conclusion_rate_summary: 'Completion Rate Summary',
        total_number_answers: 'Total No. of Responses',
        ideal_sample_reached: 'of the Ideal Sample reached',
        desired_sample_reached: 'of Desired Sample reached',
        minimum_sample_reached: 'of the General Minimum Sample reached',
        engagement_by_appraisees: 'Engagement by Evaluated',
        bigger_engagement: 'Greater engagement',
        less_engagement: 'Lower engagement',
        multiperspective_assessment: 'Multiperspective Assessment',
      },
      organization: {
        absolute_number_responses: 'Absolute number of answers',
        organization_assessment: 'Organizations assessments',
      },
      self_assessment: {
        last_answers: 'Latest Answers',
        individual_self_assessment: 'Individual self-assessment',
      },
    },

    reports: {
      yours_answers: 'Your answers',
      global_average: 'Overall average: {value}',
      your_result: 'Your Result',
      answered_average: 'Average respondents',
      less_developed: 'Less developed',
      more_developed: 'More developed',
      smallest_result: 'Lowest Result: {value}',
      biggest_result: 'Highest Result: {value}',

      competences_title: 'Skills',
      charts_title: 'Graphics',
      behavior_title: 'Behavioral exponents',
      development_title: 'Development Tips',

      report_360: {
        title: 'Result of {type, plural, one {Self-assessment} other {Assessment #}} - {title}',
        valued_questions_title: 'Averages by behavior',
        text_questions_title: 'Open Answers',
      },

      skeleton: {
        title: 'Assessment Description',
        description: 'You are participating in the Humanized Companies Research. It aims to identify humanized practices present within the Organizations through a process of listening to all parties involved. Your contribution is very important so that the company can identify how to serve you better and also the Society.',
      },
    },

    respondent_role: {
      title_individual: 'Hello, welcome, you have been invited to respond to the assessment of the',
      title_organization: 'Hello, welcome, you have been invited to respond to the assessment of the organization',
      subtitle_organization: 'Select your relationship with the evaluated organization below.',
      subtitle_individual: 'Select below what is your relationship with {value}.',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Total group',
        completed_answers: 'Complete answers',
        group_and_self_legend: 'Group Vision and Self-Assessment',
        potentially_limiting: 'Potentially limiting',
      },
      graphs: {
        labels: {
          bigger: 'Highest value',
          median: 'Median',
          smaller: 'Lower value',
          gravity_center: 'Current CGC',
        },
        group_legend: 'Group Vision',
        axis: {
          very_high: 'Very high',
          high: 'High',
          medium: 'Medium',
          low: 'Low',
          very_low: 'Very low',
          stage_replace: 'Stage {number}',
        },
      },
      recommendation_analysis: 'Recommendations for the analysis process',
      individual_development: 'Individual Development Plan',
      considerations: 'Considerations',
      recommendation: 'Recommendations',
      respondents_section: {
        reliability: 'Reliability',
        applications: 'Applications',
      },
      cards_section: {
        group_view: 'Group Vision',
        self_assessment: 'Self_assessment',
        benchmark: 'Benchmark',
        influence: 'Influence',
        stage: 'Stage',
      },
      one_page: {
        top_3_card_title: 'Top 3 | Organizational Culture',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Society',
          bar_2: 'Partners',
          bar_3: 'Clients',
          bar_4: 'Collaborators',
          bar_5: 'Leaderships',
          bar_6: 'General',
        },
        radar: {
          bar_1: 'Creation',
          bar_2: 'Realization',
          bar_3: 'Organization',
          bar_4: 'Collaboration',
          label_1: 'Current experience',
          label_2: 'Desired experience',
        },
      },
      labels: {
        difference: 'Difference',
        benchmark: 'Benchmark',
        trust_level: 'Trust level',
        perspective: 'Audience Perspective {isInternal, select, true {Internal} false {External}}',
        meaning: 'Meaning',
        score: 'Score',
        ratings_scale: 'Rating scale',
        level: 'Level',
      },
      vertical_levels: {
        level_1: {
          title: 'Survival',
          description: 'focus on protection and basic needs of the organization.<br/>Manipulation, coercion, low level of trust and engagement may occur.',
          text: 'Self-protection and short-term need.',
        },
        level_2: {
          title: 'Safety',
          description: 'focus on compliance and stability of processes and relationships.<br/>Work rules and standards govern people\'s behavior and attitude.',
          text: 'Maintenance of current norms and standards.',
        },
        level_3: {
          title: 'Specialization',
          description: 'focus on specialization, skills and efficiency.<br/>Skills and areas of interest of individuals drive behavior.',
          text: 'Process expertise and efficiency.',
        },
        level_4: {
          title: 'Results',
          description: 'focus on analyses, results and goals to be achieved.<br/>A high level of accountability and a strong sense of meritocracy dominate relations.',
          text: 'Delivery of results and effectiveness.',
        },
        level_5: {
          title: 'People',
          description: 'focus on human relations and interaction with the system.<br/>The perspective of competition is instituted by abundance and systemic interdependence.',
          text: 'Human and environmental relations.',
        },
        level_6: {
          title: 'Co-creation',
          description: 'focus on the capacity for integration and systemic transformation.<br/>Differences become support points for co-creating and co-innovating.',
          text: 'High purpose and dynamic management.',
        },
        level_7: {
          title: 'Purpose',
          description: 'focus on wisdom and collective intelligence. Ability to sustain tensions such as complexity and simplicity, integrity and fractal.',
          text: 'At the service of the well-being of mankind.',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfectionist',
          description: 'doing the right thing, avoiding mistakes and working towards self-development and self-control.',
        },
        level_2: {
          title: 'Helper',
          description: 'be helpful and reliable, be available to people, anticipate and provide for the needs of others.',
        },
        level_3: {
          title: 'Achiever',
          description: 'achieve goals, present an image that supports the success of the organization, be productive and perform.',
        },
        level_4: {
          title: 'Individualist',
          description: 'being authentic and expressing individuality, being connected and true with emotions, having meaning and making a difference in the world.',
        },
        level_5: {
          title: 'Investigator',
          description: 'make sense of the world, save resources, think, analyze, solve, avoid dependency and pursue knowledge.',
        },
        level_6: {
          title: 'Loyalist',
          description: 'create stability, trust and security, protect the organization, avoid threats and risks, be loyal and responsible.',
        },
        level_7: {
          title: 'Enthusiast',
          description: 'always with a positive outlook, focus on the future, pursuing pleasure, freedom and expanding life\'s possibilities.',
        },
        level_8: {
          title: 'Challenger',
          description: 'being strong and in control, avoiding weakness and vulnerability, making things happen, making decisions and achieving results.',
        },
        level_9: {
          title: 'Peacemaker',
          description: 'promote harmony and peace, receive influence from people, create comfort and routines, welcome and reduce conflicts in the group.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margin of error',
      confidence: 'Trust rating',
      print_button: 'Save PDF',

      engagement_section: 'General stakeholder engagement',

      organizational_identity: {
        title: 'Organizational identity',
        vertical_development: 'Vertical Development (Current)',
        horizontal_development: 'Horizontal Development (Current)',
        values_to_develop: 'Top 3 Values ​​to Develop',
        values_to_adjust_reframe: 'Top 3 Values ​​to Adjust or Reframe',
      },

      stakeholders_engagement: {
        title: 'Stakeholder engagement',
        description: 'Result of the degree of engagement of business stakeholders (score from -100 to +100). A negative score expresses disengagement, and a positive score expresses engagement.',
      },

      stakeholders_management: {
        title: 'Management principles',
        description: 'Result of the evaluation of management practices and results in five principles (score from 0 to 100).',
      },

      scores_scale: {
        focus: 'Focus',
        meaning: 'Meaning',
        min_max: '{min} to {max}',

        quality: {
          title: 'Quality of relationships',
          description: 'Relationship Quality Index (RQI)',
          '0_50': 'Extremely deteriorated relationships, putting the future of the business at risk.',
          '50_60': 'Relationships with a high degree of criticality, requiring urgent changes.',
          '60_70': 'Relationships with serious problems requiring immediate interventions with leaders.',
          '70_80': 'Relationships present critical problems requiring interventions in internal systems.',
          '80_90': 'Relationships with specific problems requiring immediate attention so as not to deteriorate.',
          '90_95': 'Positive and quite healthy relationships.',
          '95_100': 'Extremely positive and healthy relationships.',
        },

        maturity: {
          title: 'Organizational Maturity',
          description: 'Center of Gravity of Consciousness (CGC)',
          value1: 'Self-protection and short-term needs.',
          value2: 'Maintenance of current norms and standards.',
          value3: 'Process specialization and efficiency.',
          value4: 'Delivery of results and effectiveness.',
          value5: 'Human relations and the environment.',
          value6: 'High purpose and dynamic management.',
          value7: 'At the service of the well-being of mankind.',
        },
      },

      engagement_messages: {
        '-100_-50': 'In the perception of stakeholders, the organization expresses a terrible level of engagement with problems that put the future of the business at risk.',
        '-49_-1': 'In the perception of stakeholders, the organization expresses a positive level of engagement, having relationships with specific problems requiring immediate attention so as not to deteriorate.',
        '0_24': 'In the perception of stakeholders, the organization expresses a low level of engagement with critical issues that demand attention.',
        '25_49': 'In the perception of stakeholders, the organization expresses a good level of engagement with specific problems that demand attention.',
        '50_64': 'In the perception of stakeholders, the organization expresses a high level of engagement with several opportunities for improvement that should be explored.',
        '65_74': 'In the perception of stakeholders, the organization expresses a high level of engagement with the presence of opportunities for improvement.',
        '75_84': 'In the perception of stakeholders, the organization expresses a very high level of engagement and must act to keep it that way.',
        '85_100': 'In the perception of stakeholders, the organization expresses an excellent level of engagement and will likely continue to do so in the future.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimension',
        score: 'Score',
        scale: 'Scale',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO e founder',
    },
    iqr_section: {
      levels: {
        '95_100': 'Extremely positive and healthy relationships.',
        '90_95': 'Positive and quite healthy relationships.',
        '80_90': 'Relationships with specific problems requiring immediate attention so as not to deteriorate.',
        '70_80': 'Relationships present critical problems requiring interventions in internal systems.',
        '60_70': 'Relationships with serious problems requiring immediate interventions with leaders.',
        '50_60': 'Relationships with a high degree of criticality, requiring urgent changes.',
        '0_50': 'Extremely deteriorated relationships, putting the future of the business at risk.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'organizational identity chart',
    },
    narratives_analysis: {
      internal: 'Internal narratives',
      external: 'External narratives',
      title: 'The narratives reveal...',
      levels: {
        level100: 'Extremely positive relationships at the service of humanity and life.',
        level80: 'Healthy relationships with specific problems requiring attention.',
        level60: 'Healthy relationships with critical issues requiring interventions.',
        level40: 'Relationships with serious problems requiring immediate interventions with leaders.',
        level20: 'Relationships with a high degree of criticality, requiring urgent changes.',
        level0: 'Relationships with a high degree of criticality, requiring urgent changes.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Cultural Orientation',
    },
    components: {
      consolidated_results: {
        score: 'Score',
        sample_size: 'Sample size',
        margin_of_error: 'Margin of error',
        trust_rating: 'Trust rating',
      },
      consolidated_levels: {
        very_high: 'Extremely high',
        high: 'High',
        middle: 'Medium',
        low: 'Low',
        extremely_low: 'Extremely low',
      },
      time_circles: {
        past: 'Past',
        present: 'Present',
        future: 'Future',
      },
      base_page: {
        answered_by: 'Answered by',
      },
      center_chart: {
        optimistic_outlook: 'Optimistic perspective',
        pessimistic_outlook: 'Pessimistic perspective',
      },
      start_chart: {
        degree_of_maturity: 'Degree of organizational maturity',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Gravity Center',
      },
      side_menu: {
        menu_intro: 'Introduction',
        letter: 'Letter to the reader',
        intro_comments: 'General comments',
        menu_rating: 'Rating',
        intro: 'Introduction to ratings',
        general_results: 'General results',
        general_comments: 'General comments',
        focus: 'Where to focus',
        menu_perspective: 'Multi-stakeholder perspective',
        respondents: 'Respondents',
        engagement: 'Engagement',
        experience: 'Experience',
        score: 'Satisfaction',
        perspective: 'Future perspective',
        menu_principles: 'Principles',
        overview: 'Overview of stakeholders',
        purpose: 'Higher purpose',
        strategy: 'Value strategy',
        conscious_culture: 'Conscious culture',
        adaptability: 'Learning and change',
        leadership: 'Conscious leadership',
        menu_values: 'Values',
        menu_identity: 'Identity',
        formation: 'Identity formation',
        vertical_dev: 'Vertical development',
        horizontal_dev: 'Horizontal development',
        quality: 'Relationship quality index',
        menu_narratives: 'Narratives',
        map: 'Narrative map',
        main: 'Main narratives',
        analysis: 'Content analysis',
        highlights: 'Outstanding practices',
        recommended: 'Recommended practices',
        demographics: 'Demographic data',
        reputation: 'Reputation',
        general_reputation: 'General reputation',
        reputation_by_stakeholder: 'Reputation by Stakeholder',
        responding_data: 'Respondent data',
        learning_and_change: 'Learning and change',
        perceived_result: 'Perceived result',
        culture: 'Culture',
        organizational_culture: 'Organizational culture',
        respondent_profile: 'Respondent Profile',
        group_view: 'Group Vision',
        self_assessment: 'Self-assessment',
        influence_capacity: 'Ability to Influence',
        leadership_profile: 'Leadership Profile',
        maturity_stage: 'Maturity stage',
        leadership_style: 'Leadership Style',
        values_to_provide: 'Values ​​to strengthen',
        values_to_adjust: 'values ​​to adjust',
      },
    },
  },

  components: {
    average_card: {
      general: 'Overall average',
    },

    competence_card: {
      average_title: 'Averages by response groups',
    },

    star_question: {
      select_a_number: 'Select a number',
    },

    score_question: {
      good: 'Very good',
      bad: 'Very bad',
    },

    terms_of_use: {
      read: 'I read and agree with',
      terms_link: 'Terms of use',
      and: 'And',
      policies_link: 'Privacy Policy',
      platform: 'of Humanizadas Platform',
    },

    table_pagination: {
      items_per_page: 'Items per page',
      count: '{isPlural, select, true {Registered items} false {Registered item}}',
      page: 'Page',
      more_button: 'Load more',
    },

    values_matrix: {
      amount: 'Amount of values selected',
    },
  },

  forms: {
    login: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      password_label: 'Password',
      forgot_password: 'I forgot my password',
      forgot_email: 'I forgot my email',
      login_button: 'Log in',
      signup_button: 'Create Account',

      warning_dialog: {
        title: 'Did you write the e-mail correctly?',
        message: 'Make sure that you have already created your account and used the same e-mail you received an invitation to use the platform',
        cancel_button: 'I completed it correctly',
        confirm_button: 'Check',
      },
    },

    my_profile: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      first_name_label: 'Name',
      first_name_placeholder: 'Ex.: Mariana',

      last_name_label: 'Last name',
      last_name_placeholder: 'Ex.: days',

      current_password_label: 'Current password',
      password_label: 'New password',
      confirm_password_label: 'Confirm your new password',

      working_company_name_label: 'Company',
      working_company_name_placeholder: 'Ex.: Humanizadas',

      sector_label: 'Sector',
      sector_placeholder: 'Ex.: Administrativo',

      role_label: 'Function',
      role_placeholder: 'Ex.: Gerente Administrativo',

      biological_sex_label: 'Sex',
      biological_sex_placeholder: 'Select',

      gender_label: 'Gender',
      gender_placeholder: 'Ex.: Other',
      gender_tooltip: 'Gender identity refers to a person\'s experience with their own gender',

      cancel_button: 'Cancel',
      submit_button: 'Save',

      pcd_label: 'PWD (Person with Disability)',
      pcd_placeholder: 'Select',

      pcd_tooltip_pt1_title: 'Physical impairment',
      pcd_tooltip_pt1_body: 'Complete or partial alteration of one or more segments of the human body, compromising physical function, presented in the form of paraplegia, paraparesis, monoplegia, monoparesis, teraplegia, tetraparesis, triplegia, hemiplegia, hemiparesis, ostomy, amputation or absence of a limb, paralysis brain, namism, limbs with congenital or acquired deformities, except aesthetic deformities and those that do not cause difficulties in performing functions.',

      pcd_tooltip_pt2_title: 'Hearing impairment',
      pcd_tooltip_pt2_body: 'Partial or total loss of hearing possibilities, varying in degrees and levels as follows',

      pcd_tooltip_pt3_title: 'Visual impairment',
      pcd_tooltip_pt3_body: 'Visual impairment is characterized by the limitation or loss of the basic functions of the eye or of the visual system. The visually impaired may be blind or with low vision. Blindness presupposes lack of visual perception due to physiological or neurological factors. Total blindness or just amaurosis, is characterized by the complete loss of vision without visual perception of light and form. Blindness can be congenital or acquired. The visual acuity of people with low vision is very variable; but in general, low vision is defined as a condition in which the person\'s vision can not be fully corrected with glasses, interfering with your daily activities, as well as reading and mobility.',

      pcd_tooltip_pt4_title: 'Mental impairment',
      pcd_tooltip_pt4_body: 'Intellectual functioning significantly lower than average, manifested before the age of eighteen and limitations associated with two or more areas of adaptive skills, such as',

      color_label: 'Color / Race',
      color_placeholder: 'Select',
      color_tooltip: 'According to IBGE reference',

      sexual_orientation_label: 'Sexual Orientation',
      sexual_orientation_placeholder: 'Select',
      sexual_orientation_tooltip: 'Orientation refers to the attraction one feels for other individuals. Usually it also involves emotional issues, not just sexual. Reference: UFSC',

      success_dialog: {
        title: 'Changes saved',
        message: 'Your changes were successfully saved!',
      },
    },

    organization_profile: {
      company_name_label: 'Company name',
      company_name_placeholder: 'Ex.: Humanizadas',
      company_corporate_name_label: 'Business name',
      company_corporate_name_placeholder: 'Ex.: Humanizadas LTDA',

      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      website_label: 'Site',
      website_placeholder: 'Ex.: www.site.com',
      cnpj_label: 'CNPJ',
      cnpj_placeholder: 'Ex.: 12.345.678/0001-99',
      state_registry_label: 'State registration',
      state_registry_placeholder: 'Ex.: 000.000.00',
      foundation_date_label: 'Foundation',
      foundation_date_placeholder: 'Ex.: 01/01/2020',
      number_of_employees_label: 'No. of employees',
      number_of_employees_placeholder: 'Ex.: 100',
      foundation_city_label: 'Origin',
      foundation_city_placeholder: 'Ex.: San Francisco',
      sector_label: 'Sector',
      sector_placeholder: 'Ex.: Services',

      company_type_label: 'Type',
      revenue_label: 'Revenue',

      postal_code_label: 'CEP',
      postal_code_placeholder: 'Ex.: 12.345-678',
      street_label: 'Street / Avenue',
      street_placeholder: 'Ex.: Hayes Street',
      number_label: 'Number',
      number_placeholder: 'Ex.: 10',
      neighborhood_label: 'Neighborhood',
      neighborhood_placeholder: 'Ex.: Franklin St',
      complement_label: 'Complement',
      complement_placeholder: 'Ex.: Sale 101',
      city_label: 'City',
      city_placeholder: 'Ex.: San Francisco',
      state_label: 'State',
      state_placeholder: 'Ex.: California',

      select_placeholder: 'Select',
      cancel_button: 'Cancel',
      submit_button: 'Save',

      select_options: {
        public: 'Public',
        private: 'Private',
        public_private: 'Public-private',
      },
    },

    respondents_builder: {
      first_name_label: 'Name',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Last name',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      add_button: 'Add another',
      cancel_button: 'Cancel',
      submit_button: 'Add',
    },

    signup: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',
      first_name_label: 'Name',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Last name',
      last_name_placeholder: 'Ex.: days',
      cpf_label: 'CPF',
      cpf_placeholder: 'Ex.: 123.456.789-10',
      cpf_tooltip: 'We ask your CPF to validate your identity',
      password_label: 'Password',
      password_confirmation_label: 'Confirm Password',
      login_button: 'Login',
      signup_button: 'Create',
      password_helper: 'Minimum of 6 characters, 1 uppercase e 1 lowercase',

      dialog: {
        title: 'Thank you for registering!',
        message: 'Now you can enjoy the Humanized experience!',
        confirm_button: 'Login',
      },

      dialog_warning: {
        title: 'Attention',
        message: 'This registration link is unique to the email {email} and should only be completed if you are responsible for it. Would you like to continue?',
        left_button: 'No',
        right_button: 'Yes',
      },
    },

    support: {
      message_label: 'Message',
      message_placeholder: 'Ex.: email not found',
      cancel_button: 'Cancel',
      submit_button: 'Send to all assessed',

      dialog: {
        title: 'Watch your email!',
        message: 'We will send more information in the coming days to support you. Remember to also check your spam box.',
        confirm_button: 'End',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Name',
      first_name_placeholder: 'Ex.: Mariana',
      last_name_label: 'Last name',
      last_name_placeholder: 'Ex.: Oliveira',
      email_label: 'Email',
      email_placeholder: 'Ex.: mariana@email.com',
      message_label: 'Message',
      message_placeholder: 'Ex.: email not found',
      cancel_button: 'Cancel',
      submit_button: 'Send',

      dialog: {
        title: 'Watch your mail!',
        message: 'We will send more information in the coming days to support you. Remember to also check your spam box.',
        confirm_button: 'End',
      },
    },

    password_recovery_enter_email: {
      email_label: 'E-mail',
      email_placeholder: 'Ex.: mariana@email.com',

      cancel_button: 'Cancel',
      confirm_button: 'Send',
      email_not_found: 'E-mail not found. I need help',

      success_dialog: {
        title: 'Watch your mail!',
        message: 'You will receive a link to reset your password. Remember to also check your spam box.',
        help_button: 'I did not receive the email',
        confirm_button: 'End',
      },

      warning_dialog: {
        title: 'Did you fill in the email correctly?',
        message: 'Make sure that you have already created your account and used the same e-mail you received an invitation to use the platform.',

        cancel_button: 'I completed it correctly',
        confirm_button: 'Check',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Password',
      password_confirmation_label: 'Confirm Password',
      password_helper: 'Minimum of 6 characters, 1 uppercase e 1 lowercase',

      confirm_button: 'Send',

      dialog: {
        title: 'Your password was changed successfully!',
        message: 'You can already login again',
        confirm_button: 'Login',
      },
    },

    complete_register: {
      working_company_name_label: 'Company',
      working_company_name_placeholder: 'Ex.: Humanizadas',
      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Select',
      working_company_job_title_label: 'Function',
      working_company_job_title_placeholder: 'Select',
      gender_label: 'Gender',
      gender_placeholder: 'Select',

      complete_register_dialog: {
        title: 'Tell us about you!',
        subtitle: 'We need to collect more information to complete your registration',
        subtitle2: 'The information filled in, from here on, will be used for research purposes in an aggregated manner, thus guaranteeing its anonymity.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Ideal sample',
      minimum_column: 'Min. sample',
    },

    respondents: {
      title: 'Respondent',
      subtitle_1: 'The contacts added on this page will also be added to your database, and will be available in the “Respondents” menu.',
      subtitle_2: 'Attention: you are adding respondents to the {questionnaire} questionnaire for Rated {user}.',
      subtitle_3: 'Warning: you are adding respondents {showTitle, select, true {to the {title}} false {to this questionnaire}} {showUser, select, true {do ​​Rated {user}} false {}}.',
      empty: 'It was not found any respondent',
      no_content: 'Uninformed',

      actions: {
        invite: 'Invite for assessment',
        edit: 'Edit',
        delete: 'Delete',
        send: 'Send to all unfinished questionnaire',
      },

      filter: {
        department: 'Sector',
        jobTitle: 'Role',
      },

      dialogs: {
        empty: {
          title: 'No users selected.',
          body: 'Select at least one user in the table for this action.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Type',
          360: '360 degree feedback',
          270: '270 degree feedback',
          180: '180 degree feedback',
          720: '720 degree feedback',
          self_assessment: 'Self-assessment',
        },
      },
      config: {
        view_questionnaire: 'View questionnaires',
        config_assessment: 'Set up assessment',

        dialogs: {
          respondents: {
            title: 'You do not yet have any respondent registered on the database',
            body: 'Register respondents and facilitate their assessments settings',

            left_button: 'Not now',
            right_button: 'Register respondents',
          },
        },
      },
      monitor: {
        show_report: 'Ver relatório',
        tooltips: {
          play: 'Resume',
          pause: 'Pause',
          finish: 'End now',
          results: 'View results',
          view: 'View questionnaires',
        },

        pause_dialog: {
          title: 'Do you want to pause the Assessment?',
          body_1: 'While the Assessment is paused, respondents will not be able to see it or send responses. You can resume it again at any time.',
          body_2: 'Attention: the end date will not be changed.',
        },

        stop_dialog: {
          title: 'Do you want to finalize the Assessment?',
          body_1: 'After completing the Assessment, it will not be possible to collect more answers or release it again. Results will be made based on the data already received.',
          body_2: 'Attention: if you want to suspend the Assessment for a period, try to pause it.',
        },
      },
      release: {
        tooltips: {
          release: 'Release now',
          edit: 'Edit',
          view: 'View questionnaires',
        },


        header: {
          startWithin: 'Start in',
        },

        release_dialog: {
          title: 'Release assessment now?',
          body: 'If you release the assessment now it will be sent to all respondents. You can not undo this action',
          left_button: 'Save start date',
          right_button: 'Release',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'View questionnaires',
          results: 'See results',
          monitor: 'Monitor',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Reply to self-assessment',
        monitor: 'Monitor respondents',
      },
    },

    header: {
      bulk_actions: 'Bulk actions',
    },

    filters: {
      clean_filters: 'Clear Filters',
      apply_filters: 'Apply Filters',
      assessment_title: 'Filter Assessments',
      invited_option_start: 'Up to {number} guests',
      invited_option_middle: 'From {start} to {end} guests',
      invited_option_end: 'More than {number} guests',
      time_option_hour: 'Next {number} h',
      time_option_days: 'Next {number} days',
      assessments_option_start: 'Up to {number} assessments',
      assessments_option_middle: 'From {start} to {end} assessments',
      assessments_option_end: 'More than {number} assessments',
      questions_option_start: 'Up to {number} questions',
      questions_option_middle: 'From {start} to {end} questions',
      questions_option_end: 'More than {number} questions',
      assessed_option_start: 'Up to {number} evaluated',
      assessed_option_middle: 'From {start} to {end} evaluated',
      assessed_option_end: 'Over {number} rated',
    },
  },

  dialogs: {
    reminder: {
      title: 'Send email',
      body: 'Choose the email you want to send to selected respondents. When sending, this email will be triggered and it will not be possible to undo this action.',
      body_amount: 'Choose the email you want to send to {num, plural, one {the selected respondent} other {the selected respondents}}. When sending, this email will be triggered and it will not be possible to undo this action.',
      left_button: 'Cancel',
      right_button: 'Send email',
    },
    send_email_all: {
      title: 'Send email to those selected?',
      title_number: 'Send email to {num, plural, one {# respondent} other {# respondents}}?',
      body: 'When sending, this email will be triggered and it will not be possible to undo this action. To send an email to only a few respondents, select them in the table below and click on "Bulk Actions".',
      left_button: 'Cancel',
      right_button: 'Send to all',
    },
    email_successfully_sent: {
      title: 'Sending {multiple, select, true {emails} false {email}}',
      body: '{multiple, select, true {All emails sent} false {Email sent}} successfully!',
      button: 'Conclude',
    },
  },
};
