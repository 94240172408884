import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';
import CircularProgress from '@material-ui/core/CircularProgress';

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  position: ${({ fullscreen }) => (fullscreen ? 'fixed' : 'absolute')};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${({ backdropColor, backdropOpacity }) => rgba(backdropColor, backdropOpacity)};
  transition: ${({ theme }) => theme.transitions.button};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  top: 0;
  left: 0;

  circle {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

const Loading = ({
  fullscreen,
  borderRadius,
  backdropColor,
  backdropOpacity,
  visible,
}) => (
  <>
    {fullscreen && visible && <GlobalStyle />}

    <Wrapper
      fullscreen={fullscreen}
      borderRadius={borderRadius}
      backdropColor={backdropColor}
      backdropOpacity={backdropOpacity}
      visible={visible}
    >
      <CircularProgress size={48} />
    </Wrapper>
  </>
);

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  visible: PropTypes.bool,
  borderRadius: PropTypes.number,
  backdropColor: PropTypes.string,
  backdropOpacity: PropTypes.number,
};

Loading.defaultProps = {
  fullscreen: true,
  visible: true,
  borderRadius: 0,
  backdropColor: '#FFFFFF',
  backdropOpacity: 0.5,
};

export default Loading;
