import enRoutes from './routes-en';

// rotas publicas
const demos = '/demos';
const {
  resetPassword,
  signupFromEmail,
  publicReport,
  public: publicRoutes,
} = enRoutes;

// Acesso de impressão para relatório Cba one page
const reportCbaOnePage = '/report-cba-one-page/:token';
const reportCbaOnePageRating = '/report-cba-one-page-rating/:token';
const reportCbaFull = '/report-cba-full/:token';
const reportCbaEssential = '/report-cba-essential/:token';
const reportCbaV2 = '/report-cba-v2';
const reportCla = '/report-cla';

// cadastro
const start = '/inicio';
const login = `${start}/login`;
const signup = `${start}/cadastro`;
const unauthenticatedContact = `${start}/contato`;
const recoverEmail = `${start}/recuperar-email`;
const recoverPassword = `${start}/recuperar-senha`;

// rotas privadas
const home = '/home';
const myProfile = `${home}/meu-perfil`;
const editMyProfile = `${home}/meu-perfil/editar`;
const support = `${home}/contato`;
const termsConfirmation = `${home}/termos-de-uso`;

const enroll = '/enroll/:token';

const messages = '/messages';
// rotas de mensagens
const messagesRoutes = {
  root: `${messages}`,
  thankYou: `${messages}/muito-obrigado`,
  expired: `${messages}/expirado`,
  notStarted: `${messages}/nao-iniciado`,
  notAvailable: `${messages}/nao-disponivel`,
  error404: `${messages}/404`,
  error403: `${messages}/403`,
  error500: `${messages}/500`,
  invalidLink: `${messages}/link-invalido`,
  lastReport: `${messages}/ultimo-relatorio`,
  thankYouRegister: `${messages}/muito-obrigado-cadastro`,
};

// papeis
const respondentScope = 'respondente';
const respondentRouteRoot = `${home}/${respondentScope}`;
const respondentIndividualAssessments = `${respondentRouteRoot}/avaliacoes-individuais`;

const respondent = {
  root: respondentRouteRoot,

  role: `${respondentRouteRoot}/papel`,

  individualAssessments: respondentIndividualAssessments,
  individualAssessmentsOthers: `${respondentIndividualAssessments}/outros`,
  individualAssessmentsSelf: `${respondentIndividualAssessments}/autoavaliacoes`,

  organizationalAssessments: `${respondentRouteRoot}/avaliacoes-organizacionais`,

  questionnaire: `${respondentRouteRoot}/questionario/:id(\\d+)`,
  _questionnaire: `${respondentRouteRoot}/questionario/:id`,

  report: `${respondentRouteRoot}/questionario/:id/relatorio`,
  _report: `${respondentRouteRoot}/questionario/:id/relatorio`,

  monitorAssessment: `${respondentRouteRoot}/monitor-assessment/:id(\\d+)/:questionnaire_title?`,
  _monitorAssessment: `${respondentRouteRoot}/monitor-assessment/:id`,
};

const managerScope = 'gerente';
const managerRouteRoot = `${home}/${managerScope}`;

const organizationProfile = `${managerRouteRoot}/perfil-da-organizacao`;

const managerRespondentsRoot = `${managerRouteRoot}/respondentes`;
const managerOrganizationalAssessments = `${managerRouteRoot}/avaliacoes-organizacionais`;
const managerIndividualAssessments = `${managerRouteRoot}/avaliacoes-individuais`;
const managerSelfAssessments = `${managerRouteRoot}/autoavaliacoes`;

const manager = {
  root: managerRouteRoot,
  // individualAssessments: `${home}/${managerScope}/avaliacoes-individuais`,
  allCompaniesSufix: 'all',

  application: `${managerRouteRoot}/aplicacao/:id(\\d+)/:questionnaire_title?`,
  _application: `${managerRouteRoot}/aplicacao/:id`,

  report: `${managerRouteRoot}/questionario/:id/relatorio`,
  _report: `${managerRouteRoot}/questionario/:id/relatorio`,

  reportCbaOnePage: `${managerRouteRoot}${reportCbaOnePage}`,
  reportCbaOnePageRating: `${managerRouteRoot}${reportCbaOnePageRating}`,

  users: `${managerRouteRoot}/usuarios`,
  managers: `${managerRouteRoot}/gestores`,

  organizationProfile,
  organizationInformations: `${organizationProfile}/informacoes`,
  editOrganizationInformations: `${organizationProfile}/informacoes/editar`,
  organizationIndicators: `${organizationProfile}/indicadores`,
  editOrganizationIndicators: `${organizationProfile}/indicadores/editar`,

  respondents: managerRespondentsRoot,
  addRespondents: `${managerRespondentsRoot}/adicionar`,

  addRespondentsList: `${home}/${managerScope}/adicionar-lista/:id(\\d+)/:questionnaire_title?`,
  _addRespondentsList: `${home}/${managerScope}/adicionar-lista/:id/:questionnaire_title?`,

  addRespondentsNew: `${home}/${managerScope}/adicionar-novo/:id(\\d+)/:questionnaire_title?`,
  _addRespondentsNew: `${home}/${managerScope}/adicionar-novo/:id/:questionnaire_title?`,

  editRespondent: `${managerRespondentsRoot}/:id(\\d+)/editar`,
  _editRespondent: `${managerRespondentsRoot}/:id/editar`,

  organizationalAssessments: managerOrganizationalAssessments,
  organizationalAssessmentsConfig: `${managerOrganizationalAssessments}/configurar`,
  organizationalAssessmentsRelease: `${managerOrganizationalAssessments}/liberar`,
  organizationalAssessmentsMonitor: `${managerOrganizationalAssessments}/acompanhar`,
  organizationalSeeAllCompanies: `${managerOrganizationalAssessments}/mostrar-todas-empresas`,

  individualAssessments: managerIndividualAssessments,
  individualAssessmentsConfig: `${managerIndividualAssessments}/configurar`,
  individualAssessmentsRelease: `${managerIndividualAssessments}/liberar`,
  individualAssessmentsMonitor: `${managerIndividualAssessments}/acompanhar`,
  individualSeeAllCompanies: `${managerIndividualAssessments}/mostrar-todas-empresas`,

  selfAssessments: managerSelfAssessments,
  selfAssessmentsConfig: `${managerSelfAssessments}/configurar`,
  selfAssessmentsRelease: `${managerSelfAssessments}/liberar`,
  selfAssessmentsMonitor: `${managerSelfAssessments}/acompanhar`,
  selfSeeAllCompanies: `${managerSelfAssessments}/mostrar-todas-empresas`,

  configAssessment: `${home}/${managerScope}/configurar-avaliacao/:id(\\d+)/:questionnaire_title?`,
  _configAssessment: `${home}/${managerScope}/configurar-avaliacao/:id`,
  organizationDashboard: `${home}/${managerScope}/organizacao-dashboard/:id(\\d+)/:questionnaire_title?`,
  _organizationDashboard: `${home}/${managerScope}/organizacao-dashboard/:id`,
  individualDashboard: `${home}/${managerScope}/avaliacao-individual-dashboard/:token/:questionnaire_title?`,
  _individualDashboard: `${home}/${managerScope}/avaliacao-individual-dashboard/:token`,
  selfAssessmentDashboard: `${home}/${managerScope}/autoavaliacao-dashboard/:id(\\d+)/:questionnaire_title?`,
  _selfAssessmentDashboard: `${home}/${managerScope}/autoavaliacao-dashboard/:id`,

  claWebReport: `${home}/${managerScope}/cla-relatorio-web/:token`,
  _claWebReport: `${home}/${managerScope}/cla-relatorio-web/:token`,
  claWebSelfReport: `${home}/${managerScope}/cla-relatorio-web-autoavaliacao/:token`,
  _claWebSelfReport: `${home}/${managerScope}/cla-relatorio-web-autoavaliacao/:token`,

  configIndividualAssessment: `${home}/${managerScope}/configurar-avaliacao-individual/:id(\\d+)`,
  _configIndividualAssessment: `${home}/${managerScope}/configurar-avaliacao-individual/:id`,
  configIndividualAssessmentGeneral: `${home}/${managerScope}/configurar-avaliacao-individual/:id(\\d+)/geral/:questionnaire_title?`,
  _configIndividualAssessmentGeneral: `${home}/${managerScope}/configurar-avaliacao-individual/:id/geral`,
  configIndividualAssessmentEnrollments: `${home}/${managerScope}/configurar-avaliacao-individual/:id(\\d+)/convidar/:questionnaire_title?`,
  _configIndividualAssessmentEnrollments: `${home}/${managerScope}/configurar-avaliacao-individual/:id/convidar`,

  releaseIndividualAssessment: `${home}/${managerScope}/liberar-avaliacao-individual/:token`,
  releaseIndividualAssessmentOverview: `${home}/${managerScope}/liberar-avaliacao-individual/overview/:token`,
  releaseIndividualAssessmentConfig: `${home}/${managerScope}/liberar-avaliacao-individual/config/:id(\\d+)/:questionnaire_title?`,
  _releaseIndividualAssessmentConfig: `${home}/${managerScope}/liberar-avaliacao-individual/config/:id`,

  monitorIndividualAssessment: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token`,
  _monitorIndividualAssessment: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token`,
  monitorIndividualAssessmentGeneral: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token/geral`,
  _monitorIndividualAssessmentGeneral: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token/geral`,
  monitorIndividualAssessmentEmail: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token/email`,
  _monitorIndividualAssessmentEmail: `${home}/${managerScope}/acompanhar-avaliacao-individual/:token/email`,
  monitorIndividualAssessmentInvite: `${home}/${managerScope}/acompanhar-avaliacao-individual/:id(\\d+)/convidar/:questionnaire_title?`,
  _monitorIndividualAssessmentInvite: `${home}/${managerScope}/acompanhar-avaliacao-individual/:id/convidar`,
};

export default {
  // publico
  demos,
  resetPassword,
  signupFromEmail,
  publicReport,
  enroll,
  // CBA
  reportCbaOnePage,
  reportCbaOnePageRating,
  reportCbaFull,
  reportCbaEssential,
  reportCbaV2,
  reportCla,
  // cadastro
  start,
  login,
  signup,
  unauthenticatedContact,
  recoverEmail,
  recoverPassword,

  public: publicRoutes,

  // privado
  home,
  myProfile,
  editMyProfile,
  support,
  termsConfirmation,

  // mensagens
  messages: messagesRoutes,

  // papeis
  respondent,
  manager,
};
