import { createSelector } from 'reselect';
import c from './auth.constants';
import userSelectors from '../user/user.selectors';

const getStore = (store) => store[c.REDUX_NAMESPACE];

const accessHeadersSelector = () => JSON.parse(localStorage.getItem(c.ACCESS_HEADERS));

const isAuthenticatedSelector = createSelector(
  accessHeadersSelector,
  userSelectors.userId,
  (headers, userId) => Boolean(headers && userId),
);

export default {
  getStore,
  accessHeaders: accessHeadersSelector,
  isAuthenticated: isAuthenticatedSelector,
};
