import constants from './caches.constants';

const resetStore = () => ({ type: constants.RESET_STORE });

const cacheValue = (key, value) => ({
  type: constants.CACHE_VALUE,
  payload: { key, value },
});

const clearCachedValue = (key) => ({
  type: constants.CLEAR_CACHED_VALUE,
  payload: { key },
});

export default {
  resetStore,
  cacheValue,
  clearCachedValue,
};
