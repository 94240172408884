export default {
  criar_conta: {
    simple: 'Inicie Sesión',
  },

  entrar: {
    simple: 'Inicie Sesión',
  },

  olar: {
    simple: '¡Hola!',
    placeholder: '¡Hola {name}!',
  },

  plural: {
    simple: 'Tengo {num, plural, one {cat} other {cats}}',
  },

  select: {
    placeholder: 'Seleccione',
    simple: 'Soy {gender, select, male {man} female {woman}}',
  },

  layout_header: {
    options: {
      organizacional_profile: 'Perfil de la organización',
      my_profile: 'Mi cuenta',
      respondents: 'Encuestados de la organización',
      managers: 'Gerentes',
    },
    assessments: 'Evaluaciones',
    choose_view_mode: 'Cambiar a {isManagerView, select, true {encuestado} false {gerente}}',
    sign_out: 'Cerrar la sesión en mi cuenta',
    help: 'Necesito ayuda',
    see_all_companies: 'Ver todas las empresas',
    answer_assessments: 'Responder a las reseñas',
  },

  statuses: {
    questionnaires: {
      created: 'Creado',

      ready_to_start: 'No {gender, select, male {Iniciado} female {Iniciada}}',
      started: '{gender, select, male {Iniciado} female {Iniciada}}',
      answered: '{gender, select, male {Respondido} female {Respondida}}',

      not_answered: '{gender, select, female {No respondida} male {No respondido} }',
      partially_answered: 'No {gender, select, male {Cerrado} female {Cerrada}}',
      results_available: 'Ver el resultado',
    },

    assessments: {
      released: 'Liberada',
      paused: 'En pausa',
      finished: 'Cerrada',
      results: 'Resultados liberados',
    },

    see_all_companies: {
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      desisted: '{num, plural, one {Desistió} other {Desistieron}}',
      in_progress: '{num, plural, one {Liberada} other {Liberadas}}',
      paused: '{num, plural, one {En pausa} other {En pausa}}',
      finished: '{num, plural, one {Cerrada} other {Cerradas}}',
      results_available: '{num, plural, one {Ver el resultado} other {Ver los resultados}}',
    },

    respondents: {
      pending: 'Pendiente',
      registered: 'Registrado',
      not_answered: 'No respondido',
      answered: 'Respondido',
    },

    assessment_subtype: {
      self_assessment: 'Auto',
      180: '180',
      270: '270',
      360: '360',
      720: '720',
    },
  },

  user_roles: {
    manager: 'Gerente',
    owner: 'Gerente Principal',
    respondent: 'Encuestado',
  },

  errors: {
    required: {
      simple: 'El campo es obligatorio',
      email: 'El correo electrónico es obligatorio',
      password: 'La contraseña es obligatoria',
      cpf: 'El código fiscal de persona física es obligatorio',
      cnpj: 'El código fiscal de persona jurídica es obligatorio',
      first_name: 'El nombre es obligatorio',
      last_name: 'El apellido es obligatorio',
    },

    invalid: {
      start_date: 'La fecha debe ser posterior a hoy.',
      end_date: 'La fecha debe ser posterior o igual a la fecha de inicio',
      simple: 'Campo no válido',
      email: 'Correo electrónico no válido',
      cpf: 'Código fiscal de persona física no válido',
      cep: 'Código postal no válido',
      cnpj: 'Código fiscal de persona jurídica no válido',
      url: 'URL no válida',
      value: 'Valor no permitido',
    },

    invalid_fields: '¡Sus cambios no se guardarán si hay errores en el formulario!',
    generic_error_message: '¡Lo sentimos, hubo un error al procesar su solicitud! Si persiste, contáctenos a través del menú de soporte.',
    file_too_big: 'Archivo con más de {num} MB',
    wrong_mime: 'El tipo de archivo no es válido',
    passwords_do_not_match: 'La confirmación no coincide con la contraseña ingresada',
    min_string_len: 'Mínimo de {num, plural, one {# carácter} other {# caracteres}}',
    max_string_len: 'Máximo de {num, plural, one {# carácter} other {# caracteres}}',
    min_uppercase_letters: 'Mínimo de {num, plural, one {# letra mayúscula} other {# letras mayúsculas}}',
    min_lowercase_letters: 'Mínimo de {num, plural, one {# letra minúscula} other {# letras minúsculas}}',
  },

  select_options: {
    // vou deixar estas chaves em pt para facilitar
    genders: {
      female: 'Mujer',
      male: 'Hombre',
      non_binary: 'No binario',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declararlo',
    },

    sex: {
      female: 'Femenino',
      male: 'Masculino',
      intersexual: 'Intersexual',
      other: 'Otro',
    },

    orientation: {
      assexual: 'Asexual',
      bissexual: 'Bisexual',
      straight: 'Heterosexual',
      homosexual: 'Homosexual',
      pansexual: 'Pansexual',
      fluid: 'Fluido',
      other: 'Otro',
      undeclared: 'Prefiero no declararlo',
    },

    race: {
      yellow: 'Amarillo',
      white: 'Blanco',
      indian: 'Indígena',
      pardo: 'Marrón',
      black: 'Negro',
      undeclared: 'Prefiero no declararlo',
    },

    pcd: {
      nao_pcd: 'Discapacitado',
      deficiencia_fisica: 'Discapacidad física',
      deficiencia_auditivo: 'Discapacidad auditiva',
      deficiencia_visual: 'Discapacidad visual',
      deficiencia_mental: 'Deficiencia mental',
      deficiencia_multipla: 'Discapacidad múltiple',
      undeclared: 'Prefiero no declararlo',
    },

    job_titles: {
      estagiario: 'Prácticas',
      professor: 'Maestro(a)',
      consultoria: 'Consultoría',
      supervisao: 'Supervisión',
      analista: 'Analista',
      assistente: 'Asistente',
      auxiliar: 'Asistente',
      supervisor: 'Supervisor',
      coordenador: 'Coordinación',
      gerente: 'Gerencia',
      senior: 'Especialista senior',
      diretor: 'Dirección (nivel C)',
      conselho: 'Consejo',
      prestador_de_servicos: 'Proveedor de servicios',
      other: 'Otro',
    },

    departments: {
      administracao: 'Administración',
      assistencia_ou_suporte_tecnico: 'Asistencia o soporte técnico',
      atendimento: 'Atención',
      auditoria: 'Auditoría y consultoría',
      comercial: 'Comercial',
      compras: 'Compras',
      complience: 'Cumplimiento',
      comunicacao_e_assessoria: 'Comunicación y asesoramiento',
      controladoria: 'Controladuría',
      contabilidade: 'Contabilidad',
      contratos: 'Contratos',
      engenharia: 'Ingeniería',
      estrategia: 'Estrategia',
      financeiro: 'Finanzas',
      inovacao: 'Innovación',
      inteligência: 'Inteligencia',
      juridico: 'Jurídica',
      logistica: 'Logística',
      manutencao: 'Mantenimiento',
      marketing: 'Mercadotecnia',
      melhoria: 'Mejora',
      novos_negocios: 'Nuevos negocios',
      operacoes: 'Operaciones',
      turismo: 'Turismo',
      pd: 'I+D',
      pos_vendas: 'Postventa',
      projetos: 'Proyectos',
      producao: 'Producción',
      processos: 'Procesos',
      qualidade: 'Calidad',
      rh_dho: 'RRHH / DHO',
      relacoes_institucionais: 'Relaciones institucionales',
      seguranca: 'Seguridad',
      servicos_tecnicos: 'Servicios técnicos',
      suprimentos: 'Suministros',
      tecnologia_da_informacao: 'Tecnología de la informacion',
      other: 'Otro',
    },
  },

  navigation_menu: {
    assessments: {
      title: 'Evaluaciones',
    },

    multi_perspective_assessment_individual: {
      title: 'Evaluaciones individuales',
    },

    individual_assessments: {
      title: 'Evaluaciones individuales',
      self: 'Mis evaluaciones',
      others: 'Evaluar personas',
    },

    multi_perspective_assessment: {
      title: 'Evaluaciones desde múltiples perspectivas',
    },

    respondents: {
      title: 'Encuestados',
    },

    organization_profile: {
      title: 'Perfil de la organización',
    },

    my_profile: {
      title: 'Mi perfil',
    },

    managers: {
      title: 'Gerentes',
    },
  },

  indicators: {
    true: 'Sí',
    false: 'No',
    uninformed: 'No informado',
    placeholder: {
      money: 'P. ej.: R$1.000.000',
      number: 'P. ej.: 10',
      selector: 'Seleccione',
      percent: 'P. ej.: 100%',
    },
  },

  tabnav: {
    informations: {
      id: 'Información',
      name: 'Información',
    },

    indicators: {
      id: 'Indicadores',
      name: 'Indicadores',
    },
  },

  emails: {
    manual: 'Envío manual',
    invite_template: {
      title: 'Correo electrónico de invitación',
      date: 'Envío inmediato',
    },
    general_template: {
      title: 'Recordatorio General',
      date: 'Envío inmediato',
    },
    week_before_template: {
      title: 'Recordatorio - Semana antes del cierre',
      date: '1 semana antes del cierre de la evaluación',
    },
    day_before_template: {
      title: 'Recordatorio - Día anterior al cierre',
      date: '1 día antes del cierre de la evaluación',
    },
    results_available_template: {
      title: 'Liberación de los resultados',
      date: 'Después del cierre de la evaluación.',
    },
    application_email: {
      all: 'Enviar un correo electrónico a todos los encuestados de este evaluado',
      assessed: 'Enviar un correo electrónico a este evaluado',
      unanswered: 'Enviar un correo electrónico a los no respondidos de este evaluado',
      role_type_title: {
        all: '¿Enviar un correo electrónico a todos los que aún no han respondido?',
        internal: '¿Enviar un correo electrónico a todas las partes interesadas internas?',
        external: '¿Enviar un correo electrónico a todas las partes interesadas externas?',
      },
      role_type_body: {
        all: 'Este correo electrónico se enviará a todos los encuestados registrados por correo electrónico que aún no hayan completado su respuesta. Después de enviar, no se puede deshacer la acción.',
        internal: 'Tras el envío, este correo electrónico se activará para todos los encuestados registrados por correo electrónico como partes interesadas internas, y no será posible deshacer esta acción.',
        external: 'Tras el envío, este correo electrónico se activará para todos los encuestados registrados por correo electrónico como partes interesadas externas, y no será posible deshacer esta acción.',
      },
    },
    collection_email: {
      all: 'Enviar a todos los evaluados y sus encuestados',
      assessed: 'Enviar a todos los evaluados',
      unanswered: 'Enviar a todos los no respondidos de todos los evaluados',
    },
  },

  progress_bar: {
    text: 'Tiempo de {mobile, select, true {resp.} false {respuestas}}',
  },

  words: {
    email: 'Correo electrónico',
    email_simple: 'Correo electrónico',
    password: 'Contraseña',
    sector: 'Sector',
    gender: 'Género',
    company: 'Empresa',
    role: 'Rol',
    your_role: 'Su rol',
    description: 'Descripción',
    behaviour: 'Comportamiento',
    instructions: 'Instrucciones',
    cancel: 'Cancelar',
    error: 'Error',
    close: 'Cerrar',
    continue: 'Continuar',
    finish: 'Finalizar',
    pause: 'Pausa',
    next: 'Próximo',
    previous: 'Anterior',
    status: 'Estado',
    submit: 'Concluir',
    and: 'y',
    minimum: 'mínimo',
    maximum: 'máximo',
    minimum_of: 'Mínimo {num}',
    maximum_of: 'Máximo {num}',
    page: 'Página',
    available: 'Disponible:',
    unavailable: 'No disponible',
    of: 'de',
    address: 'Dirección',
    config: 'Configurar',
    release: 'Liberar',
    monitor: 'Seguir',
    edit: 'Editar',
    view: 'Visualizar',
    actions: 'Acciones',
    required: 'Obligatorio',
    type: 'Tipo',
    questions: 'Preguntas',
    invited: 'Invitados',
    answered: 'Respondidos',
    not_answered: 'No respondido',
    answer: 'Respuesta',
    beginning: 'Inicio',
    end: 'Cierre',
    duration: 'Duración',
    add: 'Añadir',
    new: 'Nuevo',
    name: 'Nombre',
    position: 'Función',
    registered: 'Registrado',
    pending: 'Pendiente',
    manager: 'Gerente',
    owner: 'Gerente principal',
    hour: 'hora',
    day: 'día',
    organization: 'Organización',
    individual: 'Individuo',
    sex: 'Sexo',
    pcd: 'PcD (Persona con Discapacidad)',
    color_race: 'Color / Raza',
    sexual_orientation: 'Orientación sexual',
    assessed: 'Evaluado',
    assessment: 'Evaluación',
    self_assessment: 'Autoevaluación',
    respondent: 'Encuestado',
    respondents: 'Encuestados',
    back_main: 'Regresar a la página principal',
    manual_sending: 'Envío manual',
    date: 'Fecha',
    sampling: 'Muestra',
    positive: 'Positivo',
    negative: 'Negativo',
    neutral: 'Neutro',
    conclusion: 'Conclusión',
    recommendation: 'Recomendación',
    do_not_apply: 'No se aplica',
    search: 'Búsqueda',
    intro: 'Introducción',
    average: 'Media',
    in: 'en',
    general: 'General',
    participant: 'Participante',
    terms_of_use: 'Términos de uso',
    privacy_policy: 'Política de privacidad',
    filter: 'Filtrar',
    occupation: 'Actuación',
    assessment_name: 'Nombre de la evaluación',
    assessment_status: 'Estado de autoevaluación',
    choose: 'Elección',
    send_all: 'Enviar a todos',
    view_template: 'Ver la plantilla',
    smallest_score: 'Grado más bajo',
    biggest_score: 'Grado más alto',
    select_all: 'Seleccionar todo',
    deselect_all: 'Deseleccionar todo',
    score: 'Grado',
    meaning: 'Significado',
    legend: 'Clave:',
    population: 'Población',
    ideal_sample: 'Muestra ideal',
    minimum_sample: 'Muestra mínima general',
    general_result: 'Resultado general',
    current: 'Actual',
    desired: 'Deseada',
    total: 'Total',
    come_back: 'Regresar',
    start: 'Comenzar',
    assessed_organization: 'Organización evaluada',
    application: 'Aplicación',
    finished: 'Cerrada',
    initiation: 'Empezó',
    finale: 'Fin',
    participations: 'Participaciones',
    reports: 'Informes',
    factors: 'Factores',
    subgroups: 'Subgrupos',
    optional: 'Opcional',

    plurals: {
      day: '{num, plural, one {# día} other {# días}}',
      hour: '{num, plural, one {# hora} other {# horas}}',
      answered: '{num, plural, one {Respondido} other {Respondidos}}',
      result: '{num, plural, one {Resultado} other {Resultados}}',
      assessed: '{num, plural, one {Evaluado} other {Evaluados}}',
      available: '{num, plural, one {# evaluación} other {# evaluaciones}}',
      tag: '{num, plural, one {tag} other {tags}}',
      question: '{num, plural, one {Pregunta} other {Preguntas}}',
      alternative: '{num, plural, one {Alternativa} other {Alternativas}}',
      answer: '{num, plural, one {Respuesta} other {Respuestas}}',
      companies: '{num, plural, one {Empresa} other {Empresas}}',
      people: '{num, plural, one {Persona} other {Personas}}',
    },

    try_again: 'Intentar otra vez',
    delete: 'Excluir',
    confirm: 'Confirmar',
    save: 'Guardar',
    last_name: 'Apellido',
    not_informed: 'No {gender, select, male {informado} female {informada}}',
  },

  checkbox: {
    title: {
      sector: 'Sector',
      role: 'Función',
      cba: 'CBA - Evaluación empresarial consciente',
      vyl: 'VYL - Jóvenes líderes valiosos',
    },

    label: {
      Administrative: 'Administrativo',
      rh: 'RH',
      attendance: 'Atención',
      commercial: 'Comercial',
      self_employed: 'Autónomo',
      specialist: 'Especialista',
      manager: 'Gerente',
      ceo: 'CEO',
      leaderships: 'Líderes',
      collaborators: 'Empleados',
      clients_partners: 'Clientes y socios',
      partnerships: 'Sociedad',
      inidividual_assessment: 'Evaluaciones de individuos',
      organizational_assessment: 'Evaluaciones de las organizaciones',
    },
  },

  public_pages: {
    complete_register: {
      title: 'Cuéntenos acerca de usted',
      subtitle: 'Necesitamos algunos datos más sobre su perfil. La información rellenada se utilizará con fines de investigación de forma agregada, garantizando así su anonimato.',

      email_label: 'Correo electrónico',
      email_placeholder: 'mariana@email.com',
      i_dont_have_an_email: 'No tengo un correo electronico',
      external_email: 'Responder a través de un enlace abierto',

      first_name_label: 'Nombre',
      first_name_placeholder: 'P. ej.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'P. ej.: Dias',

      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'P. ej.: Humanizadas',

      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Seleccione',

      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'Seleccione',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género.',

      pcd_label: 'PcD (Persona con Discapacidad)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Discapacidad física',
      pcd_tooltip_pt1_body: 'alteración total o parcial de uno o más segmentos del cuerpo humano, que provoque el deterioro de la función física, que se presente en la forma de paraplejia, paraparesia, monoplejia, monoparesia, tetraplejia, tetraparesia, triplejia, hemiplejia, hemiparesia, ostomía, amputación o ausencia de una extremidad, parálisis cerebral, enanismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no produzcan dificultades en el desempeño de las funciones',

      pcd_tooltip_pt2_title: 'Discapacidad auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las capacidades auditivas sonoras, en diversos grados y niveles de la siguiente manera: de 41 a 55 dB - sordera moderada | de 56 a 70 dB - sordera notable| de 71 a 90 dB - sordera severa | por encima de 91 dB - sordera profunda | anacusia - pérdida total',

      pcd_tooltip_pt3_title: 'Discapacidad visual',
      pcd_tooltip_pt3_body: 'La discapacidad visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo y del sistema visual. Los discapacitados visuales pueden ser personas ciegas o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total, o simplemente amaurosis, se caracteriza por la pérdida completa de la visión sin percepción visual de la luz y las formas. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, por lo general, la baja visión se define como una condición en la que la visión de una persona no se puede corregir completamente con anteojos, lo que interfiere con sus actividades diarias, como leer y caminar.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente más bajo que el promedio, con inicio antes de los 18 años y limitaciones asociadas con dos o más áreas de habilidades adaptativas, tales como: comunicación | cuidado personal | habilidades sociales | uso comunitario | salud y seguridad | habilidades académicas | ocio | trabajo',

      color_label: 'Color / Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según la referencia del IBGE',

      sexual_orientation_label: 'Orientación sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'La orientación se refiere a la atracción que se siente hacia otras personas. Suele implicar también cuestiones sentimentales, no solo sexuales. Referencia: UFSC',

      cpf_label: 'CPF (Código fiscal de persona física)',
      cpf_placeholder: 'P. ej.: 123.456.789-10',
      cpf_tooltip: 'Le pedimos su CPF para confirmar su identidad.',
    },

    questionnaire: {
      warning_dialog: {
        title: '¡Vamos! Una vez iniciada, no es posible detener la evaluación.',
        body: 'No podrá pausarla para terminar más tarde. Esta restricción es parte de nuestra metodología científica de investigación.',
        button: 'Responder a la evaluación',
        left_button: 'Cancelar',
      },
    },
  },

  pages: {
    login: {
      title: '¡Qué bien que usted volvió!',
      subtitle: 'Para acceder a su cuenta, ingrese el correo electrónico en el que recibió la invitación y la contraseña registrada.',
      stay_logged_in: 'Manténgame conectado',
    },

    signup: {
      title: '¡Le estábamos esperando!',
      subtitle: 'Tenemos todo listo para usted. Para acceder a sus evaluaciones, simplemente cree una cuenta.',
    },

    lastReport: {
      title: 'Aquí está el Informe General de la última edición de la encuesta.',
      subtitle: 'Haga clic en el siguiente enlace para acceder al informe general de la última edición de la Encuesta de Empresas Humanizadas en Brasil.',
      button: 'Acceder al informe',
    },

    thankYouRegister: {
      title: 'Tus respuestas han sido registradas con éxito.',
      subtitle: 'Agradecemos su contribución.',
    },

    unauthenticated_contact: {
      title: 'Envíenos un mensaje y nos pondremos en contacto.',
      subtitle: 'Cuéntenos más sobre el problema que ha encontrado y encontraremos una solución.',
    },

    password_recovery_enter_email: {
      title: 'Estamos aquí para ayudarle.',
      subtitle: 'Ingrese su correo electrónico de registro y le enviaremos un enlace para crear una nueva contraseña.',
    },

    password_recovery_reset_password: {
      title: 'Aquí está su nuevo comienzo.',
      subtitle: 'Críe su nueva contraseña y vuelva a disfrutar de todas las ventajas de la plataforma.',
    },

    my_profile: {
      title: 'Mi perfil',
      edit_button: 'Editar',
    },

    organization_profile: {
      title: 'Perfil de la organización',
      edit_button: 'Editar',
    },

    support: {
      title: 'Envíenos un mensaje y nos pondremos en contacto.',
      subtitle: 'Cuéntenos más sobre el problema que ha encontrado y encontraremos una solución.',
    },

    respondents: {
      title: 'Encuestados',
      title_add: 'Añadir encuestados',
      search_field: {
        placeholder: 'Búsqueda',
      },

      actions: {
        add_sheet: 'Añadir por hoja',
        download_template: 'Descargar la plantilla de la hoja',
        upload_sheet: 'Cargar la hoja',
        bulk_actions: 'Acciones a granel',
      },

      filter: {
        title: 'Filtrar',
      },

      add_button: 'Añadir',

      no_respondents: {
        message: 'Todavía no ha agregado ningún encuestado.',
        button_text: 'Añadir ahora',
      },

      owner_informations_dialog: {
        label: {
          email: 'Correo electrónico',
          company: 'Empresa',
          sector: 'Sector',
          role: 'Función',
        },

        close_button: 'Cerrar',
      },

      configure_assessment_dialog: {
        title: '¿Desea configurar una evaluación ahora?',
        body: 'Ahora que ha agregado nuevos encuestados a la base, elija un tipo de evaluación para configurar.',

        close_button: 'Cerrar',
        config_button: 'Configurar',
      },

      respondents_filter_dialog: {
        title: 'Filtrar los encuestados',

        cancel_button: 'Cancelar',
        aplly_button: 'Aplicar los filtros',
      },

      upload_and_download_sheet_dialog: {
        title: 'Recuerde usar la plantilla de la hoja para la carga de los encuestados.',
        body: 'Si aún no ha descargado la plantilla, descárguela y ajuste su hoja para que coincida con nuestro estándar. Así, evitará errores en su base de encuestados.',

        download_button: 'Descargar la plantilla',
        upload_button: 'Cargar',
      },

      delete_respondents_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir {num, plural, one {este encuestado} other {estos encuestados}}, {num, plural, one {ya no podrá} otro {ya no podrán}} acceder a ninguna de sus evaluaciones. Si {num, plural, uno {ya ha} otro {ya han}} respondido alguna de ellas, todos los datos resultarán excluidos de los cálculos de resultados.',
        body_attention: '¡Atención!: esta acción es irreversible.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      delete_enrollments_dialog: {
        title: 'Excluir {num, plural, one {encuestado?} other {encuestados?}}',
        body: 'Al excluir {num, plural, one {este encuestado} other {estos encuestados}}, {num, plural, one {ya no podrá} other {ya no podrán}} ver la evaluación y ya no recibirá correos electrónicos referentes a ella. Si {num, plural, uno {ya ha} otro {ya han}} respondido las preguntas, los datos resultarán excluidos de los cálculos de resultados.',
        body_attention: '¡Atención!: esta acción es irreversible.',

        cancel_button: 'Cancelar',
        delete_button: 'Excluir',
      },

      invitation_dialog: {
        title: 'Invitar a una evaluación',
        body: 'Elija la evaluación a la que desea invitar a los encuestados que seleccionó.',
        empty: 'No hay evaluaciones registradas en su organización',
        cancel_button: 'Cancelar',
        invitation_button: 'Invitar',
      },

      edit: {
        title: 'Editar encuestado',
      },

      add: {
        title: 'Añadir encuestados',
      },

      add_list: {
        title: 'Añadir encuestados a',
        return: 'Volver a la pantalla de configuración',
        cancel_dialog: {
          title: '¿Quiere salir sin guardar?',
          body: 'Si sale ahora, perderá toda la información que insertó.',
          left_button: 'Salir sin guardar',
          right_button: 'No salir',
        },
      },

      confirmation_message_dialog: {
        title: '¡Muchas gracias!',
        body: '¡Gracias por su tiempo ayudándonos a recopilar esta información!',

        button_finish: 'Finalizar',
        button_check: 'Revisar las respuestas',
      },
    },

    assessments: {
      finalize_assessment_dialog: {
        title: '¿Desea concluir la evaluación?',
        body: 'Una vez concluida la evaluación, no será posible recopilar más respuestas ni liberarla nuevamente. Los resultados se basarán en los datos ya recibidos.',
        body_attention: 'Atención: si desea suspender la evaluación por un período, intente pausarla.',

        cancel_button: 'Cancelar',
        finalize_button: 'Finalizar',
      },

      send_email_dialog: {
        title: '¿Enviar un correo electrónico a {num, plural, one {# encuestado} otro {# encuestados}}?',
        body: 'Al enviar, se enviará este correo electrónico a granel y no será posible deshacer esta acción. Para enviar un correo electrónico a solo unos pocos encuestados, selecciónelos en la tabla a continuación y haga clic en «Acciones a granel».',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },

      reminder_email_dialog: {
        title: 'Enviar correo electrónico',
        body: 'Elija el correo electrónico que desea enviar {num, plural, one {al encuestado seleccionado} other {a los encuestados seleccionados}}. Al enviar, se enviará este correo electrónico a granel y no será posible deshacer esta acción.',

        cancel_button: 'Cancelar',
        everyone_button: 'Enviar a todos',
      },
    },

    organizational_assessments: {
      title: 'Evaluaciones individuales',

      answered_tooltip: '¡Estamos calculando los resultados!',

      read_only_dialog: {
        title: 'Ya ha respondido a esta evaluación.',
        message: 'No se podrá cambiar ningún campo. ¿Desea ver la respuesta enviada?',
        submit_button: 'Ver la respuesta',
      },

      no_invites: {
        message: 'Todavía no ha sido invitado a responder a ninguna evaluación de la organización.',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No se encontraron resultados para su búsqueda. Pruebe los filtros para ver más opciones.',
      },
    },

    config_assessments: {
      success_dialog: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú «Liberar». Comenzará automáticamente en la fecha de inicio programada y se podrá editarla hasta entonces.',
        button_text: 'Concluir',
      },

      success_dialog_2: {
        title: '¡Cuestionario configurado con éxito!',
        body: 'Después de configurar todos los cuestionarios, la evaluación estará disponible en el menú «Liberar». Comenzará automáticamente en la fecha de inicio programada y se podrá editarla hasta entonces.',
        button_text: 'OK',
      },

      cancel_dialog: {
        title: '¿Quiere salir sin guardar?',
        body: 'Si sale ahora, perderá toda la información que insertó.',
        left_button: 'Salir sin guardar',
        right_button: 'No salir',
      },

      release_dialog: {
        title: '¿Liberar la revisión sin guardar las modificaciones recientes?',
        body: 'Si desea guardar las modificaciones antes de liberar la evaluación, haga clic en «no liberar» a continuación y guarde las configuraciones antes de continuar.',
        left_button: 'Liberar sin guardar',
        right_button: 'No liberar',
      },

      template_dialog: {
        use_template: '¿Enviar este correo electrónico a los encuestados?',
        send_date: 'Fecha de envío',
        subject: 'Asunto del mensaje',
        body: 'Cuerpo del mensaje',
      },

      save_config: 'Guardar',
      email_section: 'Envío de correos electrónicos',
      email_instructions: 'Seleccione qué plantillas de correo electrónico enviará a los encuestados invitados durante el curso de su evaluación.',
      start_date: 'Fecha de inicio',
      end_date: 'Fecha de cierre',
      link: 'https://app.humanizadas.com/avaliacoes/',
      link_title: 'Enlace de compartimiento a {name}',
      copy_link: 'Copiar el enlace',

      invite_template: {
        title: 'Correo electrónico de invitación',
        date: 'Envío inmediato',
      },
      general_template: {
        title: 'Recordatorio general',
        date: 'Envío inmediato',
      },
      week_before_template: {
        title: 'Recordatorio - Semana antes del cierre',
        date: '1 semana antes del cierre de la evaluación',
      },
      day_before_template: {
        title: 'Recordatorio - Día anterior al cierre',
        date: '1 día antes del cierre de la evaluación',
      },
      results_available_template: {
        title: 'Liberación de los resultados',
        date: 'Después del cierre de la evaluación.',
      },

      header_items: {
        role: 'Rol de los enc.',
      },

      header_menu: {
        release: 'Liberar ahora',
        play: 'Reanudar',
        pause: 'Pausa',
        finish: 'Cerrar ahora',
        results: 'Ver el resultado',
        view: 'Ver los cuestionarios',
        answer_self_assessment: 'Responder a la autoevaluación',
      },

      respondents_section: 'Encuestados',
      respondents_instructions: 'Además de los encuestados añadidos por esta área, también puede invitar a otras personas a responder a su evaluación a través de un enlace, que estará disponible después de que se libere la evaluación.',
      empty_table: 'Aún no se ha puesto a disposición ninguna plantilla para su organización.',
    },

    see_all_companies: {
      company_dialog: {
        general_public_respondents: 'Encuestados (público en general)',
        specific_public_respondents: 'Encuestados (públicos específicos)',
        specific_public: 'Públicos específicos',
      },
      organization_table_title: 'Todas las evaluaciones de las organizaciones',
      individual_table_title: 'Todas las evaluaciones de individuos',
      self_assessment_table_title: 'Todas las autoevaluaciones',
    },

    individual_assessments: {
      title: 'Evaluaciones de individuos',
      manager: {
        email: {
          manual: 'Envío manual',
          assessed_respondents: 'Enviar a todos los evaluados y sus encuestados',
          assessed: 'Enviar a todos los evaluados',
          not_answered_assessed: 'Enviar a todos los no respondidos de todos los evaluados',
        },
        invite: {
          warning: 'Marque la casilla a la izquierda del nombre de cada encuestado para invitarlo.',
          respondents: 'Además de los encuestados añadidos por esta área, también puede invitar a otras personas a responder a su evaluación a través de un enlace abierto, que estará disponible después de que se libere la evaluación.',
        },
      },
      title_self: 'Mis evaluaciones',
      title_others: 'Evaluar personas',

      read_only_dialog: {
        title: 'Ya ha respondido a esta evaluación.',
        message: 'No se podrá cambiar ningún campo. ¿Desea ver la respuesta enviada?',
        submit_button: 'Ver la respuesta',
      },

      no_invites: {
        message: 'Todavía no ha sido invitado a responder a ninguna evaluación de individuos.',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No se encontraron resultados para su búsqueda. Pruebe los filtros para ver más opciones.',
      },
    },

    config_individual_assessments: {
      title: 'Configurar evaluaciones individuales',
      confirm_button: 'Concluir configuraciones',
      dialog_complete: {
        title: '¡Evaluación configurada y lista para liberar!',
        body: 'Esta evaluación estará ahora disponible en el menú «Liberar». Comenzará automáticamente en la fecha de inicio programada y se podrá editarla hasta entonces.',
        button: 'Concluir',
      },
      dialog_incomplete: {
        title: '¿Concluir la configuración sin completar todos los evaluados disponibles?',
        body: 'El resto de las evaluaciones que ha adquirido seguirá en el menú «Configurar» para que se configuren y liberen posteriormente.',
        left_button: 'Seguir configurando',
        right_button: 'Concluir',
      },
      dialog_add: {
        title: 'Añadir un nuevo usuario',
        title_edit: 'Modificar el usuario',
        subtitle: 'Este usuario aún no está registrado. Para invitarlo como un evaluado en una evaluación, inserte sus datos a continuación.',
        email_label: 'Correo electrónico del evaluado',
      },
      info: {
        title: 'Configuraciones generales de la {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        date_title: 'Inicio y cierre',
        date_subtitle: 'Las fechas de inicio y cierre de esta evaluación serán iguales para todos los evaluados configurados a continuación.',
        start_date: 'Fecha de inicio',
        end_date: 'Fecha de cierre',
      },
      enrollments: {
        title: 'Configurar {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
      },
      details: {
        email: 'Correo electrónico del evaluado',
        warning: 'Marque la casilla a la izquierda del nombre de cada encuestado para invitarlo.',
        respondents: 'Además de los encuestados añadidos por esta área, también puede invitar a otras personas a responder a su evaluación a través de un enlace abierto, que estará disponible después de que se libere la evaluación.',
      },
    },

    release_individual_assessments: {
      title: 'Liberar evaluaciones de individuos',

      assessment_dialog: {
        title: '¿Liberar la evaluación ahora?',
        body: 'Si libera la evaluación ahora, se la enviará a todos los encuestados. Esta acción no se puede deshacer.',
        release_button: 'Liberar',
        wait_button: 'Esperar la fecha de inicio',
      },

      success_save_changes_dialog: {
        title: '¡Modificaciones guardadas con éxito!',
        body: 'Esta evaluación comenzará automáticamente en la fecha de inicio programada y se podrá editarla hasta entonces.',
        conclude_button: 'Concluir',
      },

      release_assessed: {
        title: 'Liberar {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        email_label: 'Correo electrónico del evaluado',
      },
    },

    monitor_individual_assessments: {
      title: 'Seguir las evaluaciones de individuos',

      finish_dialog: {
        title: '¿Desea concluir la evaluación?',
        body: 'Una vez concluida la evaluación, no será posible recopilar más respuestas ni liberarla nuevamente. Los resultados se basarán en los datos ya recibidos.',
      },

      pause_dialog: {
        title: '¿Desea hacer una pausa en la evaluación?',
        body: 'Mientras la evaluación esté en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarla de nuevo en cualquier momento.',
        warn: 'Tenga en cuenta que la fecha de cierre no cambiará.',
      },

      invite: {
        all: 'Enviar a todos los evaluados y sus encuestados',
        assessed: 'Enviar a todos los evaluados',
        not_answered: 'Enviar a todos los no respondidos de todos los evaluados',
      },

      assessed_invite: {
        all: 'Enviar un correo electrónico a todos los encuestados de este evaluado',
        assessed: 'Enviar un correo electrónico a este evaluado',
        not_answered: 'Enviar un correo electrónico a los no respondidos de este evaluado',
      },

      general: {
        title: 'Seguir {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        date_save_button: 'Guardar la fecha',
      },

      assessed_user: {
        status: 'Estado de la autoevaluación',
      },
    },

    release_assessments: {
      table_title: 'Liberar las evaluaciones',
      table_empty: 'Todavía no tiene ninguna evaluación configurada que liberar.',
    },

    monitor_assessments: {
      table_title: 'Seguir las evaluaciones',
      table_empty: 'Todavía no tiene ninguna evaluación liberada que seguir.',
    },

    organizational_assessments_manager: {
      title: 'Configurar las evaluaciones',

      read_only_dialog: {
        title: 'Ya ha respondido a esta evaluación.',
        message: 'No se podrá cambiar ningún campo. ¿Desea ver la respuesta enviada?',
        submit_button: 'Ver la respuesta',
      },

      empty_table: 'Aún no se ha puesto a disposición ninguna plantilla para su organización.',

      no_invites: {
        message: 'Todavía no ha sido invitado a responder a ninguna evaluación de individuos.',
        button_text: 'Necesito ayuda',
      },

      empty_search: {
        message: 'No se encontraron resultados para su búsqueda. Pruebe los filtros para ver más opciones.',
      },
    },

    questionnaire: {
      progress: 'Parte {start} de {end}',
      required_fields: 'Los campos obligatorios están marcados con un *',
      back_to_main_page: 'Volver a la página inicial',
      answer_all_required: 'Por favor, complete todos los campos obligatorios',
      matrix_error: 'Elija al menos {min} elementos',
      assessment_description: 'Descripción de la evaluación',
      exit_view: 'Salir de la vista',
      no_answers: 'No sé responder',

      warning_dialog: {
        title: 'Conclusión',
        message: 'Para completar la evaluación, haga clic en «enviar respuestas», o haga clic en «verificar» si desea modificar sus respuestas.',
        cancel_button: 'Revisar las respuestas',
      },

      success_dialog: {
        title: '¡Muchas gracias!',
        message: '¡Gracias por su tiempo ayudándonos a recopilar esta información!',
      },
    },

    managers: {
      confirm_demote_dialog: {
        title: '¡Dios mio! Ya no es el Gerente Principal de la empresa.',
        body: 'Ya no podrá modificar los usuarios ni el perfil de la organización.',
      },

      confirm_promote_dialog: {
        title: 'Transferir el rol de Gerente Principal a {name}',
        body: 'Al hacer que otro usuario sea el Gerente Principal, usted perderá este rol. Solo el Gerente Principal podrá modificar los gerentes y el perfil de la organización.',

        button_submit: 'Convertir en Gerente Principal',
      },

      confirm_destroy_dialog: {
        title: 'Eliminar el Gerente',
        body: 'Cuando elimine a este Gerente, ya no podrá acceder a la plataforma de su organización ni administrar las evaluaciones y los encuestados.',
      },

      add_manager_dialog: {
        title: 'Añadir un gerente',
        body: 'Para añadir un Gerente, él/ella debe registrarse usando el siguiente enlace:',
      },

      user_not_found_dialog: {
        title: 'Ups... No logramos encontrar ningún registro con este correo electrónico.',
        body: 'Asegúrese de que la persona a la que quiere invitar ya esté registrada y compruebe que ha escrito correctamente el correo electrónico.',
      },

      table: {
        title: 'Gerentes',
        empty_table: 'No se encontró ningún gerente.',
      },

      dialog: {
        email: 'Correo electrónico',
        company: 'Empresa',
        sector: 'Sector',
        position: 'Función',
      },
    },

    messages: {
      thank_you: {
        title: '¡Muchas gracias!',
        subtitle: '¡Gracias por su tiempo ayudándonos a recopilar esta información!',
        alt_desktop: 'La imagen muestra a dos personas frente a una computadora.',
        alt_mobile: 'La imagen muestra a dos personas frente a un teléfono celular.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      thank_you_public: {
        title: '¡Muchas gracias!',
        subtitle: '¡Gracias por su tiempo ayudándonos a recopilar esta información!',
        subtitle_2: 'Si quiere disfrutar aún más de la experiencia de la Plataforma Humanizada, acceda a su cuenta a través de una computadora.',
        alt_desktop: 'La imagen muestra a dos personas frente a una computadora.',
        alt_mobile: 'La imagen muestra a dos personas frente a una computadora.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      assessment_expired: {
        title: 'Encuesta cerrada',
        subtitle: 'El tiempo de respuesta de esta evaluación ha terminado. ¡Gracias por su interés!',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora.',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      assessment_not_started: {
        title: 'Evaluación no iniciada.',
        subtitle: 'Esta evaluación todavía no recibe respuestas, consulte con la persona que le envió este enlace para conocer la fecha de inicio correcta. Esperamos brindarle una excelente experiencia. ¡Gracias por su tiempo!',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      mobile_not_available: {
        title: 'Ups...',
        subtitle: 'Lo sentimos por los inconvenientes ocasionados. Todavía estamos construyendo la experiencia «Humanizadas» para que pueda acceder a través de un teléfono inteligente. Por ahora, solo podrá acceder a la plataforma desde su computadora.',
        alt_desktop: 'La imagen muestra a dos personas frente a una computadora.',
        alt_mobile: 'La imagen muestra a dos personas frente a un teléfono celular.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      error: {
        title: 'Error',
        subtitle: 'Página no encontrada.',
        errorMessage: 'Lo sentimos, pero algo salió mal durante su navegación. Pruebe una de las siguientes alternativas.',
        alt_desktop: 'La imagen muestra a dos personas frente a una computadora.',
        alt_mobile: 'La imagen muestra a dos personas frente a una computadora.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
      invalid_link: {
        title: 'Enlace inválido',
        subtitle: 'Si está aquí es porque recibió un enlace exclusivo de otra persona.',
        body: 'Compruebe que ha recibido un correo electrónico de registro en su buzón (incluido el buzón de correo no deseado) y, de no ser así, póngase en contacto con nosotros a través de',
        email: 'suporte@humanizadas.com',
        alt_desktop: 'La imagen muestra a una persona frente a una computadora.',
        alt_mobile: 'La imagen muestra a una persona frente a una computadora.',
        subtitle_desktop: 'Ilustración diseñada por pikisuperstar',
        subtitle_mobile: 'Ilustración diseñada por pikisuperstar y pch.vector',
      },
    },

    terms_confirmation: {
      title: 'Aviso de Privacidad',
      subtitle: 'Hola, hemos actualizado la Política de privacidad y los Términos de uso de nuestra plataforma para brindarle más transparencia sobre cómo manejamos sus datos. Por ello, para seguir usando nuestra plataforma, necesitamos que lea y acepte los siguientes términos:',
      confirm_button: 'He leído y acepto',
    },

    monitor_assessments_page: {
      title: 'Seguir',
      respondents_section: 'Encuestados',
      header_menu: {
        answer_self_assessment: 'Responder a la autoevaluación',
      },
    },

    dashboard: {
      tables: {
        assessed_invite: {
          all: 'Enviar un correo electrónico a todos los encuestados {num, plural, one {de este evaluado} other {de estos evaluados}}',
          assessed: 'Enviar un correo electrónico a {num, plural, one {este evaluado} other {estos evaluados}}',
          not_answered: 'Enviar un correo electrónico a los no respondidos de este evaluado',
        },
        evaluators_link: 'Enlace evaluadores',
        assessed_link: 'Enlace evaluados',
        internal_link: 'Evaluadores internos',
        external_link: 'Evaluadores externos',
      },
      end_application: 'Para los fines de la aplicación',
      send_reminder: 'Enviar recordatorio',
      higher_answers: 'Número de respuestas mayor que la muestra',
      lower_answers: 'Número de respuestas inferior a la muestra',
      absolute_number_answers: 'Respuestas (números absolutos)',
      desired_sample: '{answers, plural, one {# respuesta} other {# respuestas}} /npor encima de la muestra deseada',
      achieve_ideal_sample: '{answers, plural, one {Falta # respuesta} other {Faltan # respuestas}} /npara alcanzar la muestra ideal',
      email_reminder: 'Correos electrónicos y recordatorios',
      completed_assessments: 'Evaluaciones completadas',
      see_all: 'Ver todas',
      number_of_answers: 'Nº de respuestas',
      header: {
        play_and_pause: '{isPaused, select, true {Reanudar} false {Pausar}} la aplicación',
        view_reports: 'Ver los informes',
        view_group_reports: 'Ver los resultados del grupo',
        export_reports: 'Exportar el informe',
        share_reports: 'Compartir los resultados',
        view_questionnaire: 'Ver el cuestionario',
        finish_application: 'Cerrar la aplicación final',
        share_link: 'Enlace de compartimiento',
        internal_stakeholders: 'Partes interesadas internas',
        external_stakeholders: 'Partes interesadas externas',
        response_rate: 'Índice de respuestas',
        calculate_results: 'Calcular los resultados',
        results_being_calculate: 'Los resultados se están calculando',
      },
      line_graph: {
        answers_per_day: 'Respuestas por día',
        total_answers: 'Respuestas totales',
        number_of_responses: 'Nº de respuestas',
      },
      reminder: {
        automatic_emails: 'Correos electrónicos automáticos',
        email_results_appraised: 'Correo electrónico con resultados para Evaluados',
        send_email_to_participants: 'Envíe un correo electrónico (predeterminado) a los participantes de esta evaluación',
        send_to_all_unanswered: 'Enviar a todos los no respondidos',
        send_email_to_appraised: 'Enviar un correo electrónico (predeterminado) poniendo los resultados a disposición de los evaluados',
        send_results_to_all_appraised: 'Enviar los resultados a todos los evaluados',
        send_all_internal_stakeholders: 'Enviar a todas las partes interesadas internas',
        send_all_external_stakeholders: 'Enviar a todas las partes interesadas externas',
      },
      individual: {
        conclusion_rate_summary: 'Resumen del índice de conclusión',
        total_number_answers: 'N.º total de respuestas',
        ideal_sample_reached: 'de la Muestra Ideal alcanzada',
        desired_sample_reached: 'de la Muestra Deseada alcanzada',
        minimum_sample_reached: 'de la Muestra General Mínima alcanzada',
        engagement_by_appraisees: 'Participación de los evaluados',
        bigger_engagement: 'Mayor participación',
        less_engagement: 'Menor participación',
        multiperspective_assessment: 'Evaluación por múltiples perspectivas',
      },
      organization: {
        absolute_number_responses: 'N.º absoluto de respuestas',
        organization_assessment: 'Evaluación de las organizaciones',
      },
      self_assessment: {
        last_answers: 'Últimas respuestas',
        individual_self_assessment: 'Autoevaluación individual',
      },
    },

    reports: {
      yours_answers: 'Sus respuestas',
      global_average: 'Promedio general {value}',
      your_result: 'Su resultado',
      answered_average: 'Promedio de los respondidos',
      less_developed: 'Menos desarrollados',
      more_developed: 'Más desarrollados',
      smallest_result: 'Menor resultado: {value}',
      biggest_result: 'Mayor resultado: {value}',

      competences_title: 'Habilidades',
      charts_title: 'Gráficos',
      behavior_title: 'Exponentes de comportamiento',
      development_title: 'Consejos de desarrollo',

      report_360: {
        title: 'Resultado de {type, plural, one {Autoevaluación} other {Evaluación #}} - {title}',
        valued_questions_title: 'Promedios por comportamiento',
        text_questions_title: 'Respuestas abiertas',
      },

      skeleton: {
        title: 'Descripción de la evaluación',
        description: 'Usted está participando de la Investigación Empresas Humanizadas. Su objetivo es identificar las prácticas humanizadas presentes en las Organizaciones a través de un proceso de escucha de todas las partes involucradas. Su contribución es muy importante para que la empresa pueda identificar cómo servirle mejor a usted y a la sociedad.',
      },
    },

    respondent_role: {
      title_individual: 'Hola, bienvenido(a), ha sido invitado(a) a responder a una evaluación de',
      title_organization: 'Hola, bienvenido(a), ha sido invitado(a) a responder a una evaluación de la organización',
      subtitle_organization: 'Seleccione a continuación su relación con la organización evaluada.',
      subtitle_individual: 'Seleccione a continuación cuál es su relación con {value}.',
    },
  },

  reports: {
    cba_cla: {
      tables: {
        total_group: 'Grupo total',
        completed_answers: 'Respuestas completas',
        group_and_self_legend: 'Vista del grupo y autoevaluación',
        potentially_limiting: 'Potencialmente limitante',
      },
      graphs: {
        labels: {
          bigger: 'Valor más alto',
          median: 'Mediana',
          smaller: 'Valor más bajo',
          gravity_center: 'CGC actual',
        },
        group_legend: 'Vista del grupo',
        axis: {
          very_high: 'Muy alta',
          high: 'Alta',
          medium: 'Mediana',
          low: 'Baja',
          very_low: 'Muy baja',
          stage_replace: 'Etapa {número}',
        },
      },
      recommendation_analysis: 'Recomendaciones para el proceso de análisis',
      individual_development: 'Plan de desarrollo individual',
      considerations: 'Consideraciones',
      recommendation: 'Recomendaciones',
      respondents_section: {
        reliability: 'Fiabilidad',
        applications: 'Aplicaciones',
      },
      cards_section: {
        group_view: 'Vista del grupo',
        self_assessment: 'Autoevaluación',
        benchmark: 'Punto de referencia',
        influence: 'Influencia',
        stage: 'Prácticas',
      },
      one_page: {
        top_3_card_title: 'Los 3 mejores | Cultura de la organización',
      },
      graph_interpretation: {
        center: {
          bar_1: 'Sociedad',
          bar_2: 'Socios',
          bar_3: 'Clientes',
          bar_4: 'Empleados',
          bar_5: 'Líderes',
          bar_6: 'General',
        },
        radar: {
          bar_1: 'Creación',
          bar_2: 'Realización',
          bar_3: 'Organización',
          bar_4: 'Colaboración',
          label_1: 'Experiencia actual',
          label_2: 'Experiencia deseada',
        },
      },
      labels: {
        difference: 'Diferencia',
        benchmark: 'Punto de referencia',
        trust_level: 'Nivel de confianza',
        perspective: 'Perspectiva público {isInternal, select, true {Interno} false {Externo}}',
        meaning: 'Significado',
        score: 'Puntaje',
        ratings_scale: 'Escala de valoraciones',
        level: 'Nivel',
      },
      vertical_levels: {
        level_1: {
          title: 'Supervivencia',
          description: 'enfoque en la protección y las necesidades básicas de la organización.<br/>Puede producirse manipulación, coerción, bajo nivel de confianza y participación.',
          text: 'Autoprotección y necesidad a corto plazo.',
        },
        level_2: {
          title: 'Procesos',
          description: 'enfoque en el cumplimiento y la estabilidad de los procesos y las relaciones.<br/>Las reglas y normas de trabajo rigen el comportamiento y la actitud de las personas.',
          text: 'Mantenimiento de las normas y los estándares vigentes.',
        },
        level_3: {
          title: 'Especialización',
          description: 'enfoque en la especialización, las competencias y la eficiencia.<br/>Las habilidades de los individuos y las áreas de interés impulsan los comportamientos.',
          text: 'Especialización del procesos y de la eficiencia.',
        },
        level_4: {
          title: 'Resultados',
          description: 'enfoque en los análisis, los resultados y las metas que alcanzar.<br/>Un alto nivel de responsabilidad y un fuerte sentido de meritocracia dominan las relaciones.',
          text: 'Entrega de resultados y efectividad.',
        },
        level_5: {
          title: 'Personas',
          description: 'enfoque en las relaciones humanas y la interacción con el sistema.<br/>La perspectiva de competencia es instituida por la abundancia y la interdependencia sistémica.',
          text: 'Relaciones humanas y con el medio ambiente.',
        },
        level_6: {
          title: 'Creación conjunta',
          description: 'enfoque en la capacidad de integración y transformación sistémica.<br/>Las divergencias se convierten en puntos de apoyo para la creación y la innovación conjuntas.',
          text: 'Propósito elevado y gestión dinámica.',
        },
        level_7: {
          title: 'Propósito',
          description: 'enfoque en la sabiduría y la inteligencia colectivas. Capacidad de sostener tensiones como complejidad y simplicidad, integridad y fractal.',
          text: 'Al servicio del bienestar de la humanidad.',
        },
      },
      horizontal_levels: {
        level_1: {
          title: 'Perfeccionista',
          description: 'hacer lo correcto, evitar errores y trabajar hacia el autodesarrollo y el autocontrol.',
        },
        level_2: {
          title: 'Útil',
          description: 'ser útil y fiable, estar disponible para las personas, prever y satisfacer las necesidades de los demás.',
        },
        level_3: {
          title: 'Realizadora',
          description: 'lograr metas, presentar una imagen que apoye el éxito de la organización, ser productivo y desempeñarse.',
        },
        level_4: {
          title: 'Creativa',
          description: 'ser auténtico y expresar la individualidad, estar conectado y fiel a las emociones, tener un significado y marcar una diferencia en el mundo.',
        },
        level_5: {
          title: 'Observadora',
          description: 'aportar un sentido al mundo, ahorrar recursos, pensar, analizar, resolver, evitar la dependencia y dedicarse al conocimiento.',
        },
        level_6: {
          title: 'Preguntadora',
          description: 'crear estabilidad, confianza y seguridad, proteger la organización, evitar amenazas y riesgos, ser leal y responsable.',
        },
        level_7: {
          title: 'Entusiasta',
          description: 'siempre con una perspectiva positiva, foco en el futuro, busca el placer, la libertad y amplía las posibilidades de la vida.',
        },
        level_8: {
          title: 'Protectora',
          description: 'ser fuerte y controlar, evitar la debilidad y la vulnerabilidad, hacer que las cosas sucedan, tomar decisiones y lograr resultados.',
        },
        level_9: {
          title: 'Pacificadora',
          description: 'promover la armonía y la paz, recibir la influencia de las personas, crear comodidad y rutinas, acoger y reducir los conflictos en el grupo.',
        },
      },
    },
    cba: {
      title: 'CBA® One Page',
      error_margin: 'Margen de error',
      confidence: 'Nivel de confianza',
      print_button: 'Guardar PDF',

      engagement_section: 'Participación general de las partes interesadas',

      organizational_identity: {
        title: 'Identidad organizativa',
        vertical_development: 'Desarrollo vertical (actual)',
        horizontal_development: 'Desarrollo horizontal (actual)',
        values_to_develop: 'Los 3 principales valores que desarrollar',
        values_to_adjust_reframe: 'Los 3 valores principales que ajustar o reencuadrar',
      },

      stakeholders_engagement: {
        title: 'Participación de las partes interesadas',
        description: 'Resultado del grado de participación de las partes interesadas de la empresa (puntaje de -100 a +100). Un puntaje negativo expresa desconexión y un puntaje positivo expresa la participación.',
      },

      stakeholders_management: {
        title: 'Principios de gerencia',
        description: 'Resultado de la evaluación de las prácticas y los resultados de la gestión en cinco principios (puntaje de 0 a 100).',
      },

      scores_scale: {
        focus: 'Enfoque',
        meaning: 'Significado',
        min_max: '{min} a {max}',

        quality: {
          title: 'Calidad de las relaciones',
          description: 'Índice de calidad de las relaciones (IQR)',
          '0_50': 'Relaciones extremadamente deterioradas, poniendo en riesgo el futuro del negocio.',
          '50_60': 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
          '60_70': 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
          '70_80': 'Las relaciones presentan problemas críticos que requieren intervenciones en los sistemas internos.',
          '80_90': 'Relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
          '90_95': 'Relaciones positivas y bastante sanas.',
          '95_100': 'Relaciones extremadamente positivas y saludables.',
        },

        maturity: {
          title: 'Madurez organizativa',
          description: 'Centro de gravedad de conciencia (CGC)',
          value1: 'Autoprotección y necesidades a corto plazo.',
          value2: 'Mantenimiento de las normas y los estándares vigentes.',
          value3: 'Especialización de los procesos y de la eficiencia.',
          value4: 'Entrega de resultados y efectividad.',
          value5: 'Relaciones humanas y con el medio ambiente..',
          value6: 'Propósito elevado y gestión dinámica.',
          value7: 'Al servicio del bienestar de la Humanidad.',
        },
      },

      engagement_messages: {
        '-100_-50': 'En la percepción de los grupos de interés, la organización expresa un pésimo nivel de compromiso con los problemas que ponen en riesgo el futuro del negocio.',
        '-49_-1': 'En la percepción de los grupos de interés, la organización expresa un nivel de compromiso positivo, teniendo relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
        '0_24': 'En la percepción de los grupos de interés, la organización expresa un bajo nivel de compromiso con los temas críticos que demandan atención.',
        '25_49': 'En la percepción de los grupos de interés, la organización expresa un buen nivel de compromiso con problemas específicos que demandan atención.',
        '50_64': 'En la percepción de los grupos de interés, la organización expresa un alto nivel de compromiso con varias oportunidades de mejora que deben explorarse.',
        '65_74': 'En la percepción de los grupos de interés, la organización expresa un alto nivel de compromiso con la presencia de oportunidades de mejora.',
        '75_84': 'En la percepción de los grupos de interés, la organización expresa un nivel muy alto de compromiso y debe actuar para mantenerlo así.',
        '85_100': 'En la percepción de las partes interesadas, la organización expresa un excelente nivel de compromiso y probablemente seguirá haciéndolo en el futuro.',
      },
    },
    focus: {
      header: {
        dimension: 'Dimensión',
        score: 'Puntaje',
        scale: 'Escala',
      },
    },
    letter: {
      name: 'Pedro Ernesto Paro',
      role: 'CEO y fundador',
    },
    iqr_section: {
      levels: {
        '95_100': 'Relaciones extremadamente positivas y saludables.',
        '90_95': 'Relaciones positivas y bastante sanas.',
        '80_90': 'Relaciones con problemas específicos que requieren atención inmediata para no deteriorarse.',
        '70_80': 'Las relaciones presentan problemas críticos que requieren intervenciones en los sistemas internos.',
        '60_70': 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
        '50_60': 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
        '0_50': 'Relaciones extremadamente deterioradas, poniendo en riesgo el futuro del negocio.',
      },
      labels: {
        '95_100': '95 a 100',
        '90_95': '90 a 95',
        '80_90': '80 a 90',
        '70_80': '70 a 80',
        '60_70': '60 a 70',
        '50_60': '50 a 60',
        '0_50': '0 a 50',
      },
    },
    organization_identity: {
      image_alt: 'gráfico de identidad organizativa',
    },
    narratives_analysis: {
      internal: 'Narrativas internas',
      external: 'Narrativas externas',
      title: 'Las narrativas revelan...',
      levels: {
        level100: 'Relaciones sumamente positivas al servicio de la Humanidad y de la Vida.',
        level80: 'Relaciones saludables, con problemas ocasionales que necesitan atención.',
        level60: 'Relaciones saludables, con problemas críticos que necesitan intervención.',
        level40: 'Relaciones con problemas graves que necesitan intervención inmediata con los líderes.',
        level20: 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
        level0: 'Relaciones con un alto nivel de criticidad, que necesitan cambios urgentes.',
      },
    },
    archetypes_table: {
      cultural_orientation: 'Orientación cultural',
    },
    components: {
      consolidated_results: {
        score: 'Puntaje',
        sample_size: 'Tamaño de la muestra',
        margin_of_error: 'Margen de error',
        trust_rating: 'Nivel de confianza',
      },
      consolidated_levels: {
        very_high: 'Extremadamente alto',
        high: 'Alto',
        middle: 'Mediano',
        low: 'Bajo',
        extremely_low: 'Extremadamente bajo',
      },
      time_circles: {
        past: 'Pasado',
        present: 'Presente',
        future: 'Futuro',
      },
      base_page: {
        answered_by: 'Respondido por',
      },
      center_chart: {
        optimistic_outlook: 'Perspectiva optimista',
        pessimistic_outlook: 'Perspectiva pesimista',
      },
      start_chart: {
        degree_of_maturity: 'Grado de madurez organizativa',
      },
      multiple_bars_chart: {
        center_of_gravity: 'Centro de gravedad',
      },
      side_menu: {
        menu_intro: 'Introducción',
        letter: 'Carta al lector',
        intro_comments: 'Comentarios generales',
        menu_rating: 'Calificaciones',
        intro: 'Introducción a las calificaciones',
        general_results: 'Resultado general',
        general_comments: 'Comentarios generales',
        focus: 'Dónde enfocarse',
        menu_perspective: 'Perspectiva de múltiples partes interesadas',
        respondents: 'Encuestados',
        engagement: 'Participación',
        experience: 'Experiencia',
        score: 'Satisfacción',
        perspective: 'Perspectiva de futuro',
        menu_principles: 'Principios',
        overview: 'Vista general de los principios',
        purpose: 'Propósito mayor',
        strategy: 'Estrategia de valor',
        conscious_culture: 'Cultura consciente',
        adaptability: 'Aprendizaje y cambio',
        leadership: 'Liderazgo consciente',
        menu_values: 'Valores',
        menu_identity: 'Identidad',
        formation: 'Formación de la identidad',
        vertical_dev: 'Desarrollo vertical',
        horizontal_dev: 'Desarrollo horizontal',
        quality: 'Índice de calidad de las relaciones',
        menu_narratives: 'Narrativas',
        map: 'Mapa de narrativas',
        main: 'Narrativas principales',
        analysis: 'Análisis de contenido',
        highlights: 'Prácticas destacadas',
        recommended: 'Prácticas recomendadas',
        demographics: 'Datos demográficos',
        reputation: 'Reputación',
        general_reputation: 'Reputación general',
        reputation_by_stakeholder: 'Reputación por parte interesada',
        responding_data: 'Datos de los encuestados',
        learning_and_change: 'Aprendizaje y cambio',
        perceived_result: 'Resultado percibido',
        culture: 'Cultura',
        organizational_culture: 'Cultura organizativa',
        respondent_profile: 'Perfil de los encuestados',
        group_view: 'Vista del grupo',
        self_assessment: 'Autoevaluación',
        influence_capacity: 'Capacidad de influencia',
        leadership_profile: 'Perfil de liderazgo',
        maturity_stage: 'Etapa de madurez',
        leadership_style: 'Estilo de liderazgo',
        values_to_provide: 'Valores que fortalecer',
        values_to_adjust: 'Valores que ajustar',
      },
    },
  },

  components: {
    average_card: {
      general: 'Promedio general',
    },

    competence_card: {
      average_title: 'Promedios por grupos de respuesta',
    },

    star_question: {
      select_a_number: 'Seleccione un número',
    },

    score_question: {
      good: 'Muy bueno',
      bad: 'Muy malo',
    },

    terms_of_use: {
      read: 'He leído y acepto los',
      terms_link: 'Términos de uso',
      and: 'y la',
      policies_link: 'Política de privacidad',
      platform: 'de la Plataforma Humanizadas.',
    },

    table_pagination: {
      items_per_page: 'Elementos por página',
      count: '{isPlural, select, true {Elementos registrados} false {Elemento registrado}}',
      page: 'Pág.',
      more_button: 'Cargar más',
    },

    values_matrix: {
      amount: 'Número de características seleccionadas',
    },
  },

  forms: {
    login: {
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',
      password_label: 'Contraseña',
      forgot_password: 'Olvidé mi contraseña',
      forgot_email: 'Olvidé mi correo electrónico',
      login_button: 'Iniciar sesión',
      signup_button: 'Crear una cuenta',

      warning_dialog: {
        title: '¿Ha informado correctamente el correo electrónico?',
        message: 'Asegúrese de que ya ha creado su cuenta y que ha utilizado el correo electrónico en el que recibió la invitación para usar la plataforma.',
        cancel_button: 'He informado correctamente',
        confirm_button: 'Controlar',
      },
    },

    my_profile: {
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',

      first_name_label: 'Nombre',
      first_name_placeholder: 'P. ej.: Mariana',

      last_name_label: 'Apellido',
      last_name_placeholder: 'P. ej.: Dias',

      current_password_label: 'Contraseña actual',
      password_label: 'Nueva contraseña',
      confirm_password_label: 'Confirme su nueva contraseña',

      working_company_name_label: 'Organización en la que trabaja',
      working_company_name_placeholder: 'P. ej.: Humanizadas',

      sector_label: 'Sector en el que trabaja',
      sector_placeholder: 'P. ej.: Administrativo',

      role_label: 'Rol en el cual trabaja',
      role_placeholder: 'P. ej.: Gerente administrativo',

      biological_sex_label: 'Sexo',
      biological_sex_placeholder: 'Seleccione',

      gender_label: 'Género',
      gender_placeholder: 'P. ej.: Otro',
      gender_tooltip: 'La identidad de género se refiere a la experiencia de una persona con su propio género.',

      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      pcd_label: 'PcD (Persona con Discapacidad)',
      pcd_placeholder: 'Seleccione',

      pcd_tooltip_pt1_title: 'Discapacidad física',
      pcd_tooltip_pt1_body: 'alteración total o parcial de uno o más segmentos del cuerpo humano, que provoque el deterioro de la función física, que se presente en la forma de paraplejia, paraparesia, monoplejia, monoparesia, tetraplejia, tetraparesia, triplejia, hemiplejia, hemiparesia, ostomía, amputación o ausencia de una extremidad, parálisis cerebral, enanismo, miembros con deformidad congénita o adquirida, excepto las deformidades estéticas y las que no produzcan dificultades en el desempeño de las funciones',

      pcd_tooltip_pt2_title: 'Discapacidad auditiva',
      pcd_tooltip_pt2_body: 'Pérdida parcial o total de las capacidades auditivas sonoras, en diversos grados y niveles de la siguiente manera: de 41 a 55 dB - sordera moderada | de 56 a 70 dB - sordera notable| de 71 a 90 dB - sordera severa | por encima de 91 dB - sordera profunda | anacusia - pérdida total',

      pcd_tooltip_pt3_title: 'Discapacidad visual',
      pcd_tooltip_pt3_body: 'La discapacidad visual se caracteriza por la limitación o pérdida de las funciones básicas del ojo y del sistema visual. Los discapacitados visuales pueden ser personas ciegas o con baja visión. La ceguera presupone la falta de percepción visual debido a factores fisiológicos o neurológicos. La ceguera total, o simplemente amaurosis, se caracteriza por la pérdida completa de la visión sin percepción visual de la luz y las formas. La ceguera puede ser congénita o adquirida. La agudeza visual de las personas con baja visión es muy variable; pero, por lo general, la baja visión se define como una condición en la que la visión de una persona no se puede corregir completamente con anteojos, lo que interfiere con sus actividades diarias, como leer y caminar.',

      pcd_tooltip_pt4_title: 'Deficiencia mental',
      pcd_tooltip_pt4_body: 'Funcionamiento intelectual significativamente más bajo que el promedio, con inicio antes de los 18 años y limitaciones asociadas con dos o más áreas de habilidades adaptativas, tales como: comunicación | cuidado personal | habilidades sociales | uso comunitario | salud y seguridad | habilidades académicas | ocio | trabajo',

      color_label: 'Color / Raza',
      color_placeholder: 'Seleccione',
      color_tooltip: 'Según la referencia del IBGE',

      sexual_orientation_label: 'Orientación sexual',
      sexual_orientation_placeholder: 'Seleccione',
      sexual_orientation_tooltip: 'La orientación se refiere a la atracción que se siente hacia otras personas. Suele implicar también cuestiones sentimentales, no solo sexuales. Referencia: UFSC',

      success_dialog: {
        title: 'Cambios guardados',
        message: '¡Sus cambios se han guardado con éxito!',
      },
    },

    organization_profile: {
      company_name_label: 'Nombre de la empresa',
      company_name_placeholder: 'P. ej.: Humanizadas',
      company_corporate_name_label: 'Razón social',
      company_corporate_name_placeholder: 'P. ej.: Humanizadas LTDA',

      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',
      website_label: 'Sitio web',
      website_placeholder: 'P. ej.: www.site.com',
      cnpj_label: 'Código fiscal',
      cnpj_placeholder: 'P. ej.: 12.345.678/0001-99',
      state_registry_label: 'Inscripción estatal',
      state_registry_placeholder: 'P. ej.: 000.000.00',
      foundation_date_label: 'Fechga de fundación',
      foundation_date_placeholder: 'P. ej.: 01/01/2020',
      number_of_employees_label: 'Número de empleados',
      number_of_employees_placeholder: 'P. ej.: 100',
      foundation_city_label: 'Origen',
      foundation_city_placeholder: 'P. ej.: Belo Horizonte',
      sector_label: 'Sector',
      sector_placeholder: 'P. ej.: Servicios',

      company_type_label: 'Tipo',
      revenue_label: 'Ingresos',

      postal_code_label: 'Código postal',
      postal_code_placeholder: 'P. ej.: 12.345-678',
      street_label: 'Calle/avenida',
      street_placeholder: 'P. ej.: Rua São Félix',
      number_label: 'Número',
      number_placeholder: 'P. ej.: 10',
      neighborhood_label: 'Districto',
      neighborhood_placeholder: 'P. ej.: Planalto',
      complement_label: 'Complemento',
      complement_placeholder: 'P. ej.: Sala 101',
      city_label: 'Ciudad',
      city_placeholder: 'P. ej.: Belo Horizonte',
      state_label: 'Estado',
      state_placeholder: 'P. ej.: Minas Gerais',

      select_placeholder: 'Seleccione',
      cancel_button: 'Cancelar',
      submit_button: 'Guardar',

      select_options: {
        public: 'Pública',
        private: 'Privada',
        public_private: 'Pública-privada',
      },
    },

    respondents_builder: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'P. ej.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'P. ej.: Oliveira',
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',

      add_button: 'Añadir otro',
      cancel_button: 'Cancelar',
      submit_button: 'Añadir',
    },

    signup: {
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',
      first_name_label: 'Nombre',
      first_name_placeholder: 'P. ej.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'P. ej.: Dias',
      cpf_label: 'CPF (Código fiscal de persona física)',
      cpf_placeholder: 'P. ej.: 123.456.789-10',
      cpf_tooltip: 'Le pedimos su CPF para confirmar su identidad.',
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar la contraseña',
      login_button: 'Ya tengo una cuenta',
      signup_button: 'Crear registro',
      password_helper: 'Mínimo de 6 caracteres, 1 mayúscula y 1 minúscula',

      dialog: {
        title: '¡Muchas gracias por registrarse!',
        message: 'Para garantizar su seguridad, si desea acceder a la plataforma, deberá ingresar su nombre de usuario y contraseña en la página de inicio de sesión.',
        confirm_button: 'Iniciar una sesión',
      },

      dialog_warning: {
        title: 'Aviso',
        message: 'Este enlace de registro es único para el correo electrónico {email} y solo debe completarse si usted es el responsable del mismo. ¿Le gustaría continuar?',
        left_button: 'No',
        right_button: 'Sí',
      },
    },

    support: {
      message_label: 'Mensaje',
      message_placeholder: 'Por ejemplo: correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: '¡Quédese atento a su correo electrónico!',
        message: 'Le enviaremos más información en los próximos días para apoyarle. Recuerde revisar también tu carpeta de spam.',
        confirm_button: 'Finalizar',
      },
    },

    unauthenticated_contact: {
      first_name_label: 'Nombre',
      first_name_placeholder: 'P. ej.: Mariana',
      last_name_label: 'Apellido',
      last_name_placeholder: 'P. ej.: Oliveira',
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',
      message_label: 'Mensaje',
      message_placeholder: 'Por ejemplo: correo electrónico no encontrado',
      cancel_button: 'Cancelar',
      submit_button: 'Enviar',

      dialog: {
        title: '¡Quédese atento a su correo electrónico!',
        message: 'Le enviaremos más información en los próximos días para apoyarle. Recuerde revisar también tu carpeta de spam.',
        confirm_button: 'Finalizar',
      },
    },

    password_recovery_enter_email: {
      email_label: 'Correo electrónico',
      email_placeholder: 'P. ej.: mariana@email.com',

      cancel_button: 'Cancelar',
      confirm_button: 'Enviar',
      email_not_found: 'Correo electrónico no encontrado. Necesito ayuda',

      success_dialog: {
        title: '¡Quédese atento a su correo electrónico!',
        message: 'Recibirá un enlace para restablecer su contraseña. Recuerde revisar también tu carpeta de spam.',
        help_button: 'No recibi el correo electrónico',
        confirm_button: 'Finalizar',
      },

      warning_dialog: {
        title: '¿Ha informado correctamente el correo electrónico?',
        message: 'Asegúrese de que ya ha creado su cuenta y que ha utilizado el correo electrónico en el que recibió la invitación para usar la plataforma.',

        cancel_button: 'He informado correctamente',
        confirm_button: 'Controlar',
      },
    },

    password_recovery_reset_password: {
      password_label: 'Contraseña',
      password_confirmation_label: 'Confirmar la contraseña',
      password_helper: 'Mínimo de 6 caracteres, 1 mayúscula y 1 minúscula',

      confirm_button: 'Enviar',

      dialog: {
        title: '¡Su contraseña ha sido cambiada exitosamente!',
        message: 'Ahora puede iniciar una sesión de nuevo',
        confirm_button: 'Iniciar una sesión',
      },
    },

    complete_register: {
      working_company_name_label: 'Empresa',
      working_company_name_placeholder: 'P. ej.: Humanizadas',
      working_company_department_label: 'Sector',
      working_company_department_placeholder: 'Seleccione',
      working_company_job_title_label: 'Función',
      working_company_job_title_placeholder: 'Seleccione',
      gender_label: 'Género',
      gender_placeholder: 'Seleccione',

      complete_register_dialog: {
        title: '¡Cuéntenos acerca de usted!',
        subtitle: 'Necesitamos registrar más información para completar su registro.',
        subtitle2: 'La información suministrada desde aquí se utilizará con fines de investigación de forma agregada, garantizando así su anonimato.',
      },
    },
  },

  tables: {
    table_labels: {
      ideal_column: 'Muestra ideal',
      minimum_column: 'Muestra mín.',
    },

    respondents: {
      title: 'Encuestados',
      subtitle_1: 'Los contactos agregados en esta página también se agregarán a su base de datos y estarán disponibles en el menú «Encuestados».',
      subtitle_2: 'Atención: está agregando encuestados al cuestionario {questionnaire} del evaluado {user}.',
      subtitle_3: 'Atencón: está añadiendo encuestados {showTitle, select, true {al cuestionario {title}} false {a este cuestionario}} {showUser, select, true {del evaluado {user}} false {}}.',
      empty: 'No se encontró ningún encuestado',
      no_content: 'No informado',

      actions: {
        invite: 'Invitar a una evaluación',
        edit: 'Editar',
        delete: 'Excluir',
        send: 'Enviar un correo electrónico',
      },

      filter: {
        department: 'Sector',
        jobTitle: 'Función',
      },

      dialogs: {
        empty: {
          title: 'Ningún usuario seleccionado.',
          body: 'Seleccione al menos un usuario en la tabla para esta acción.',
        },
      },
    },

    assessments: {
      self: {
        filter: {
          title: 'Tipo',
          360: 'Evaluación 360',
          270: 'Evaluación 270',
          180: 'Evaluación 180',
          720: 'Evaluación 720',
          self_assessment: 'Autoevaluación',
        },
      },
      config: {
        view_questionnaire: 'Ver los cuestionarios',
        config_assessment: 'Configurar la evaluación',

        dialogs: {
          respondents: {
            title: 'Todavía no tiene ningún encuestado registrado en la base de datos.',
            body: 'Registre a los encuestados y facilite la configuración de sus evaluaciones.',

            left_button: 'Ahora no',
            right_button: 'Registrar encuestados',
          },
        },
      },
      monitor: {
        show_report: 'Ver el informe',
        tooltips: {
          play: 'Reanudar',
          pause: 'Pausa',
          finish: 'Cerrar ahora',
          results: 'Ver el resultado',
          view: 'Ver los cuestionarios',
        },

        pause_dialog: {
          title: '¿Desea hacer una pausa en la evaluación?',
          body_1: 'Mientras la evaluación esté en pausa, los encuestados no podrán verla ni enviar respuestas. Puede reanudarla de nuevo en cualquier momento.',
          body_2: 'Tenga en cuenta que la fecha de cierre no cambiará.',
        },

        stop_dialog: {
          title: '¿Desea concluir la evaluación?',
          body_1: 'Una vez concluida la evaluación, no será posible recopilar más respuestas ni liberarla nuevamente. Los resultados se basarán en los datos ya recibidos.',
          body_2: 'Atención: si desea suspender la evaluación por un período, intente pausarla.',
        },
      },
      release: {
        tooltips: {
          release: 'Liberar ahora',
          edit: 'Editar',
          view: 'Ver los cuestionarios',
        },


        header: {
          startWithin: 'Inicio en',
        },

        release_dialog: {
          title: '¿Liberar la evaluación ahora?',
          body: 'Si libera la evaluación ahora, se la enviará a todos los encuestados. Esta acción no se puede deshacer.',
          left_button: 'Esperar la fecha de inicio',
          right_button: 'Liberar',
        },
      },

      assessed_table: {
        tooltips: {
          view: 'Ver los cuestionarios',
          results: 'Ver el resultado',
          monitor: 'Seguir',
        },
      },
    },

    questionnaire: {
      icons: {
        answer: 'Responder a la autoevaluación',
        monitor: 'Seguir los encuestados',
      },
    },

    header: {
      bulk_actions: 'Acciones a granel',
    },

    filters: {
      clean_filters: 'Borrar los filtros',
      apply_filters: 'Aplicar los filtros',
      assessment_title: 'Filtrar las evaluaciones',
      invited_option_start: 'Hasta {number} invitados',
      invited_option_middle: 'De {start} a {end} invitados',
      invited_option_end: 'Más que {number} invitados',
      time_option_hour: 'Próximas {number}h',
      time_option_days: 'Próximos {number} días',
      assessments_option_start: 'Hasta {number} evaluaciones',
      assessments_option_middle: 'De {start} a {end} evaluaciones',
      assessments_option_end: 'Más que {number} evaluaciones',
      questions_option_start: 'Hasta {number} preguntas',
      questions_option_middle: 'De {start} a {end} preguntas',
      questions_option_end: 'Más que {number} preguntas',
      assessed_option_start: 'Hasta {number} evaluados',
      assessed_option_middle: 'De {start} a {end} evaluados',
      assessed_option_end: 'Más que {number} evaluados',
    },
  },

  dialogs: {
    reminder: {
      title: 'Enviar correo electrónico',
      body: 'Elija el correo electrónico que desea enviar a los encuestados seleccionados. Al enviar, se enviará este correo electrónico a granel y no será posible deshacer esta acción.',
      body_amount: 'Elija el correo electrónico que desea enviar {num, plural, one {al encuestado seleccionado} otro {a los encuestados seleccionados}}. Al enviar, se enviará este correo electrónico a granel y no será posible deshacer esta acción.',
      left_button: 'Cancelar',
      right_button: 'Enviar correo electrónico',
    },
    send_email_all: {
      title: '¿Enviar un correo electrónico a los seleccionados?',
      title_number: '¿Enviar un correo electrónico a {num, plural, one {# encuestado} otro {# encuestados}}?',
      body: 'Al enviar, se enviará este correo electrónico a granel y no será posible deshacer esta acción. Para enviar un correo electrónico a solo unos pocos encuestados, selecciónelos en la tabla a continuación y haga clic en «Acciones a granel».',
      left_button: 'Cancelar',
      right_button: 'Enviar a todos',
    },
    email_successfully_sent: {
      title: 'Envío de {multiple, select, true {correos electrónicos} false {correo electrónico}}',
      body: '{multiple, select, true {¡Todos los correos electrónicos han sido enviados} false{¡El correo electrónico ha sido enviado}} con éxito!',
      button: 'Concluir',
    },
  },
};
