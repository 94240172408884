import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { withTheme } from 'styled-components';
import classnames from 'classnames';

import Box from '../Styled/Box';
import Skeleton from '../Skeleton/Skeleton';

const getInitials = (alt = '') => {
  const names = alt.split(' ');

  const firstName = names[0];
  const lastName = names[names.length - 1];

  return `${firstName[0] || 'U'} ${(lastName[0] || 'S')}`;
};

const getTextVariant = (size = '') => {
  switch (size.toLowerCase()) {
  case 'sm':
    return 'capsSM';

  case 'md':
  default:
    return 'h3';
  }
};

const useStyles = (theme, size, src) => makeStyles(() => ({
  root: {
    border: `solid 2px ${theme.colors.white}`,
    boxShadow: theme.shadows.button,
    color: theme.colors.white,
    backgroundColor: src ? theme.colors.white : theme.colors.purple100,
    letterSpacing: size === 'sm' ? -1 : -3, // por algum motivo as letras estão super separadas
    textTransform: 'uppercase',
    ...theme.typography[getTextVariant(size)],
  },

  sm: {
    width: 28,
    height: 28,
  },

  md: {
    width: 72,
    height: 72,
  },
}));

const Avatars = ({
  alt,
  size,
  src,
  boxProps,
  theme,
  isLoading,
  ...props
}) => {
  const classes = useStyles(theme, size, src)();

  return (
    <Box {...boxProps}>
      {isLoading && (
        <Box
          borderRadius='50%'
          className={classes[size] || classes.md}
        >
          <Skeleton
            circle
            height={72}
            width={72}
          />
        </Box>
      )}

      {!isLoading && (
        <Avatar
          alt={alt}
          src={src}
          className={classnames(classes.root, (classes[size] || classes.md))}
          {...props}
        >
          {!src && alt && (getInitials(alt))}
        </Avatar>
      )}
    </Box>
  );
};

Avatars.propTypes = {
  theme: PropTypes.object.isRequired,
  alt: PropTypes.string,
  src: PropTypes.string,
  boxProps: PropTypes.object,
  size: PropTypes.oneOf(['sm', 'md']),
  isLoading: PropTypes.bool,
};

Avatars.defaultProps = {
  alt: '',
  src: null,
  size: 'md',
  boxProps: {},
  isLoading: false,
};

export default withTheme(Avatars);
