import { sortBy } from 'lodash';

import FlagPortugal from '../assets/imgs/flags/portugal.svg';
import FlagBrazil from '../assets/imgs/flags/brazil.svg';
import FlagUSA from '../assets/imgs/flags/usa.svg';
import FlagMexico from '../assets/imgs/flags/mexico.svg';
import FlagSpain from '../assets/imgs/flags/spain.svg';


export const assessmentTypes = {
  organization: {
    id: 'organization',
    value: 0,
  },
  individual: {
    id: 'individual',
    value: 1,
  },
  self: {
    id: 'self_assessment',
    value: 2,
  },
};

export const assessmentRoleTypes = {
  e51mW4: 'external',
  BTdZNZ: 'internal',
  Xgx0tN: 'self',
};

export const userRoles = {
  respondent: {
    id: 'respondent',
  },
  owner: {
    id: 'owner',
  },
  manager: {
    id: 'manager',
  },
};

// @alan: completed somente quando o status da avaliação for in_progress
export const questionnaireStatuses = {
  ready_to_start: {
    id: 'ready_to_start',
    text: 'statuses.questionnaires.ready_to_start',
    color: 'danger',
  },

  started: {
    id: 'started',
    text: 'statuses.questionnaires.started',
    color: 'warning',
  },

  answered: {
    id: 'answered',
    text: 'statuses.questionnaires.answered',
    color: 'success',
    completed: true,
  },

  not_answered: {
    id: 'not_answered',
    text: 'statuses.questionnaires.not_answered',
    color: 'coldgrey600',
    completed: true,
  },

  partially_answered: {
    id: 'partially_answered',
    text: 'statuses.questionnaires.partially_answered',
    color: 'warning',
    completed: true,
  },

  results_available: {
    id: 'results_available',
    text: 'statuses.questionnaires.results_available',
    color: 'purple',
    completed: true,
  },
};

export const applicationStatuses = {
  // starts_in not set
  no_date_yet: {
    id: 'no_date_yet',
    value: 'no_date_yet',
    isNew: true,
  },

  // now < starts_in
  created: {
    id: 'created',
    value: 'created',
  },

  // now > starts_in and now < ends_in
  in_progress: {
    id: 'in_progress',
    value: 'in_progress',
    isReleased: true,
  },

  // now > ends_in
  ended: {
    id: 'ended',
    value: 'ended',
    isReleased: true,
  },

  // the application was paused.
  paused: {
    id: 'paused',
    value: 'paused',
    isReleased: true,
  },

  // all applications questionnaires have their results available.
  results_available: {
    id: 'results_available',
    value: 'results_available',
    isReleased: true,
  },
};

export const assessmentStatuses = {
  in_progress: {
    id: 'in_progress',
    text: 'statuses.assessments.released',
    color: 'teal',
  },

  paused: {
    id: 'paused',
    text: 'statuses.assessments.paused',
    color: 'warning',
  },

  ended: {
    id: 'ended',
    text: 'statuses.assessments.finished',
    color: 'success',
    completed: true,
  },

  results_available: {
    id: 'results_available',
    text: 'statuses.assessments.results',
    color: 'purple',
    completed: true,
  },
};

export const seeAllCompaniesStatuses = {
  desisted: {
    id: 'desisted',
    text: 'statuses.see_all_companies.desisted',
    color: 'coldgrey700',
  },

  in_progress: {
    id: 'in_progress',
    text: 'statuses.see_all_companies.in_progress',
    color: 'teal100',
  },

  paused: {
    id: 'paused',
    text: 'statuses.see_all_companies.paused',
    color: 'warning',
  },

  finished: {
    id: 'finished',
    text: 'statuses.see_all_companies.finished',
    color: 'success',
  },

  results_available: {
    id: 'results_available',
    text: 'statuses.see_all_companies.results_available',
    color: 'purple100',
  },
};

export const respondentStatuses = {
  pending: {
    id: 'pending',
    text: 'statuses.respondents.pending',
    color: 'danger',
  },

  registered: {
    id: 'registered',
    text: 'statuses.respondents.registered',
    color: 'success',
  },

  not_answered: {
    id: 'not_answered',
    text: 'statuses.respondents.not_answered',
    color: 'danger',
  },

  answered: {
    id: 'answered',
    text: 'statuses.respondents.answered',
    color: 'success',
  },
};

export const questionTypes = {
  text: 'TextQuestion',
  select: 'SelectorQuestion',
  score: 'ScoreQuestion',
  rating: 'RatingQuestion',
  card: 'CardQuestion',
  star: 'StarQuestion',
  matrix: 'MatrixQuestion',
};

export const languages = [
  {
    id: 'pt-BR',
    language: 'pt-BR',
    name: 'Português (BR)',
    image: FlagBrazil,
  },
  {
    id: 'pt-PT',
    language: 'pt-PT',
    name: 'Português (PT)',
    image: FlagPortugal,
  },
  {
    id: 'es-ES',
    name: 'Español (ES)',
    image: FlagSpain,
  },
  {
    id: 'es-MX',
    language: 'es',
    name: 'Español (MX)',
    image: FlagMexico,
  },
  {
    id: 'en-US',
    language: 'en',
    name: 'English (US)',
    image: FlagUSA,
  },
];

export const reportTypes = [
  {
    id: 'default',
    label: 'Ver Relatório',
  },
  {
    id: 'one_page',
    label: 'One Page',
  },
  {
    id: 'one_page_rating',
    label: 'One Page Rating',
  },
  {
    id: 'full',
    label: 'Full',
  },
  {
    id: 'essential',
    label: 'Essential',
  },
];

export const defaultLanguage = 'pt-BR';

// monta array de opcoes com base nas opcoes presentes
// no arquivo de mensagens do i18n
export const mapFromMessages = (messages, key) => sortBy(Object.keys(messages || {})
  .filter((_key) => _key.includes(key))
  .map((_key) => ({
    id: _key,
    name: messages[_key],
  })), 'name');

export const jobTitles = (messages) => mapFromMessages(messages, 'select_options.job_titles');

export const departments = (messages) => mapFromMessages(messages, 'select_options.departments');

export const genders = (messages) => mapFromMessages(messages, 'select_options.genders');

export const sex = (messages) => mapFromMessages(messages, 'select_options.sex');

export const sexual_orientation = (messages) => mapFromMessages(messages, 'select_options.orientation');

export const race = (messages) => mapFromMessages(messages, 'select_options.race');

export const pcd = (messages) => mapFromMessages(messages, 'select_options.pcd');

export default {
  questionnaireStatuses,
  assessmentStatuses,
};
