import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import withRoutes from './withRoutes';
import { authSelectors } from '../../redux/selectors';

const PrivateRoute = ({
  component: Component,
  routes,
  location,
  ...props
}) => {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);

  const search = React.useMemo(() => queryString.parse(location.search), [location]);
  const redirectToSignUp = _.has(search, 'aq_tkn') && location.pathname === '/home';

  const redirectTo = redirectToSignUp ? routes.signup : routes.login;

  if (!isAuthenticated) {
    return <Redirect to={`${redirectTo}${location.search || ''}`} />;
  }

  return (
    <Route
      render={(routerProps) => <Component {...routerProps} {...props} />}
      {...props}
    />
  );
};


PrivateRoute.propTypes = {
  routes: PropTypes.object.isRequired,
  component: PropTypes.any,
  location: PropTypes.object.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
};

export default withRoutes(PrivateRoute);
