import c from './user.constants';

const disableLoading = () => ({ type: c.DISABLE_LOADING });

const resetStore = () => ({ type: c.RESET_STORE });

const setShowWelcomeDialog = (flag = false) => ({
  type: c.SET_SHOW_WELCOME_DIALOG,
  payload: { flag },
});

const enrollUser = (user_id, aq_tkn) => ({
  type: c.ENROLL_USER,
  payload: { user_id, aq_tkn },
});

const fetchUserRequest = ({ user_id, params = c.params.default, ...rest }) => ({
  type: c.FETCH_USER_REQUEST,
  payload: { user_id, params, ...rest },
});

const onFetchUserSuccess = (user) => ({
  type: c.ON_FETCH_USER_SUCCESS,
  payload: { user },
});

const onFetchUserFail = (error) => ({
  type: c.ON_FETCH_USER_FAIL,
  payload: { error },
});

const saveUserOnStore = onFetchUserSuccess;

const createUserRequest = ({ user, params = c.params.default, ...rest }) => ({
  type: c.CREATE_USER_REQUEST,
  payload: { user, params, ...rest },
});

const onCreateUserSuccess = (user) => ({
  type: c.ON_CREATE_USER_SUCCESS,
  payload: { user },
});

const onCreateUserFail = (error) => ({
  type: c.ON_CREATE_USER_FAIL,
  payload: { error },
});

const updateUserRequest = ({ user, params = c.params.default, ...rest }) => ({
  type: c.UPDATE_USER_REQUEST,
  payload: { user, params, ...rest },
});

const updateAndAuthenticate = ({ user, params = c.params.default, ...rest }) => ({
  type: c.UPDATE_AND_AUTHENTICATE_USER,
  payload: { user, params, ...rest },
});

const onUpdateUserSuccess = (user) => ({
  type: c.ON_UPDATE_USER_SUCCESS,
  payload: { user },
});

const onUpdateUserFail = (error) => ({
  type: c.ON_UPDATE_USER_FAIL,
  payload: { error },
});

const setCurrentCompany = (company) => ({
  type: c.SET_ACTIVE_COMPANY,
  payload: { company },
});

const resetActiveCompany = () => setCurrentCompany(null);

const updateCompanyUserRole = (companyUserId, role) => ({
  type: c.UPDATE_COMPANY_USER_ROLE,
  payload: { companyUserId, role },
});

const showCompanyRequest = ({ companyId, params = c.params.company, ...rest }) => ({
  type: c.SHOW_COMPANY_REQUEST,
  payload: { companyId, params, ...rest },
});

const onShowCompanySuccess = (company) => ({
  type: c.ON_SHOW_COMPANY_SUCCESS,
  payload: { company },
});

const onShowCompanyFail = (error) => ({
  type: c.ON_SHOW_COMPANY_FAIL,
  payload: { error },
});

const updateCompanyRequest = ({ company, params = c.params.company, ...rest }) => ({
  type: c.UPDATE_COMPANY_REQUEST,
  payload: { company, params, ...rest },
});

const onUpdateCompanySuccess = (company) => ({
  type: c.ON_UPDATE_COMPANY_SUCCESS,
  payload: { company },
});

const onUpdateCompanyFail = (error) => ({
  type: c.ON_UPDATE_COMPANY_FAIL,
  payload: { error },
});

const setActiveLanguage = (language) => ({
  type: c.SET_ACTIVE_LANGUAGE,
  payload: language,
});

export default {
  resetStore,
  saveUserOnStore,
  enrollUser,

  fetchUserRequest,
  onFetchUserSuccess,
  onFetchUserFail,

  createUserRequest,
  onCreateUserSuccess,
  onCreateUserFail,

  updateAndAuthenticate,
  updateUserRequest,
  onUpdateUserSuccess,
  onUpdateUserFail,

  setShowWelcomeDialog,
  updateCompanyUserRole,

  setCurrentCompany,
  resetActiveCompany,

  showCompanyRequest,
  onShowCompanySuccess,
  onShowCompanyFail,

  updateCompanyRequest,
  onUpdateCompanySuccess,
  onUpdateCompanyFail,

  disableLoading,

  setActiveLanguage,
};
