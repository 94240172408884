import constants from './caches.constants';

const initialState = {};

const reducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
  case constants.RESET_STORE:
    return { ...initialState };

  case constants.CACHE_VALUE: {
    const { key, value } = payload;

    return {
      ...state,
      [key]: value,
    };
  }

  case constants.CLEAR_CACHED_VALUE: {
    const { key } = payload;
    const { [key]: oldValue, ...rest } = state;
    return { ...rest };
  }

  default:
    return state;
  }
};

export default reducer;
