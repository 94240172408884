import React from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { makeStyles } from '@material-ui/core';
import DefaultDialogContent from './DefaultDialogContent';

import Box from '../Styled/Box';
import Icon from '../Icons/Icon';

import { useTheme, useIsMobileLayout } from '../../hooks';

const Card = styled(Box)`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.coldgrey100};
  border: solid 1px ${({ theme }) => theme.colors.coldgrey300};
  box-shadow: ${({ theme }) => theme.shadows.light[4]};
`;

const IconView = styled.div`
  position: relative;
  padding: 8px;
  margin-right: -8px;
`;

const useStyles = (appTheme, isMobile) => makeStyles((theme) => ({
  root: {
    backgroundColor: `${rgba(appTheme.colors.coldgrey700, 0.6)}`,
  },
  paperFullWidth: isMobile && {
    width: 'calc(100% - 48px)',
  },
  paper: isMobile && {
    margin: '24px',
    position: 'relative',
    overflowY: 'auto',
  },
  paperWidthSm: isMobile && {
    '&$paperScrollBody': {
      [theme.breakpoints.down(theme.breakpoints.values.sm + 24 * 2)]: {
        maxWidth: 'calc(100% - 48px)',
      },
    },
  },
}));

const Dialog = ({
  visible,
  onClose,
  component: Component,
  dialogProps,
  contentViewProps,
  preventCloseOnClickOutside,
  hasCloseIcon,
  ...props
}) => {
  const isMobile = useIsMobileLayout();
  const theme = useTheme();
  const classes = useStyles(theme, isMobile)();

  return (
    <MuiDialog
      classes={classes}
      fullWidth
      maxWidth={isMobile ? 'sm' : 'xs'}
      open={visible}
      onClose={preventCloseOnClickOutside ? null : onClose}
      aria-labelledby='max-width-dialog-title'
      PaperComponent={Card}
      PaperProps={contentViewProps}
      {...dialogProps}
    >
      {hasCloseIcon && (
        <Box
          display='flex'
          width='100%'
          justifyContent='flex-end'
          pr={3}
          style={{ zIndex: 1 }}
        >
          <IconView>
            <Icon icon='times' onClick={onClose} />
          </IconView>
        </Box>
      )}

      {Component && (<Component closeDialog={onClose} {...props} />)}

      {!Component && (<DefaultDialogContent closeDialog={onClose} {...props} />)}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dialogProps: PropTypes.object,
  contentViewProps: PropTypes.object,
  component: PropTypes.any,
  preventCloseOnClickOutside: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
};

Dialog.defaultProps = {
  visible: false,
  component: null,
  contentViewProps: {},
  dialogProps: {},
  preventCloseOnClickOutside: false,
  hasCloseIcon: false,
};

export default Dialog;
