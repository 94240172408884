let baseURL = 'http://localhost:3001/api/v1';

if (process.env.REACT_APP_ENV === 'production') {
  baseURL = 'https://guidance-humanizadas-api-prod.herokuapp.com/api/v1';
}

if (process.env.REACT_APP_ENV === 'master') {
  baseURL = 'https://guidance-humanizadas-master.herokuapp.com/api/v1';
}

if (process.env.REACT_APP_ENV === 'staging') {
  baseURL = 'https://guidance-humanizadas-api-stage.herokuapp.com/api/v1';
}

if (process.env.REACT_APP_ENV === 'dev') {
  // baseURL = 'https://guidance-humanizadas-api-dev.herokuapp.com/api/v1';
}

export const API_HOST = baseURL;

const auth = {
  login: {
    url: 'users/authenticate',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },

  resetPassword: {
    url: 'auth/password',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  },

  requestResetPasswordLink: {
    url: 'auth/password',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },

  logout: {
    url: 'auth/sign_out',
    method: 'DESTROY',
    headers: { 'Content-Type': 'application/json' },
  },
};

const s3 = {
  getS3URL: {
    url: 's3-sign?file_name=:file_name',
    method: 'POST',
  },
};

const user = {
  index: {
    url: 'users',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },

  create: {
    url: 'users',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },

  update: {
    url: 'users/:user_id',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  },

  show: {
    url: 'users/:user_id',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },

  destroy: {
    url: 'users/:user_id',
    method: 'DELETE',
  },

  create_without_password: {
    url: 'users/create_without_password',
    method: 'PUT',
  },
};


const respondents = {
  sections: {
    current_section: {
      url: 'respondents/sections/current',
      method: 'GET',
      requiredParams: ['enrollment_id'],
    },

    previous_section: {
      url: 'respondents/sections/previous',
      method: 'GET',
      requiredParams: ['enrollment_id'],
    },

    next_section: {
      url: 'respondents/sections/next',
      method: 'GET',
      requiredParams: ['enrollment_id'],
    },
  },

  reports: {
    vyl_report: {
      url: 'respondents/enrollments/:enrollment_id/vyl_report',
      method: 'GET',
    },
  },

  questionnaires: {
    roles: {
      url: 'respondents/questionnaires/roles',
      method: 'GET',
    },
  },

  enrollments: {
    index: {
      url: 'respondents/enrollments',
      method: 'GET',
    },

    show: {
      url: 'respondents/enrollments/:id',
      method: 'GET',
    },

    create: {
      url: 'respondents/enrollments',
      method: 'POST',
    },

    finish: {
      url: 'respondents/enrollments/:id/finish',
      method: 'PUT',
    },

    vyl_report: {
      url: 'respondents/enrollments/:id/vyl_report',
      method: 'GET',
    },

    update: {
      url: 'respondents/enrollments/:id/',
      method: 'PUT',
    },
  },

  companies: {
    index: {
      url: 'respondents/companies',
      method: 'GET',
    },

    show: {
      url: 'respondents/companies/:id',
      method: 'GET',
    },
  },

  answers: {
    create: {
      url: 'respondents/answers',
      method: 'POST',
    },

    update: {
      url: 'respondents/answers/:id',
      method: 'PUT',
    },

    create_or_update: {
      url: 'respondents/answers/create_or_update',
      method: 'POST',
    },
  },

  applications_questionnaires: {
    status: {
      url: 'respondents/applications_questionnaires/status_by_token',
      method: 'GET',
    },
  },

  applications: {
    index: {
      url: 'respondents/applications',
      method: 'GET',
    },

    show: {
      url: 'respondents/applications/:id',
      method: 'GET',
    },

    show_by_token: {
      url: 'respondents/applications/show_by_token',
      method: 'GET',
      requiredParams: ['token'],
    },
  },
};

const managers = {
  users: {
    index: {
      url: 'managers/users',
      method: 'GET',
      requiredParams: ['company_id'],
    },

    show: {
      url: 'managers/users/:id',
      method: 'GET',
      requiredParams: ['company_id'],
    },

    valid_email: {
      url: 'managers/users/valid_email',
      method: 'GET',
      requiredParams: ['email'],
    },
  },

  companies: {
    show: {
      url: 'managers/companies/:id',
      method: 'GET',
    },

    update: {
      url: 'managers/companies/:id',
      method: 'PUT',
    },

    indicators: {
      url: 'managers/companies/indicators',
      method: 'GET',
    },
  },

  companies_users: {
    index: {
      url: 'managers/companies_users',
      method: 'GET',
    },

    create: {
      url: 'managers/companies_users',
      method: 'POST',
      requiredParams: ['company_id'],
    },

    show: {
      url: 'managers/companies_users/:id',
      method: 'GET',
    },

    update: {
      url: 'managers/companies_users/:id',
      method: 'PUT',
    },

    destroy: {
      url: 'managers/companies_users/:id',
      method: 'DELETE',
    },
  },

  respondents: {
    index: {
      url: 'managers/respondents',
      method: 'GET',
      requiredParams: ['company_id'],
    },
    update: {
      url: 'managers/respondents/:respondent_id',
      method: 'PUT',
      requiredParams: ['company_id'],
    },
    create: {
      url: 'managers/respondents',
      method: 'POST',
      requiredParams: ['company_id'],
    },
    destroy: {
      url: 'managers/respondents/:respondent_id',
      method: 'DELETE',
      requiredParams: ['company_id'],
    },
  },

  applications: {
    index: {
      url: 'managers/applications',
      method: 'GET',
      requiredParams: ['company_id'],
    },

    indexGroups: {
      url: 'managers/applications/index_individual_applications_groups',
      method: 'GET',
      requiredParams: ['company_id'],
    },

    show: {
      url: 'managers/applications/:id',
      method: 'GET',
      requiredParams: ['company_id'],
    },

    release_results: {
      url: 'managers/applications/:application_id/release_results',
      method: 'PUT',
    },

    update: {
      url: 'managers/applications/:application_id',
      method: 'PUT',
    },

    pause: {
      url: 'managers/applications/:application_id/pause',
      method: 'GET',
    },

    stop: {
      url: 'managers/applications/:application_id/stop',
      method: 'GET',
    },

    play: {
      url: 'managers/applications/:application_id/play',
      method: 'GET',
    },

    launch: {
      url: 'managers/applications/:application_id/launch',
      method: 'GET',
    },

    assess_user: {
      url: 'managers/applications/:application_id/assess_user',
      method: 'PUT',
    },

    send_reminder: {
      url: 'managers/applications/:application_id/send_reminder',
      method: 'PUT',
    },

    multievaluators_report: {
      url: 'managers/applications/:application_id/multievaluators_report',
      method: 'GET',
    },

    // cba_essential: {
    //   url: 'managers/applications/:application_id/cba_essential_report',
    //   method: 'GET',
    // },

    // cba_full: {
    //   url: 'managers/applications/:application_id/cba_full_report',
    //   method: 'GET',
    // },

    cba_one_page: {
      url: 'managers/applications/:application_id/cba_one_page_report',
      method: 'GET',
    },

    cba_one_page_rating: {
      url: 'managers/applications/:application_id/cba_one_page_rating_report',
      method: 'GET',
    },

    cba_full: {
      url: 'managers/applications/cba_full_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },

    cba_essential: {
      url: 'managers/applications/cba_essential_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },

    cba_v2: {
      url: 'managers/applications/cbav2_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },

    cla_by_token: {
      url: 'managers/applications/cla_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },

    cba_one_page_print: {
      url: 'managers/applications/cba_one_page_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },

    cba_one_page_rating_print: {
      url: 'managers/applications/cba_one_page_rating_report',
      method: 'GET',
      requiredParams: [':report_token'],
    },
  },

  enrollments: {
    index: {
      url: 'managers/enrollments',
      method: 'GET',
    },

    show: {
      url: 'managers/enrollments/:id',
      method: 'GET',
    },

    create: {
      url: 'managers/enrollments',
      method: 'POST',
    },

    vyl_report: {
      url: 'managers/enrollments/:id/vyl_report',
      method: 'GET',
    },
  },

  individual_application_collection: {
    index: {
      url: 'managers/individual_applications_collections',
      method: 'GET',
      requiredParams: [':company_id'],
    },

    get: {
      url: 'managers/individual_applications_collections/:individual_application_collection_id',
      method: 'GET',
    },

    emails: {
      url: 'managers/individual_applications_collections/:individual_application_id',
      method: 'GET',
    },

    getByToken: {
      url: 'managers/individual_applications_collections/show_group',
      method: 'GET',
    },

    update: {
      url: 'managers/individual_applications_collections/:individual_applications_collections/update_application_data',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    play: {
      url: 'managers/individual_applications_collections/play_group',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    pause: {
      url: 'managers/individual_applications_collections/pause_group',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    stop: {
      url: 'managers/individual_applications_collections/stop_group',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    launch: {
      url: 'managers/individual_applications_collections/launch_group',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    finish: {
      url: 'managers/individual_applications_collections/:individual_applications_collections/finish_applications_configuration',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    send_reminder: {
      url: 'managers/individual_applications_collections/send_reminder',
      method: 'PUT',
      requiredParams: [':collection_token'],
    },

    multievaluators_report: {
      url: 'managers/individual_applications_collections/multievaluators_report',
      method: 'GET',
      requiredParams: [':collection_token'],
    },
  },

  reports: {
    vyl_manager: {
      url: 'managers/applications/:application_id/vyl_report',
      method: 'GET',
    },

    vyl_manager_by_user: {
      url: 'managers/enrollments/:enrollment_id/vyl_report',
      method: 'GET',
    },
  },

  applicationsQuestionnaires: {
    show: {
      url: 'managers/applications_questionnaires/:id',
      method: 'GET',
    },

    update: {
      url: 'managers/applications_questionnaires/:id',
      method: 'PUT',
    },

    reminder: {
      url: 'managers/applications_questionnaires/:id/send_reminder',
      method: 'PUT',
    },
  },

  questionnaires: {
    index: {
      url: 'managers/questionnaires',
      method: 'GET',
    },

    show: {
      url: 'managers/questionnaires/:id',
      method: 'GET',
    },

    roles: {
      url: 'managers/questionnaires/roles',
      method: 'GET',
    },
  },

  sections: {
    current_section: {
      url: 'managers/sections/current',
      method: 'GET',
      requiredParams: ['application_questionnaire_id'],
    },

    previous_section: {
      url: 'managers/sections/previous',
      method: 'GET',
      requiredParams: ['current_section_id', 'current_page'],
    },

    next_section: {
      url: 'managers/sections/next',
      method: 'GET',
      requiredParams: ['current_section_id', 'current_page'],
    },
  },
};

const assessments = {
  index: {
    url: 'assessments',
    method: 'GET',
  },

  companies: {
    url: 'assessments/companies',
    method: 'GET',
  },
};

const messages = {
  create: {
    url: 'messages',
    method: 'POST',
  },
};

const reports = {
  vyl: {
    url: 'questionnaires/:questionnaire_id/vyl_report',
    method: 'GET',
  },

  vyl_by_user: {
    url: 'questionnaires/:questionnaire_id/vyl_report_by_user',
    method: 'GET',
  },

  vyl_manager: {
    url: 'managers/applications/:application_id/vyl_report',
    method: 'GET',
  },

  vyl_manager_by_user: {
    url: 'managers/applications/:application_id/vyl_report_by_user',
    method: 'GET',
  },

  vyl_application_questionnaire: {
    url: 'managers/applications_questionnaires/:application_questionnaire_id/vyl_report',
    method: 'GET',
  },
};

const selectors = {
  list: {
    url: 'users/profile_selectors_list?locale=:locale',
    method: 'GET',
  },
};

export const endpoints = {
  auth,
  s3,
  user,
  users: user,
  assessments,
  messages,
  reports,
  selectors,

  managers,
  respondents,
};

export default { API_HOST, endpoints };
