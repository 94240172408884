export default {
  REDUX_NAMESPACE: 'HUMANIZADAS/APPLICATION',
  RESET_STORE: 'APPLICATION/RESET_STORE',

  RESET_APPLICATION_LOADING: 'APPLICATION/RESET_APPLICATION_LOADING',

  TOGGLE_LOADING: 'APPLICATION/TOGGLE_LOADING',
  HIDE_LOADING: 'APPLICATION/HIDE_LOADING',

  TOGGLE_DIALOG: 'APPLICATION/TOGGLE_DIALOG',
  HIDE_DIALOG: 'APPLICATION/HIDE_DIALOG',

  TOGGLE_ERROR_DIALOG: 'APPLICATION/TOGGLE_ERROR_DIALOG',
  HIDE_ERROR_DIALOG: 'APPLICATION/HIDE_ERROR_DIALOG',

  SET_VIEW_MODE: 'APPLICATION/SET_VIEW_MODE',

  ENABLE_ALL_COMPANIES_VIEW: 'APPLICATION/ENABLE_ALL_COMPANIES_VIEW',
  DISABLE_ALL_COMPANIES_VIEW: 'APPLICATION/DISABLE_ALL_COMPANIES_VIEW',

  SAVE_APPLICATION: 'APPLICATION/SAVE_APPLICATION',

  DISABLE_PERSIST_USER: 'DISABLE_PERSIST_USER',

  modes: {
    respondent: 'respondent',
    manager: 'manager',
  },
};
