/* eslint-disable no-console */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import store from '../redux/store';
import { applicationActions, authActions } from '../redux/actions';

import API from './API';

const AxiosToken = {};

const APIRedux = ({
  options = {},
  apiOptions = {},
} = {}) => {
  const apiInstance = API(apiOptions);

  const defaultArgs = {
    toggleDialog: true,
    toggleLoading: true,
    shouldLogout: true,
    ...options,
  };

  apiInstance.interceptors.request.use(
    (instanceConfig) => {
      if (!['production', 'staging', 'test'].includes(process.env.REACT_APP_ENV)) {
        console.log('-----');
        console.log('--- MAKING REQUEST ---');
        console.log('REQUEST HEADERS:', instanceConfig.headers);
        console.log('METHOD', instanceConfig.method);
        console.log('REQUEST URL:', instanceConfig.url);
        console.log('REQUEST DATA:', instanceConfig.data);
        console.log('-----');
      }

      // caso exista uma chamada em andamento será cancelada
      if (AxiosToken.source) {
        AxiosToken.source.cancel('aborted-call');
        AxiosToken.source = null;
      }

      // só poderão ser canceladas chamadas com isCancelable
      if (apiOptions.isCancelable) {
        AxiosToken.source = axios.CancelToken.source();
      }

      if (defaultArgs.toggleLoading) {
        store.dispatch(applicationActions.toggleLoading());
      }

      return { ...instanceConfig, ...AxiosToken.source && { cancelToken: AxiosToken.source.token } };
    },

    (error) => {
      if (defaultArgs.toggleLoading) {
        store.dispatch(applicationActions.hideLoading());
      }

      if (defaultArgs.toggleDialog) {
        store.dispatch(applicationActions.toggleErrorDialog({
          title: 'Erro!',
          body: error.message,
          variant: 'danger',
          boxProps: { p: '24px' },
          button: {
            text: <FormattedMessage id='words.close' />,
          },
        }));
      }

      return Promise.reject(error);
    },
  );

  apiInstance.interceptors.response.use(
    (response) => {
      if (!['production', 'staging', 'test'].includes(process.env.REACT_APP_ENV)) {
        console.log('-----');
        console.log('--- SUCCESS RESPONSE ---');
        console.log('RESPONSE DATA:', response.data);
        console.log('-----');
      }

      // caso tenha resposta essa chamada não pode ser cancelada
      AxiosToken.source = null;

      store.dispatch(applicationActions.hideLoading());
      return response.data;
    },

    (err) => {
      const { response } = err;
      let { code, message } = err;

      if (response) {
        code = response.status;
        message = response.data.errors ? response.data.errors[0] : message;
      }

      const isAbortedCallError = err.message === 'aborted-call';

      const error = { code, message };

      if (!isAbortedCallError && defaultArgs.shouldLogout && error.code === 401) {
        store.dispatch(authActions.logoutRequest());
      }

      if (defaultArgs.toggleLoading) {
        store.dispatch(applicationActions.hideLoading());
      }

      if (!isAbortedCallError && defaultArgs.toggleDialog) {
        store.dispatch(applicationActions.toggleErrorDialog({
          title: 'Erro!',
          body: error.message,
          variant: 'danger',
          boxProps: { p: '24px' },
          button: {
            text: <FormattedMessage id='words.close' />,
          },
        }));
      }

      return Promise.reject(error);
    },
  );

  return apiInstance;
};

export default APIRedux;
