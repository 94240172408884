const display = {
  fontSize: '14mm',
  fontWeight: 800,
  lineHeight: '18mm',
  tag: 'h2',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const lightDisplay = {
  fontSize: '14mm',
  fontWeight: 300,
  lineHeight: '18mm',
  tag: 'h2',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h1 = {
  fontSize: '10mm',
  fontWeight: 700,
  lineHeight: '12mm',
  tag: 'h1',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h2 = {
  fontSize: '9mm',
  fontWeight: 800,
  lineHeight: '12mm',
  tag: 'h2',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h3 = {
  fontSize: '6mm',
  fontWeight: 800,
  lineHeight: '8mm',
  tag: 'h3',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h4 = {
  fontSize: '5mm',
  fontWeight: 800,
  lineHeight: '6mm',
  tag: 'h4',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h5 = {
  fontSize: '4mm',
  fontWeight: 800,
  lineHeight: '5mm',
  tag: 'h5',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h5Half = {
  fontSize: '3.5mm',
  fontWeight: 800,
  lineHeight: '4mm',
  tag: 'h5',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const h6 = {
  fontSize: '3mm',
  fontWeight: 700,
  lineHeight: '5mm',
  tag: 'h6',
  color: 'darkblue100',
  fontFamily: 'Bitter',
};

const ultraLarge = {
  fontSize: '12mm',
  lineHeight: '10mm',
  fontWeight: 900,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const large = {
  fontSize: '8mm',
  lineHeight: '10mm',
  fontWeight: 900,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const lightLarge = {
  fontSize: '8mm',
  lineHeight: '10mm',
  fontWeight: 300,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const title = {
  fontSize: '5mm',
  lineHeight: '6mm',
  fontWeight: 700,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const lightTitle = {
  fontSize: '5mm',
  lineHeight: '6mm',
  fontWeight: 300,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const subtitle = {
  fontSize: '4mm',
  lineHeight: '6mm',
  fontWeight: 700,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const lightSubtitle = {
  fontSize: '4mm',
  lineHeight: '6mm',
  fontWeight: 300,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const chartTitle = {
  fontSize: '4mm',
  lineHeight: '4mm',
  fontWeight: 700,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const chartSubtitle = {
  fontSize: '4mm',
  lineHeight: '4mm',
  fontWeight: 400,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const chartSmallBold = {
  fontSize: '3mm',
  lineHeight: '4mm',
  fontWeight: 700,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const chartSmall = {
  fontSize: '3mm',
  lineHeight: '4mm',
  fontWeight: 400,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const mediumText = {
  fontSize: '3.5mm',
  lineHeight: '4mm',
  fontWeight: 400,
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const body = {
  fontSize: '3.5mm',
  lineHeight: '6mm',
  fontWeight: 400,
  tag: 'p',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const button = {
  fontSize: '3mm',
  lineHeight: '6mm',
  fontWeight: 900,
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const helper = {
  fontSize: '3mm',
  lineHeight: '4mm',
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const small = {
  fontSize: '3mm',
  lineHeight: '6mm',
  fontWeight: 400,
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const xSmall = {
  fontSize: '2.5mm',
  lineHeight: '3mm',
  fontWeight: 400,
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const buttonSM = {
  fontSize: '3mm',
  lineHeight: '4mm',
  fontWeight: 'bold',
  tag: 'span',
  color: 'darkblue100',
};

const caps = {
  fontSize: '4mm',
  lineHeight: '4mm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const capsSM = {
  fontSize: '3mm',
  lineHeight: '4mm',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: '0.01em',
  tag: 'span',
  color: 'darkblue100',
  fontFamily: 'Lato',
};

const typography = {
  display,
  lightDisplay,
  h1,
  h2,
  h3,
  h4,

  h5Half,
  h5,

  h6,
  H1: h1,
  H2: h2,
  H3: h3,
  H4: h4,
  H5: h5,
  H6: h6,

  ultraLarge,
  large,
  lightLarge,

  title,
  lightTitle,

  subtitle,
  lightSubtitle,

  chartTitle,
  chartSubtitle,
  chartSmallBold,
  chartSmall,

  mediumText,

  body,
  helper,
  button,
  buttonSM,

  caps,
  capsSM,
  capssm: capsSM,
  label: {
    ...button,
    tag: 'label',
  },

  small,
  xSmall,

};

export default typography;
