import authSelectors from './auth/auth.selectors';
import applicationSelectors from './application/application.selectors';
import cachesSelectors from './caches/caches.selectors';
import persistentCacheSelectors from './persistent.caches/caches.selectors';
import userSelectors from './user/user.selectors';

export { authSelectors };
export { applicationSelectors };
export { userSelectors };
export { cachesSelectors };
export { cachesSelectors as cacheSelectors };
export { persistentCacheSelectors };

const selectors = {
  auth: authSelectors,
  user: userSelectors,
  caches: cachesSelectors,
  cache: cachesSelectors,
  persistentCache: persistentCacheSelectors,
};

export default selectors;
