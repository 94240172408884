import React from 'react';
import { put } from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';

import { authActions, applicationActions } from './actions';
import { noop } from '../utils';

export function* handleError(err, actionCreator, options = {}) {
  const { response } = err;
  let { code, message } = err;

  const {
    displayDialog = true,
  } = options;

  if (response) {
    code = response.status;
    message = response.data.errors ? response.data.errors[0] : message;
  }

  const error = { code, message };

  if (error.code === 401) {
    yield put(authActions.logoutRequest());
  }

  if (actionCreator) {
    yield put(actionCreator({ error }));
  }

  if (displayDialog) {
    yield put(applicationActions.toggleErrorDialog({
      title: 'Erro!',
      body: error.message,
      variant: 'danger',
      boxProps: { p: '24px' },
      button: {
        text: <FormattedMessage id='words.close' />,
      },
    }));
  }
}

export function* sagaFunctionBuilder(payload, callback, catchCallback) {
  const {
    resolve = noop,
    reject = noop,
    disableLoading = false,
    disableErrorDialog = false,
  } = (payload || {});

  try {
    if (!disableLoading) { yield put(applicationActions.toggleLoading()); }
    const data = yield callback();
    resolve(data);
  }

  catch (err) {
    if (!disableErrorDialog) { yield handleError(err, catchCallback, payload.options); }
    reject(err);
  }

  finally {
    yield put(applicationActions.hideLoading());
  }
}

export default {
  handleError,
  sagaFunctionBuilder,
};
