import c from './auth.constants';
import { userConstants } from '../constants';

const resetStore = () => ({
  type: c.RESET_STORE,
});

const authenticateRequest = ({
  email,
  password,
  params = userConstants.params.login,
  ...rest
}) => ({
  type: c.AUTHENTICATE_REQUEST,
  payload: {
    email,
    password,
    params,
    ...rest,
  },
});

const updateUnauthenticatedUserRequest = ({
  user,
  headers,
  params = userConstants.params.login,
  ...rest
}) => ({
  type: c.UPDATE_UNAUTHENTICATED_USER,
  payload: {
    user,
    headers,
    params,
    ...rest,
  },
});

const logoutRequest = () => ({
  type: c.LOGOUT_REQUEST,
});

const restore = () => ({
  type: c.RESTORE,
});


export default {
  resetStore,
  authenticateRequest,
  logoutRequest,
  updateUnauthenticatedUserRequest,
  restore,
};
