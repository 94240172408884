import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ApiProvider } from 'react-use-api';

import '@fontsource/lato';
import '@fontsource/bitter';
import 'react-toggle/style.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

// import App from './pages/App';
import initializers from './initializers/initializers';
import store from './redux/store';

import theme from './config/theme/theme';
import TranslationMiddleWare from './pages/TranslationMiddleware';
import { LayoutProvider } from './containers/Layouts/LayoutContext';

const GlobalStyle = createGlobalStyle`
  html, body {
    min-height: 100% !important;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  #root {
    position: relative;
    width: 100%;
    min-height: 100%;
    /* height: 100%; */
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;

    &.underline {
      text-decoration: underline;
    }
  }

  .MuiGrid-root {
    position: relative;
  }

  .pdf {
    width: 1000px;
    height: 1416px;
    background-color: white;
    margin: 0 auto;
  }

/* 
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track 
  ::-webkit-scrollbar-track {
    background-color: ${theme.colors.grey10};
  }
  
  /* Handle 
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.grey6}; 
  } */
`;

initializers();

ReactDOM.render((
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LayoutProvider>
          <Router>
            <ApiProvider>
              <TranslationMiddleWare />
            </ApiProvider>
          </Router>
        </LayoutProvider>
      </Provider>
    </ThemeProvider>
  </>
),

document.getElementById('root'));
