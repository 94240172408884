import { createSelector } from 'reselect';
import constants from './application.constants';

const getStore = (store) => store[constants.REDUX_NAMESPACE];

const dialog = (store) => getStore(store).dialog;

const errorDialog = (store) => getStore(store).errorDialog;

const loading = (store) => getStore(store).loading;

const viewModeSelector = (store) => getStore(store).viewMode;

const isAllCompaniesViewSelector = (store) => getStore(store).isAllCompaniesView;

const currentApplication = (store) => getStore(store).application;

const shouldPersistUser = (store) => getStore(store).persistUser;

const isManagerViewSelector = createSelector(
  viewModeSelector,
  (viewMode) => viewMode === constants.modes.manager,
);

export default {
  getStore,
  dialog,
  loading,
  errorDialog,
  viewMode: viewModeSelector,
  isManagerView: isManagerViewSelector,
  isAllCompaniesView: isAllCompaniesViewSelector,
  currentApplication,
  shouldPersistUser,
};
