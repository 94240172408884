import axios from 'axios';
import { API_HOST } from '../config/endpoints';
import { authConstants } from '../redux/constants';

const API = ({
  config = {},
  options = {},
} = {
  config: {},
  options: {},
}) => {
  const axiosConfig = {
    method: config.method || 'GET',
    baseURL: config.baseURL || API_HOST,
    headers: config.headers || {},
    ...config,
  };

  const {
    noApiV1 = false,
  } = options;

  const accessHeaders = JSON.parse(localStorage.getItem(authConstants.ACCESS_HEADERS));

  if (noApiV1) {
    axiosConfig.baseURL = axiosConfig.baseURL.replace('api/v1', '');
  }

  if (accessHeaders && !config.headers && !options.disableHeaders) {
    axiosConfig.headers['Access-Token'] = accessHeaders.token;
    axiosConfig.headers.Client = accessHeaders.client;
    axiosConfig.headers.Uid = accessHeaders.uid;
    axiosConfig.headers.Expiry = accessHeaders.expiry;
    axiosConfig.headers['Token-Type'] = accessHeaders.tokenType;
  }

  return axios.create(axiosConfig);
};

export default API;
