import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import PrivateRoute from '../components/Routes/PrivateRoute';
import Dialog from '../components/Dialogs/Dialog';
import Loading from '../components/Loading/Loading';

import { applicationActions, authActions } from '../redux/actions';
import { applicationSelectors } from '../redux/selectors';

import { useRoutes } from '../hooks';
import { assessmentRoleTypes } from '../config/maps';
import { showApplicationByToken } from '../services/apis/respondents/applications.respondents.api';

const AppDemos = React.lazy(() => import('./demos/AppDemos'));

const RegisterRoutes = React.lazy(() => import('./Register/RegisterRoutes'));
const HomeRoutes = React.lazy(() => import('./Home/HomeRoutes'));
const PublicLinkRoutes = React.lazy(() => import('./PublicRoutes/PublicLinkRoutes'));
const MessagesRoutes = React.lazy(() => import('./Messages/MessagesRoutes'));

// Esses formulários precisam estar na raíz, pois são redirects do backend e rotas lá estão em .env
// e por consequencia, não localizadas
const LayoutWithHeader = React.lazy(() => import('../containers/Layouts/LayoutWithHeader'));
const FormCard = React.lazy(() => import('../components/Cards/FormCard'));
const Box = React.lazy(() => import('../components/Styled/Box'));
const PasswordRecoveryResetPasswordForm = React.lazy(() => import('../containers/Forms/Register/PasswordRecovery__ResetPasswordForm'));
const SignUpFromEmailForm = React.lazy(() => import('../containers/Forms/Register/SignUpFromEmailForm'));
const ReportPage = React.lazy(() => import('./Reports/ReportPage'));
const ReportCbaOnePage = React.lazy(() => import('./Reports/ReportCbaOnePage'));

const ReportCbaFullPage = React.lazy(() => import('./Reports/ReportCbaFullPage'));
const ReportCbaEssentialPage = React.lazy(() => import('./Reports/ReportCbaEssentialPage'));
const ReportCBA = React.lazy(() => import('./Reports/ReportCbaV2'));
const ReportCLA = React.lazy(() => import('./Reports/ReportCla'));

const ErrorPage404 = React.lazy(() => import('./Messages/ErrorPage404'));

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const routes = useRoutes();
  const { formatMessage: f } = useIntl();
  const hubspotScriptLink = React.useMemo(() => process.env.REACT_APP_HUBSPOT_SCRIPT, []);

  const [preventRender, setPreventRender] = useState(true);
  const [isPublicAssessment, setIsPublicAssessment] = useState(false);

  const dialogState = useSelector(applicationSelectors.dialog);
  const appIsLoading = useSelector(applicationSelectors.loading);
  const errorDialogState = useSelector(applicationSelectors.errorDialog);

  const queryParams = queryString.parse(location.search);

  const { token, aq_tkn } = queryParams;

  const getUrlParamsQuestionnaire = React.useCallback(() => {
    const urlAssessmentType = { ind: 'individual', org: 'organization' };
    const paramsArray = location.pathname.split('/') || [];
    return {
      roleType: assessmentRoleTypes[paramsArray[1]] || null,
      assessmentType: urlAssessmentType[paramsArray[2]] || null,
    };
  }, [location.pathname]);

  // Configura script do hubspot
  useEffect(() => {
    const path = location.pathname.split('/')[1];

    const isPdfPage = [
      routes.reportCbaOnePage.replace('/:token', ''),
      routes.reportCbaOnePageRating.replace('/:token', ''),
      routes.reportCbaFull.replace('/:token', ''),
      routes.reportCbaEssential.replace('/:token', ''),
      routes.reportCbaV2,
      routes.reportCla,
    ].includes(`/${path}`);

    if (!isPdfPage) {
      const script = document.createElement('script');

      script.src = hubspotScriptLink; // referente à conta
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);
    }
  }, [hubspotScriptLink, location.pathname, routes]);

  // Busca dados de uma aplicação se houver um token no link (em casos de convite de usuário por link)
  useEffect(() => {
    const { roleType, assessmentType } = getUrlParamsQuestionnaire();

    const getApplicationData = async () => {
      try {
        const { data } = await (showApplicationByToken({
          applicationToken: token,
          assessmentType,
          roleType,
          apiArgs: { options: { toggleDialog: false } },
        }));

        const notYetReleased = ['no_date_yet', 'created', 'paused'].includes(data.status);
        const isExpiredQuestionnaire = ['ended', 'not_answered', 'results_available'].includes(data.status);

        if (notYetReleased) {
          history.push(routes.messages.notStarted);
        }

        else if (isExpiredQuestionnaire) {
          history.push(routes.messages.expired);
        }

        else {
          dispatch(applicationActions.saveApplication({
            ...data,
            application_token: token,
          }));
          setIsPublicAssessment(data?.open_link);
        }
      }

      catch (err) {
        const errorPage = routes.messages[`error${err.code}`] || routes.messages.error500;
        history.push(errorPage);
      }

      finally {
        setPreventRender(false);
      }
    };

    // busca questionários de organização e individuo
    if (token && (roleType === 'internal' || roleType === 'external')) {
      getApplicationData();
    }

    // somente questionários de autoavaliação
    else if (aq_tkn && (roleType === 'self' && assessmentType === 'individual')) {
      dispatch(applicationActions.saveApplication({
        assessment_type: assessmentType,
        role_type: roleType,
        open_link: false,
        aq_tkn,
      }));
      setPreventRender(false);
    }

    else {
      setPreventRender(false);
    }
  }, [token, aq_tkn, dispatch, history, routes.messages, getUrlParamsQuestionnaire]);

  React.useEffect(() => {
    dispatch(authActions.restore());
  }, [dispatch]);

  return !preventRender && (
    <>
      <Loading visible={appIsLoading} />

      <Dialog
        isVisible={dialogState.visible}
        onClose={() => dispatch(applicationActions.hideDialog())}
        {...dialogState}
      />

      <Dialog
        variant='danger'
        isVisible={errorDialogState.visible}
        onClose={() => dispatch(applicationActions.hideErrorDialog())}
        {...errorDialogState}
      />

      <Suspense fallback={<Loading visible />}>
        <Switch>
          <Route
            path={routes.demos}
            component={AppDemos}
          />

          <Route
            path={routes.public.root}
            component={PublicLinkRoutes}
          />

          <Route
            path={routes.messages.root}
            component={MessagesRoutes}
          />

          <Route
            path={routes.start}
            component={RegisterRoutes}
          />

          <PrivateRoute
            routes={routes}
            path={routes.home}
            component={HomeRoutes}
          />

          {isPublicAssessment && <Redirect to={routes.public.root} />}

          <Route
            exact
            path={routes.resetPassword}
            render={(routerProps) => (
              <LayoutWithHeader>
                <PasswordRecoveryResetPasswordForm {...routerProps} />
              </LayoutWithHeader>
            )}
          />

          <Route
            exact
            path={routes.publicReport}
            render={(routerProps) => (
              <LayoutWithHeader>
                <ReportPage {...routerProps} />
              </LayoutWithHeader>
            )}
          />

          <Route
            exact
            path={routes.reportCbaOnePage}
            render={(routerProps) => (
              <ReportCbaOnePage isPrint {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.reportCbaOnePageRating}
            render={(routerProps) => (
              <ReportCbaOnePage isRating isPrint {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.reportCbaFull}
            render={(routerProps) => (
              <ReportCbaFullPage {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.reportCbaEssential}
            render={(routerProps) => (
              <ReportCbaEssentialPage {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.reportCbaV2}
            render={(routerProps) => (
              <ReportCBA {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.reportCla}
            render={(routerProps) => (
              <ReportCLA {...routerProps} />
            )}
          />

          <Route
            exact
            path={routes.signUpFromEmail}
            render={(routerProps) => (
              <LayoutWithHeader>
                <Box py='108px' display='flex' justifyContent='center'>
                  <FormCard
                    title={f({ id: 'pages.signup.title' })}
                    subtitle={f({ id: 'pages.signup.subtitle' })}
                  >
                    <SignUpFromEmailForm {...routerProps} />
                  </FormCard>
                </Box>
              </LayoutWithHeader>
            )}
          />

          <Redirect
            exact
            from='/'
            push
            to={`${routes.home}${location.search}`}
          />
          <Route exact component={ErrorPage404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default App;
