import { useEffect, useContext, useCallback } from 'react';

import { LayoutContext } from '../containers/Layouts/LayoutContext';

const useCustomLayout = ({
  graphism = true,
  color = false,
  image = '',
  hubSpotCss = '',
}) => {
  const { updateValues, resetValues } = useContext(LayoutContext);

  const setLayout = useCallback(() => {
    updateValues({
      graphism,
      color,
      image,
      hubSpotCss,
    });
  },
  [
    color,
    graphism,
    image,
    hubSpotCss,
    updateValues,
  ]);

  useEffect(() => {
    setLayout();
    return () => resetValues();
  }, [resetValues, setLayout]);
};

export default useCustomLayout;
