const displayBig = {
  fontSize: 64,
  fontWeight: 700,
  tag: 'h1',
  fontFamily: 'Bitter',
};

const display = {
  fontSize: 48,
  fontWeight: 700,
  tag: 'h1',
  fontFamily: 'Bitter',
};

const h1 = {
  fontSize: 40,
  fontWeight: 700,
  tag: 'h1',
  fontFamily: 'Bitter',
};

const h2 = {
  fontSize: 32,
  fontWeight: 700,
  tag: 'h2',
  fontFamily: 'Bitter',
};

const h3 = {
  fontSize: 28,
  fontWeight: 700,
  tag: 'h3',
  fontFamily: 'Bitter',
};

const h4 = {
  fontSize: 24,
  fontWeight: 700,
  tag: 'h4',
  fontFamily: 'Bitter',
};

const h5 = {
  fontSize: 20,
  fontWeight: 700,
  tag: 'h5',
  fontFamily: 'Bitter',
};

const h6 = {
  fontSize: 18,
  fontWeight: 700,
  tag: 'h6',
  fontFamily: 'Bitter',
};

const h7 = {
  fontSize: 16,
  fontWeight: 700,
  tag: 'p',
  fontFamily: 'Bitter',
};

const body = {
  fontSize: 16,
  fontWeight: 400,
  tag: 'p',
  color: 'coldgrey600',
  fontFamily: 'Lato',
};

const helper = {
  fontSize: 14,
  fontWeight: 400,
  tag: 'span',
  fontFamily: 'Lato',
};

const button = {
  fontSize: 16,
  fontWeight: 700,
  tag: 'span',
  fontFamily: 'Lato',
};

const buttonSM = {
  fontSize: 14,
  fontWeight: 700,
  tag: 'span',
  fontFamily: 'Lato',
};

const caps = {
  fontSize: 14,
  fontWeight: 400,
  textTransform: 'uppercase',
  letterSpacing: '0.16em',
  tag: 'span',
  fontFamily: 'Lato',
};

const capsSM = {
  fontSize: 12,
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: '0.8px',
  tag: 'span',
  fontFamily: 'Lato',
};

const verySmall = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '100%',
  tag: 'span',
  fontFamily: 'Lato',
};

const legend = {
  fontSize: 9,
  fontWeight: 400,
  lineHeight: '120%',
  tag: 'span',
  fontFamily: 'Lato',
};

const typography = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  H1: h1,
  H2: h2,
  H3: h3,
  H4: h4,
  H5: h5,
  H6: h6,
  H7: h7,
  body,
  helper,
  button,
  legend,
  buttonSM,
  buttonsm: buttonSM,
  caps,
  capsSM,
  capssm: capsSM,
  label: {
    ...button,
    tag: 'label',
  },
  verySmall,
  display,
  displayBig,
};

export default typography;
